import React, { useState, useEffect } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import PageNav from "./CounsellorSidebar";

import { BsFillTelephoneFill } from 'react-icons/bs';
import { BsChatLeftFill } from 'react-icons/bs';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdWork, MdSchool, MdStar } from 'react-icons/md';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';

import INSTITUTEAPI from '../http/institute';
import Sidebar from './Sidebar';

/* timeline */
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';

/* timeline 2 */
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Divider } from '@mui/material';

import LEADAPI from '../http/lead';
/*
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
*/

const TAG = 'LeadDetails2.jsx';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


/*
const VertTimeline = (props) => {
    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date="2011 - present"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Creative Director</h3>
                <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
                <p>
                    Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2010 - 2011"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Art Director</h3>
                <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                <p>
                    Creative Direction, User Experience, Visual Design, SEO, Online Marketing
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2008 - 2010"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Web Designer</h3>
                <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                <p>
                    User Experience, Visual Design
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2006 - 2008"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<MdWork />}
            >
                <h3 className="vertical-timeline-element-title">Web Designer</h3>
                <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                <p>
                    User Experience, Visual Design
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="April 2013"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<MdSchool />}
            >
                <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
                <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
                <p>
                    Strategy, Social Media
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="November 2012"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<MdSchool />}
            >
                <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                <h4 className="vertical-timeline-element-subtitle">Certification</h4>
                <p>
                    Creative Direction, User Experience, Visual Design
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date="2002 - 2006"
                iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                icon={<MdSchool />}
            >
                <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                <p>
                    Creative Direction, Visual Design
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                icon={<MdStar />}
            />
        </VerticalTimeline>
    )
}
*/

const TIMELINE_ITEM = (props) => {
    const { item, index } = props;
    let time2 = moment(item.activity_time).format('MMM Do YYYY, h:mm a')
    let clr = '#e86971';
    let dateClr = '#FFF';

    if (((index + 1) % 2) == 0) {
        clr = '#61b8ff';
        dateClr = '#FFF'
    } else if (((index + 2) % 2) == 0) {
        clr = '#76bb7f';
        dateClr = '#000'
    } else if (((index + 3) % 2) == 0) {
        clr = '#e86971';
        dateClr = '#FFF'
    }

    console.log(TAG, 'TIMELINE_ITEM clr ' + clr);

    return (
        <TimelineItem
            key="001"
            dateText={time2}
            style={{ color: clr }}
            dateInnerStyle={{ background: 'transparent', color: '#000' }}
        // bodyContainerStyle={{
        //     background: '#ddd',
        //     // padding: '20px',
        //     borderRadius: '8px',
        //     boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
        // }}
        >
            <h5 style={{ color: clr }}>{item.page_feature}</h5>
            {/* <h6 style={{ color: clr }}>Subtitle</h6> */}
            <p>
                {item.activity_text}
            </p>
            {/* <p>
                Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                est.
            </p>
            <p>
                Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                est.
            </p> */}
        </TimelineItem>
    )
}

const VertTimeline2 = (props) => {
    const [timeline, setTimeline] = useState(null)
    const [followUp, setFollowUp] = useState(null)

    const search = useLocation().search;
    const id2 = new URLSearchParams(search).get('enquiry_id');

    const _loadTimeline = async () => {
        const resp = await LEADAPI._loadTimeline(id2);

        console.log(TAG, '_loadTimeline() resp: ' + JSON.stringify(resp));
        setTimeline(resp); // reload page
    }

    const _loadFollowup = async () => {

        const resp = await LEADAPI._loadFollowup(id2)
        console.log(TAG, '_loadFollowup() resp: ' + JSON.stringify(resp));
        setFollowUp(resp); // reload page
    }

    useEffect(() => {
        _loadTimeline();
        _loadFollowup();
    }, [])

    return (
        <div
            className='ms-5'>

            {(timeline && timeline.length) ?

                <Timeline lineColor={'#ddd'}
                    timeline={timeline}>
                    {
                        timeline.map((item, idx) => {
                            return (
                                <TIMELINE_ITEM
                                    key={idx}
                                    item={item}
                                    index={idx}
                                />
                            )
                        })
                    }
                </Timeline>
                :

                <p>No timeline!</p>
            }


            {/*
            <TimelineItem
                key="001"
                dateText="11/2010 – Present"
                style={{ color: '#e86971' }}
            >
                <h3>Title, Company</h3>
                <h4>Subtitle</h4>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
            </TimelineItem>
            <TimelineItem
                key="002"
                dateText="04/2009 – 11/2010"
                dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
                bodyContainerStyle={{
                    background: '#ddd',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                }}
            >
                <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
                <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
            </TimelineItem>
            <TimelineItem
                key="003"
                dateComponent={(
                    <div
                        style={{
                            display: 'block',
                            float: 'left',
                            padding: '10px',
                            background: 'rgb(150, 150, 150)',
                            color: '#fff',
                        }}
                    >
                        11/2008 – 04/2009
                    </div>
                )}
            >
                <h3>Title, Company</h3>
                <h4>Subtitle</h4>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
            </TimelineItem>
            <TimelineItem
                key="004"
                dateText="08/2008 – 11/2008"
                dateInnerStyle={{ background: '#76bb7f' }}
            >
                <h3>Title, Company</h3>
                <h4>Subtitle</h4>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
                <p>
                    Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
                    exercitation. Veniam velit adipisicing anim excepteur nostrud magna
                    nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
                    reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
                    est.
                </p>
            </TimelineItem>

            */}
        </div>
    )
}

const VertFollowup = (props) => {
    const [followUp, setFollowUp] = useState(null)

    const search = useLocation().search;
    const id2 = new URLSearchParams(search).get('enquiry_id');

    const _loadFollowup = async () => {

        const resp = await LEADAPI._loadFollowup(id2)
        console.log(TAG, '_loadFollowup() resp: ' + JSON.stringify(resp));
        setFollowUp(resp.data); // reload page
    }

    useEffect(() => {

        _loadFollowup();
    }, [])

    return (
        <div
            className='ms-5'>

            {(followUp && followUp.length) ?

                <Timeline lineColor={'#ddd'}
                    timeline={followUp}>
                    {
                        followUp.map((item, idx) => {
                            return (
                                <TIMELINE_ITEM
                                    key={idx}
                                    item={item}
                                    index={idx}
                                />
                            )
                        })
                    }
                </Timeline>
                :

                <p>No followup!</p>
            }

        </div>
    )
}


/*
const LEAD_DETAILS = (props) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    }

    return (

        <div className="page-wrapper" style={{
            backgroundColor: 'white',
            width: '1250px', height: '500px', position: 'relative', top: '-100px',
            left: '50px', borderRadius: '10px'
        }}>
            <div className="lead-top">
                <h3 style={{ color: '#23468c' }}>
                    <BsFillPersonFill />
                    Lead Details</h3>
                <div><BsThreeDotsVertical /></div>
            </div>
            <hr />
            <div>

                <div>
                    <table>
                        <thead>
                            <tr>

                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            <tr>
                                <td
                                    className={`tab ${activeTab === 0 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(0)}
                                >
                                    Timeline
                                </td>
                                <td
                                    className={`tab ${activeTab === 1 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Follow Up & Notes
                                </td>
                                <td
                                    className={`tab ${activeTab === 2 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(2)}
                                >
                                    Communication Logs
                                </td>
                                <td
                                    className={`tab ${activeTab === 3 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(3)}
                                >
                                    Document Locker
                                </td>
                                <td
                                    className={`tab ${activeTab === 4 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(4)}
                                >
                                    Tickets
                                </td>
                                <td
                                    className={`tab ${activeTab === 5 ? 'selected' : ''}`}
                                    onClick={() => handleTabClick(5)}
                                >
                                    Call Logs
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab-content">
                        {activeTab === -1 &&
                            <div>
                                <p>Form Interested In: <span>Application Form</span></p>
                                <p>Candidate's Email Address:<span>ajayk@gmail.com</span></p>
                                <p>State: <span>Telangana</span></p>
                                <p>City: <span>Hyderabad</span></p>
                                <p>Country: <span>India</span></p>
                                <p>Course: <span>10<sup>th</sup></span></p>
                                <p>Specilization : <span>Computers</span></p>


                            </div>
                        }
                        {activeTab === 0 &&
                            <VertTimeline2 />
                        }
                        {activeTab === 1 && <div>Follow Up & Notes</div>}
                        {activeTab === 2 && <div>Communication Logs</div>}
                        {activeTab === 3 && <div>Document Locker</div>}
                        {activeTab === 4 && <div>Tickets</div>}
                        {activeTab === 5 && <div>Call Logs</div>}
                    </div>
                </div>


            </div>
        </div>

    )
}
*/

/* updated lead details UI */
/*
const LEAD_DETAILS2 = () => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Tab 1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Tab 2</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            Tab 1
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            Tab 2
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}
*/

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            className='w-100'
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Timeline" {...a11yProps(0)} />
                <Tab label="Follow Up & Notes" {...a11yProps(1)} />
                {/* <Tab label="Communication Logs" {...a11yProps(2)} /> */}
                <Tab label="Document Locker" {...a11yProps(3)} />
                {/* <Tab label="Tickets" {...a11yProps(4)} />
                <Tab label="Call Logs" {...a11yProps(5)} /> */}
                {/* <Tab label="Item Seven" {...a11yProps(6)} /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
                <VertTimeline2 />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <VertFollowup />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                Communication Logs
            </TabPanel> */}
            <TabPanel value={value} index={3}>
                Document Locker
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
                Tickets
            </TabPanel>
            <TabPanel value={value} index={5}>
                Call Logs
            </TabPanel> */}
            {/* <TabPanel value={value} index={6}>
                Item Seven
            </TabPanel> */}
        </Box>
    );
}

const LeadDetails2 = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [isSchool, setIsSchool] = useState(true)
    const [lead, setLead] = useState(null);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    }

    const search = useLocation().search;
    const id2 = new URLSearchParams(search).get('enquiry_id');

    const _getLeadDetails = async (isSchool) => {
        let token2 = null;
        token2 = localStorage.getItem('applier.couns.token');
        if (isSchool) {
            token2 = localStorage.getItem('applier.inst.token');
        }

        const lead2 = await INSTITUTEAPI.getLeadDetails(id2, token2)
        setLead(lead2);
    }

    useEffect(() => {
        let isSchool2 = false;
        const role = localStorage.getItem('applier.role');
        console.log(TAG, 'useEffect(): role ' + role);
        // decide who has logged in..
        if (role == 'COUNSELOR') {
            setIsSchool(false);
            isSchool2 = false;
        } else {
            isSchool2 = true;
        }

        _getLeadDetails(isSchool2);

    }, [])

    if (!lead) {
        return (
            <p>Unauthorized!</p>
        )
    }

    let unVerifiedStatus = 'step ';
    let verifiedStatus = 'step ';
    let applStartStatus = 'step ';
    let payStatus = 'step ';
    let enrollStatus = 'step ';

    console.log(TAG, 'lead ' + JSON.stringify(lead));
    const { enquiry_obj } = lead;

    if (lead.enquiry_obj && !lead.enquiry_obj.councellor_id) {
        unVerifiedStatus = unVerifiedStatus.concat('current');
    } else {
        verifiedStatus = verifiedStatus.concat('current');
    }

    // moved to application
    if (lead.application_obj && lead.application_obj.admission_status) {

        // reset
        unVerifiedStatus = 'step ';
        verifiedStatus = 'step ';
        applStartStatus = 'step ';
        payStatus = 'step ';
        enrollStatus = 'step ';
        console.log(TAG, 'inside application object')
        switch (lead.application_obj.admission_status) {
            case 'APPLICATION':
            case 'EXAM_SCHEDULED':
                applStartStatus = applStartStatus.concat('current');
                break;
            case 'PAID':
                payStatus = payStatus.concat('current');
                break;
            case 'ENROLLED':
                enrollStatus = enrollStatus.concat('current');
                break;
            default:
                verifiedStatus = verifiedStatus.concat('current');
                break;
        }

    }

    console.log(TAG, 'unVerifiedStatus ' + unVerifiedStatus);
    console.log(TAG, 'verifiedStatus ' + verifiedStatus);
    console.log(TAG, 'applStartStatus ' + applStartStatus);
    console.log(TAG, 'payStatus ' + payStatus);
    console.log(TAG, 'enrollStatus ' + enrollStatus);

    return (
        <div>
            {(isSchool) ?
                <Sidebar />
                :
                <PageNav />
            }

            <div className='page-wrapper'>
                <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>Application Details</h3>
                <div className='std-top d-flex flex-column' style={{ position: 'relative', top: '-40px' }}>
                    <div className='d-flex flex-row'>
                        <div className="student">
                            <div className="std-id">
                                #{enquiry_obj.enquiry_id}
                                <div className="dots">
                                    <BsThreeDotsVertical />
                                </div>
                            </div>
                            <hr />
                            <div className="std-img">
                                <img src="assets/img/noimage.jpg" alt="" />
                                <div className='std-profile'>
                                    <h3 style={{
                                        color: '#23468c',
                                        padding: '5px 0px', fontSize: '1.3rem', width: '200px'
                                    }}>{enquiry_obj.Student_name}</h3>
                                    <div className="std-name">
                                        <div className="comm-icons2">
                                            {/* <BsFillTelephoneFill className='icon' style={{ color: '#0d6efd', fontSize: '1rem' }} />
                                            <BsChatLeftFill className='icon' style={{ color: '198754', fontSize: '1rem' }} />
                                            <BsFillChatDotsFill className='icon' style={{ color: '#f7dc6f', fontSize: '1rem' }} /> */}
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            Email: {enquiry_obj.parent_email}<br />
                                            Mobile: <BsWhatsapp style={{ color: 'green' }} />  {enquiry_obj.parent_contact}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="follow-up">
                                <h6>Follow Up</h6>
                                <div className="add-on">
                                    <div><span style={{ fontWeight: 500 }}> Added On:</span>  {enquiry_obj.created_date} <span style={{ fontSize: '0.8rem', color: 'gray' }}> 2 months ago</span></div>
                                    <div><span style={{ fontWeight: 500 }}> Last Active :</span>  05/12/2021 <span style={{ fontSize: '0.8rem', color: 'gray' }}> 1 week ago</span></div>

                                </div>
                            </div>
                        </div>
                        <div className="std-couns">

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="assg-couns">
                                    <h6 style={{ color: '#23468c' }}>Communication Status</h6>

                                    <p>Email Sent - 2</p>
                                    <p>SMS Sent - 0</p>
                                    <p>Whatsapp Sent - 0</p>
                                </div>
                                <div className="up-coming">
                                    <h6 style={{ color: '#23468c' }}>
                                        UpComing Followup
                                    </h6>
                                    <p>NA</p>
                                </div>
                            </div>


                            <div className="comm-stat" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                <div className="assg-couns">
                                    <h6 style={{ color: '#23468c' }}>Telephony Status</h6>

                                    <p>Inbound Call - 2</p>
                                    <p>Outbound Call - 0</p>
                                    <p>Whatsapp Sent - 0</p>
                                </div>
                                <div className="up-coming">
                                    <h6 style={{ color: '#23468c' }}>
                                        Lead Source
                                    </h6>
                                    <p>Direct</p>
                                </div>

                            </div>
                            <div className="tel-stat" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                <div className="assg-couns">
                                    <h6 style={{ color: '#23468c' }}>Assigned Counsellor</h6>
                                    <div
                                        className='d-flex flex-column align-items-center'>
                                        <div className="std-img"
                                            style={{
                                                width: 64,
                                            }}>
                                            <img width={24} src="/assets/img/profiles/avatar-01.jpg" className='img-responsive1' />
                                        </div>
                                        Counsellor name
                                    </div>
                                </div>
                                {/* <div className="up-coming">
                                    <h6 style={{ color: '#23468c' }}>
                                        Assigned RA
                                    </h6>
                                    <p>NA</p>
                                </div> */}

                            </div>
                        </div>
                    </div>


                    {/* step-progress */}
                    <div className='page-wrapper2'>
                        <div className="step-progress2">
                            <div className="container">
                                <div className="arrow-wrapper">
                                    <div className="arrow-steps clearfix"  >
                                        <div className={unVerifiedStatus}> <span> Unverified</span> </div>
                                        <div className={verifiedStatus}> <span>Verified</span> </div>
                                        <div className={applStartStatus}> <span>Application Started</span></div>
                                        <div className={payStatus}> <span>Payment Approved</span> </div>
                                        <div className={enrollStatus}> <span>Enrolled</span> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end step-progress */}



                    {/* <LEAD_DETAILS2 /> */}

                    <div className='mt-3'></div>
                    <Divider />
                    <div className='px-5 mt-2'>
                        <VerticalTabs />
                    </div>
                </div>
            </div>







        </div>

    )
}

export default LeadDetails2;
