import React from 'react'
import Sidebar from './Sidebar'

const Approved = () => {
  return (
    <div>
           <div>
    <Sidebar />
      <div class="page-wrapper">
<div class="content container-fluid">

<div class="page-header">
<div class="row">
<div class="col-sm-12">
<div class="page-sub-header">
<h3 class="page-title"></h3>

</div>
</div>
</div>
</div>

<div class="student-group-form" style={{position:'relative', top:'-20px'}}>
<div class="row">
<div class="col-lg-3 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by ID ..." />
</div>
</div>
<div class="col-lg-3 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by Name ..." />
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by Phone ..." />
</div>
</div>
<div class="col-lg-2">
<div class="search-student-btn">
<button type="btn" class="btn btn-primary">Search</button>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-sm-12">
<div class="card card-table comman-shadow">
<div class="card-body">


{/* <div class="page-header">
<div class="row align-items-center">
<div class="col" style={{display:'flex', justifyContent:'space-between'}}>
<h3 class="page-title">Leads</h3>
<div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
Assign Counsellor:
<select>
  <option>one</option>
  <option>two</option>
  <option>three</option>
</select>
</div>
</div> */}

<div class="page-header">
<div class="row align-items-center">
<div class="col">
<div class="col" style={{display:'flex', justifyContent:'space-between'}}>
<h3 class="page-title">Approved</h3>

</div>

</div>
<div class="col-auto text-end float-end ms-auto download-grp">
<div>
</div>

{/* <Link to='/enquiry'  className="btn btn-outline-primary me-2">
Add Lead
</Link> */}


{/* openmodal */}



{/* endopenmodal */}



{/* <a href="EnquiryForm.html" class="btn btn-primary"><i class="fas fa-plus"></i></a> */}
</div>
</div>
</div>

<div class="table-responsive" >
<table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped" style={{border: "1px solid black"}}>
<thead class="student-thread">
<tr>

<th>Id</th>
<th>Name</th>
<th>Grade</th>
<th>Section</th>
<th>House</th>



<th class="text-end">Action</th>
</tr>
</thead>
<tbody>
    
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>


</div>
  </div>
    </div>
  )
}

export default Approved