import React from 'react'
import Sidebar from './Sidebar';

const ExamSlot = () => {
  return (
    <div>
        <Sidebar />
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Exam</h3>
<ul className="breadcrumb">

</ul>
</div>
</div>
</div>
</div>

<div className="student-group-form">

<div className="col-xl-6">
    <div className="card">
    <div className="card-body">
  
        
    <div id="signup-modal" className="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog">
    <div className="modal-content">
    <div className="modal-body">
    <div className="text-center mt-2 mb-4">
    <div className="auth-logo">
    <a href="index.html" className="logo logo-dark">
    <span className="logo-lg">
    <img src="assets/img/schooler-applier.png" alt="" height="42" />
    </span>
    </a>
    </div>
    </div>
    <form className="px-3" action="#">
    <div className="mb-3">
    <label for="username" className="form-label">Name</label>
    <input className="form-control" type="email" id="username" required="" />
    </div>
    <div className="mb-3">
        <label for="password" className="form-label">Application No.</label>
        <input className="form-control" type="password" required="" id="password" placeholder="" />
        </div>
    <div className="mb-3">
    <label for="emailaddress" className="form-label">Email address (optional)</label>
    <input className="form-control" type="email" id="emailaddress" required="" />
    </div>
    <div className="mb-3">
        <label for="password" className="form-label">Phone No. (optional) </label>
        <input className="form-control" type="password" required="" id="password" placeholder="" />
        </div>
    <div className="mb-3">
        <label for="password" className="form-label">Exam Slot</label>
        <input className="form-control" type="password" required="" id="password" placeholder="" />
        </div>
 
    <div className="mb-3">
    
    </div>
    <div className="mb-3 text-center">
    <button className="btn btn-primary" type="submit">Submit</button>
    </div>
    </form>
    </div>
    </div>
    </div>
    </div>
    
  
    <div className="button-list">
    
    <button type="button" className="btn btn-primary mt-1" data-bs-toggle="modal" data-bs-target="#signup-modal">Enter Exam Details</button>
    
    </div>
    </div> 
    </div> 
    </div> 
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">
<div className="col">

</div>
<div className="col-auto text-end float-end ms-auto download-grp">


</div>
</div>
</div>

<div className="table-responsive w-100">
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped" >
<thead className="student-thread">
<tr>
<th>
<div className="form-check check-tables">
<input className="form-check-input" type="checkbox" value="something" />
</div>
</th>
<th>Registered Name</th>
<th>Application Number</th>
<th>Email</th>

<th>Registered Mobile</th>
<th>Exam Slot</th>
<th>Result</th>

<th className="text-end">Action</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className="form-check check-tables">
<input className="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 className="table-avatar">
<a href="IndividualStudent.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-08.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Meera Jasmin</a>
</h2>
</td>
<td>9AB1245</td>
<td>meera@hotmail.com</td>

<td>701 753 3810</td>
<td>1A</td>
<td>Pass</td>

<td className="text-end">
<div className="actions ">
    <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
   
    </a>
    <a href="edit-student.html" className="btn btn-sm bg-danger-light">
    <i className="feather-edit"></i>
    </a>
    </div>
</td>
</tr>
<tr>
<td>
    <div className="form-check check-tables">
        <input className="form-check-input" type="checkbox" value="something" />
        </div>
</td>

<td>
    
<h2 className="table-avatar">
<a href="IndividualStudent.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-02.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Bharat</a>
</h2>
</td>
<td>9AA2462</td>
<td>03/03/2003</td>

<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>

<td className="text-end">
    <div className="actions ">
       
        <a href="edit-student.html" className="btn btn-sm bg-danger-light">
        <i className="feather-edit"></i>
        </a>
        </div>
</td>
</tr>
<tr>
<td>
<div className="form-check check-tables">
<input className="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 className="table-avatar">
<a href="IndividualStudent.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Mukesh </a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>

<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>

<td className="text-end">
    <div className="actions ">
       
        <a href="edit-student.html" className="btn btn-sm bg-danger-light">
        <i className="feather-edit"></i>
        </a>
        </div>
</td>
</tr>
<tr>
<td>
<div className="form-check check-tables">
<input className="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 className="table-avatar">
<a href="IndividualStudent.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Vinay</a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>

<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>

<td className="text-end">
    <div className="actions ">
       
        <a href="edit-student.html" className="btn btn-sm bg-danger-light">
        <i className="feather-edit"></i>
        </a>
        </div>
</td>
</tr>

</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>



</div>
</div>

  )
}

export default ExamSlot;