import React, { useState } from 'react';

const ExampleTable = ({ data }) => {
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    const handleSort = (field) => {
        if (field === sortBy) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortDirection('asc');
        }
    };

    const sortedData = [...data].sort((a, b) => {
        if (sortBy === null) {
            return 0;
        }
        if (sortDirection === 'asc') {
            return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
            return a[sortBy] < b[sortBy] ? 1 : -1;
        }
    });

    return (
        <table>
            <thead>
                <tr>
                    <th onClick={() => handleSort('field1')}>Field 1</th>
                    <th onClick={() => handleSort('field2')}>Field 2</th>
                    <th onClick={() => handleSort('field3')}>Field 3</th>
                </tr>
            </thead>
            <tbody>
                {sortedData.map((row, index) => (
                    <tr key={index}>
                        <td>{row.field1}</td>
                        <td>{row.field2}</td>
                        <td>{row.field3}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const FilterTable = () => {
    return (
        <div>
            <ExampleTable />
        </div>
    )
}

export default FilterTable;
