import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Dashboard } from "@mui/icons-material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { TbReportSearch } from 'react-icons/tb';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { AiOutlineDown } from 'react-icons/ai';
import { RiSettings5Fill } from 'react-icons/ri';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { AiOutlineForm } from 'react-icons/ai';
import { AiFillSchedule } from 'react-icons/ai';
import { MdOutlineEventSeat } from 'react-icons/md';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dropdown from 'react-bootstrap/Dropdown';
import UserSession from "../../resource/UserSession";
import Constants from "../../resource/Constants";
import INSTITUTEAPI from "../../http/institute";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import 'sweetalert2/src/sweetalert2.scss'
const MySwal = withReactContent(Swal)
const TAG = "SidebarCouns.jsx";

function BasicDropdown() {
  const [userName, setUserName] = useState("Admin");
  const navigate = useNavigate();

  const _doLogout = () => {
    // remove token
    localStorage.removeItem("applier.couns.token");

    // remove role
    localStorage.removeItem("applier.role");
    let path = "/login";
    navigate(path);
  };

  useEffect(() => {
    const userName2 = localStorage.getItem("applier.couns.name");
    setUserName(userName2);
  }, [])

  return (
    <Dropdown>
      <Dropdown.Toggle variant="none" id="dropdown-basic">
        <span className="user-img">
          <img
            className="rounded-circle"
            src="assets/img/profiles/avatar-09.jpg"
            width="31"
            alt="Soeng Souy"
          />
          <div className="user-text" >
            <h6>{userName}</h6>
            <p className="text-muted mb-0">Counselor</p>
          </div>

          <div>
            <i className="fa fa-chevron-down"></i>
          </div>
        </span>
      </Dropdown.Toggle>
      {/* 
      <a className="dropdown-item" href="profile.html">
        My Profile
      </a>
      <a className="dropdown-item" href="inbox.html">
        Inbox
      </a>
      <a
        className="dropdown-item"
        href="javascript:void(0)"
        onClick={_doLogout}
      >
        Logout
      </a> */}
      <Dropdown.Menu>
        <Dropdown.Item href="profile.html">My Profile</Dropdown.Item>
        <Dropdown.Item href="inbox.html">Inbox</Dropdown.Item>
        <Dropdown.Item href="javascript:void(0)"

          onClick={_doLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const SidebarCouns = () => {
  const [showDash, setShowDash] = useState(false);
  const [showLead, setShowLead] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [showCamp, setShowCamp] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showSet, setShowSet] = useState(false);
  const [showAcc, setShowAcc] = useState(false);
  const [showActive, setShowActive] = useState("activate")
  const [inviteEmail, setInviteEmail] = useState("");

  const [dropdown1Open, setDropdown1Open] = useState(false);
  const [dropdown2Open, setDropdown2Open] = useState(false);
  const [dropdown3Open, setDropdown3Open] = useState(false);

  const [schCounsellors, setSchCounsellors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addActive, setAddActive] = useState(false);


  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeBackground = () => {

  }


  const ActiveMenu = (className) => {
    setShowActive(className)
  }

  const toggleDropdown = (dropdown) => {
    if (dropdown === 1) {
      setDropdown1Open(!dropdown1Open);
      setDropdown2Open(false);
      setDropdown3Open(false);
    }
    if (dropdown === 2) {
      setDropdown2Open(!dropdown2Open);
      setDropdown1Open(false);
      setDropdown3Open(false);
    }
    if (dropdown === 3) {
      setDropdown3Open(!dropdown3Open);
      setDropdown1Open(false);
      setDropdown2Open(false);
    }
  };

  const ClickDash = () => {
    setShowDash(!showDash);
  };
  const ClickLead = () => {
    setShowLead(!showLead);
  };
  const ClickApp = () => {
    setShowApp(!showApp);
  };
  const ClickCamp = () => {
    setShowCamp(!showCamp);
  };
  const ClickAdmin = () => {
    setShowAdmin(!showAdmin);
  };
  const ClickSet = () => {
    setShowSet(!showSet);
  };
  const ClickAcc = () => {
    setShowAcc(!showAcc);
  };

  const navigate = useNavigate();

  const handleAddCounsellor = async () => {
    // verify

    const schoolId = localStorage.getItem(Constants.Application.APPLIER_STORE_SCHOOLID);
    const body = {
      email: inviteEmail,
      institute_id: schoolId
    }

    // close dialog
    handleClose();

    if (!schoolId || !inviteEmail) {
      console.log(TAG, 'handleAddCounsellor(): empty values')

      MySwal.fire({
        title: 'Error',
        text: 'Please enter email address to send invite to..',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        handleClickOpen()
      })

      return false;
    }

    MySwal.fire({
      title: 'Please wait..',
      text: 'Sending invite to counselor',
      icon: 'info',
      confirmButtonText: 'OK',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    }).then(() => {
      // do something..
    })

    try {
      const resp3 = await INSTITUTEAPI.addCounsellor(body);
      console.log(TAG, 'handleAddCounsellor(): resp3 ' + JSON.stringify(resp3));

      MySwal.fire('Invite successfully sent to counsellor!');
    } catch (e) {
      console.error(TAG, 'handleAddCounsellor(): exception ' + e);
    }

    setInviteEmail(""); // reset

    // close swal if open
    setTimeout(function () {
      MySwal.close();
    }, 2000); // 2 seconds..


  }

  const _doLogout = () => {
    // remove token
    localStorage.removeItem("applier.couns.token");

    // remove role
    localStorage.removeItem("applier.role");
    let path = "/login";
    navigate(path);
  };

  const verifyLink = () => {

    setDropdown2Open(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(TAG, 'handleChange(): name ' + name)
    console.log(TAG, 'handleChange(): value ' + value)

    // set invite email
    setInviteEmail(value);
  }

  useEffect(() => {
    verifyLink()
  }, [])

  return (
    <>
      <div className="header ">
        <div className="header-left">
          <a href="index.html" className="logo">
            <NavLink to="/admin-dashboard">
              <img src="assets/img/schooler-applier.png" alt="Logo" />
            </NavLink>
          </a>
          <a href="index.html" className="logo logo-small">
            <img
              src="assets/img/logo-small.png"
              alt="Logo"
              width="30"
              height="30"
            />
          </a>
        </div>

        <a className="mobile_btn" id="mobile_btn">
          <i className="fas fa-bars"></i>
        </a>
        <div>

          <ul className="nav user-menu">

            {/* 
            <li className="nav-item dropdown noti-dropdown me-2">
              <a
                href="#"
                className="dropdown-toggle nav-Navlink header-nav-list"
                data-bs-toggle="dropdown"
              >
                <RiSettings5Fill style={{ fontSize: '50px' }} />
                <AiOutlineDown />
              </a>
              <div className="dropdown-menu notifications">
                <div className="topnav-dropdown-header">
                  <span className="notification-title">Settings</span>
                  <a href="javascript:void(0)" className="clear-noti">


                  </a>
                </div>
                <div className="noti-content" style={{ height: '180px' }}>
                  
        <ul className="notification-list">
          <li className="notification-message">

            <div className="media d-flex">
              <span className="avatar avatar-sm flex-shrink-0">
                
                <MdPersonAddAlt1 style={{ fontSize: '2.2em', paddingLeft: '10px', paddingLeft: '10px', paddingTop: '10px' }} />
              </span>
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">Add Counsellor</span>
                </p>
              </div>
            </div>
          </li>
          <li className="notification-message">

            <div className="media d-flex">
              <span className="avatar avatar-sm flex-shrink-0">
                
                <AiOutlineForm style={{ fontSize: '2.4em', paddingLeft: '10px', paddingTop: '10px' }} />
              </span>
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">Set Application Form</span>
                </p>
              </div>
            </div>
          </li>
          <li className="notification-message">

            <div className="media d-flex">
              <span className="avatar avatar-sm flex-shrink-0">
                
                <AiFillSchedule style={{ fontSize: '2.2em', paddingLeft: '10px', paddingLeft: '10px', paddingTop: '10px' }} />
              </span>
              <NavLink className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">Exam Schedule</span>
                </p>
              </NavLink>
            </div>
          </li>
          <li className="notification-message">

            <div className="media d-flex">
              <span className="avatar avatar-sm flex-shrink-0">
                
                <MdOutlineEventSeat style={{ fontSize: '2.2em', paddingLeft: '10px', paddingLeft: '10px', paddingTop: '10px' }} />
              </span>
              <div className="media-body flex-grow-1">
                <p className="noti-details">
                  <span className="noti-title">Seats Availability</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
  
                </div>
                <div className="topnav-dropdown-footer">
                  <a href="#">View all Notifications</a>
                </div>
              </div>
            </li >
            */}

            <li>
              <BasicDropdown />
            </li>
            {/* 
            <li className="nav-item dropdown has-arrow new-user-menus">
              <a
                href="#"
                className="dropdown-toggle nav-Navlink"
                data-bs-toggle="dropdown"
              >
                <span className="user-img">
                  <img
                    className="rounded-circle"
                    src="assets/img/profiles/avatar-09.jpg"
                    width="31"
                    alt="Soeng Souy"
                  />
                  <div className="user-text" >
                    <h6>School</h6>
                    <p className="text-muted mb-0">Administrator</p>
                  </div>
                </span>
              </a>
              <div className="dropdown-menu">
                <div className="user-header">
                  <div className="avatar avatar-sm">
                    <img
                      src="assets/img/profiles/avatar-01.jpg"
                      alt="User Image"
                      className="avatar-img rounded-circle"
                    />
                  </div>
                  <div className="user-text">
                    <h6>Soeng Souy</h6>
                    <p className="text-muted mb-0">Administrator</p>
                  </div>
                </div>
                <a className="dropdown-item" href="profile.html">
                  My Profile
                </a>
                <a className="dropdown-item" href="inbox.html">
                  Inbox
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={_doLogout}
                >
                  Logout
                </a>
              </div>
            </li>
             */}
          </ul >


        </div >
        <div>

        </div>

      </div >
      <section className="sideBar">
        {/* <h6 style={{ color: "gray" }}>Main Menu</h6> */}
        <div className="sidebar-menu">
          <div className="Dashboard">
            <div className="menu-item">
              <NavLink className="NavlinkTag" to="/sch-couns-db">
                <Dashboard className="menuIcon" />
                Dashboard
              </NavLink>
            </div>
          </div>
          <div className="leadManager">
            <div className="menu-item">
              <NavLink className="NavlinkTag" to="/lead-manager-couns">
                <LeaderboardIcon className="menuIcon" />
                Leads
              </NavLink>
            </div>
          </div>
          <div className="applicationManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/applications">
                <TextSnippetOutlinedIcon className="menuIcon" />
                Applications
              </NavLink>
            </div>

          </div>
          <div className="applicationManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/results">
                <TbReportSearch className="menuIcon" />
                Results
              </NavLink>
            </div>

          </div>
          <div className="applicationManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/payment">
                <RiSecurePaymentFill className="menuIcon" />
                Payments
              </NavLink>
            </div>

          </div>

          <div className="applicationManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/admissions">
                <BsFillPersonCheckFill className="menuIcon" />
                Admissions
              </NavLink>
            </div>

          </div>
          <div className="campManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/sch-analytics">
                <TrendingUpIcon className="menuIcon" />
                Campaign Analytics
              </NavLink>
            </div>

          </div>
          <div className="campManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/sch-campaign">
                <CampaignIcon className="menuIcon" />
                Campaigns
              </NavLink>
            </div>
          </div>
          <div className="campManager">
            <div className="menu-item" >
              <NavLink className="NavlinkTag" to="/sch-campaign1" onClick={(e) => { e.preventDefault(); toggleDropdown(2) }}>
                {/* <div className="NavlinkTag" style={{ cursor: 'pointer' }}> */}
                <SettingsIcon className="menuIcon " />
                Settings
                {/* </div> */}
              </NavLink>
              <div>
                <div>
                  {dropdown2Open && (
                    <ul>
                      {/* <li>
                        <Link >
                          <Button variant="outlined" onClick={handleClickOpen}
                            style={{ border: 'none', marginLeft: '-30px', color: 'black', }}
                          >
                            Add Counsellor
                          </Button>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Appoint Counsellor</DialogTitle>
                            <DialsogContent>
                              <DialogContentText>
                                Please Enter the email you wish to register as a Counsellor, a link will be sent to his/her email, once clicked on the link
                                account gets activated as a counsellor
                              </DialogContentText>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={handleClose}>Send</Button>
                            </DialogActions>
                          </Dialog>
                        </Link>
                      </li> */}

                      <div className="campManager">
                        <div className="menu-item" >
                          <NavLink className="NavlinkTag" to='/set-form1' onClick={(e) => { e.preventDefault(); handleClickOpen() }}>
                            {/* <CampaignIcon className="menuIcon" /> */}
                            Appoint Counsellor
                          </NavLink>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Appoint Counsellor</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Please Enter the email you wish to register as a Counsellor, a link will be sent to his/her email, once clicked on the link
                                account gets activated as a counsellor
                              </DialogContentText>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                                onChange={handleChange}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={handleAddCounsellor}>Send</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>

                      <div className="campManager">
                        <div className="menu-item" >
                          <NavLink className="NavlinkTag" to='/set-form'>
                            {/* <CampaignIcon className="menuIcon" /> */}
                            Set application form
                          </NavLink>
                        </div>
                      </div>
                      {/* <li>
                        <NavLink to='/set-form' className="NavlinkTag">
                          Set application form
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to='/exam-schedule' className="NavlinkTag">
                          Exam Schedule
                        </NavLink>
                      </li> */}
                      <div className="campManager">
                        <div className="menu-item" >
                          <NavLink className="NavlinkTag" to='/exam-schedule'>
                            {/* <CampaignIcon className="menuIcon" /> */}
                            Exam Schedule
                          </NavLink>
                        </div>
                      </div>

                      {/* <li>
                        <NavLink to='/seat-avail' className="NavlinkTag">
                          Seats Availability
                        </NavLink>
                      </li> */}

                      <div className="campManager">
                        <div className="menu-item" >
                          <NavLink className="NavlinkTag" to='/seat-avail'>
                            {/* <CampaignIcon className="menuIcon" /> */}
                            Seats Availability
                          </NavLink>
                        </div>
                      </div>

                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SidebarCouns;
