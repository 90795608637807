/**
 * @project kgtopg - schooler - publisher
 * http api calls for
 * authentication
 */

import API from './http';
import axios from 'axios';

import Constants from '../resource/Constants';
import Cookie from 'js-cookie';
import UserSession from '../resource/UserSession';
//  import UserSession from '../resource/UserSession';

const TAG = "lead.js";

export default {

    async _loadTimeline(enqId) {
        const URL = Constants.Application.APPLIER_END_URL + '/get-lead_activity/?enquiry_id=' + enqId;//12';
        let data = null;

        // see if institute is logged in
        let token = localStorage.getItem('applier.inst.token');

        if (!token) // check counsellor
            token = localStorage.getItem('applier.couns.token');
        console.log(TAG, '_loadTimeline(): URL ' + URL);

        const config = {
            headers: { Authorization: `${token}` }
        };


        try {
            const resp = await axios.get(URL, config);
            let resp2 = null;

            console.log(TAG, '_loadTimeline(): resp ' + JSON.stringify(resp));
            if (resp && resp.data && resp.data)
                resp2 = resp.data;
            return resp2;
        } catch (e) {
            console.error(TAG, '_loadTimeline(): exception ' + e);
            return false;
        }
    },

    async _loadFollowup(id) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/get-enquiry-followup-activity/?enquiry_id=' + id;

        // see if institute is logged in
        let token = localStorage.getItem('applier.inst.token');

        if (!token) // check counsellor
            token = localStorage.getItem('applier.couns.token');
        console.log(TAG, '_loadFollowup(): URL ' + URL);

        const config = {
            headers: { Authorization: `${token}` }
        };

        // we wait
        try {

            console.log(TAG, "_loadFollowup() URL: ", URL);
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "_loadFollowup() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async _getCodeUser(code) {
        const URL = Constants.Application.APPLIER_END_URL + '/get-code-user/?code=' + code;
        let data = null;

        // see if institute is logged in
        let token = localStorage.getItem('applier.inst.token');

        if (!token) // check counsellor
            token = localStorage.getItem('applier.couns.token');
        console.log(TAG, '_loadTimeline(): URL ' + URL);

        const config = {
            headers: { Authorization: `${token}` }
        };


        try {
            const resp = await axios.get(URL);//, config);
            let resp2 = null;

            console.log(TAG, '_getCodeUser(): resp ' + JSON.stringify(resp));
            if (resp && resp.data)
                resp2 = resp.data;
            return resp2;
        } catch (e) {
            console.error(TAG, '_getCodeUser(): exception ' + e);
            return false;
        }
    },

}