import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import Sidebar from "../Sidebar";


const Input = () => {
    return <input placeholder="Your input here" />;
};
const DateInput = () => {
    return <input type='date' placeholder="choose the date" />
}
const NumberInp = () => {
    return <input type='number' placeholder="Enter number" />
}
const EmailInp = () => {
    return <input type='email' placeholder="enter email" />
}
const PasswordInp = () => {
    return <input type='password' placeholder="enter password" />
}
const FileInp = () => {
    return <input type='file' placeholder="Choose email" />
}


const BinPage = () => {
    const [inputList, setInputList] = useState([]);
    const [inputDate, setInputDate] = useState([]);
    const [numInp, setNumInp] = useState([]);
    const [emailInp, setEmailInp] = useState([]);
    const [passwordInp, setPasswordInp] = useState([]);
    const [fileInp, setFileInp] = useState([]);

    const onAddBtnClick = event => {
        setInputList(inputList.concat(<Input key={inputList.length} />));
    };
    const onAddDateInp = event => {
        setInputDate(inputDate.concat(<DateInput key={inputDate.length} />));
    };
    const onAddNumInp = event => {
        setNumInp(numInp.concat(<NumberInp key={numInp.length} />));
    }
    const onAddEmail = event => {
        setEmailInp(emailInp.concat(<EmailInp key={emailInp.length} />));
    }
    const onAddPassword = event => {
        setPasswordInp(passwordInp.concat(<PasswordInp key={passwordInp.length} />));
    }
    const onAddFile = event => {
        setFileInp(fileInp.concat(<FileInp key={fileInp.length} />));
    }
    return (
        <>
        <Sidebar />
           <div className="full-section">
          
            <div className="left-section">
                <div className="left-inp" >
                  <div>
                  <button >Add input</button>
                  </div>
                  <div>
                    <input placeholder="Your input here" />
                  </div>
                   <div>
                   <AiOutlinePlus onClick={onAddBtnClick} />
                   </div>
                </div>
                <div className="left-inp">
                    <button >Add Date</button>
                    <input type='date' placeholder="choose the date" />

                    <AiOutlinePlus onClick={onAddDateInp} />
                </div>
                <div className="left-inp">
                    <button >Add Number</button>
                    <input type='number' placeholder="Enter number" />
                    <AiOutlinePlus onClick={onAddNumInp} />
                  
                </div>
                <div className="left-inp">
                    <button >Add Email</button>
                    <input type='email' placeholder="enter email" />
                    <AiOutlinePlus onClick={onAddEmail} />
                  
                </div>
                <div className="left-inp">
                    <button >Add Password</button>
                    <input type='password' placeholder='password'/>
                    <AiOutlinePlus onClick={onAddPassword} />
                   
                </div>
                <div className="left-inp">
                    <button >Add File</button>
                    <input type="file"  />
                    <AiOutlinePlus onClick={onAddFile} />
                    
                </div>
            </div>
            <div className="right-section">
                <h3>Build your own form</h3>
         
                <div className="first" >
                   {inputList}
                </div>
                <div className="first" >
                    {inputDate}
                </div>
                <div className="first" >
                    {numInp}
                </div>
                <div className="first" >
                    {emailInp}
                </div>
                <div className="first" >
                    {passwordInp}
                </div>
                <div className="first" >
                    {fileInp}
                </div>
                </div>
            </div>
        </>

    );
};

export default BinPage