import React from 'react'
import Sidebar from './Sidebar'

const LeadDetails = () => {
  return (
    <div>
        <Sidebar />
<div class="page-wrapper" >
   <div class="sec-1" style="display: flex; margin-top: 20px; margin-left: 50px;">
    <div class="stud-details" style="width:350px; height:250px; background-color:white; ">
            <div class="card-header d-flex justify-content-between align-items-center" style="background-color: white;">
                <a href="view-invoice.html" class="invoice-grid-link" >IN093439#@10</a>
                    <div class="dropdown dropdown-action">
                         <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="edit-invoice.html"><i class="far fa-edit me-2"></i>Edit</a>
                        <a class="dropdown-item" href="view-invoice.html"><i class="far fa-eye me-2"></i>View</a>
                        <a class="dropdown-item" href="javascript:void(0);"><i class="far fa-trash-alt me-2"></i>Delete</a>
                    </div>
                    </div>
            </div>
            <div class="card-middle" style="background-color: white;display: flex;">
                <h2 class="card-middle-avatar">
                    <a href="profile.html"><img class="avatar avatar-sm me-2 avatar-img rounded-circle" src="assets/img/profiles/avatar-06.jpg" 
                        style="width: 80px; height: 80px;"
                        alt="User Image" /></a>
                </h2>
              <div class="stud-comm">
                <h2 style="color:#23468c; margin-left: 30px;cursor: pointer;font-size: 1.5rem;">Padmaja</h2>
                
                <div class="comm" style="display: flex; justify-content: space-evenly; padding-left: 20px; cursor: pointer;">
                    <i class='fas fa-phone-alt' style="width: 30px;height: 30px;border-radius: 50%;  color: rgb(32, 47, 178);"></i>
                <i class='fas fa-comment-alt' style="width: 30px;height: 30px;border-radius: 50%;  color: #4cbb87;"></i>
                <i class="fa fa-wechat" style="color: #f7dc6f; font-size: 20px;"></i>
                </div>
              
              </div>
            </div>
            <h6 style="margin-left: 30px; color: rgb(48, 48, 189);padding-bottom: 10px;">Follow Up</h6>
            <div  class="col" align="left" style="padding-left: 20px;margin-top: -10px; ">
                <h6 style="font-size: 0.8rem;"><span style="color:gray; font-size:0.6rem">Added On:</span>02/09/2021| 11:36 AM </h6>
                <h6 style="font-size: 0.8rem;"><span style="color:gray; font-size:0.6rem">Added On:</span>02/09/2021| 11:36 AM </h6>           
            </div>
          
        </div>   
        <div class="counsellor-detail" style="border-radius: 5px;padding-top: 20px; margin-left: 40px; 
         display: flex; width: 800px; justify-content: space-around; background-color: white;">
            <div class="councel-left" style="display: flex; flex-direction: column; justify-content: space-around;">
                <div class="counsellor-assigned">
                    <h5 >Assigned Counsellor</h5>
                    <h6  style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">Name</span> Vishal</h6>
                </div>
                <div class="lead-source">
                    <a href="" style="color: blue;">Paid Ads: Google</a>
                </div>
            </div>
            <div class="communication" style="display: flex; flex-direction: column; padding-top: 50px;">
                <h5>Communication Status</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">Email Sent:</span>4</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">SMS Sent:</span>5</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">WhatApp Sent:</span>4</h5>
            </div>
            <div class="telphony" style="display: flex; flex-direction: column; padding-top: 50px;">
                <h5>Telephony Status</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">Inbound Calls:</span>23</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">Outbound Calls:</span>7</h5>
                <h5 style="font-size: 1rem;"><span style="color:gray; font-size:0.8rem">Avg Call Duration</span>03.20</h5>
                
            </div>
    
    </div>
   </div>
   <div class="status" >

<div class="container">	
    <div class="wrapper">	
    <div class="arrow-steps clearfix" style="position: relative; top: -10rem; left: -125px;" >
              <div class="step current"> <span> Unverified</span> </div>
              <div class="step"> <span>Verified</span> </div>
              <div class="step"> <span>Application Started</span> </div>
              <div class="step"> <span>Payment Approved</span> </div>
              <div class="step"> <span>Payment Submitted</span> </div>
                </div>
             {/* <div class="nav clearfix">  */}
         
            </div>
    </div>



{/* Timeline   */}
<div class="col-12 col-lg-12 col-xl-12 d-flex" 
style="margin-top: -20rem; padding: 50px;padding-top: 0;" >
    <div class="card flex-fill comman-shadow">
    <div class="card-header d-flex align-items-center">
    <h5 class="card-title">Lead Details</h5>
    <ul class="chart-list-out student-ellips">
    <li class="star-menus"><a href="javascript:;"><i class="fas fa-ellipsis-v"></i></a></li>
    </ul>
    </div>
    <div class="card-body">
    <div class="teaching-card">
        <ul style="width: 250px;">
            <li style="border:1px solid rgb(207, 195, 195); padding:5px; border-right: none;">
                <i class="fa fa-clock-o" ></i>
                Timeline</li>
            <li style="border:1px solid rgb(207, 195, 195); padding:5px">
                <i class="fa fa-sticky-note-o" ></i>
                Follow Up & Notes</li>
            <li style="border:1px solid rgb(207, 195, 195); padding:5px">
                <i class='far fa-comment-alt'></i>
                Communication Logs</li>
            <li style="border:1px solid rgb(207, 195, 195); padding:5px">
                <i class='fas fa-ban'></i>
                Document Locker</li>
            <li style="border:1px solid rgb(207, 195, 195); padding:5px">
                <i class="fa fa-ticket"></i>
                Tickets</li>
            <li style="border:1px solid rgb(207, 195, 195); padding:5px">
                <i class='far fa-paper-plane'></i>
                Call Logs</li>
        </ul>
    <div class="teaching-card" style="width: 1000px; margin-left: 20px;">
    <ul class="steps-history">
        <ul class="teacher-date-list">
         {/* <li>Sep22</li>  */}

        <li><i class="fas fa-calendar-alt me-2"></i>September 23, 2022</li>
    </ul>
    <ul class="teacher-date-list">
         {/* <li>Sep22</li>  */}

        <li><i class="fas fa-calendar-alt me-2"></i>September 24, 2022</li>
    </ul>
    <ul class="teacher-date-list">
        {/* <li>Sep22</li> */}

        <li><i class="fas fa-calendar-alt me-2"></i>September 30, 2022</li>
    </ul>

    </ul>
    <ul class="activity-feed">
    <li class="feed-item d-flex align-items-center" style="height: 100px;">
    <div class="dolor-activity">

    <ul class="teacher-date-list">
    <li style="margin-top: -40px;">Prakash Logged in</li>
     {/* <li>|</li>  */}
     {/* <li><i class="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>  */}
    </ul>
    </div>
    <div class="activity-btns ms-auto">
    <button type="submit" class="btn btn-warning">Website</button>
    </div>
    </li>
    <li class="feed-item d-flex align-items-center" style="height: 100px">
    <div class="dolor-activity">
    {/* <span class="feed-text1"><a>Geography	</a></span>  */}
    <ul class="teacher-date-list">
        <li style="margin-top: -40px;">Prakash Submitted Form for Admission</li>
    </ul>
</div>
<div class="activity-btns ms-auto">
    <button type="submit" class="btn btn-primary">Completed</button>
</div>
</li>
<li class="feed-item d-flex align-items-center" style="height: 100px;">
    <div class="dolor-activity">
         {/* <span class="feed-text1"><a>Botony</a></span>  */}
        <ul class="teacher-date-list">
            <li style="margin-top: -40px;">Prakash received email & SMS with Appliation Submitted</li>
        </ul>
    </div>
    <div class="activity-btns ms-auto">
    <button type="submit" class="btn btn-success">Administrator</button>
    </div>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>


</div>
</div>
    </div>
  )
}

export default LeadDetails