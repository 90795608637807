import React from 'react'
import Sidebar from './Sidebar'

const CampaignByClassName = () => {
  return (
    <div>
        <Sidebar />
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col">
<h3 className="page-title">Campaign Manager</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
<li className="breadcrumb-item active">Data Tables</li>
</ul>
</div>
</div>
</div>


<div className="row">
<div className="col-md-6">
    <div className="card">
    <div className="card-header">
    <div className="card-title">Leads by ClassName</div>
    </div>
    <div className="card-body">
        <div className="colors-def" >
            <ul style={{justifyContent: "space-evenly", position: "absolute",display:"inline-flex",left: "50%"}}>
                <div style={{width: "10px",height: "10px", backgroundColor: "#44c4fa",border:" 1px solid black",textAlign: "center"}}></div>
                <li > Leads</li>
                <div style={{width: "10px",height: "10px", backgroundColor:"#664dc9",border: "1px solid black", marginLeft: "20px"}}></div>
                <li> Paid</li>
               
            </ul>
        </div>
    <div id="chart-bar-stacked"></div>
    </div>
    </div>
    </div>
    
    
    <div className="col-md-6">
    <div className="card">
    <div className="card-header">
    <div className="card-title">Leads Resource</div>
    </div>
    <div className="card-body">
        <div className="colors-def" >
            <ul style={{ justifyContent:" space-evenly", position: "absolute",display: "inline-flex",left: "50%"}}>
                <div style={{width: "10px",height: "10px", backgroundColor: "#44c4fa",border: "1px solid black",textAlign:" center"}}></div>
                <li > Leads</li>
                <div style={{width: "10px",height:" 10px", backgroundColor:"#664dc9",border: "1px solid black", marginLeft:"20px"}}></div>
                <li> Paid</li>
               
            </ul>
        </div>
    <div id="chart-bar"></div>
    </div>
    </div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card">
<div className="card-header">
<ul >
    <li style={{borderBottom: "2px solid blue"}}>Channel Summary</li>
    <li>Lead Disposition</li>
    <li>Registered Program vs Course</li>
</ul>

</div>
<div className="col-auto text-end float-end ms-auto download-grp">
      
    <a href="#" className="btn btn-outline-primary me-2"><i className="fa fa-filter" style={{fontSize:"22px"}}></i> </a>
    <a href="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a>
    
    </div>
<div className="card-body">
<div className="table-responsive">
<table className="datatable table table-stripped">
<thead>
<tr>
<th>Traffic Channel</th>
<th>ClassName</th>
<th>Leads Count</th>
<th>Applications Count</th>
<th>Enrolments Count</th>
</tr>
</thead>
<tbody>
<tr>
<td>
 <select style={{border: "none"}}>
    <option>Social</option>
    <option>Facebook</option>
    <option>Instagram</option>
    <option>Linkedin</option>
 </select>
</td>
<td>10<sup>th</sup></td>
<td>90</td>
<td>61</td>
<td>24</td>

</tr>
<tr>
<td>Email</td>
<td>5<sup>th</sup></td>

<td>30</td>
<td>27</td>
<td>22</td>
</tr>
<tr>
<td>What's App</td>
<td>2<sup>nd</sup></td>
<td>50</td>
<td>50</td>
<td>48</td>

</tr>
<tr>
<td>SMS</td>
<td>6<sup>th</sup></td>

<td>55</td>
<td>50</td>
<td>45</td>

</tr>
</tbody>
</table>

</div>
</div>
</div>
</div>
</div>
</div>


</div>

</div>

    </div>
  )
}

export default CampaignByClassName