import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import { Audio } from 'react-loader-spinner'
import ReactLoading from 'react-loading';
import MyComponent from 'react-fullpage-custom-loader'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Edit } from '@mui/icons-material';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Constants from '../resource/Constants';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import INSTITUTEAPI from '../http/institute';

const URL = Constants.Application.APPLIER_END_URL + "/school-registration/"
const TAG = "SchReg.jsx";

const MySwal = withReactContent(Swal);

/* firebase configuration */

const config = {
    apiKey: Constants.Application.FIREBASE.API_KEY,
    authDomain: Constants.Application.FIREBASE.AUTH_DOMAIN,
    //   databaseURL: "https://<DATABASE_NAME>.firebaseio.com",
    storageBucket: Constants.Application.FIREBASE.STORAGE_BUCKET,
};

// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

const UploadBox7 = (props) => {
    const profilePictureRef = React.createRef();
    const [open, setOpen] = React.useState(false);
    const [downloadUrls, setDownloadUrls] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
    const [progress, setProgress] = useState(0);

    const { planSetup } = props;

    const handleUpload = () => {
        const PP = profilePictureRef.current;
        const imageData = PP.getData();
        const file = imageData.file;
        const imageAsDataURL = PP.getImageAsDataUrl();

        //add here the upload logic...
    }

    // Create new plugin instance
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const handleUploadStart = () => {
        setIsUploading(true);
        setUploadProgress(0);
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const handleUploadError = (error) => {
        setIsUploading(false);
    };

    const handleUploadSingleSuccess = async (filename) => {

        const downloadURL = await firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL();

        console.log("download url", downloadURL);

        if (downloadURL) {
            setProgress(0);
        }
        // setFileNames(filename)
        setImageDownloadUrls(downloadURL);
        props.setDocPicSource(downloadURL)
        // setUploadProgress(100)
        // setIsUploading(false)

    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let imgSrc = "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

    if (ImageDownloadUrls) {
        imgSrc = ImageDownloadUrls;
    }

    const setPicSource = (src) => {
        setImageDownloadUrls(src)
    }

    let type = null;
    let newPicSrc = imgSrc;
    if (newPicSrc) {
        if (newPicSrc.includes('pdf') || newPicSrc.includes('doc'))
            type = 'doc';
        else if (newPicSrc.includes('ppt')) {
            console.log('ApplicationList.js UploadBox7: newPicSrc has PPT')
            type = 'ppt';
        }

    }

    // base on type
    if (type) {
        switch (type) {
            case 'doc':
                newPicSrc = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png';
                break;
            case 'ppt':
                newPicSrc = 'https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024';
                break;
        }
    }

    console.log('ApplicationList.js UploadBox7 setPicSource type ' + type)
    console.log('ApplicationList.js UploadBox7 setPicSource newPicSrc ' + newPicSrc)

    return (
        <>
            <div
                className='d-flex flex-column align-items-center justify-content-center'
                style={{
                    // height: 200
                    width: '100%'
                }}>

                {progress ? <CircularProgress />
                    :

                    <img
                        src={newPicSrc}
                        style={{
                            width: '50%',
                            height: '100%',

                        }} />
                }

                <div
                    style={{
                        alignSelf: 'right'
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            // marginLeft: 15,
                            // marginRight: 15,
                        }}
                    >
                        <label
                            style={{
                                fontFamily: "Poppins, Helvetica, sans-serif",
                                backgroundColor: "lightsteelblue",
                                color: "black",
                                paddingLeft: 5,
                                paddingRight: 5,
                                borderRadius: 4,
                                cursor: "pointer",
                                // width: '100%',
                                textAlign: "center",
                                alignSelf: 'right',
                                float: 'right'
                            }}
                        >
                            {/* <Typography style={{ width: 250, padding: 5 }}> */}
                            CHOOSE
                            {/* </Typography> */}

                            <FileUploader
                                hidden
                                // accept="image/*"
                                storageRef={firebase.storage().ref("images")}
                                onUploadStart={handleUploadStart}
                                onUploadError={handleUploadError}
                                onUploadSuccess={handleUploadSingleSuccess}
                                onProgress={handleProgress}
                            />
                        </label>

                        {/* <div style={{ display: "flex", justifyContent: "center" }}>
                {progress ? <CircularProgress /> : <></>}
              </div> */}
                        {/* 
            {ImageDownloadUrls ?
              <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
                <ImageOutlinedIcon style={{ fontsize: 80 }} />
              </div>
              :
              <></>
            } */}

                    </div>
                </div>
            </div>
        </>
    )
}

const STEPASSET = (props) => {
    const { asset } = props
    return (
        <div
            sx={{
                backgroundColor: '#DCDCDC'
            }}
            style={{
                // width: '10%',
                background: '#DCDCDC',
                verticalAlign: 'middle',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'right',
                padding: 2,
                borderRadius: 5,
                margin: 0,
                cursor: 'pointer',
                float: 'right'
            }}>
            {asset}
        </div>
    )
}


/* to upload all kinds of media */
function PickImageDialog7(props) {
    let type2 = props.type;
    let src = (props.src) ? props.src : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
    const [open, setOpen] = React.useState(false);
    const [picSrc, setPicSrc] = useState(src);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setPicSource = (src) => {
        setPicSrc(src)
    }

    const onUpdateDocPicSource = (src) => {
        setPicSrc(src);

        // move up as we need to update data to master object
        props.onUpdateDocPicSource(src)
    }

    let type = type2;
    let newPicSrc = picSrc;
    if (newPicSrc) {
        if (newPicSrc.includes('pdf') || newPicSrc.includes('doc'))
            type = 'doc';
        else if (newPicSrc.includes('ppt'))
            type = 'ppt';

    }

    // base on type
    if (type) {
        switch (type) {
            case 'doc':
                newPicSrc = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
                break;
            case 'ppt':
                newPicSrc = 'https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024'
                break;
        }
    }

    console.log('ApplicationList.js PickImageDialog7 setPicSource type ' + type)
    console.log('ApplicationList.js PickImageDialog7 setPicSource newPicSrc ' + newPicSrc)

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}> */}
            {(type2 == 'video') ?
                <STEPASSET asset={
                    <Tooltip title="Add Video" placement="top">
                        <VideoCallIcon color="secondary"
                            onClick={handleClickOpen}
                        />
                    </Tooltip>

                } />
                :
                <STEPASSET asset={
                    <Tooltip title="Add Document" placement="top">
                        <Edit color="secondary"
                            onClick={handleClickOpen} />
                    </Tooltip>
                }

                />
            }
            {/* <STEPASSET asset={<VideoCallIcon color="secondary"
                onClick={handleClickOpen}
            />
             */}
            {/*
            <div
                style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: 10,
                    width: 100,
                    height: 140,
                    background: `url('')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                onClick={handleClickOpen}
            >
                <img
                    src={newPicSrc}
                    width={100}
                    height={140}
                />
            </div>
            */}

            <Dialog open={open} onClose={handleClose}

                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle>Upload Photo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose the picture (jpg/png)
                    </DialogContentText>

                    <UploadBox7
                        setDocPicSource={(src) => onUpdateDocPicSource(src)} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {/* <Button onClick={handleClose}>UPLOAD</Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://applier.co/">
                https://applier.co
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const SignUp = (props) => {
    const { isToken } = props;

    const [id, setId] = useState(1)
    const [level, setLevel] = useState("medium");
    const [board, setBoard] = useState(100001);
    const [superintendent, setSuperintendent] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [photo, setPhoto] = useState(null);
    const [address, setAddress] = useState("");
    const [summary, setSummary] = useState("");
    const [estd, setEstd] = useState("1970-01-01");
    const [count, setCount] = useState(0);
    const [city, setCity] = useState("");
    const [schState, setSchState] = useState("");
    const [location, setLocation] = useState("");
    const [pincode, setPincode] = useState(45545);
    const [code, setCode] = useState("");
    const [medium, setMedium] = useState("english");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const onUpdateDocPicSource = (pic) => {
        console.log(TAG, 'RegSch onUpdateDocPicSource(): ' + pic);
        setPhoto(pic);
    }

    const handleSubmitClicked = () => {

        // validate
        let proceed = true; // MAKE THIS TRUE

        if (!name || !email || !phone || !password
            || !photo) {

            MySwal.fire({
                title: 'Please fill in required fields!',
                icon: 'warning',
                confirmButtonText: 'Retry'
            });

            proceed = false;
        }

        if (proceed) {
            // password check
            if (password2 != password) {
                MySwal.fire({
                    title: 'Passwords do not match!',
                    icon: 'warning',
                    confirmButtonText: 'Retry'
                })

                proceed = false;
            }

        }

        if (proceed) {
            let formValues = ({
                school_type_id: id,
                school_level: level,
                school_board_id: board,
                schooler_superintendent_id: superintendent,
                school_name: name,
                school_email: email,
                school_phone: phone,
                school_photo: photo,
                school_addr: address,
                school_f_summary: summary,
                school_estd: estd,
                school_class_count: count,
                school_city: city,
                school_state: schState,
                school_location: location,
                school_pincode: pincode,
                school_reg_code: code,
                school_medium: medium,
                password: password
            })

            // show dialog

            console.log(formValues);
            setIsSubmitting(true);

            // show success
            MySwal.fire({
                title: 'Saving..',
                text: 'Your registration details are now getting saved..',
                icon: 'info',
                confirmButtonText: 'OK',
                didOpen: () => {
                    // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                    MySwal.showLoading()
                },
            })

            axios({
                method: 'post',
                url: URL,
                data: formValues,
            }).then(function (response) {

                console.log(TAG, 'SignUp handleSubmitClicked(): response ' + JSON.stringify(response));
                const data = response.data;

                const { msg, msg1 } = data;

                console.log(TAG, 'SignUp handleSubmitClicked(): msg ' + msg);
                console.log(TAG, 'SignUp handleSubmitClicked(): msg1 ' + msg1);

                // console.log(TAG, response);

                if (msg1 == 'exists') {
                    // exists message
                    MySwal.fire({
                        title: 'There was an error saving',
                        text: msg,
                        icon: 'error'
                    })
                } else if (msg1 == 'error') {
                    MySwal.fire({
                        title: 'There was an error saving',
                        text: msg,
                        icon: 'error'
                    })
                    // error message
                } else if (msg1 == 'pass') {
                    // success
                    setTimeout(function () {
                        navigate('/SchActivate')
                    }, 3000)
                    MySwal.close();

                }

                setIsSubmitting(false)
            }).catch(function (error) {
                console.log(TAG, error);
                setIsSubmitting(false);
                MySwal.close();
            })
        }

    }


    const onUpdateProfileClicked = async (e) => {

        let proceed = true;

        if (!name || !email || !phone
            || !photo) {
            proceed = false;

            MySwal.fire({
                title: 'There was an invalid input',
                text: "Please check the form if all fields were entered",
                icon: 'error'
            })

            setIsSubmitting(false);
        }

        if (proceed) {
            const body = {
                school_photo: photo,
                school_addr: address,
                school_f_summary: summary,
                school_city: city,
                school_location: location,
                school_pincode: pincode
            }

            setIsSubmitting(true)
            // show success
            MySwal.fire({
                title: 'Saving..',
                text: 'Saving your account..',
                icon: 'info',
                confirmButtonText: 'OK',
                // didOpen: () => {
                //     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                //     MySwal.showLoading()
                // },
            })
            const resp = await INSTITUTEAPI.updateProfileDetails(body);
            console.log(TAG, 'onUpdateProfileClicked() response ' + JSON.stringify(resp));

            setIsSubmitting(false);

            // update local storage
            localStorage.setItem("applier.inst.name", name);
            localStorage.setItem("applier.inst.photo", photo);
            MySwal.fire({
                title: 'Success!',
                text: "Your profile details were saved successfully!",
                icon: 'success'
            })
            // MySwal.close();
        } else {
            setIsSubmitting(false);
            // MySwal.close();
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };


    const _getTokenSchool = async () => {
        try {
            const resp = await INSTITUTEAPI.getSchoolDetails();
            console.log(TAG, '_getTokenSchool() response ' + JSON.stringify(resp));

            if (resp && resp.school_id) {
                props.setIsToken(true);

                // get details to prefill

                const { school_level, school_board_id,
                    schooler_superintendent_id,
                    school_name, school_email, school_phone, school_photo,
                    school_addr, school_f_summary, school_estd, school_class_count,
                    school_city, school_state, school_location, school_pincode,
                    school_reg_code, school_medium } = resp;

                setLevel(school_level);
                setBoard(school_board_id);
                setSuperintendent(schooler_superintendent_id);
                setName(school_name);
                setEmail(school_email);
                setPhone(school_phone);
                setPhoto(school_photo);
                setAddress(school_addr);
                setSummary(school_f_summary);
                setEstd(school_estd);
                setCount(school_class_count);
                setCity(school_city);
                setSchState(school_state);
                setLocation(school_location);
                setCode(school_reg_code);
                setPincode(school_pincode);
                setMedium(school_medium);


            } else {
                props.setIsToken(false); // un-authorize
            }

        } catch (error) {
            console.error(TAG, '_getTokenSchool() exception ' + error);
            props.setIsToken(false);
        }

    }


    useEffect(() => {

        // verify if already logged in and updating
        _getTokenSchool();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="/assets/img/schooler-applier.png" width="70%" />
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography component="h4" variant="h4">
                        {(isToken) ?
                            <>
                                Profile Update
                            </>
                            :
                            <>
                                School Registration
                            </>}

                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid> */}

                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="School Name"
                                    autoFocus
                                    value={name}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'name onChange val ' + e.target.value);
                                        setName(val)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    type={"email"}
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'email onChange val ' + val);
                                        setEmail(val)
                                    }}
                                />
                            </Grid>
                            {(!props.isToken) ?
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={(e) => {
                                                const val = e.target.value
                                                console.log(TAG, 'password onChange val ' + val);
                                                setPassword(val)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password2"
                                            label="Retype Password"
                                            type="password"
                                            id="password2"
                                            autoComplete="new-password"
                                            onChange={(e) => {
                                                const val = e.target.value
                                                console.log(TAG, 'password2 onChange val ' + val);
                                                setPassword2(val)
                                            }}
                                        />
                                    </Grid>
                                </>
                                :
                                <></>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="phone"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="School Phone"
                                    value={phone}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'phone onChange val ' + val);
                                        setPhone(val)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                <h6 variant="h6">Upload School Logo*</h6>

                                <div
                                    style={{
                                        width: '10%'
                                    }}>
                                    <PickImageDialog7
                                        // idx={idx}
                                        // planSetup={planSetup}
                                        // image={resource.url}
                                        // handleUpdatePlanSetup={(newPlanSetup) => props.handleUpdatePlanSetup(newPlanSetup)}
                                        // onClick={() => {
                                        //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
                                        // }}
                                        src={photo}
                                        onUpdateDocPicSource={(src) => onUpdateDocPicSource(src)}
                                        onAddStepVid={(idx, url) => props.onAddStepVid(idx, url)}
                                        type="document"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                {photo ?
                                    <img src={photo} width="100%" />
                                    :
                                    <></>
                                }
                            </Grid>
                            {/* address */}
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="address"
                                    multiline={true}
                                    rows={2}

                                    fullWidth
                                    id="address"
                                    label="School Address"
                                    value={address}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'address onChange val ' + val);
                                        setAddress(val)
                                    }}
                                />
                            </Grid>

                            {/* summary */}
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="summary"
                                    multiline={true}
                                    rows={2}

                                    fullWidth
                                    id="summary"
                                    label="School Summary"
                                    value={summary}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'summary onChange val ' + val);
                                        setSummary(val)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="city"

                                    fullWidth
                                    id="city"
                                    label="School City"
                                    value={city}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'city onChange val ' + val);
                                        setCity(val)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="state"

                                    fullWidth
                                    id="state"
                                    label="School State"
                                    value={schState}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'state onChange val ' + val);
                                        setSchState(val)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="location"

                                    fullWidth
                                    id="location"
                                    label="School Location"
                                    value={location}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'location onChange val ' + val);
                                        setLocation(val)
                                    }}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="given-name"
                                    name="code"

                                    fullWidth
                                    id="code"
                                    label="School Code"
                                    value={code}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        console.log(TAG, 'code onChange val ' + val);
                                        setCode(val)
                                    }}
                                />
                            </Grid>



                            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid> */}
                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => {
                                if (!isToken) {
                                    handleSubmitClicked();
                                } else {
                                    onUpdateProfileClicked();
                                }

                            }}
                            disabled={isSubmitting}
                        >
                            {(!isToken) ?
                                `Register`
                                :
                                `Update Profile`
                            }
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                {(isToken) ?
                                    <>
                                        <Link to="/login">Close</Link>
                                    </>
                                    :
                                    <>
                                        <Link to="/login" href="/login" variant="body2">
                                            Already have an account? Sign in
                                        </Link>
                                    </>
                                }

                            </Grid>
                        </Grid>

                        {/*                         
                        {isSubmitting ?
                            <MyComponent sentences={['Please wait while we process your form..']} />
                            :
                            <>

                            </>
                        }
                         */}
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}

const RegSch = () => {
    const [id, setId] = useState(1)
    const [level, setLevel] = useState("medium");
    const [board, setBoard] = useState(100001);
    const [superintendent, setSuperintendent] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [photo, setPhoto] = useState(null);
    const [address, setAddress] = useState("");
    const [summary, setSummary] = useState("");
    const [estd, setEstd] = useState("1970-01-01");
    const [count, setCount] = useState(0);
    const [city, setCity] = useState("");
    const [schState, setSchState] = useState("");
    const [location, setLocation] = useState("");
    const [pincode, setPincode] = useState(45545);
    const [code, setCode] = useState("");
    const [medium, setMedium] = useState("english");
    const [password, setPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isToken, setIsToken] = useState(false);

    const navigate = useNavigate();

    const onUpdateDocPicSource = (pic) => {
        console.log(TAG, 'RegSch onUpdateDocPicSource(): ' + pic);
        setPhoto(pic);
    }

    const REGFORM = (props) => {
        return (
            <>
                <div >
                    <div>
                        <h3>Welcome to Applier</h3>
                        <p>Please fill the below information for activation</p>
                    </div>
                    <div className="row" style={{
                        display: 'flex', gap: '50px', backgroundColor: 'white', width: '800px',
                        position: 'relative', top: '100px', left: '200px', paddingLeft: '100px'
                    }}>
                        <div className='col-md-12' >
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School id</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='id' value={id} onChange={(e) => setId(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School level</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='level' value={level} onChange={(e) => setLevel(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">Board</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='level' value={board} onChange={(e) => setBoard(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">Superintendent</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='superintendent' value={superintendent} onChange={(e) => setSuperintendent(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Name</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='name' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Email</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Phone</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Photo</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='photo' value={photo} onChange={(e) => setPhoto(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Address</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='address' value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Summary</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='summary' value={summary} onChange={(e) => setSummary(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School Estd</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='estd' value={estd} onChange={(e) => setEstd(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School Class Count</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='count' value={count} onChange={(e) => setCount(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School City</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='city' value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School State</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='schState' value={schState} onChange={(e) => setSchState(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Location</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='location' value={location} onChange={(e) => setLocation(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School Pincode</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='pincode' value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Code</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='code' value={code} onChange={(e) => setCode(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form" style={{ display: 'none' }}>
                                <label className="col-lg-3 col-form-label">School Medium</label>
                                <div className="ind-inp">
                                    <input type="text" className="form-control" name='medium' value={medium} onChange={(e) => setMedium(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row reg-form">
                                <label className="col-lg-3 col-form-label">School Password</label>
                                <div className="ind-inp">
                                    <input type="password" className="form-control" name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="submit" className="btn btn-primary"
                                    onClick={regSchHandler}
                                >Submit
                                    {/* <Audio
            height="80"
            width="80"
            radius="9"
            color='green'
            ariaLabel='three-dots-loading'
            wrapperStyle
            wrapperClass
        /> */}

                                    {/* <ReactLoading height={'20%'} width={'20%'} /> */}

                                </button>
                            </div>
                        </div>
                    </div>
                    {isSubmitting ?
                        <MyComponent sentences={['Please wait while we process your form..']} />
                        :
                        <>

                        </>
                    }
                </div>

            </>
        )
    }

    const regSchHandler = () => {
        let formValues = ({
            school_type_id: id,
            school_level: level,
            school_board_id: board,
            schooler_superintendent_id: superintendent,
            school_name: name,
            school_email: email,
            school_phone: phone,
            school_photo: photo,
            school_addr: address,
            school_f_summary: summary,
            school_estd: estd,
            school_class_count: count,
            school_city: city,
            school_state: schState,
            school_location: location,
            school_pincode: pincode,
            school_reg_code: code,
            school_medium: medium,
            password: password
        })
        console.log(formValues);
        setIsSubmitting(true);

        axios({
            method: 'post',
            url: URL,
            data: formValues,
        }).then(function (response) {
            setTimeout(function () {
                navigate('/SchActivate')
            }, 3000)
            console.log(TAG, response);
        }).catch(function (error) {
            console.log(TAG, error);
            setIsSubmitting(false);
        })

    }

    useEffect(() => {
        // _getTokenSchool();
    }, []);

    return (
        <div
            className='d-flex flex-column'>
            <SignUp
                regSchHandler={() => regSchHandler()}
                isToken={isToken}
                setIsToken={setIsToken}
            />


            {/* <REGFORM /> */}
        </div>
    )
}

export default RegSch;