import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Constants from '../resource/Constants';

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Button from '@mui/material/Button';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import COUNSELLORAPI from '../http/counsellor';

const MySwal = withReactContent(Swal);

const COUNSREGAPI = Constants.Application.APPLIER_END_URL + "/counsellor-profile-updation/"
const TAG = "CounsDetailForm.jsx";

const UploadBox7 = (props) => {
  const profilePictureRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const { planSetup } = props;

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  }

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {

    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    props.setDocPicSource(downloadURL)
    // setUploadProgress(100)
    // setIsUploading(false)

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let imgSrc = "https://www.nicepng.com/png/detail/129-1298055_file-upload-comments-icons-for-file-upload.png";

  if (ImageDownloadUrls) {
    imgSrc = ImageDownloadUrls;
  }

  const setPicSource = (src) => {
    setImageDownloadUrls(src)
  }

  let type = null;
  let newPicSrc = imgSrc;
  if (newPicSrc) {
    if (newPicSrc.includes('pdf') || newPicSrc.includes('doc'))
      type = 'doc';
    else if (newPicSrc.includes('ppt')) {
      console.log('ApplicationList.js UploadBox7: newPicSrc has PPT')
      type = 'ppt';
    }

  }

  // base on type
  if (type) {
    switch (type) {
      case 'doc':
        newPicSrc = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png';
        break;
      case 'ppt':
        newPicSrc = 'https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024';
        break;
    }
  }

  console.log('ApplicationList.js UploadBox7 setPicSource type ' + type)
  console.log('ApplicationList.js UploadBox7 setPicSource newPicSrc ' + newPicSrc)

  return (
    <>
      <div
        className='d-flex flex-column align-items-center justify-content-center'
        style={{
          // height: 200
          width: '100%'
        }}>

        {progress ? <CircularProgress />
          :

          <img
            src={newPicSrc}
            style={{
              width: '50%',
              height: '100%',

            }} />
        }

        <div
          style={{
            alignSelf: 'right'
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginLeft: 15,
              // marginRight: 15,
            }}
          >
            <label
              style={{
                fontFamily: "Poppins, Helvetica, sans-serif",
                backgroundColor: "lightsteelblue",
                color: "black",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 4,
                cursor: "pointer",
                // width: '100%',
                textAlign: "center",
                alignSelf: 'right',
                float: 'right'
              }}
            >
              {/* <Typography style={{ width: 250, padding: 5 }}> */}
              CHOOSE
              {/* </Typography> */}

              <FileUploader
                hidden
                // accept="image/*"
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSingleSuccess}
                onProgress={handleProgress}
              />
            </label>

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div> */}
            {/* 
          {ImageDownloadUrls ?
            <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
              <ImageOutlinedIcon style={{ fontsize: 80 }} />
            </div>
            :
            <></>
          } */}

          </div>
        </div>
      </div>
    </>
  )
}

const STEPASSET = (props) => {
  const { asset } = props
  return (
    <div
      sx={{
        backgroundColor: '#DCDCDC'
      }}
      style={{
        background: '#DCDCDC',
        verticalAlign: 'middle',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 2,
        borderRadius: 5,
        margin: 0,
        cursor: 'pointer'
      }}>
      {asset}
    </div>
  )
}


/* to upload all kinds of media */
function PickImageDialog7(props) {
  let type2 = props.type;
  const [open, setOpen] = React.useState(false);
  const [picSrc, setPicSrc] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPicSource = (src) => {
    setPicSrc(src)
  }

  const onUpdateDocPicSource = (src) => {
    setPicSrc(src);

    // move up as we need to update data to master object
    props.onUpdateDocPicSource(src)
  }

  let type = type2;
  let newPicSrc = picSrc;
  if (newPicSrc) {
    if (newPicSrc.includes('pdf') || newPicSrc.includes('doc'))
      type = 'doc';
    else if (newPicSrc.includes('ppt'))
      type = 'ppt';

  }

  // base on type
  if (type) {
    switch (type) {
      case 'doc':
        newPicSrc = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
        break;
      case 'ppt':
        newPicSrc = 'https://www.pcworld.com/wp-content/uploads/2021/09/pcw-ppt-primary-100787681-orig-1.jpg?quality=50&strip=all&w=1024'
        break;
    }
  }

  console.log('ApplicationList.js PickImageDialog7 setPicSource type ' + type)
  console.log('ApplicationList.js PickImageDialog7 setPicSource newPicSrc ' + newPicSrc)

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}> */}
      {(type2 == 'video') ?
        <STEPASSET asset={
          <Tooltip title="Add Video" placement="top">
            <VideoCallIcon color="secondary"
              onClick={handleClickOpen}
            />
          </Tooltip>

        } />
        :
        <STEPASSET asset={
          <Tooltip title="Add Document" placement="top">
            <NoteAddIcon color="secondary"
              onClick={handleClickOpen} />
          </Tooltip>
        }

        />
      }
      {/* <STEPASSET asset={<VideoCallIcon color="secondary"
              onClick={handleClickOpen}
          />
           */}
      {/*
          <div
              style={{
                  border: '1px #DCDCDC solid',
                  borderRadius: 10,
                  width: 100,
                  height: 140,
                  background: `url('')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
              }}
              onClick={handleClickOpen}
          >
              <img
                  src={newPicSrc}
                  width={100}
                  height={140}
              />
          </div>
          */}

      <Dialog open={open} onClose={handleClose}

        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Upload Photo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose the picture (jpg/png)
          </DialogContentText>

          <UploadBox7
            setDocPicSource={(src) => onUpdateDocPicSource(src)} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>UPLOAD</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const CounsDetailForm = () => {
  const [email, setEmail] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState('');
  const [photo, setPhoto] = useState('')
  const [password, setPassword] = useState([]);
  const [passw2, setPassw2] = useState("");
  const [councellor_photo, setCouncellor_photo] = useState("")
  const [isToken, setIsToken] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const search = useLocation().search;
  const email2 = new URLSearchParams(search).get('email');

  const navigate = useNavigate();

  const onUpdateProfileClicked = async (e) => {

    let proceed = false;

    if (name) {
      proceed = true;

    } else {
      proceed = false;
      MySwal.fire({
        title: 'There was an invalid input',
        text: "Please check the form if all fields were entered",
        icon: 'error'
      })

      setIsSubmitting(false);
    }

    if (proceed) {
      const body = {
        councellor_name: name,
        councellor_photo_url: photo
      }

      setIsSubmitting(true)
      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Saving your account..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await COUNSELLORAPI.updateProfileDetails(body);
      console.log(TAG, 'onUpdateProfileClicked() response ' + JSON.stringify(resp));

      setIsSubmitting(false);

      // update local storage
      localStorage.setItem("applier.couns.name", name);
      localStorage.setItem("applier.couns.photo", photo);
      MySwal.fire({
        title: 'Success!',
        text: "Your profile details were saved successfully!",
        icon: 'success'
      })
      // MySwal.close();
    } else {
      setIsSubmitting(false);
      MySwal.close();
    }
  }

  const counsFormDetails = async (e) => {
    let detail = { email, name, phone, password, councellor_photo }

    console.log(TAG, 'email ' + email);
    console.log(TAG, 'name ' + name);
    console.log(TAG, 'password ' + password);
    console.log(TAG, 'councellor photo ' + councellor_photo);
    let proceed = true;

    setIsSubmitting(true)
    if (!email || !name || !password || !councellor_photo) {

      proceed = false;
      MySwal.fire({
        title: 'There was an invalid input',
        text: "Please check the form if all fields were entered",
        icon: 'error'
      })

      setIsSubmitting(false);
    }

    if (proceed) {

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Saving your account..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })

      if (password === passw2) {
        console.log(TAG, "consFormDeatails detail URL " + COUNSREGAPI);
        console.log(TAG, "counsFormDetails detail body " + JSON.stringify(detail))
        const response = await axios.put(COUNSREGAPI, detail);
        console.log(response.data);
        console.log(response.data.msg);
        if (response.data.msg === "updated") {

          navigate('/login')
        }

      } else {
        // alert("Password didn't match")
        MySwal.fire({
          title: 'Passwords do not match!',
          text: "Please check the form if passwords are same",
          icon: 'error'
        })

        setIsSubmitting(false);
        MySwal.close();
      }
    } else {
      setIsSubmitting(false);
      MySwal.close();
    }

  }

  const onUpdateDocPicSource = (src) => {
    // setPicSrc(src);

    // move up as we need to update data to master object
    setPhoto(src)
    setCouncellor_photo(src)
  }

  const _getTokenCounsellor = async () => {
    try {
      const resp = await COUNSELLORAPI.getCounsellorDetails();
      console.log(TAG, '_getTokenCounsellor() response ' + JSON.stringify(resp));

      if (resp && resp.councellor_id) {
        const { councellor_id, councellor_name, councellor_email, councellor_phone, councellor_photo } = resp;

        setIsToken(true);
        setEmail(councellor_email);
        setName(councellor_name)
        setPhone(councellor_phone);
        setPhoto(councellor_photo);
      } else {
        setIsToken(false); // un-authorize
      }

    } catch (error) {
      console.error(TAG, '_getTokenCounsellor() exception ' + error);
      setIsToken(false);
    }


  }

  useEffect(() => {
    setEmail(email2)

    // if token exists get from back-end
    _getTokenCounsellor();

  }, [])

  return (
    <div className="row" style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh'
    }}>

      <div className="col-xl-6">

        {/* logo */}
        <div className='logo1 d-flex flex-row justify-content-center'>

          <img src="/assets/img/applier_logo1.png" height={100} />
        </div>

        <div className="card1">
          <div className="card-header1 mb-5">
            <h5 className="card-title1"  >Update your Profile</h5>
          </div>
          <div className="card-body1">

            <div className="form-group row">
              <label className="col-lg-3 col-form-label" >Counsellor Email*</label>
              <div className="col-lg-9">
                <input type="email" className="form-control" name='email' value={email}
                  onChange={(e) => setEmail(e.target.value)} readOnly required />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Counsellor Name*</label>
              <div className="col-lg-9">
                <input type="text" className="form-control" name='name' value={name}
                  onChange={(e) => setName(e.target.value)} required />
              </div>
            </div>
            <div className="form-group row">

            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Counsellor Phone</label>
              <div className="col-lg-9">
                <input type="text" className="form-control" name="phone" value={phone}
                  onChange={(e) => setPhone(e.target.value)} placeholder="Optional" />
              </div>
            </div>

            {
              (!isToken) ?
                <>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Password*</label>
                    <div className="col-lg-9">
                      <input type="password" className="form-control" name='password' value={password}
                        onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">Re-type password*</label>
                    <div className="col-lg-9">
                      <input type="password" className="form-control" name='password' value={passw2}
                        onChange={(e) => setPassw2(e.target.value)} required />
                    </div>
                  </div>
                </>
                :
                <></>
            }


            <div className="form-group row">
              <label className="col-lg-3 col-form-label">Upload profile picture*</label>
              <div className="col-lg-9">

                <p>
                  {photo ?
                    <img src={photo} width="100%" />
                    :
                    <></>
                  }
                </p>


                <div className='w-25'>

                  <PickImageDialog7
                    // idx={idx}
                    // planSetup={planSetup}
                    // image={resource.url}
                    // handleUpdatePlanSetup={(newPlanSetup) => props.handleUpdatePlanSetup(newPlanSetup)}
                    // onClick={() => {
                    //     props.onAddStepImage(idx, 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d')
                    // }}
                    onUpdateDocPicSource={(src) => onUpdateDocPicSource(src)}

                    type="document"
                  />
                </div>
              </div>
            </div>


            <div className="text-end m-20 d-flex flex-horizontal justify-content-end">
              <button
                type="button"
                className='btn btn-secondary'
                onClick={() => {
                  navigate('/login')
                }}
              >Close</button>
              <div className='me-3'></div>
              <button
                disabled={isSubmitting}
                type="button" className="btn btn-primary" onClick={() => {
                  if (isToken) {
                    onUpdateProfileClicked();
                  } else {
                    counsFormDetails()
                  }
                }}>Update</button>

            </div>

          </div>
        </div>
      </div>

    </div>

  )
}

export default CounsDetailForm