import React, { useState } from 'react'
import { TagsInput } from "react-tag-input-component";

const CampaignForm = () => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(["Admissions"]);



    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <>
            <div >
                <button onClick={handleOpenModal}>Open Modal</button>
                <div className={`modalForm-overlay ${showModal ? "active" : ""}`}>
                    <div className={`modalForm-content ${showModal ? "active" : ""}`}>
                        <span className="close" onClick={handleCloseModal} style={{cursor:'pointer'}}>
                            &times;
                        </span>
                        <div>
                            <div className="" style={{height:"80vh", overflow:'scroll'}}>
                                <div className="">
                                    <div className="row">
                                        <h3>
                                            Create Campaign
                                        </h3>

                                        <div className="col-xl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="bank-inner-details">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-group">
                                                                    <label>Title<span className="text-danger"></span></label>
                                                                    <input type="text" className="form-control" value="" placeholder='Define a title for your campaign' />
                                                                </div>
                                                            </div>

                                                            <div style={{ width: '500px' }}>
                                                                Add hash tags for campaign
                                                                <TagsInput
                                                                    value={selected}
                                                                    onChange={setSelected}
                                                                    name="fruits"
                                                                    placeHolder="#tags"
                                                                />
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-group">
                                                                    <label>Upload File/Image</label>
                                                                    <div className="change-photo-btn">
                                                                        <div>
                                                                            <p>Upload File</p></div>
                                                                        <input type="file" className="upload" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-group w-50">
                                                                    <form action="index.html">
                                                                        <div className="form-group">
                                                                            <label>Facebook <span className=""></span></label>
                                                                            <div >
                                                                                <input className="form-control" type="text" />
                                                                                <button>
                                                                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                                                                    Facebook
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Instagram <span className=""></span></label>
                                                                            <input className="form-control pass-input" type="text" />
                                                                            <button >
                                                                                <a href="#"><i class="fab fa-google-plus-g"></i></a>
                                                                                Google
                                                                            </button>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Twitter <span className=""></span></label>
                                                                            <input className="form-control pass-input" type="text" />
                                                                            <button>
                                                                                <a href="#"><i class="fab fa-twitter"></i></a>
                                                                                Twitter
                                                                            </button>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>LinkedIn<span className=""></span></label>
                                                                            <input className="form-control pass-input" type="text" />
                                                                            <button>
                                                                                <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                                                                LinkedIn
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className=" blog-categories-btn pt-0 ">
                                                        <div className="bank-details-btn ">
                                                            <a href="blog.html" className="btn bank-cancel-btn me-2">Add Post</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignForm