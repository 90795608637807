import React, { useEffect, useState } from 'react';
import PageNav from './CounsellorSidebar';
import { BiSort } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';

import INSTITUTEAPI from '../http/institute';
import Alert from 'react-bootstrap/Alert';

import SearchIcon from '@mui/icons-material/Search';
import Form from 'react-bootstrap/Form';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Sidebar from './Sidebar';

const MySwal = withReactContent(Swal);

const stat = 'APPLICATION';
const pageType = 'PAYMENTS';
const TAG = 'Payments.jsx';

const PROOFBUTTON = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}
        title="Upload payment proof">
        <i class="fa fa-image fa-2x"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Check proof of the payment before you approve.</p>
          <small>Please upload proof for further evidence. <i className="fa fa-upload"></i></small>&nbsp;<i className="fa fa-check text-success"></i>&nbsp;<i className="fa fa-times text-danger"></i>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Confirm Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const NoteModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'NoteModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setChannel(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setNotes(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleClose();
      props.handleSaveNote();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the activity notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const PAYMENTROW = (props) => {

  const { payment, lead } = props;
  const { enquiry_id, admission_form_id } = payment;
  console.log(TAG, 'PAYMENTROW payment ' + JSON.stringify(payment));

  const onChangeStatus = (e) => {

    const applId = payment.admission_form_id;
    const { name, value } = e.target;

    console.log(TAG, 'PAYMENTROW onChangeStatus(): name ' + name);
    console.log(TAG, 'PAYMENTROW onChangeStatus(): value ' + value);

    props.onChangeStatus(value, applId);
  }

  let stat2 = payment.admission_status;
  let sSel = 'selected="selected"';
  let noShow = (stat2 == 'NO_SHOW') ? sSel : '';
  let paid = (stat2 == 'PAID') ? sSel : '';
  let unPaid = (stat2 == 'UNPAID') ? sSel : '';
  let noProof = (payment.payment_verify_url == '');

  return (
    <tr>
      <td>{payment.admission_form_id}</td>
      <td>
        <h2 class="table-avatar">
          <a
            href="IndividualStudent.html"
            class="avatar avatar-sm me-2"
          >
            <img
              class="avatar-img rounded-circle"
              src="assets/img/profiles/avatar-02.jpg"
              alt="User Image"
            />
          </a>
          <Link to={`/lead-details?enquiry_id=${payment.enquiry_id}`}>{payment.Student_name}
            <br />

          </Link>
        </h2>
      </td>
      {/* <td style={{ textAlign: 'center' }}>2 <sup>nd</sup> </td> */}
      <td style={{ textAlign: 'center' }}>{payment.student_class_applied}</td>
      <td style={{ textAlign: 'center' }} className="d-flex flex-row justify-content-center">
        {/* <select>
            <option>No Show</option>
            <option>Pass</option>
            <option>Fail</option>
          </select> */}
        <div className='w-50'>


          {(paid) ?
            <>PAID</>
            :
            <>UNPAID</>
          }


        </div>
      </td>
      {/* <td>Aravind</td>
  
        <td className="text-end" style={{ width: '10px' }}>
          <div className="dropdown dropdown-action">
            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
            <div className="dropdown-menu dropdown-menu-end">
              <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
              <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>
  
            </div>
          </div>
        </td> */}

      <td style={{ width: '20%' }}>
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleNoteShown(); props.handleAddNote(lead)
          }}
        >
          <i className='fa fa-edit fa-1x'></i>
        </a>
      </td>
      <td>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          {/* <img src="https://templates.invoicehome.com/invoice-template-us-neat-750px.png" class="img-responsive" width="96px" /> */}


          {/* <PROOFBUTTON /> */}
          {(!noProof) ?
            <i className="fa fa-check text-success fa-2x"></i>
            :
            <></>
          }

          {(noProof) ?
            <i className='fa fa-exclamation text-warning fa-2x'></i>
            :
            <></>
          }
        </div>
      </td>
    </tr>
  )
}

const PAYMENTSLIST = (props) => {
  const { payments } = props;


  if (!payments || !payments.length) {
    return (
      <Alert variant="warning">
        <Alert.Heading>No payments yet!</Alert.Heading>
        <p>
          No payments are shown here as they have not yet been set in the system. Start setting up now!
        </p>
        <hr />
        <p className="mb-0">
          Payments can be achieved by converting results.
        </p>
      </Alert>
    )
  }

  return (
    <>
      <table
        class="table border-0 star-student  table-center mb-0 datatable"
        style={{ border: "1px solid black" }}
      >
        <thead class="student-thread">
          <tr>
            <th>
              Application Id <BiSort />
            </th>
            <th>
              Name <BiSort />
            </th>
            <th style={{ textAlign: 'center' }}>
              Grade <BiSort />
            </th>
            <th style={{ textAlign: 'center' }}>
              Status <BiSort />
            </th>
            <th>Add notes <BiSort /></th>
            <th style={{ textAlign: 'center' }}>
              Payment proof
            </th>
            {/* <th>
                Counsellor <BiSort />
              </th>
  
              <th class="text-end">Action</th> */}
          </tr>
        </thead>
        <tbody>

          {payments.map((payment, index) => {
            return <PAYMENTROW
              lead={payment}
              payment={payment}
              key={index}
              index={index}
              onChangeStatus={(stat, applId) => props.onChangeStatus(stat, applId)}

              handleAddNote={(lead) => props.handleAddNote(lead)}
              handleNoteShown={() => props.handleNoteShown()}
              currLead={props.currLead}
              setCurrLead={(lead) => props.setCurrLead(lead)}
            />
          })}




        </tbody>
      </table>
    </>
  )
}


const SEARCHBAR = (props) => {
  const { applications, oApplications } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.admission_form_id.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_name.toString().includes(value)
          || itm.Student_name.toString().includes(value)
        ) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title"></h3>
  
              </div>
            </div>
          </div>
        </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
              <div className="form-group1">
                <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
              </div>
            </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
              <Button variant="contained" endIcon={<SearchIcon />}>
                Search
              </Button>
            </div> */}
        </div>
      </div>
    </div>
  )
}


const CounsPayment = () => {

  const [applications, setApplications] = useState(null);
  const [oApplications, setOApplications] = useState(null);
  const [currApp, setCurrApp] = useState(null);
  const [stat2, setStat2] = useState('');
  const [applicationId2, setApplicationId2] = useState(0);
  const [onShow, setOnShow] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const [notes, setNotes] = useState(null)
  const [channel, setChannel] = useState(null)
  const [currLead, setCurrLead] = useState(null)


  const handleNoteShown = () => {
    setShowNote(true);
  }

  const handleAddNote = (lead) => {
    setCurrLead(lead);
  }

  const handleNoteClose = () => {
    setShowNote(false);
  }

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);

    // now reload applications
    _loadApplications();

  }

  const handleClose2 = () => {
    setShow(false);
    _onUpdateApplicationStatus2();
  }

  const handleShow = () => setShow(true);


  const _loadApplications = async () => {
    const data = await INSTITUTEAPI.getApplications(stat, pageType);
    console.log(TAG, '_loadApplications() data: ' + JSON.stringify(data));

    setApplications(data);
    setOApplications(data);
  }

  const _onUpdateApplicationStatus = async (stat, applicationId) => {
    console.log(TAG, '_onUpdateApplicationStatus() stat ' + stat);
    console.log(TAG, '_onUpdateApplicationStatus() applicationId ' + applicationId);

    setStat2(stat);
    setApplicationId2(applicationId);

    if (stat == 'PAID')
      handleShow();
    else
      // silently update status
      _onUpdateApplicationStatus2(stat, applicationId);

  }

  const _onUpdateApplicationStatus2 = async (stat, applicationId) => {
    if (!stat) stat = stat2;
    if (!applicationId) applicationId = applicationId2;

    const body = {
      admission_form_id: applicationId,
      admission_status: stat,
      admission_status1: "",
      payment_verify_url: ""
    }

    console.log(TAG, '_onUpdateApplicationStatus() body ' + JSON.stringify(body));

    const resp = await INSTITUTEAPI.postSaveApplicationStatus(body);
    console.log(TAG, '_onUpdateApplicationStatus() resp ' + JSON.stringify(resp));

    MySwal.fire('Success', 'Payment status updated successfully!', 'info');

    // reload the page
    _loadApplications();
  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  const handleSaveNote = async () => {

    console.log(TAG, 'handleSaveNote(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveNote(): notes ' + notes);
    console.log(TAG, 'handleSaveNote(): channel ' + channel);

    const { enquiry_id } = currLead;
    if (notes && enquiry_id > 0 && channel) {
      const body = {
        enquiry_id: enquiry_id,
        page_feature: channel,
        activity_text: notes
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Note for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await INSTITUTEAPI.postSaveActivityNotes(body);

      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleNoteClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleNoteShown();
    }
  }

  useEffect(() => {
    _loadApplications();
  }, [])

  return (
    <div>
      <Sidebar />
      <div class="page-wrapper">
        <div class="content container-fluid">

          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-sub-header">
                  <h3 class="page-title">PAYMENTS</h3>
                  <SEARCHBAR
                    applications={applications}
                    oApplications={oApplications}
                    setApplications={setApplications}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card card-table comman-shadow">
                <div class="card-body">
                  <div class="page-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <div class="col" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h3 class="page-title">Admission Fee</h3>
                        </div>
                      </div>
                      <div class="col-auto text-end float-end ms-auto download-grp">
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive" >


                    <PAYMENTSLIST
                      payments={applications}
                      onChangeStatus={(stat, applId) => _onUpdateApplicationStatus(stat, applId)}
                      onShowEditForm={(lead) => onShowEditForm(lead)}
                      setOnShow={setOnShow}
                      handleAddNote={(lead) => handleAddNote(lead)}
                      handleSaveNote={(lead) => handleSaveNote(lead)} /* show modal */
                      showNote={showNote}
                      handleClose={() => handleNoteClose()}
                      handleNoteShown={() => handleNoteShown()}
                    />



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>


      {/* NOTE MODAL */}
      <NoteModal
        show={showNote}
        handleClose={() => handleNoteClose()}
        handleNoteShown={() => handleNoteShown()}
        handleSaveNote={(lead) => handleSaveNote(lead)}
        setNotes={(note) => setNotes(note)}
        currLead={currLead}
        setCurrLead={setCurrLead}
        handleChangeNote={(val) => setNotes(val)}
        setChannel={(val) => setChannel(val)}
      />


      {/* modal for payment proof */}
      <>
        <Button variant="primary" onClick={handleShow}
          title="Upload payment proof">
          <i class="fa fa-image fa-2x"></i>
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Check proof of the payment before you approve.</p>
            <small>Please upload proof for further evidence. <i className="fa fa-upload"></i></small>&nbsp;<i className="fa fa-check text-success"></i>&nbsp;<i className="fa fa-times text-danger"></i>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleClose2}>
              Confirm Payment
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default CounsPayment