import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar'
import { BiPlusMedical } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';


const AddCounsellor = () => {

    const [schCounsellors, setSchCounsellors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <Sidebar />
            <div className='page-wrapper'>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Add Counsellor
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Appoint Counsellor</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please Enter the email you wish to register as a Counsellor, a link will be sent to his/her email, once clicked on the link
                            account gets activated as a counsellor
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleClose}>Send</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default AddCounsellor