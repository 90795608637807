import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Constants from '../resource/Constants';

const URL = Constants.Application.APPLIER_END_URL + "/school-registration/"

const SchoolRegister = () => {
    const [id, setId] = useState([])
    const [level, setLevel] = useState([]);
    const [board, setBoard] = useState([]);
    const [Superintendent, setSuperintendent] = useState([]);
    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [phone, setPhone] = useState([]);
    const [photo, setPhoto] = useState([]);
    const [address, setAddress] = useState([]);
    const [summary, setSummary] = useState([]);
    const [estd, setEstd] = useState([]);
    const [count, setCount] = useState([]);
    const [city, setCity] = useState([]);
    const [schState, setSchState] = useState([]);
    const [location, setLocation] = useState([]);
    const [pincode, setPincode] = useState([]);
    const [code, setCode] = useState([]);
    const [medium, setMedium] = useState([]);
    const [password, setPassword] = useState([])


    const checkResult = (e) => {
        e.preventDefault();
        console.log({ id, level, board, Superintendent, name, email, phone, photo, address, summary, estd, count, city, schState, location, pincode, code, medium, password });
        let data = ({
            "school_type_id": 1, "school_level": "medium",
            "school_board_id": 100001, "schooler_superintendent_id": 1,
            name, email, phone, photo, address, summary, "school_estd": "2022-04-12", count, city, schState, location, pincode, "school_reg_code": "454",
            "school_medium": "english",
            "password": "venkat"
        });
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {
                console.log("resp", resp)
            })
        })
    }

    return (
        <div>
            <Sidebar />

            <div className="page-wrapper"  >

                <div className="row ">
                    <div className="sch-detail" >
                        <div className="col-xl-6 d-flex ">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">School Details</h5>
                                </div>
                                <div className="card-body" >
                                    <form action="#" style={{ marginLeft: '50px' }}>
                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School id</label>
    <div className="col-lg-9">
    <input type="text" className="form-control" name="school_type_id" 
    value={id} onChange={(e)=>setId(e.target.value)} />
    </div>
    </div> */}
                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School Level</label>
    <div className="col-lg-9">
    <input type="text" className="form-control" name='school_level' 
    value={level} onChange={(e)=>setLevel(e.target.value)} />
    </div>
    </div> */}

                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School Superintendent Id</label>
    <div className="col-lg-9">
    <input type="text" className="form-control" name="schooler_superintendent_id"
    value={Superintendent} onChange={(e)=>setSuperintendent(e.target.value)} />
    </div>
    </div> */}
                                        <div className=" ">
                                            <label className="col-lg-3 col-form-label">Name</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_name"
                                                    value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School Board</label>
    <div className="col-lg-9">
    <input type="text" className="form-control" name="school_board_id"
    value={board} onChange={(e)=>setBoard(e.target.value)} />
    </div>
    </div> */}
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Official Email</label>
                                            <div className="col-lg-9">
                                                <input type="email" className="form-control" name="school_email"
                                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Phone Number</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_phone"
                                                    value={phone} onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                        </div>
                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School Reg-code</label>
    <div className="col-lg-9">
    <input type="text" className="form-control" name="school_photo"
    value={photo} onChange={(e)=>setPhoto(e.target.value)} />
    </div>
    </div> */}
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Medium</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_class_count"
                                                    value={medium} onChange={(e) => setMedium(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Logo</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_photo"
                                                    value={photo} onChange={(e) => setPhoto(e.target.value)} />
                                            </div>
                                        </div>

                                        <h5 style={{ marginTop: '20px' }}> School Address</h5>

                                        <div className="">
                                            <label className="">Address, H.No., Flat, building, Apartment </label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_addr"
                                                    value={address} onChange={(e) => setAddress(e.target.value)} />
                                            </div>
                                        </div>
                                        {/* <div className="">
                                            <label className="col-lg-3 col-form-label">School Summary</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_f_summary"
                                                    value={summary} onChange={(e) => setSummary(e.target.value)} />
                                            </div>
                                        </div> */}
                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School estd</label>
    <div className="col-lg-9">
    <input type="text" className="form-control"   name="school_class_count"
    value={estd} onChange={(e)=>setEstd(e.target.value)} />
    </div>
    </div> */}

                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Town / City</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_city"
                                                    value={city} onChange={(e) => setCity(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">State</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_state"
                                                    value={schState} onChange={(e) => setSchState(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="">Area, street, sector, village</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_pincode"
                                                    value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label className="col-lg-3 col-form-label">Pincode</label>
                                            <div className="col-lg-9">
                                                <input type="text" className="form-control" name="school_reg_code"
                                                    value={code} onChange={(e) => setCode(e.target.value)} />
                                            </div>
                                        </div>


                                        {/* <div className="">
    <label className="col-lg-3 col-form-label">School Password</label>
    <div className="col-lg-9">
    <input type="password" className="form-control"  name="School_medium"
    value={medium} onChange={(e)=>setMedium(e.target.value)}  />
    </div>
    </div> */}
                                        {/* 
    <div className="">
    <label className="col-lg-3 col-form-label">School Password</label>
    <div className="col-lg-9">
    <input type="password" className="form-control"  name="password"
    value={password} onChange={(e)=>setPassword(e.target.value)}  />
    </div>
    </div> */}
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary" onClick={checkResult}>Submit</button>
                                        </div>

                                        {/* <div className="text-end">
    <button type="submit" className="btn btn-primary">Submit</button>
    </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}

export default SchoolRegister