/**
 * application constants
 * 
 */




const Constants = {
    Application: {
        PLATFORM_END_URL: 'https://admin.kgtopg.com',
        APPLIER_END_URL: 'https://learner.kgtopg.com',
        // APPLIER_END_URL: 'http://192.168.29.224/applier',
        SITE_URL: 'https://applier.co',
        // SITE_URL: 'http://localhost:3000',
        // PLATFORM_END_URL: 'http://192.168.29.224/platform',
        PARTNER_END_URL: 'https://partner.kgtopg.com',
        // PARTNER_END_URL: 'http://192.168.29.224/partner',
        NODEJS_END_URL: 'https://api.kgtopg.com',
        // NODEJS_END_URL: 'http://192.168.29.224/nodeserver',
        QBANK_END_URL: 'https://qbank.co.in',
        // QBANK_END_URL: 'http://192.168.29.224/qbank',
        PUSTAK_URL: 'https://pustak.co',
        // PUSTAK_URL: 'http://192.168.29.224/pustak',
        PUSTAK_BOOKS_URL: 'https://pustak.co/static/books',
        // PUSTAK_BOOKS_URL: 'http://192.168.29.224/pustak/static/books',
        AUTH_END_URL: 'https://auth.kgtopg.com',
        // AUTH_END_URL: 'http://192.168.29.224/auth',
        // PLATFORM_END_URL: 'http://192.168.10.117:8002',
        // PARTNER_END_URL: 'http://192.168.10.117:8004',
        TEMP_END_URL: 'http://192.168.10.117:8003',
        TEMP_END_URL1: 'http://192.168.10.117:8001',
        FIREBASE: {
            API_KEY: 'AIzaSyBTCkmhlLRvtmztSh9yIl_rgfXvieYg3eY',
            AUTH_DOMAIN: 'kgtopg-profiles.firebaseapp.com',
            // DATABASE_URL: 'https://<DATABASE_NAME>.firebaseio.com',
            STORAGE_BUCKET: 'kgtopg-profiles.appspot.com'
        },
        ROLE_CONT_PROV: "CONTENT_PROVIDER",
        ROLE_EBOOK_PUB: "EBOOK_PROVIDER",
        ROLE_CONT_EBOOK_PUB: "CONTENT_PROVIDER AND PUBLISHER",
        QUIZ: {
            ID_QTYPE_MULTIPLE_CHOICE: 201,
            ID_QTYPE_MULTIPLE_SELECT: 202,
            ID_QTYPE_TRUE_FALSE: 203,
            ID_QTYPE_TEXT_TYPE: 204,
            ID_QTYPE_PICTURE_CHOICE: 205,
            ID_QTYPE_MATCH_FLW: 206
        },
        NAME_AIM: 'aim',
        NAME_ACTION: 'action',
        NAME_ANALYSIS: 'analysis',
        NAME_APPLICATION: 'application',
        NAME_ASSESSMENT: 'assessment',
        ROLE_COUNSELOR: "Counselor",
        ROLE_INSTITUTE: "Institute",
        COLOR_PRIMARY: '#5664D2',
        COLOR_SECONDARY: '#EEE6FF',
        COLOR_INFO: '#9C27B0',
        COLOR_LIGHT: '#EEEEEE',
        COLOR_PRIMARY_LIGHT: '#EEE6FF',
        // store keys
        APPLIER_STORE_SCHOOLID: "applier.inst.id",
        APPLIER_STORE_SCHOOLNAME: "applier.inst.name",
        APPLIER_STORE_SCHOOLEMAIL: "applier.inst.email",
        APPLIER_STORE_SCHOOLLOGO: "applier.inst.logo",
    }
}

export default Constants;
