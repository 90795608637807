import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API from '../http/auth';
import AuthAPI from '../http/auth';
import { Link } from 'react-router-dom';
import Constants from '../resource/Constants';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserSession from '../resource/UserSession';

import firebase from "firebase";
// import firebaseui from 'firebaseui'
import firebaseConfig from '../firebaseConfig';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap/Modal';
import INSTITUTEAPI from '../http/institute';

const notify = () => toast("Please check your input");
const LOGINURL = Constants.Application.PLATFORM_END_URL + "/user-signin/"
const LEADREG = Constants.Application.APPLIER_END_URL + "/lead-registration/"

const TAG = "Login.jsx";
// import * as firebaseui from "firebaseui";
// import firebase from "firebase";

// initialize
try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }
} catch (error) {

}
firebase.auth().languageCode = 'en';

// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

// window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);

function SMSModal(props) {
  const [smsCode, setSmsCode] = useState("");
  const [show, setShow] = useState("");

  const handleClose = () => {
    setShow(false);
    props.setShowSMSModal(false);
  }
  const handleShow = () => {
    setShow(true);
    props.setShowSMSModal(true);
  }

  const handleSubmit = () => {
    console.log(TAG, 'SMSModal handleSubmit() smsCode ' + smsCode);
    try {
      window.confirmationResult.confirm(smsCode).then((result) => {
        // User signed in successfully.
        const user = result.user;
        // ...
        Swal.fire({
          title: 'Success',
          text: 'Authentication successful!',
          icon: 'success'
        })

        console.log(TAG, 'SMSModal handleSubmit() user ' + JSON.stringify(user))

        handleClose(); // close modal..

        // send him to his page
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        handleClose();
        Swal.fire({
          title: 'Oops!',
          text: 'The code you entered is invalid. Try again!',
          icon: 'error',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
          handleShow();
        })
        // console.error(TAG, 'SMSModal handleSubmit(): confirm sms error ' + error);

      });

    } catch (error) {
      console.error(TAG, 'SMSModal handleSubmit(): error ' + error);
    }
  }

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Show SMS
      </Button> */}

      <Modal show={props.showSMSModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter SMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Label htmlFor="inputPassword5">Please enter SMS received in your phone</Form.Label>
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={smsCode}
              onChange={(e) => setSmsCode(e.target.value)}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Enter the SMS code in the above text field to login.
            </Form.Text>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [showSMSModal, setShowSMSModal] = useState(false);

  const navigate = useNavigate();

  const _verifyLogin = () => {
    const token = localStorage.getItem("applier.token"); // unused
    const role = localStorage.getItem("applier.role");

    console.log(TAG, '_verifyLogin() token ' + token)
    console.log(TAG, '_verifyLogin() role ' + role)

    if (token) {
      // token exists, now check role
      if (role === 'INSTITUTE') {
        // take him to institute page
        navigate('/admin-dashboard')
      } else if (role == 'STUDENT') {
        // take him to student page
        navigate('/student')
      } else if (role == 'COUNSELOR') {
        // take him to counselor page
        navigate('/counsellor');
      }
    }
  }

  const _verifyPhoneNumber = () => {
    if (phoneNumber) {
      // pre-pend +91
      let phoneNumber2 = '+91' + phoneNumber;
      // const phoneNumber = getPhoneNumberFromUserInput();
      const appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(phoneNumber2, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log(TAG, '_verifyPhoneNumber() confirmationResult: ' + JSON.stringify(confirmationResult));
          // ...

          // show SMS code modal
          setSmsCode(true);
          setShowSMSModal(true);

        }).catch((error) => {
          // Error; SMS not sent
          // ...

          console.error(TAG, '_verifyPhoneNumber() error: ' + JSON.stringify(error));
          // window.recaptchaVerifier.render().then(function (widgetId) {
          // grecaptcha.reset(widgetId);
          // });
        });
    } else {
      Swal.fire({
        title: 'Oops!',
        text: 'Please enter a phone number',
        icon: 'warning'
      })
    }
  }

  const _initializeReCaptcha = () => {
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    } catch (error) {
      console.error(TAG, '_initializeReCaptcha() exception ' + error);
    }
  }

  useEffect(() => {
    // verify if logged in
    _verifyLogin()

    // const fbase = firebase.initializeApp(firebaseConfig);
    // const uiConfig = {
    //   signInSuccessUrl: "https://netflix-clone-ankur.herokuapp.com/", //This URL is used to return to that page when we got success response for phone authentication.
    //   signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
    //   tosUrl: "https://netflix-clone-ankur.herokuapp.com/"
    // };
    // var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // ui.start("#firebaseui-auth-container", uiConfig);
    _initializeReCaptcha();

  }, [])

  const loginHandler = async (e) => {
    e.preventDefault();

    let details = ({ userName, password });

    if (!userName || !password) {
      // alert('Please enter details')
      notify();
      return false;
    }

    let body = {
      user_email: userName,
      user_password: password
    }

    /*
    const response = await axios.post(LOGINURL, body)
    console.log(TAG, "loginHandler() response " + JSON.stringify(response))
    */

    setIsLoading(true)
    let token = await AuthAPI.doLoginUser(body);

    if (token) {
      // now perform applier login
      localStorage.setItem('applier.token', token);

      const config = {
        headers: { Authorization: `${token}` }
      };

      console.log(TAG, 'config ' + JSON.stringify(config));
      const bodyParameters = {
        key: "value"
      };

      const response2 = await axios.post(
        Constants.Application.APPLIER_END_URL + '/applier-signin/',
        bodyParameters,
        config
      )
      console.log(TAG, "loginHandler() response2 " + JSON.stringify(response2))
      let msg = response2.data.msg


      // let response2 = AuthAPI.doApplierLogin(bodyParameters, config);
      //let msg = response2.data.msg
      if (msg == "pass") {
        let type = response2.data.login_type;
        const user = response2.data.user_obj;
        const schoolId = response2.data.school_id;
        const schoolDetails = response2.data.school_obj;


        // get school details
        // const schoolDetails = await INSTITUTEAPI.getSchoolDetails2(token);
        console.log(TAG, 'schoolDetails ', JSON.stringify(schoolDetails));

        if (type == Constants.Application.ROLE_COUNSELOR) {
          localStorage.setItem('applier.role', "COUNSELOR");
          localStorage.setItem("applier.couns.id", user.councellor_id)
          UserSession.counselor.id = user.councellor_id;
          localStorage.setItem("applier.couns.name", user.councellor_name)
          UserSession.counselor.name = user.councellor_name;

          localStorage.setItem(Constants.Application.APPLIER_STORE_SCHOOLEMAIL, user.school_email)
          localStorage.setItem("applier.couns.photo", user.councellor_photo)
          localStorage.setItem("applier.couns.school.id", schoolId)

          localStorage.setItem('applier.couns.token', token);
          localStorage.setItem("applier.couns.inst.name", schoolDetails.school_name);
          localStorage.setItem("applier.couns.inst.photo", schoolDetails.school_photo);

          UserSession.counselor.photo = user.councellor_photo;

          navigate('/counsellor');

        } else if (type == "Student") {
          localStorage.setItem('applier.role', "STUDENT");
          navigate('/student')
        } else if (type == Constants.Application.ROLE_INSTITUTE) {
          localStorage.setItem('applier.role', "INSTITUTE");
          localStorage.setItem("applier.inst.id", user.school_id);
          UserSession.school.id = user.school_id;
          localStorage.setItem("applier.inst.name", user.school_name);
          localStorage.setItem('applier.inst.token', token);

          UserSession.school.name = user.school_name;

          localStorage.setItem(Constants.Application.APPLIER_STORE_SCHOOLEMAIL, user.school_email);
          localStorage.setItem("applier.inst.photo", user.school_photo);
          UserSession.school.photo = user.school_photo;
          navigate('/admin-dashboard');
        }
      }
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center vh-100">
        <h4>Loading....</h4>
      </div>
    )
  }

  return (
    <section className='login-section' >
      <div className="login-sec">
        <div className="start-login">
          <img src="./assets/img/schooler-applier.png" className='img-logo' alt="" />
          <div className="sign">Sign In</div>
          <form action="" onSubmit={loginHandler}>
            <input type="text" placeholder='Username' name='userName' value={userName} onChange={(e) => setUserName(e.target.value)} /><br /><br />
            <input type="password" placeholder='Password' name='userName' value={password} onChange={(e) => setPassword(e.target.value)} /><br /><br />
            <button type='submit'
              style={{ backgroundColor: "#6610f2", border: "none" }} >Login</button>
          </form>

          {/* 
          <p>(Or)</p>
          <div>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
              <Form.Control
                placeholder="Phone"
                aria-label="Phone"
                aria-describedby="basic-addon1"
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
              />
            </InputGroup>

            <Button
              variant="contained"
              onClick={_verifyPhoneNumber}
              className="btn btn-primary">Login with Phone</Button>

            <hr />
            <div id="recaptcha-container"></div>
          </div> */}


          <div className="rem-pass">
            <a href={`${Constants.Application.AUTH_END_URL}/forgot-password`}>
              Forgot Password?
            </a>
            <Link to='/register'>
              Create Account
            </Link>
          </div>
        </div>
      </div>


      <div id="firebaseui-auth-container"></div>

      {/* SMS modal enter */}
      <SMSModal
        showSMSModal={showSMSModal}
        setShowSMSModal={(show) => setShowSMSModal(show)}
        smsCode={smsCode}

      />

      <ToastContainer />
    </section>
  )
}

export default Login