import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom';
import Constants from '../resource/Constants';

const TAG = "SchoolEnquiries.jsx";
const URL = Constants.Application.APPLIER_END_URL + "/school-list-of-enquiries/"


const SchoolEnquiries = () => {
  const [schEnquiries, setSchEnquiries] = useState([]);

  const search = useLocation().search
  const query = new URLSearchParams(useLocation().search);
  const eml = new URLSearchParams(search).get('email');


  const loadEnquiries = async () => {
    let token = localStorage.getItem('applier.inst.token');
    const config = {
      headers: { Authorization: `${token}` }
    };

    const response = await axios.get(URL, config)
    console.log(TAG, "response " + JSON.stringify(response));
    setSchEnquiries(response.data.data)
  }

  useEffect(() => {
    loadEnquiries();
  }, [])

  console.log(schEnquiries);

  const TBL_BODY = (props) => {
    const { schEnquiries } = props
    return (
      (schEnquiries && schEnquiries.length) ?


        schEnquiries?.map((item) => {
          const { enquiry_id, user_id, parent_name, Student_name, student_Gender,
            student_class_applied, parent_contact, parent_email, parent_other_desc,
            institute_id, application_status, created_date } = item
          return (
            // <tbody>
            <div >
              <tr>
                <td>

                </td>
                <td>{enquiry_id}</td>
                <td>
                  <h2 className="table-avatar">

                    <a href="Lead-details.html">{Student_name}</a>
                  </h2>
                </td>
                <td>{parent_contact}</td>
                <td>{student_Gender}</td>
                <td>{student_class_applied}</td>
                <td>--</td>
                <td>{created_date}</td>
                <td className="text-end">
                  <div className="actions ">
                    <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
                      <i className="feather-eye"></i>
                    </a>
                    <a href="edit-student.html" className="btn btn-sm bg-danger-light">
                      <i className="feather-edit"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </div>
            // </tbody>
          )
        })

        :
        <>
          <div>No enquiries!</div>
        </>
    )
  }

  const TBL = (props) => {
    const { schEnquiries } = props;

    return (
      (schEnquiries && schEnquiries.length) ?
        <>
          <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped" >
            <thead className="student-thread">
              <tr>
                <th>

                </th>
                <th> Name</th>
                <th> Email</th>
                <th> Mobile No.</th>
                <th>Gender</th>
                <th>Standard</th>
                <th> Capmpaign</th>
                <th>Registered Date</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <TBL_BODY schEnquiries={schEnquiries} />
          </table>
        </>
        :
        <><h5>No enquiries!</h5></>
    )
  }

  const MAIN = (props) => {
    return (
      <>
        <Sidebar />
        <div className='enquiry-section w-80'>
          <div className="student-group-form">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by Registered Email ID, Mobile No. or Name" />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="search-student-btn">
                  <button type="btn" className="btn btn-primary">Search</button>
                </div>
              </div>
            </div>
            <div className="table-responsive" >
              <TBL schEnquiries={props.schEnquiries} />
            </div>
          </div>

        </div>
      </>
    )
  }

  return (
    <div>
      <MAIN schEnquiries={schEnquiries} />

    </div>
  )


}


export default SchoolEnquiries;

