import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { SiMinutemailer } from 'react-icons/bi';
import Constants from '../resource/Constants';
import Modal from 'react-bootstrap/Modal';
import COUNSELLORAPI from '../http/counsellor';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import CounsellorSidebar from './CounsellorSidebar';

/* file upload */
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

// import 'sweetalert2/src/sweetalert2.scss'
const MySwal = withReactContent(Swal);
const COUNSLISTURL = Constants.Application.APPLIER_END_URL + '/school-list-of-enquiries/'

const APPLICATIONURL = Constants.Application.APPLIER_END_URL + "/create-school-custom-form/";

const instId = localStorage.getItem("applier.couns.school.id"); // 24
const GETFORMURL = Constants.Application.APPLIER_END_URL + "/get-school-admission-form/?school_id=" + instId;

const FORM_OBJECT = {
  institute_id: instId,
  fields: [
    {
      //admission year
      field_name: "admission_year",
      field_type: "text",
      field_label: "Admission Year",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 0,
    },
    {
      //name of the student
      field_name: "student_name",
      field_type: "text",
      field_label: "Name of the Student",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Student Gender
      field_name: "student_gender",
      field_type: "radio",
      field_label: "Student Gender",
      field_value: '',
      max_length: 5,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
      options: ['M', 'F']
    },
    {
      //Date of Birth
      field_name: "student_dob",
      field_type: "date",
      field_label: "Date of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //City
      field_name: "city",
      field_type: "text",
      field_label: "City",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Enrollment in Grade
      field_name: "student_enroll",
      field_type: "text",
      field_label: "Enrollment in Grade",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Campus
      field_name: "campus",
      field_type: "text",
      field_label: "Campus",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    {
      //student picture
      field_name: "student_picture",
      field_type: "file",
      field_label: "Upload Student Image",
      field_value: '',
      max_length: 20,
      max_file_size: 10,
      accept_type: "jpeg/png/gif",
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //permanent address
      field_name: "perm_addr",
      field_type: "textarea",
      field_label: "Permanent Address",
      field_value: '',
      max_length: 500,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //last school attended
      field_name: "schl_attend",
      field_type: "text",
      field_label: "Last School Attended",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //Caste
      field_name: "caste",
      field_type: "text",
      field_label: "Caste",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Religion
      field_name: "religion",
      field_type: "text",
      field_label: "Religion",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Mother Tongue
      field_name: "mother_tongue",
      field_type: "text",
      field_label: "Mother Tongue",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //Naionality
      field_name: "nationality",
      field_type: "text",
      field_label: "Nationality",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //student email
      field_name: "student_email",
      field_type: "email",
      field_label: "Student Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student contact
      field_name: "student_contact",
      field_type: "text",
      field_label: "Student Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student place of birth
      field_name: "student_placeofbirth",
      field_type: "text",
      field_label: "Student Place of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student temp address
      field_name: "student_temp_addr",
      field_type: "text",
      field_label: "Student Temporary Address",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student adhar number
      field_name: "student_adhar_number",
      field_type: "text",
      field_label: "Student Adhaar Number ",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //blood group
      field_name: "student_blood_group",
      field_type: "text",
      field_label: "Student Blood Group",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //resides with
      field_name: "resides_with",
      field_type: "text",
      field_label: "Student Resides With",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    // PARENT DETAILS
    {
      //Parent Name
      field_name: "parent_name",
      field_type: "text",
      field_label: "Parent Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },
    {
      //Parent Contact
      field_name: "parent_contact",
      field_type: "text",
      field_label: "Parent Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },

    {
      //parent email
      field_name: "parent_email",
      field_type: "email",
      field_label: "Parent Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Name
      field_name: "father_name",
      field_type: "text",
      field_label: "Father Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Contact
      field_name: "father_contact",
      field_type: "text",
      field_label: "Father Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Father email
      field_name: "father_email",
      field_type: "email",
      field_label: "Father Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Name
      field_name: "mother_name",
      field_type: "text",
      field_label: "Mother Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "mother_contact",
      field_type: "text",
      field_label: "Mother Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "mother_email",
      field_type: "email",
      field_label: "Mother Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Guardian Name
      field_name: "guard_name",
      field_type: "text",
      field_label: "Guardian Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "guard_contact",
      field_type: "text",
      field_label: "Guardian Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "guard_email",
      field_type: "email",
      field_label: "Guardian Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    // DOCUMENTS
    {
      // Father photo
      field_name: "father_photo",
      field_type: "file",
      field_label: "Father photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Mother photo
      field_name: "mother_photo",
      field_type: "file",
      field_label: "Mother photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },

    {
      // Guardian photo
      field_name: "guardian_photo",
      field_type: "file",
      field_label: "Guardian photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // DOB certificate
      field_name: "student_dob_cert",
      field_type: "file",
      field_label: "Student DOB Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Child Aadhaar
      field_name: "student_aadhaar",
      field_type: "file",
      field_label: "Student Aadhaar",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student TC
      field_name: "student_tc",
      field_type: "file",
      field_label: "Student TC",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_caste_cert",
      field_type: "file",
      field_label: "Student Caste Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_res_cert",
      field_type: "file",
      field_label: "Student Residence Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    }
  ],
};

const saveForm = () => {
  console.log(FORM_OBJECT);
};

console.log("label:", FORM_OBJECT.field_label);

const FORM_OBJECT_INPUT = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="text" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control type="text" placeholder={obj.field_name} onChange={(e) => props.onChange(e, obj)} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_TEXTAREA = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <textarea placeholder={props.placeholder}>this is text area </textarea> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} as="textarea" placeholder={obj.field_name} rows={3} />
        </Form.Group>

      </div>
    </>
  );
};

const FORM_OBJECT_EMAIL = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} type="email" placeholder={obj.field_name} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_RADIO = (props) => {
  const { obj } = props;
  const count = obj.options.length;
  return (
    <>
      <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>

      <div className="custom2 d-flex flex-row ">
        {/* <input type="email" placeholder={props.placeholder} /> */}

        {obj.options.map((op, idx) => {
          return (
            <div key={`default-radio`} className="me-3">
              <Form.Check
                type={'radio'}
                name={`radio-${obj.field_label}`}
                id={`default-${op}`}
                label={op}
                value={op}
                onChange={(e) => props.onChange(e, obj)}
              />
            </div>
          )
        })}


      </div>
    </>
  );
};

const FORM_OBJECT_DATE = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} type="date" placeholder={obj.field_name} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_FILE3 = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{obj.field_label}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} type="file" />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_FILE = (props) => {
  const { obj } = props;
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {

    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // props.setDocPicSource(downloadURL)
    // setUploadProgress(100)
    // setIsUploading(false)

    props.onChangeFile(obj, downloadURL);

  };

  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} type="file" /> */}

          <div
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <label
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  backgroundColor: "lightsteelblue",
                  color: "black",
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 4,
                  cursor: "pointer",
                  // width: '100%',
                  textAlign: "center",

                }}
              >
                {/* <Typography style={{ width: 250, padding: 5 }}> */}
                CHOOSE
                {/* </Typography> */}

                <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />
              </label>

              {obj.field_value ?
                <>
                  <i class="fa fa-check text-primary ms-3"></i>
                </>
                :
                <></>
              }

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div> */}
              {/* 
          {ImageDownloadUrls ?
            <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
              <ImageOutlinedIcon style={{ fontsize: 80 }} />
            </div>
            :
            <></>
          } */}

            </div>
          </div>
        </Form.Group>
      </div>
    </>
  );
};

// const TableHead =()=>{
//   return(
//     <div>

//     </div>
//   )
// }

const TAG = 'CounsellorLeads.jsx';

const CounsellorList = (props) => {
  const { lead, cons } = props;

  // let oConsId = lead.councellor_id;

  const [oConsId, setOConsId] = useState(lead.councellor_id);
  console.log(TAG, 'CounsellorList lead ' + JSON.stringify(lead));
  console.log(TAG, 'CounsellorList cons ' + JSON.stringify(cons));

  const handleChangeCons = async (event) => {
    const { name, value } = event.target;
    console.log(TAG, 'CounsellorList handleChangeCons(): name ' + name)
    console.log(TAG, 'CounsellorList handleChangeCons(): value ' + value)

    const leadId = lead.enquiry_id;
    const consId = value;

    console.log(TAG, 'CounsellorList handleChangeCons(): leadId ' + leadId);
    console.log(TAG, 'CounsellorList handleChangeCons(): consId ' + consId);

    const body = {
      counsellor_id: consId,
      lead_id: leadId
    }
    const resp = await COUNSELLORAPI.setCounsellor(body);
    console.log(TAG, 'CounsellorList handleChangeCons(): resp ' + JSON.stringify(resp));

    // update state
    setOConsId(consId);
  }

  return (
    <Form.Select aria-label="Default select example"
      onChange={handleChangeCons}
      defaultValue={oConsId}
      value={oConsId}
    >
      <option>Choose counsellor</option>
      {(cons && cons.length) ?
        <>
          {cons.map((con, idx) => {
            let sel = 'false';

            let conId = con.councellor_id;
            if (oConsId == conId)
              sel = 'selected';
            return <option value={conId} selected={sel}>{con.councellor_name}</option>
          })}
          {/* 
           <option value="2">Two</option>
           <option value="3">Three</option> */}
        </>
        :
        <></>
      }
    </Form.Select>
  );
}

const LEADROW = (props) => {
  const { lead, cons } = props;

  let classStd = '-';
  switch (lead.student_class_applied) {
    case 13:
      classStd = 'L.K.G';
      break;
    case 14:
      classStd = 'U.K.G';
      break;
    case 15:
      classStd = 'Nursery';
      break;
    default:
      classStd = lead.student_class_applied;
      break;
  }

  const ucFirst = (str) => {
    //split the above string into an array of strings 
    //whenever a blank space is encountered

    str = str.toLowerCase();
    const arr = str.split(" ");

    //loop through each element of the array and capitalize the first letter.


    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }

    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");

    return str2;
  }

  console.log(TAG, 'LEADROW: lead ' + JSON.stringify(lead));
  return (
    <tr>
      <td>{lead.enquiry_id}</td>
      <td>
        <h2 className="table-avatar">
          <Link to={`/lead-details?enquiry_id=${lead.enquiry_id}`} className="avatar avatar-sm me-2">
            {lead.Student_name}
          </Link>

        </h2>
      </td>
      <td>{classStd} </td>
      <td>{lead.created_date}</td>
      <td>{ucFirst(lead.application_status)}&nbsp;
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleFollowupShown(); props.handleAddFollowup(lead)
          }}
        >
          <i className='fa fa-edit fa-1x'></i>
        </a></td>
      <td style={{ width: '20%' }}>
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleNoteShown(); props.handleAddNote(lead)
          }}
        >
          Add notes
        </a>
      </td>


      <td className="text-end" style={{ width: '10px' }}>
        <div className="dropdown dropdown-action">
          <a href="javascript:void(0)" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
          <div className="dropdown-menu dropdown-menu-end">
            <a className="dropdown-item" href="javascript:void(0)"
              onClick={() => { props.onShowEditForm(lead); props.setCurrLead(lead) }}><i className="far fa-edit me-2"></i>Application Form</a>
            <a className="dropdown-item" href="javascript:void(0)"
              onClick={() => { props.onSendEmail(lead); props.setCurrLead(lead) }}
            ><i className="far fa-paper-plane me-2"></i>Send Email</a>

          </div>
        </div>
      </td>
    </tr>

  )
}

const LEADSLIST = (props) => {
  const { enquiryList, cons } = props;
  return (
    (enquiryList && enquiryList.length) ?
      <>
        <div className="table-responsive" >
          <table className="table border-0 star-student table-center mb-0 datatable" style={{ border: "1px solid black" }}>
            <thead className="student-thread">
              <tr>
                <th>Lead Id <BiSort /></th>
                <th>Name <BiSort /></th>
                <th>Grade <BiSort /></th>
                <th>Date <BiSort /></th>
                <th>Status</th>
                <th>Activity <BiSort /></th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {enquiryList.map((lead, idx) => {
                return <LEADROW
                  lead={lead}
                  idx={idx}
                  key={idx}
                  cons={cons}
                  onShowEditForm={(lead) => props.onShowEditForm(lead)}
                  handleAddNote={(lead) => props.handleAddNote(lead)}
                  handleNoteShown={() => props.handleNoteShown()}

                  handleAddFollowup={(lead) => props.handleAddFollowup(lead)}
                  handleFollowupShown={() => props.handleFollowupShown()}
                  onSendEmail={(lead) => props.onSendEmail(lead)}

                  currLead={props.currLead}
                  setCurrLead={(lead) => props.setCurrLead(lead)}
                />
              })}

              {/*
          <tr>
            <td>PRE2209</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Siva Shankar
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>2 Feb 2002</td>
            <td>
              <select>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>


            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2213</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Madhu bala
                </Link>

              </h2>
            </td>
            <td>8 </td>
            <td>3 June 2010</td>
            <td>
              <select>
                <option>Fields Malynne</option>
                <option>Jeffrey Wong</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2143</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Rahul Rana
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>12 Apr 2002</td>
            <td>
              <select>
                <option>	Jeffrey Scott</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2431</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Koushik
                </Link>

              </h2>
            </td>
            <td>11 </td>
            <td>24 Feb 2000</td>
            <td>
              <select>
                <option>J Shaffer</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE1534</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Lawrence
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>22 Jul 2006</td>
            <td>
              <select>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>

          <tr>

            <td>PRE1234</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Jasmin
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>26 Apr 1994</td>
            <td>
              <select>
                <option>	Jeffrey Scott</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>
                </div>
              </div>
            </td>
          </tr>
          */}

            </tbody>
          </table>
        </div>
      </>
      :
      <>
        <Alert variant="warning">
          <Alert.Heading>No leads created yet!</Alert.Heading>
          <p>
            No leads are shown here as they have not yet been achieved by the staff. Start adding now!
          </p>
          <hr />
          <p className="mb-0">
            Leads can be achieved by adding using the button above or using various social media campaigns.
          </p>
        </Alert>
      </>
  )
}

const AddLeadDialog2 = () => {
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AddLeadForm />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

const AddLeadForm = (props) => {

  const {
    studentName,
    studentGender,
    parentName,
    classApplied,
    contact,
    parentEmail,
    isParent
  } = props;

  let classAppliedStr = classApplied;
  switch (classApplied) {
    case 13:
      classAppliedStr = 'L.K.G';
      break;
    case 14:
      classAppliedStr = 'U.K.G';
      break;
    case 15:
      classAppliedStr = 'Nursery';
      break;
  }
  console.log(TAG, 'AddLeadForm studentGender ' + studentGender);

  return (
    <div className='enq-form py-0' >
      <div className="">
        {/* <h5 className="form-title" style={{ color: '#0d6efd' }}>
          Admission Form 2022-2023
        </h5> */}

        <div className="right-form">
          <div >
            <label className="col-lg-3 col-form-label">Student Name</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='studentName' value={studentName} onChange={(e) => props.setStudentName(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Name of the Parent</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='parentName' value={parentName} onChange={(e) => props.setParentName(e.target.value)} />
            </div>
          </div>

          <div >
            <label className="col-lg-3 col-form-label">Gender</label>
            <div className="col-lg-9">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="male" checked={(studentGender == 'male')}
                  onClick={() => props.setStudentGender('male')}
                />
                <label className="form-check-label" for="gender_male">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="female" checked={(studentGender == 'female')}
                  onClick={() => props.setStudentGender('female')}
                />
                <label className="form-check-label" for="gender_female">
                  Female
                </label>
              </div>
            </div>
          </div>



          <div >
            <label className="col-lg-3 col-form-label">Class for Admission</label>
            <div className="col-lg-9">
              {/* <input type="text" className="form-control" name='classApplied' value={classApplied} onChange={(e) => props.setClassApplied(e.target.value)} /> */}

              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Standard {classAppliedStr}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(1)}>Standard 1</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(2)}>Standard 2</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(3)}>Standard 3</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(4)}>Standard 4</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(5)}>Standard 5</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(6)}>Standard 6</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(7)}>Standard 7</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(8)}>Standard 8</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(9)}>Standard 9</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(10)}>Standard 10</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(11)}>Standard 11</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(12)}>Standard 12</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(13)}>Standard L.K.G</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(14)}>Standard U.K.G</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => props.setClassApplied(15)}>Standard Nursery</Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div >
            <label className="col-lg-3 col-form-label">Enquired by</label>
            <div className="col-lg-9">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="enq" id="enq_parent" value="1" checked={(isParent == '1')}
                  onClick={() => props.setIsParent('1')}
                />
                <label className="form-check-label" for="gender_male">
                  Parent
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="enq" id="enq_student" value="0" checked={(isParent == '0')}
                  onClick={() => props.setIsParent('0')}
                />
                <label className="form-check-label" for="gender_female">
                  Student
                </label>
              </div>
            </div>
          </div>

          <div >
            <label className="col-lg-3 col-form-label">Enquirer Contact Number</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='contact' value={contact} onChange={(e) => props.setContact(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Enquirer Contact Email</label>
            <div className="col-lg-9">
              <input type="email" className="form-control" name='parentEmail' value={parentEmail} onChange={(e) => props.setParentEmail(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="text-end" style={{ display: 'flex', justifyContent: "center", textAlign: "center", marginTop: '20px' }}>
        <button type="button" className="btn btn-primary" onClick={enquiryHandler}>Submit</button>
      </div> */}

    </div>
  )
}

const FollowupModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'FollowupModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setFollowupChannel(value);
  }

  const handleChangeStatus = (e) => {
    const { name, value } = e.target;

    props.setStatus(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setFollowup(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleCloseFollowup();
      props.handleSaveFollowup();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a followup</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <div className="my-2"></div>
          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeStatus}>
            <option>Select A Status</option>
            <option value="COLD">Cold</option>
            <option value="WARM">Warm</option>
            <option value="HOT">Hot</option>
            <option value="SUBMITTED">Submitted</option>
            <option value="CLOSED">Closed</option>
            <option value="NOT_REACHABLE">Not Reachable</option>
            <option value="INFORMED">Informed</option>
            <option value="DUPLICATE">Duplicate</option>
            <option value="INVALID">Invalid</option>

            <option value="NOT_INTERESTED">NOT_INTERESTED</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the followup notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleFollowupClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const NoteModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'NoteModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setChannel(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setNotes(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleClose();
      props.handleSaveNote();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the activity notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const EnquiryModal = (props) => {

  const { onShow, currLead } = props;

  let parentName2 = (currLead) ? currLead.parent_name : '';
  let studentName2 = (currLead) ? currLead.Student_name : '';
  let studentGender2 = (currLead) ? currLead.student_Gender : 'male';
  let classApplied2 = (currLead) ? currLead.student_class_applied : '';
  let contact2 = (currLead) ? currLead.parent_contact : '';
  let parentEmail2 = (currLead) ? currLead.parent_email : '';
  let isParent2 = (currLead) ? ((currLead.is_parent == 1) ? '1' : '0') : '1';

  console.log(TAG, 'EnquiryModal currLead ' + JSON.stringify(currLead));

  const [parentName, setParentName] = useState(parentName2);
  const [studentName, setStudentName] = useState(studentName2);
  const [studentGender, setStudentGender] = useState(studentGender2);
  const [classApplied, setClassApplied] = useState(classApplied2);
  const [contact, setContact] = useState(contact2);
  const [parentEmail, setParentEmail] = useState(parentEmail2);
  const [desc, setDesc] = useState("ghdfgfg");
  const [medium, setMedium] = useState("english");
  const [instId, setInstId] = useState(0);
  const [consId, setConsId] = useState(0);
  const [isParent, setIsParent] = useState(isParent2);
  const [consExs, setConsExs] = useState('no');
  const [actvLead, setActvLead] = useState(null)

  const [show, setShow] = useState(false);
  const [formObject, setFormObject] = useState(FORM_OBJECT);

  const applicationHandler = async () => {
    let body = formObject;

    let token = localStorage.getItem("applier.couns.token");

    const headers = {
      headers: { Authorization: `${token}` },
    };


    MySwal.fire({
      title: 'Please wait..',
      text: 'Saving the form setup..',
      icon: 'info',
      confirmButtonText: 'OK',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    }).then(() => {
      // do something..
    })

    console.log(TAG, "applicationHandler() headers " + JSON.stringify(headers));
    const response = await axios.post(APPLICATIONURL, body, headers);
    console.log(TAG, "applicationHandler() response " + JSON.stringify(response));


    MySwal.fire({
      title: 'Success!',
      text: 'Your form was saved successfully..',
      icon: 'success',
      confirmButtonText: 'OK'
    })

  };

  const formSetupHandler = async () => {
    let token = localStorage.getItem("applier.couns.token");
    const config = {
      headers: { Authorization: `${token}` },
    };

    const response = await axios.get(GETFORMURL, config);
    console.log(TAG, "formSetupHandler() response " + JSON.stringify(response));

    const data3 = (response.data && response.data.data) ? response.data.data.field_obj : null;
    if (data3) {

      setFormObject(data3);

      // update object with pre-filled data
    }

  };

  const onUpdateFieldChecked = (e, fieldName) => {
    let isChecked = e.target.checked;
    console.log(TAG, "onUpdateFieldChecked() isChecked " + isChecked);
    console.log(TAG, "onUpdateFieldChecked() fieldName " + fieldName);

    // update the object
    let newFormObject = JSON.parse(JSON.stringify(formObject)); // full copy

    newFormObject.fields.map((field, index) => {
      console.log(TAG, "onUpdateFieldChecked() field name " + field.field_name);
      if (field.field_name == fieldName) {
        console.log(TAG, "onUpdateFieldChecked(): match found!");

        field.isSelected = isChecked;
      }
    });

    setFormObject(newFormObject);

    console.log(
      TAG,
      "onUpdateFieldChecked(): setstate completed " +
      JSON.stringify(newFormObject)
    );
  };

  const SELECT_FIELD = (props) => {
    const { field } = props;
    let isChecked = field.isSelected;
    let isDisabled = !field.isEditable;
    console.log(TAG, 'isDisabled ' + isDisabled);
    return (
      <div className="formF" style={{ display: "flex" }}>
        <input
          type="checkbox"
          name="checkbox-name"
          onChange={(e) => {
            onUpdateFieldChecked(e, field.field_name);
          }}
          checked={isChecked}
          style={{
            position: "relative",
            top: "-5px",
            marginLeft: "10px",
          }}
          disabled={isDisabled}
        />
        <h6 className="forInp">{field.field_label}</h6>
      </div>
    )
  }

  const LEFT_BAR = (props) => {
    //const { formObject } = props;

    let groupedFields = groupObject();
    let admYearFields = groupedFields[0];
    let stuFields = groupedFields[1];
    let parentFields = groupedFields[2];
    let docFields = groupedFields[3];

    console.log(TAG, "LEFTBAR is rendered again..");
    return (
      <>
        <div
          className="left-section"
          style={{ width: "25%", height: "auto", position: "relative", overflowY: 'scroll' }}
        >
          {/* ------------------------------Personal detail section------------------------------------ */}
          <div className="sect-title">
            <h4>Select Fields</h4>
            {/* controls */}

            <h6>Admission for</h6>
            {admYearFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}

            <hr className="divider" style={{ margin: 10 }} />

            <h6>Student details</h6>
            {stuFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}

            <hr className="divider" style={{ margin: 10 }} />

            <h6>Parent details</h6>
            {parentFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}


            <hr className="divider" style={{ margin: 10 }} />
            <h6>Documents</h6>
            {docFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}
          </div>
        </div>
      </>
    );
  };

  function groupObject() {
    var result = formObject.fields.reduce(function (r, a) {
      r[a.groupIndex] = r[a.groupIndex] || [];
      r[a.groupIndex].push(a);
      return r;
    }, Object.create(null));

    return result;
  }

  const FORM_FIELD = (props) => {

    const { obj } = props;
    console.log(TAG, 'SetApplicationForm.jsx FORM_FIELD: ' + JSON.stringify(obj));
    if (obj.isSelected) {
      switch (obj.field_type) {
        case "text":
          console.log(TAG, "APPLICATION_FORM2 rendering text");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_INPUT obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case "textarea":
          console.log(TAG, "APPLICATION_FORM2 rendering textarea");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_TEXTAREA obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case "email":
          console.log(TAG, "APPLICATION_FORM2 rendering email");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_EMAIL obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'radio':
          console.log(TAG, "APPLICATION_FORM2 rendering radio");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_RADIO obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'date':
          console.log(TAG, "APPLICATION_FORM2 rendering date");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_DATE obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case 'file':
          console.log(TAG, "APPLICATION_FORM2 rendering file");
          return (
            <div className="col-md-6 col-lg-6">
              {/* <FORM_OBJECT_FILE obj={obj} onChange={(e, obj) => props.onChange(e, obj)} /> */}
              <FORM_OBJECT_FILE obj={obj} onChange={(e, obj) => props.onChange(e, obj)} onChangeFile={(obj, val) => props.onChangeFile(obj, val)} />
            </div>
          )
        default:
          return <></>
      }
    } else {
      return <></>;
    }
  }

  const APPLICATION_FORM = (props) => {
    let objIdx = -1;

    let groupedFields = groupObject();
    let admYearFields = groupedFields[0];
    let stuFields = groupedFields[1];
    let parentFields = groupedFields[2];
    let docFields = groupedFields[3];
    console.log(TAG, 'APPLICATION_FORM groupedFields: ' + JSON.stringify(groupedFields));
    //const { formObject } = props;

    let studentName2 = '-';
    let classFor2 = '-'
    if (currLead) {
      studentName2 = currLead.Student_name

      if (currLead.student_class_applied) {
        classFor2 = currLead.student_class_applied;
      }

    }

    const onChangeFile = (obj, value) => {
      console.log(TAG, 'APPLICATION_FORM onChangeFile(): value ' + value);

      obj.field_value = value;

    }

    const onChange = (e, obj) => {
      const { name, value, files } = e.target;
      console.log(TAG, 'APPLICATION_FORM onChange(): name ' + name);
      console.log(TAG, 'APPLICATION_FORM onChange(): value ' + value);
      // console.log(TAG, 'APPLICATION_FORM onChange(): file ' + files[0]);

      // verify if object is file
      if (obj.field_type == 'file') {
        obj.field_value = files[0];

        // upload to firebase and get url

      } else {
        obj.field_value = value;
      }

      // update form object
      // let newFormObject = JSON.parse(JSON.stringify(formObject));

      // setFormObject(newFormObject);

      // see if form object was updated
      console.log(TAG, 'APPLICATION_FORM onChange(): updated form object ' + JSON.stringify(formObject));
    }

    const onChange3 = (e, obj) => {
      const { name, value } = e.target;
      console.log(TAG, 'APPLICATION_FORM onChange(): name ' + name);
      console.log(TAG, 'APPLICATION_FORM onChange(): value ' + value);

      obj.field_value = value;

      // update form object
      // let newFormObject = JSON.parse(JSON.stringify(formObject));

      // setFormObject(newFormObject);

      // see if form object was updated
      console.log(TAG, 'APPLICATION_FORM onChange(): updated form object ' + JSON.stringify(formObject));
    }

    return (
      <>
        <div
          className="right-section"
          style={{
            position: "relative",
            // left: "400px",
            width: "75%",
            padding: "50px",
            // marginLeft: "50px",
            borderRadius: "10px",
          }}
        >

          <div style={{ display: "flex", flexDirection: 'column', gap: "10px" }}>
            {/* admission for */}
            <div>
              <div className="ind-inp">
                <h4>Admission for {studentName2} (Class {classFor2})</h4>
              </div>

              <div className="row">
                {admYearFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={(e, obj) => onChange(e, obj)} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>

            {/* Student details */}
            <div>
              <div className="ind-inp">
                <h4>Student details</h4>
              </div>

              <div className="row">
                {stuFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>


            {/* Student information */}
            <div>
              <div className="ind-inp">
                <h4>Parent's / Guardian's information</h4>
              </div>

              <div className="row">
                {parentFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );

                  return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>


            {/* Parent information */}
            <div>
              <div className="ind-inp">
                <h4>Documents to upload</h4>
              </div>

              {docFields && docFields.length ?
                <div className="row">
                  {docFields.map((obj, index) => {
                    // let formObj = null;
                    console.log(
                      TAG,
                      "APPLICATION_FORM2 obj field_type " +
                      JSON.stringify(obj.field_type)
                    );

                    return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                  })}
                </div>
                :
                <>
                  <p>You have not selected any document fields!</p>
                </>
              }
            </div>

          </div>

        </div>
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    props.setOnShow(false);
  }

  const handleShow = () => {
    setShow(true);
    props.setOnShow(true);
    props.setCurrLead(null);
  }

  const queryURL = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"
  let enqId = (currLead) ? currLead.enquiry_id : 0;

  const onSaveClicked = async () => {
    if (currLead) {
      // validate the form object
      let isValid = true;
      let emptyCount = 0;


      // any item empty should be invalid
      if (formObject && formObject.fields && formObject.fields.length) {
        formObject.fields.map((obj, idx) => {
          if (obj.isSelected && !obj.field_value)
            emptyCount++;
        })
      } else {
        isValid = false;
      }

      if (emptyCount > 0) {
        isValid = false;
      }

      if (isValid) {


        handleClose();
        const body = {
          enquiry_id: currLead.enquiry_id,
          admission_obj: formObject,
          admission_status: 'APPLICATION',
          payment_verify_url: ''
        }

        const resp = await COUNSELLORAPI.postSaveAdmission(body);
        console.log(TAG, 'onSaveClicked(): resp ' + JSON.stringify(resp));

        Swal.fire({
          title: 'Success',
          text: 'Application saved successfully..',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      } else {

        Swal.fire({
          title: 'Invalid input',
          text: 'Please check if all fields are entered..',
          icon: 'error',
          confirmButtonText: 'OK'
        })

        return false;
      }

    } else {
      // sweetalert
      console.log(TAG, 'onSaveClicked: empty values')

      MySwal.fire({
        title: 'Error',
        text: 'Please enter required fields..',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        // handleClose();


      })

      return false;
    }

    // reload leads
    props.reloadEnquiries();
  }

  useEffect(() => {
    const instId2 = localStorage.getItem("applier.inst.id");
    setInstId(instId2);

    // get school application form

    formSetupHandler();
  }, [])

  console.log(TAG, 'EnquiryModal props currLead parent name2 ' + parentName2);
  console.log(TAG, 'EnquiryModal state parent name ' + parentName);

  let dt1 = new Date();
  let yr1 = dt1.getFullYear();
  let yr2 = yr1 + 1;

  let enqDt = `${yr1} - ${yr2}`;

  return (
    <>
      {/* <Button variant="contained" color='primary' onClick={handleShow}
        startIcon={<AddIcon />}>
        Add Lead
      </Button> */}

      <Modal
        size="xl"
        show={onShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Admission Form {enqDt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <AddLeadForm
            studentName={studentName}
            studentGender={studentGender}
            parentName={parentName}
            parentEmail={parentEmail}
            classApplied={classApplied}
            contact={contact}
            isParent={isParent}
            setParentEmail={(em) => setParentEmail(em)}
            setClassApplied={(cl) => setClassApplied(cl)}
            setStudentName={(nm) => setStudentName(nm)}
            setStudentGender={(g) => setStudentGender(g)}
            setParentName={(nm) => setParentName(nm)}
            setContact={(c) => setContact(c)}
            setIsParent={(v) => setIsParent(v)}
          /> */}
          <APPLICATION_FORM />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSaveClicked}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


const SEARCHBAR = (props) => {
  const { enquiryList, oEnquiryList } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.enquiry_id.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        if (itm.parent_name.toString().includes(value)
          || itm.Student_name.toString().includes(value)
        ) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}



const CounsellorLeads = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [enquiryList, setEnquiryList] = useState([])
  const [oEnquiryList, setOEnquiryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cons, setCons] = useState(null)
  const [onShow, setOnShow] = useState(false)
  const [currLead, setCurrLead] = useState(null)
  const [showNote, setShowNote] = useState(false)
  const [notes, setNotes] = useState(null)
  const [showFollowup, setShowFollowup] = useState(false)
  const [followup, setFollowup] = useState(null)
  const [channel, setChannel] = useState(null)
  const [followupChannel, setFollowupChannel] = useState(null)
  const [status, setStatus] = useState('PROSPECTIVE');

  // const [parentName, setParentName] = useState("");
  // const [studentName, setStudentName] = useState("");
  // const [classApplied, setClassApplied] = useState('');
  // const [contact, setContact] = useState("");
  // const [parentEmail, setParentEmail] = useState("");

  // const [parentName, setParentName] = useState("");
  // const [studentName, setStudentName] = useState("");
  // const [studentGender, setStudentGender] = useState("male");
  // const [classApplied, setClassApplied] = useState('');
  // const [contact, setContact] = useState("");
  // const [parentEmail, setParentEmail] = useState("");
  // const [desc, setDesc] = useState("ghdfgfg");
  // const [medium, setMedium] = useState("english");
  // const [instId, setInstID] = useState(17);
  // const [consId, setConsId] = useState(1);
  // const [isParent, setIsParent] = useState(1);
  // const [consExs, setConsExs] = useState("yes");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNoteShown = () => {
    setShowNote(true);
  }

  const handleAddNote = (lead) => {
    setCurrLead(lead);
  }

  const handleNoteClose = () => {
    setShowNote(false);
  }

  const handleFollowupShown = () => {
    setShowFollowup(true);
  }

  const handleAddFollowup = (lead) => {
    setCurrLead(lead);
  }

  const handleFollowupClose = () => {
    setShowFollowup(false);
  }

  const handleClick = () => {
    setModalOpen(true);
    document.body.classNameList.add('blurred');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classNameList.remove('blurred');
  };


  console.log('list of councellors', cons);

  const ROWS = () => {
    return enquiryList.map((item) => {
      return (
        <tr>

          <td>{item.enquiry_id}</td>
          <td>
            <h2 className="table-avatar">
              {/* <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-07.jpg" alt="User Image" /></a> --> */}
              <td>{item.parent_name}</td>
            </h2>
          </td>
          <td>{item.student_className_applied}</td>
          <td>{item.parent_contact}</td>
          <td>
            <select>
              <option>{item.parent_name}</option>
              <option>two</option>
              <option>three</option>
              <option>four</option>
            </select>
          </td>
          <td>
            <select>
              <option>Jeffrey Wong</option>
              <option>Fields Malynne</option>
              <option>	Jeffrey Scott</option>
              <option>J Shaffer</option>
            </select>
          </td>
          <td>
            <Link to='/sch-student'>
              <AiOutlineEye style={{ fontSize: 'large' }} />
            </Link>
          </td>
          <td className="text-end">
            <div className="actions ">
              <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">

              </a>class
              <a href="" className="btn btn-sm bg-danger-light" >
                <div >
                  <MdOutlineDeleteForever style={{ fontSize: '1.2rem', color: 'red' }} />
                </div>

              </a>
            </div>
          </td>
        </tr>
      )
    })
  }

  const loadEnquiries = async () => {
    const data = await COUNSELLORAPI.getLeads();
    console.log(TAG, 'loadEnquiries(): data ' + JSON.stringify(data));
    setEnquiryList(data);
    setOEnquiryList(data);
  }

  const loadCons = async () => {
    const data = await COUNSELLORAPI.loadCounsellors(); // get logged in institute's counsellor's list
    setCons(data);
  }

  useEffect(() => {
    loadEnquiries()

  }, [])

  const onSaveClicked2 = async () => {

    handleClose();
  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  const handleSaveNote = async () => {

    console.log(TAG, 'handleSaveNote(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveNote(): notes ' + notes);
    console.log(TAG, 'handleSaveNote(): channel ' + channel);

    const { enquiry_id } = currLead;
    if (notes && enquiry_id > 0 && channel) {
      const body = {
        enquiry_id: enquiry_id,
        page_feature: channel,
        activity_text: notes
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Note for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await COUNSELLORAPI.postSaveActivityNotes(body);

      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleNoteClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleNoteShown();
    }
  }

  const handleSaveFollowup = async () => {

    console.log(TAG, 'handleSaveFollowup(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveFollowup(): notes ' + followup);
    console.log(TAG, 'handleSaveFollowup(): channel ' + followupChannel);

    const { enquiry_id } = currLead;
    if (followup && enquiry_id > 0 && followupChannel) {
      const body = {
        enquiry_id: enquiry_id,
        status: status,
        page_feature: followupChannel,
        activity_text: followup
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Followup for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })

      const resp = await COUNSELLORAPI.postSaveFollowup(body);

      MySwal.close();
      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleFollowupClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleFollowupShown();
    }

    // reload enquiries
    loadEnquiries();
  }

  const onSendEmail = async (lead) => {
    MySwal.fire({
      title: 'Sending email..',
      text: 'Application form is being sent to the lead..',
      icon: 'info',
      confirmButtonText: 'OK',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    })
    const resp = await COUNSELLORAPI.postSendEmailApp(lead);

    console.log(TAG, 'onSendEmail(): resp ' + JSON.stringify(resp));

    MySwal.close();
    MySwal.fire({
      title: 'Success',
      text: 'Email with application sent successfully..',
      icon: 'success',
      confirmButtonText: 'OK'
    })

  }

  return (
    <div>
      <CounsellorSidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table comman-shadow">
                <div className="card-body">


                  <div className="page-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="d-flex flex-row align-items-center w-75">
                        <div className="col2">
                          <div className="col2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <h3 className="page-title">Leads</h3>

                          </div>

                        </div>
                        <div className="col2-auto w-100">
                          <SEARCHBAR
                            enquiryList={enquiryList}
                            oEnquiryList={oEnquiryList}
                            setEnquiryList={setEnquiryList}
                          />

                        </div>

                      </div>
                      <div className="col-lg-2">
                        <div className="search-student-btn">
                          {/* <Link to='/enquiry'>
                            <Button variant="contained" startIcon={<AddIcon />}>
                              Add Lead
                            </Button>
                          </Link> */}

                          <EnquiryModal
                            reloadEnquiries={loadEnquiries}
                            onShow={onShow}
                            setOnShow={setOnShow}
                            currLead={currLead}
                            setCurrLead={setCurrLead} // update
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <LEADSLIST
                    enquiryList={enquiryList}
                    cons={cons}
                    onShowEditForm={(lead) => onShowEditForm(lead)}
                    setOnShow={setOnShow}
                    handleAddNote={(lead) => handleAddNote(lead)}
                    handleSaveNote={(lead) => handleSaveNote(lead)} /* show modal */

                    handleAddFollowup={(lead) => handleAddFollowup(lead)}
                    handleSaveFollowup={(lead) => handleSaveFollowup(lead)} /* show modal */

                    showNote={showNote}
                    showFollowup={showFollowup}
                    handleClose={() => handleNoteClose()}
                    handleNoteShown={() => handleNoteShown()}
                    handleCloseFollowup={() => handleFollowupClose()}
                    handleFollowupShown={() => handleFollowupShown()}
                    currLead={currLead}
                    setCurrLead={setCurrLead}
                    reloadEnquiries={loadEnquiries}

                    onSendEmail={(lead) => onSendEmail(lead)}
                  />


                  {/* NOTE MODAL */}
                  <NoteModal
                    show={showNote}
                    handleClose={() => handleNoteClose()}
                    handleNoteShown={() => handleNoteShown()}
                    handleSaveNote={(lead) => handleSaveNote(lead)}
                    setNotes={(note) => setNotes(note)}
                    currLead={currLead}
                    setCurrLead={setCurrLead}
                    handleChangeNote={(val) => setNotes(val)}
                    setChannel={(val) => setChannel(val)}
                  />

                  {/* FOLLOWUP MODAL */}
                  <FollowupModal
                    show={showFollowup}
                    handleCloseFollowup={() => handleFollowupClose()}
                    handleFollowupClose={() => handleFollowupClose()}
                    handleFollowupShown={() => handleFollowupShown()}
                    handleSaveFollowup={(lead) => handleSaveFollowup(lead)}
                    currLead={currLead}
                    setCurrLead={setCurrLead}
                    handleChangeFollowup={(val) => setFollowup(val)}
                    setStatus={(stat) => setStatus(stat)}
                    setFollowup={(note) => setFollowup(note)}
                    setFollowupChannel={(val) => setFollowupChannel(val)}

                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default CounsellorLeads;
