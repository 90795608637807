
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AreaAdmin from './components/charts/AreaAdmin'
import BarAdmin from './components/charts/BarAdmin'
import Sidebar from './Sidebar'
import INSTITUTEAPI from '../http/institute';

const TAG = 'Home.jsx';
const LIKE_CHART_STATS = {
    series: [
        {
            name: 'Admissions',
            data: [11, 32, 45, 32, 34, 58, 14]
        },
        {
            name: 'Leads',
            data: [131, 148, 128, 151, 148, 109, 180]
        }
    ],
    options: {
        chart: {
            height: 350,
            type: 'area',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'text',
            categories: ["10th",
                "9th",
                "8th",
                "7th",
                "6th",
                "5th",
                "4th",
                "3th",
                "2th",
                "1th",
            ]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        }
    }
};

const COUNS_CHART_STATS = {
    series: [
        {
            name: 'Admissions',
            data: [11, 32, 45, 32, 34, 58, 14]
        },
        {
            name: 'Leads',
            data: [131, 148, 128, 151, 148, 109, 180]
        }
    ],
    options: {
        chart: {
            height: 350,
            type: 'bar',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'text',
            categories: ["Praveen",
                "Mukesh",
                "Akhil",
                "Gowri",
                "Shankar",
                "Murali",
                "Narayana",
                "Naresh",
                "Rosy",
                "Padma",
            ]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        }
    }
}

const SEATAVAIL = (props) => {
    const { aval, idx } = props;

    const { classroom_std, no_of_seats, admission_count, seats_remaining } = aval;
    let suffix = 'th';
    let clr = classroom_std;

    switch(classroom_std) {
        case 1:
            suffix = 'st';
            break;
        case 2:
            suffix = 'nd';
            break;
        case 3:
            suffix = 'rd';
            break;
        case 13:
            clr = 'L.K.G';
            suffix = '';
            break;
            
        case 14:
            clr = 'U.K.G';
            suffix = '';
            break;
        case 15:
            clr = 'Nursery';
            suffix = '';
            break;
    }

    let cls = 'table-primary';
    if(((idx+1)%2)===0)
        cls = 'table-primary';
    else if(((idx+1)%3)===0)
        cls = 'table-info';
    else if(((idx+1)%4)===0)
        cls = 'table-secondary';
    else if(((idx+1)%5)===0)
        cls = 'table-danger';
    else if(((idx+1)%6)===0)
        cls = 'table-warning';

    return (
        <tr class={cls}>
            <td>{clr} <sup>{suffix}</sup></td>
            <td>{admission_count}</td>
            <td>{seats_remaining}</td>

        </tr>
    )
}

const SEATSAVAIL = (props) => {
    const { seatsAvail } = props;

    if(!seatsAvail || !seatsAvail.data || !seatsAvail.data.length) {
        return <></>
    }



    return seatsAvail.data.map((aval, idx5) => {
        return <SEATAVAIL
        aval={aval}
        key={idx5}
        idx={idx5}
        />
    })
}

const COUNSELLORTARGET = (props) => {
    const { tar } = props;
    console.log(TAG, 'COUNSELLORTARGET tar single ', JSON.stringify(tar));

    return (
        <tr>
            <td className="text-nowrap">
                <div>{tar.councellor_id}</div>
            </td>
            <td className="text-nowrap">
                <a href="profile.html">
                    <img className="rounded-circle"
                        src={tar.councellor_photo} width="25"
                        alt={tar.councellor_name} />
                    {tar.councellor_name}
                </a>
            </td>
            <td className="text-center">{tar.admission_count}</td>
            <td className="text-center">{tar.target_reached_percentage}%</td>
            <td className="text-end">

            </td>
        </tr>
    )
}

const COUNSELLORTARGETS = (props) => {
    const { targets } = props;

    console.log(TAG, 'COUNSELLORTARGETS targets list ', JSON.stringify(targets));

    if(!targets || !targets.data || !targets.data.length) {
        return <></>
    }

    return targets.data.map((tar, idx) => {
        return <COUNSELLORTARGET 
        tar={tar}
        key={idx}
        />
    })
}

const Home = () => {
    const [overviewStats, setOverviewStats] = useState(LIKE_CHART_STATS);
    const [counsellorStats, setCounsellorStats] = useState(COUNS_CHART_STATS);
    const [counsellorTargets, setCounsellorTargets] = useState([]);
    const [seatsAvail, setSeatsAvail] = useState([]);
    const [enqCount, setEnqCount] = useState(0);
    const [currMonthAdm, setCurrMonthAdm] = useState(0);
    const [consCount, setConsCount] = useState(0);
    const [fbCount, setFbCount] = useState(0);
    const [twtCount, setTwtCount] = useState(0);
    const [instaCount, setInstaCount] = useState(0);
    const [lnkdCount, setLnkdCount] = useState(0);

    const _setupOverviewStats = (stats) => {
        let sLikeChart = JSON.parse(JSON.stringify(LIKE_CHART_STATS)); // copy back

        console.log(TAG, '_setupOverviewStats stats from api ', JSON.stringify(stats));
        if(stats) {
            // leads data
            let leadsData = [];
            let admData = [];
            let categories = []; // standards

            if(stats.lead_data) {
                stats.lead_data.map((led, idx) => {
                    // console.log(TAG, '_setupStats led ', JSON.stringify(led));
                    // console.log(TAG, '_setupStats led keys ', Object.keys(led));
                    const key = Object.keys(led)[0];
                    console.log(TAG, '_setupOverviewStats key ', key);
                    const val = led[key];
                    console.log(TAG, '_setupOverviewStats val ', val);

                    let suffix = 'th';
                    if(parseInt(key) == 1)
                        suffix = 'st';
                    else if(parseInt(key) == 2)
                        suffix = 'nd';
                    else if(parseInt(key) == 3)
                        suffix = 'rd';
                    categories.push(key+suffix);
                    leadsData.push(val);
                })
            }

            if(stats.admissions_data) {
                stats.admissions_data.map((adm, idx2) => {
                    const key2 = Object.keys(adm)[0]; // only 1 key exists in this object
                    const val2 = adm[key2];

                    admData.push(val2);
                })
            }

            // update object
            sLikeChart.series[0].data = admData;
            sLikeChart.series[1].data = leadsData;

            sLikeChart.options.xaxis.categories = categories;
        }

        // update
        setOverviewStats(sLikeChart);
    }

    const _setupCounsellorStats = (stats) => {
        let sLikeChart = JSON.parse(JSON.stringify(COUNS_CHART_STATS)); // copy back

        console.log(TAG, '_setupCounsellorStats stats from api ', JSON.stringify(stats));
        if(stats) {
            // leads data
            let leadsData = [];
            let admData = [];
            let categories = []; // standards

            if(stats.data) {
                stats.data.map((couns, idx) => {

                    // each counsellor
                    const key = Object.keys(couns)[0];
                    console.log(TAG, '_setupCounsellorStats key ', key);
                    const val = couns[key];
                    console.log(TAG, '_setupCounsellorStats val ', val);

                    leadsData.push(val.lead_count);
                    admData.push(val.admission_count);



                    categories.push(key);
                    
                })
            }

            // update object
            sLikeChart.series[0].data = admData;
            sLikeChart.series[1].data = leadsData;

            sLikeChart.options.xaxis.categories = categories;
        }

        // update
        setCounsellorStats(sLikeChart);
    }

    const _getOverviewStats = async () => {
        const resp = await INSTITUTEAPI.getOverviewStats();

        console.log(TAG, 'Home _getOverviewStats() resp ', JSON.stringify(resp));
        const stats = resp;
        // setOverviewStats(stats);
        _setupOverviewStats(stats);
        console.log(TAG, 'setstate completed..');
    }

    const _getCounsellorStats = async () => {
        const resp = await INSTITUTEAPI.getCounsellorStats();
        console.log(TAG, 'Home _getCounsellorStats() resp ', JSON.stringify(resp));
        const stats = resp;
        
        _setupCounsellorStats(stats);
        console.log(TAG, 'setstate completed..');
    }

    const _getCounsellorTargets = async () => {
        const resp = await INSTITUTEAPI.getCounsellorTargets();
        console.log(TAG, 'Home _getCounsellorTargets() resp ', JSON.stringify(resp));
        const stats = resp;
        
        setCounsellorTargets(stats);
        console.log(TAG, 'setstate completed..');
    }

    const _getSeatsAvail = async () => {
        const resp = await INSTITUTEAPI.getSeatsAvail();

        console.log(TAG, 'Home _getSeatsAvail() resp ', JSON.stringify(resp));
        const stats = resp;
        
        setSeatsAvail(stats);
        console.log(TAG, 'setstate completed..');
    }

    
    const _getTopCounts = async () => {
        const resp = await INSTITUTEAPI.getTopCounts();

        console.log(TAG, 'Home _getTopCounts() resp ', JSON.stringify(resp));
        const stats = resp;
        
        setEnqCount(stats.students_enquired_count);
        setCurrMonthAdm(stats.current_month_admissions_count);
        setConsCount(stats.school_counsellor_count);
    }

    const _getSocialCounts = async () => {
        const resp = await INSTITUTEAPI.getSocialCounts();

        console.log(TAG, 'Home _getSocialCounts() resp ', JSON.stringify(resp));
        const stats = resp;
        
        setFbCount(stats.facebook_count);
        setTwtCount(stats.twitter_count);
        setInstaCount(stats.instagram_count);
        setLnkdCount(stats.linkedin_count);

    }

    useEffect(() => {

        _getOverviewStats();
        _getCounsellorStats();
        _getCounsellorTargets();
        _getSeatsAvail();

        _getTopCounts();
        _getSocialCounts();

        return () => {

        }
    }, [])

    console.log(TAG, 'rendered again with stats ', JSON.stringify(overviewStats));

    return (
        <div className='home-page'>
            <Sidebar />
            <div className="main-wrapper">

                <div className="page-wrapper">
                    <div className="content container-fluid">

                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="page-sub-header">
                                        <h3 className="page-title">Welcome Admin!</h3>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                            <li className="breadcrumb-item">Admin</li>
                                        </ul>
                                        <div className="register">
                                            {/* <Link to="/register">
                             <button>Register</button>
                             </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-sm-6 col-12 d-flex">
                                <div className="card bg-comman w-100">
                                    <div className="card-body">
                                        <div className="db-widgets d-flex justify-content-between align-items-center">
                                            <div className="db-info">
                                                <h6>Enquiries / Leads</h6>
                                                <h3>{enqCount}</h3>
                                            </div>
                                            <div className="db-icon">
                                                <img src="assets/img/icons/dash-icon-01.svg" alt="Dashboard Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6 col-12 d-flex">
                                <div className="card bg-comman w-100">
                                    <div className="card-body">
                                        <div className="db-widgets d-flex justify-content-between align-items-center">
                                            <div className="db-info">
                                                <h6>Current Month Admissions</h6>
                                                <h3>{currMonthAdm}</h3>
                                            </div>
                                            <div className="db-icon">
                                                <img src="assets/img/icons/dash-icon-02.svg" alt="Dashboard Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6 col-12 d-flex">
                                <div className="card bg-comman w-100">
                                    <div className="card-body">
                                        <div className="db-widgets d-flex justify-content-between align-items-center">
                                            <div className="db-info">
                                                <h6>Counsellors</h6>
                                                <h3>{consCount}</h3>
                                            </div>
                                            <div className="db-icon">
                                                <img src="assets/img/icons/dash-icon-03.svg" alt="Dashboard Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-3 col-sm-6 col-12 d-flex">
                                <div className="card bg-comman w-100">
                                    <div className="card-body">
                                        <div className="db-widgets d-flex justify-content-between align-items-center">
                                            <div className="db-info">
                                                <h6>Revenue</h6>
                                                <h3>$555</h3>
                                            </div>
                                            <div className="db-icon">
                                                <img src="assets/img/icons/dash-icon-04.svg" alt="Dashboard Icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className="card card-chart">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="card-title">Overview</h5>
                                            </div>
                                            <div className="col-6">
                                                <ul className="chart-list-out">
                                                    <li><span className="circle-blue"></span>Admissions</li>
                                                    <li><span className="circle-green"></span>Leads</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <AreaAdmin
                                        stats={overviewStats}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12 col-lg-6">

                                <div className="card card-chart">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="card-title"></h5>
                                            </div>
                                            <div className="col-6">
                                                <h5 className="card-title">Counsellor wise admissions</h5>

                                                <ul className="chart-list-out">
                                                    <li><span className="circle-green"></span>Leads</li>
                                                    <li><span className="circle-blue"></span>Admissions</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <BarAdmin 
                                        stats={counsellorStats}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 d-flex">

                                <div className="card flex-fill student-space comman-shadow">
                                    <div className="card-header d-flex align-items-center">
                                        <h5 className="card-title">Councellors List</h5>
                                        <ul className="chart-list-out student-ellips">

                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table
                                                className="table star-student table-hover table-center table-borderless table-striped">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th className="text-center">No. of Admissions</th>
                                                        <th className="text-center">Target Reached</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <COUNSELLORTARGETS
                                                    targets={counsellorTargets}
                                                    />

                                                    {/*
                                                    <tr>
                                                        <td className="text-nowrap">
                                                            <div>PRE2209</div>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <a href="profile.html">
                                                                <img className="rounded-circle"
                                                                    src="assets/img/profiles/avatar-02.jpg" width="25"
                                                                    alt="Star Students" />
                                                                John Smith
                                                            </a>
                                                        </td>
                                                        <td className="text-center">1185</td>
                                                        <td className="text-center">98%</td>
                                                        <td className="text-end">

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap">
                                                            <div>PRE1245</div>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <a href="profile.html">
                                                                <img className="rounded-circle"
                                                                    src="assets/img/profiles/avatar-01.jpg" width="25"
                                                                    alt="Star Students" />
                                                                Jolie Hoskins
                                                            </a>
                                                        </td>
                                                        <td className="text-center">1195</td>
                                                        <td className="text-center">99.5%</td>
                                                        <td className="text-end">

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap">
                                                            <div>PRE1625</div>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <a href="profile.html">
                                                                <img className="rounded-circle"
                                                                    src="assets/img/profiles/avatar-03.jpg" width="25"
                                                                    alt="Star Students" />
                                                                Pennington Joy
                                                            </a>
                                                        </td>
                                                        <td className="text-center">1196</td>
                                                        <td className="text-center">99.6%</td>
                                                        <td className="text-end">

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap">
                                                            <div>PRE2516</div>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <a href="profile.html">
                                                                <img className="rounded-circle"
                                                                    src="assets/img/profiles/avatar-04.jpg" width="25"
                                                                    alt="Star Students" />
                                                                Millie Marsden
                                                            </a>
                                                        </td>
                                                        <td className="text-center">1187</td>
                                                        <td className="text-center">98.2%</td>
                                                        <td className="text-end">

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap">
                                                            <div>PRE2209</div>
                                                        </td>
                                                        <td className="text-nowrap">
                                                            <a href="profile.html">
                                                                <img className="rounded-circle"
                                                                    src="assets/img/profiles/avatar-05.jpg" width="25"
                                                                    alt="Star Students" />
                                                                John Smith
                                                            </a>
                                                        </td>
                                                        <td className="text-center">1185</td>
                                                        <td className="text-center">98%</td>
                                                        <td className="text-end">

                                                        </td>
                                                    </tr>
                            */}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xl-6 d-flex">

                                <div className="card flex-fill comman-shadow">
                                    <div className="card-header d-flex align-items-center">
                                        <h5 className="card-title ">Seats Availability by Class </h5>

                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Class / Standard</th>
                                                        <th>Admission Count</th>
                                                        <th>Seats Available</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <SEATSAVAIL
                                                    seatsAvail={seatsAvail}
                                                    />

                                                    {/*
                                                    <tr class="table-info">
                                                        <td>1 <sup>st</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-secondary">
                                                        <td>2 <sup>nd</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-danger">
                                                        <td>3 <sup>rd</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-warning">
                                                        <td>4 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-info">
                                                        <td>5 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-primary">
                                                        <td>6 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-danger">
                                                        <td>7 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-primary">
                                                        <td>8 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-secondary">
                                                        <td>7 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-danger">
                                                        <td>8 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-primary">
                                                        <td>9 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                                                    <tr class="table-dark">
                                                        <td>10 <sup>th</sup></td>
                                                        <td>35</td>
                                                        <td>5</td>

                                                    </tr>
                        */}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card flex-fill fb sm-box">
                                    <div className="social-likes">
                                        <p>Leads from facebook</p>
                                        <h6>{fbCount}</h6>
                                    </div>
                                    <div className="social-boxs">
                                        <img src="assets/img/icons/social-icon-01.svg" alt="Social Icon" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card flex-fill twitter sm-box">
                                    <div className="social-likes">
                                        <p>Leads from twitter</p>
                                        <h6>{twtCount}</h6>
                                    </div>
                                    <div className="social-boxs">
                                        <img src="assets/img/icons/social-icon-02.svg" alt="Social Icon" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card flex-fill insta sm-box">
                                    <div className="social-likes">
                                        <p>Leads from instagram</p>
                                        <h6>{instaCount}</h6>
                                    </div>
                                    <div className="social-boxs">
                                        <img src="assets/img/icons/social-icon-03.svg" alt="Social Icon" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card flex-fill linkedin sm-box">
                                    <div className="social-likes">
                                        <p>Leads from linkedin</p>
                                        <h6>{lnkdCount}</h6>
                                    </div>
                                    <div className="social-boxs">
                                        <img src="assets/img/icons/social-icon-04.svg" alt="Social Icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <p>Copyright © 2022 Applier</p>
                    </footer>
                </div>
            </div>


        </div>
    )
}

export default Home;

