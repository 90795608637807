import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from '../resource/Constants';

const REGAPI = Constants.Application.APPLIER_END_URL + "/school-creator/"

const FirstReg = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(false)

  const navigate = useNavigate();
  const notify = () => toast("Please enter contact!");
  const nameNotify = () => toast("Please enter name!");
  const emailNotify = () => toast("Please check email format!");

  const newRegHandler = () => {

    let proceed = false;
    if (!name) {
      nameNotify()
      setBtnDisabled(false);
      return false;
    } else if (!ValidateEmail(email)) {
      emailNotify()

      setBtnDisabled(false);
      return false;
    } else if (!contact) {
      notify()
      setBtnDisabled(false);
      return false;
    } else {
      // all okay
      proceed = true;
    }

    if (proceed) {
      setIsLoading(true);
      setBtnDisabled(true) // button is disabled, unless server response comes, no double click allowed

      let regData = ({
        creator_name: name,
        email: email,
        contact_number: contact
      })

      axios({
        method: 'post',
        url: REGAPI,
        data: regData,
      }).then(function (response) {
        console.log(response);
        setIsLoading(true)
        navigate('/alert-resp')
        // setBtnDisabled(false); // enable button for re-submit
      }).catch(function (error) {
        console.log(error);
        setBtnDisabled(false); // enable button for re-submit
        setIsLoading(false)
      })
    }
  }

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    // alert("You have entered an invalid email address!")
    return (false)
  }

  if (isLoading) {
    return (
      <div
        class="d-flex justify-content-center align-items-center vh-100">
        <h5>Please wait...</h5>
      </div>
    )
  }



  return (
    <div>
      <section className='login-section' >
        <div className="login-sec">
          <div className="start-login">
            <img src="./assets/img/schooler-applier.png" className='img-logo' alt="" />
            <div className="sign">Registration</div>
            <form action="">
              <input type="text" placeholder='Name *' name='name' value={name} onChange={(e) => setName(e.target.value)} /><br /><br />
              <input type="email" placeholder='Email address *' name='email' value={email} onChange={(e) => setEmail(e.target.value)} /><br /><br />
              <input type="number" placeholder='Contact number *' name='contact' value={contact} onChange={(e) => setContact(e.target.value)} disabled={isLoading} /><br /><br />
              <button
                disabled={btnDisabled}
                type='button' style={{ backgroundColor: "#6610f2", border: "none" }} onClick={newRegHandler}>Register</button>
            </form>
            <div className="rem-pass" style={{ color: "#6610f2" }}>
              {/* <Link to='/login'>
                <p style={{ textAlign: "center", width: "100%", color: "black" }}>Already have an account? <span style={{ fontSize: "1.2rem" }}>Sign In</span></p>
              </Link> */}

              <Link to='/login'>
                Already have an account? <span style={{ fontSize: "1.2rem" }}>Sign In</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />
    </div>
  )
}

export default FirstReg