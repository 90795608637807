import React, { useState } from 'react';

import ReactApexChart from 'react-apexcharts'

const AnalyticBar = (props) => {
    const { analytics } = props;

    const [likeChart, setLikeChart] = useState(analytics)

    return (
        <div>
            <ReactApexChart
                options={analytics.options}
                series={analytics.series}
                type="bar" height={350} />
        </div>


    )
}

export default AnalyticBar;