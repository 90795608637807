import React, { useState, useEffect } from "react";


import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";

import { BiSort } from "react-icons/bi";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import EmailIcon from '@mui/icons-material/Email';
import INSTITUTEAPI from '../http/institute';
import { Web } from "@mui/icons-material";
import Constants from "../resource/Constants";

const TAG = 'SchoolCampaign.jsx';

const STATS = (props) => {
  const { stats } = props;

  if (!stats) {
    return (
      <div className='alert alert-warning'>

        No campaigns setup yet!
      </div>
    )
  }

  const { other_count,
    twitter_count,
    facebook_count,
    instagram_count,
    whatsapp_count,
    linkedin_count,
    email_count,
    quora_count,
    sms_count
  } = stats;

  return (

    <div class="table-responsive">

      <table
        class="table border-0 star-student table-center mb-0 datatable "
        style={{ border: "1px solid black" }}
      >
        <thead class="student-thread">
          <tr>
            {/* <th>
            Campaign Id <BiSort />
          </th> */}
            <th>
              Campaign Name <BiSort />
            </th>
            <th>
              Medium <BiSort />
            </th>
            <th>
              Leads <BiSort />
            </th>
            <th></th>
            {/* <th class="text-end">Action</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>FB2618</td> */}
            <td>Facebook Camp</td>
            <td>Facebook</td>
            <td>{facebook_count}</td>
          </tr>
          <tr>
            {/* <td>LK2356</td> */}
            <td>LinkedIn Camp</td>
            <td>LinkedIn</td>
            <td>{linkedin_count}</td>
          </tr>
          <tr>
            {/* <td>INS1836</td> */}
            <td>Insta Camp</td>
            <td>Instagram</td>
            <td>{instagram_count}</td>
          </tr>
          <tr>
            {/* <td>TwT1256</td> */}
            <td>Twitter Camp</td>
            <td>Twitter</td>
            <td>{twitter_count}</td>
          </tr>
          <tr>
            {/* <td>WAP3961</td> */}
            <td>What Camp</td>
            <td>What's App</td>
            <td>{whatsapp_count}</td>
          </tr>
          <tr>
            {/* <td>WAP3961</td> */}
            <td>Email Camp</td>
            <td>Email</td>
            <td>{email_count}</td>
          </tr>
          <tr>
            {/* <td>WAP3961</td> */}
            <td>Quora Camp</td>
            <td>Quora</td>
            <td>{quora_count}</td>
          </tr>
          <tr>
            {/* <td>WAP3961</td> */}
            <td>SMS Camp</td>
            <td>Simple Message Service</td>
            <td>{sms_count}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const SchoolCampaign = () => {
  const [selected, setSelected] = useState(["Admissions"]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState('');
  const [fbStyle, setFbStyle] = useState('outlined');
  const [twtStyle, setTwtStyle] = useState('outlined');
  const [lnkdStyle, setLnkdStyle] = useState('outlined');
  const [whtStyle, setWhtStyle] = useState('outlined');
  const [emlStyle, setEmlStyle] = useState('outlined');

  const [stats, setStats] = useState(null);

  const schoolId = localStorage.getItem("applier.inst.id");
  const schoolLogo = localStorage.getItem("applier.inst.photo");

  const websiteUrl = '<button onclick="window.location.assign(\'' + Constants.Application.SITE_URL + '/enquiry?school_id=' + schoolId + '&logo=' + schoolLogo + '\')">Apply</button>';

  const onClickFb = () => {
    setFbStyle('contained');
    setTwtStyle('outlined');
    setLnkdStyle('outlined');
    setWhtStyle('outlined');
    setEmlStyle('outlined');
  }

  const onClickTwt = () => {
    setFbStyle('outlined');
    setTwtStyle('contained');
    setLnkdStyle('outlined');
    setWhtStyle('outlined');
    setEmlStyle('outlined');
  }

  const onClickLnkd = () => {
    setFbStyle('outlined');
    setTwtStyle('outlined');
    setLnkdStyle('contained');
    setWhtStyle('outlined');
    setEmlStyle('outlined');
  }

  const onClickWht = () => {
    setFbStyle('outlined');
    setTwtStyle('outlined');
    setLnkdStyle('outlined');
    setWhtStyle('contained');
    setEmlStyle('outlined');
  }

  const onClickEml = () => {
    setFbStyle('outlined');
    setTwtStyle('outlined');
    setLnkdStyle('outlined');
    setWhtStyle('outlined');
    setEmlStyle('contained');
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal2 = () => {
    setShowModal2(true);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const genUrl = async (MEDIUM) => {
    const body = {
      media_type: MEDIUM
    }

    console.log(TAG, 'genUrl() body ' + JSON.stringify(body));

    const resp = await INSTITUTEAPI.genCampaignUrl(body);
    let url = 'INVALID URL';
    console.log(TAG, 'genUrl() resp ' + JSON.stringify(resp));
    if (resp && resp.data) {
      url = resp.data.url;
    }

    setCampaignUrl(url);

  }

  const _loadStats = async () => {

    const resp = await INSTITUTEAPI.getCampaignStats();
    console.log(TAG, '_loadStats() resp ' + JSON.stringify(resp));

    // set statistics
    setStats(resp);
  }

  useEffect(() => {
    _loadStats();
  }, [])

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <Sidebar />
      <div>
        <div>
          <div
            class="search-student-btn"
            style={{
              position: "absolute",
              top: "80px",
              right: "100px",
              zIndex: "20",
            }}
          >
            <Button variant="outlined"
              onClick={handleOpenModal}>
              Start Campaign

            </Button>

            <Button variant="outlined"
              color="info"
              className="ms-2"
              onClick={handleOpenModal2}>
              Website Button

            </Button>
            {/* 
            <button
              type="btn"
              class="btn btn-primary"
              onClick={handleOpenModal2}
            >
              Website Button
            </button>
             */}
          </div>

          <div className={`modalForm-overlay ${showModal ? "active" : ""}`}>
            <div className={`modalForm-content ${showModal ? "active" : ""}`}>
              <span
                className="close"
                onClick={handleCloseModal}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
              <div>
                <div
                  className=""
                  style={{ height: "50vh", width: "800px" }}
                >
                  <div className="">
                    <div className="row">
                      <h4>URL Builder</h4>
                      {/* <h4 style={{ textAlign: "center" }}>
                        Enter the Campaign Info
                      </h4> */}
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="bank-inner-details">
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  {/* <div className="form-group">
                                    <TextField
                                      fullWidth
                                      label="Website URL"
                                      id="fullWidth"
                                    />

                                    <p style={{ fontSize: "0.7rem" }}>
                                      The full website url
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      fullWidth
                                      label="Enter Counsellor Code"
                                      id="fullWidth"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <TextField
                                      fullWidth
                                      label="Campaign Medium"
                                      id="fullWidth"
                                    />

                                    <p style={{ fontSize: "0.7rem" }}>
                                      Marketing Medium Ex: Facebook
                                    </p>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            {/* <div>
                              <button type="button" class="btn btn-primary">
                                Generate URL
                              </button>
                            </div> */}
                            <div
                              class="form-floating1"
                              style={{ marginTop: "20px" }}
                            >
                              <textarea
                                readOnly
                                class="form-control"
                                placeholder="Generate URL by clicking any social network button below"
                                id="floatingTextarea2"
                                style={{ height: "100px", overflow: "hidden" }}
                                value={campaignUrl}
                              >{campaignUrl}</textarea>
                              <span className="small text-sm text-small text-dark">
                                Copy the generated URL and paste in your medium
                              </span>

                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <Button variant={fbStyle} endIcon={<FacebookIcon />}
                              onClick={() => {
                                genUrl('FACEBOOK');
                                onClickFb();
                              }}>
                              Facebook
                            </Button>
                            <Button variant={twtStyle} endIcon={<TwitterIcon />}
                              onClick={() => {
                                genUrl('TWITTER');
                                onClickTwt();
                              }}>
                              Twitter
                            </Button>
                            <Button variant={lnkdStyle} endIcon={<LinkedInIcon />}
                              onClick={() => {
                                genUrl('LINKEDIN');
                                onClickLnkd();
                              }}
                            >
                              LinkedIn
                            </Button>
                            {/* <Button variant="outlined" endIcon={} >
                              Insram
                            </Button> */}
                            <Button variant={whtStyle} endIcon={<WhatsAppIcon />}
                              onClick={() => {
                                genUrl('WHATSAPP');
                                onClickWht();
                              }}
                            >
                              WhatsApp
                            </Button>
                            <Button variant={emlStyle} endIcon={<EmailIcon />}
                              onClick={() => {
                                genUrl('EMAIL');
                                onClickEml();
                              }}
                            >
                              Email
                            </Button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* modal to copy school enquire button */}

          <div className={`modalForm-overlay ${showModal2 ? "active" : ""}`}>
            <div className={`modalForm-content ${showModal2 ? "active" : ""}`}>
              <span
                className="close"
                onClick={handleCloseModal2}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
              <div>
                <div
                  className=""
                  style={{ height: "36vh", width: "800px" }}
                >
                  <div className="">
                    <div className="row">
                      <h4>Website Button Builder</h4>

                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="bank-inner-details">
                              <div className="row">
                                <div className="col-lg-12 col-md-12">

                                </div>
                              </div>
                            </div>

                            <div
                              class="form-floating1"
                              style={{ marginTop: "20px" }}
                            >
                              <textarea
                                readOnly
                                class="form-control"
                                placeholder="Generate URL by clicking any social network button below"
                                id="floatingTextarea2"
                                style={{ height: "100px", overflow: "hidden" }}
                                value={websiteUrl}
                              >{websiteUrl}</textarea>
                              <span className="small text-sm text-small text-dark">
                                Copy the generated HTML and place in an appropriate place of your website.
                              </span>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div
          class="page-wrapper"
          style={{ position: "relative", top: "100px" }}
        >
          <div class="col-sm-12">
            <div class="card card-table comman-shadow">
              <div class="card-body">
                <div class="page-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <div
                        class="col"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 class="page-title">Campaign Results</h3>
                        {/* <div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
        Assign Leads:
        <select>
          <option>one</option>
          <option>two</option>
          <option>three</option>
        </select>
      </div> */}
                      </div>
                    </div>
                    <div class="col-auto text-end float-end ms-auto download-grp">
                      <div></div>

                      {/* <Link to='/enquiry'  className="btn btn-outline-primary me-2">
        Add Lead
      </Link> */}

                      {/* openmodal */}

                      {/* endopenmodal */}

                      {/* <a href="EnquiryForm.html" class="btn btn-primary"><i class="fas fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>

                <STATS
                  stats={stats}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolCampaign;
