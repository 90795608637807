/**
 * @project kgtopg - schooler - publisher
 * http api calls for
 * authentication
 */

import API from './http';
import axios from 'axios';

import Constants from '../resource/Constants';
import Cookie from 'js-cookie';
import UserSession from '../resource/UserSession';
//  import UserSession from '../resource/UserSession';

const TAG = "auth.js";

export default {
    async getLeadStatuses() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-dashboard-track-by-leadbehaviour/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getLeadStatuses() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getLeadStatuses() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getCampaignLeads() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-or-school-campaign-medium-lead-admission-enroll-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "geCampaignLeads() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "geCampaignLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    async getStdwiseLeads() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-stdwise-leads-admissions-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getStdwiseLeads() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getStdwiseLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getLeads() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-list-of-enquiries/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getLeads() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getLeadDetails() {

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        let url = Constants.Application.APPLIER_END_URL;
        url += "/counsellor-lead-details/?counellor_email=xyz";

        // we wait
        try {

            console.log("login authentication api url", url)
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(url, config);

            console.log("get lead details", response)

            let data = null;
            if (response) {
                data = response.data.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    async postSaveAdmission(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/councellor-school-admission-upload/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveAdmission(): URL ' + URL);

        console.log(TAG, 'postSaveAdmission(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSaveAdmission(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveAdmission(): exception ' + e);
            return false;
        }
    },
    async postSaveAdmission1(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/councellor-school-admission-upload1/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveAdmission(): URL ' + URL);

        console.log(TAG, 'postSaveAdmission(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSaveAdmission(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveAdmission(): exception ' + e);
            return false;
        }
    },

    async getApplications(stat, pageType) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/list-counsellor-admissions/?application_status=' + stat;
        URL += '&page_type=' + pageType;

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getApplications() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getApplications() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async postSaveExamSlot(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/set-exam-slot/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveExamSlot(): URL ' + URL);

        console.log(TAG, 'postSaveExamSlot(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSaveExamSlot(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveExamSlot(): exception ' + e);
            return false;
        }
    },

    async postSaveApplicationStatus(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/update-councellor-school-admission-upload/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveApplicationStatus(): URL ' + URL);

        console.log(TAG, 'postSaveApplicationStatus(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.put(URL, body, config);

            console.log(TAG, 'postSaveApplicationStatus(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveApplicationStatus(): exception ' + e);
            return false;
        }
    },


    async genCampaignUrl(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/campaign-url-generation/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'genCampaignUrl(): URL ' + URL);

        console.log(TAG, 'genCampaignUrl(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'genCampaignUrl(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'genCampaignUrl(): exception ' + e);
            return false;
        }
    },

    async postSaveActivityNotes(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/save-lead_activity/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveActivityNotes(): URL ' + URL);

        console.log(TAG, 'postSaveActivityNotes(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSaveActivityNotes(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveActivityNotes(): exception ' + e);
            return false;
        }
    },

    async postSaveFollowup(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/counsellor-enquiry-update-status/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSaveFollowup(): URL ' + URL);

        console.log(TAG, 'postSaveFollowup(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            // let resp = null;
            const resp = await axios.put(URL, body, config);

            console.log(TAG, 'postSaveFollowup(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveFollowup(): exception ' + e);
            return false;
        }
    },

    async getCodeCounsellor(code) {

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        let url = Constants.Application.APPLIER_END_URL;
        url += "/get-code-user/?code=" + code;

        // we wait
        try {

            console.log(TAG, "getCodeCounsellor url", url)
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(url, config);
            console.log(TAG, "getCodeCounsellor response ", response)

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getCampaignStats() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/campaign-medium-count/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getCampaignStats() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getCampaignStats() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async postSendEmailApp(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/send-school-admission-form/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'postSendEmailApp(): URL ' + URL);

        console.log(TAG, 'postSendEmailApp(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSendEmailApp(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSendEmailApp(): exception ' + e);
            return false;
        }
    },

    async getCounsellorDetails() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-details/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getCounsellorDetails() URL: ", URL);
            let token = localStorage.getItem('applier.couns.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getCounsellorDetails() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }


    },

    async updateProfileDetails(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/counsellor-details-updation/';
        let token = localStorage.getItem('applier.couns.token');
        console.log(TAG, 'updateProfileDetails(): URL ' + URL);

        console.log(TAG, 'updateProfileDetails(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.put(URL, body, config);

            console.log(TAG, 'updateProfileDetails(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'updateProfileDetails(): exception ' + e);
            return false;
        }
    },

}