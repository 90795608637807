import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar';
import Constants from '../resource/Constants';

const COUNSLISTURL = Constants.Application.APPLIER_END_URL + '/school-list-of-enquiries/'

const ListofEnquiries = ['EnquiryId', 'Lead Name', 'Grade', 'Phone No.', 'Email', 'Assign'];
const TAG = 'CounsellorList.jsx';

const CounsellorList = () => {
    const [enquiryList, setEnquiryList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const listHandler = async () => {
        setIsLoading(true)
        let token = localStorage.getItem('applier.inst.token');
        const config = {
            headers: { Authorization: `${token}` }
        };
        const response = await axios.get(COUNSLISTURL, config);
        console.log(TAG, 'CounsellorList listHandler(): ' + JSON.stringify(response))
        setEnquiryList(response.data.data);
        setIsLoading(false)
    }

    useEffect(() => {
        console.log(listHandler());
    }, [])

    console.log('list of councellors', enquiryList);

    const ROWS = () => {
        return enquiryList.map((item) => {
            return (

                <tr>

                    <td>{item.enquiry_id}</td>
                    <td>{item.parent_name}</td>
                    <td>{item.student_class_applied}</td>
                    <td>{item.parent_contact}</td>

                    <td>
                        <select>
                            <option>{item.parent_name}</option>
                            <option>two</option>
                            <option>three</option>
                            <option>four</option>
                        </select>
                    </td>
                </tr>
            )
        })
    }



    if (isLoading) {
        return <p>Loading.....</p>
    }

    const TitleObj = () => {
        return (
            <div className='page-wrapper'>

                <div class="page-header" >
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-sub-header">
                                <h3 class="page-title">Lead Manager</h3>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="student-group-form" >
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Search by ID ..." />
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Search by Name ..." />
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Search by Phone ..." />
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="search-student-btn">
                                <button type="btn" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <>
            <div style={{ marginTop: '-50px' }}>
                <Sidebar />


            </div>
            <div className='page-wrapper'>
                <TitleObj />
            </div>
            <table className="table " style={{ width: '1080px', backgroundColor: 'white', marginLeft: '300px', marginTop: '100px' }}>
                <thead>
                    <tr>
                        <th>EnquiryId</th>
                        <th>Lead Name</th>
                        <th>Grade</th>
                        <th>Date</th>

                        <th>Assign Counsellor</th>

                    </tr>
                </thead>
                <tbody>
                    <div>

                    </div>

                    {<ROWS />}
                </tbody>
            </table>


        </>
    )
}

export default CounsellorList