
import React, { useState, useEffect } from 'react'
import Sidebar from './dashboard/Sidebar'
import Constants from './resource/Constants';

const url = Constants.Application.APPLIER_END_URL + "/lead-registration/"



const ApiTest = () => {
    const [parent_name, setParent_name] = useState("")
    const [parent_contact, setParent_contact] = useState("")
    const [parent_email, setParent_email] = useState("")
    const [parent_photo, setParent_photo] = useState("")
    const [parent_password, setParent_password] = useState("")
    const [parent_comments, setParent_comments] = useState("")
    const [permanent_location, setpermanent_location] = useState("")
    const [verification_url, setverification_url] = useState("")
    const [is_parent, setis_parent] = useState("0")

    const changeHandler = (e) => {
        e.preventDefault()
        console.log({ parent_name, parent_contact, parent_email, parent_photo, parent_comments, parent_password, parent_comments, permanent_location, verification_url, is_parent })
        let data = { parent_name, parent_contact, parent_email, parent_photo, parent_comments, parent_password, parent_comments, permanent_location, verification_url, is_parent }
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {
                console.log("resp", resp)
            })
        })
    }

    return (
        <section>
            <div>

                <div className="page-wrapper">
                    <div className="row w-50 ">
                        <div className="col-xl-6 d-flex parent-form">
                            <div className="card flex-fill parentForm">
                                <div className="card-header">
                                    <h5 className="card-title">Registration Form</h5>
                                </div>
                                <div className="card-body">

                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label" >Parent Name</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="parent_name" value={parent_name} onChange={(e) => setParent_name(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Parent Contact</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name='parent_contact' value={parent_contact} onChange={(e) => setParent_contact(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label" >Parent Email</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="parent_email" value={parent_email} onChange={(e) => setParent_email(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label"  >Parent Photo</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="parent_photo" value={parent_photo} onChange={(e) => setParent_photo(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Parent Password</label>
                                        <div className="col-lg-9">
                                            <input type="password" className="form-control" name="parent_password" value={parent_password} onChange={(e) => setParent_password(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Parent Comments</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name='parent_comments' value={parent_comments} onChange={(e) => setParent_comments(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Parmanent Location</label>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="permanent_location" value={permanent_location} onChange={(e) => setpermanent_location(e.target.value)} />
                                        </div>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="verification_url" value={verification_url} onChange={(e) => setverification_url(e.target.value)} />
                                        </div>
                                        <div className="col-lg-9">
                                            <input type="text" className="form-control" name="is_parent" value={is_parent} onChange={(e) => setis_parent(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" className="btn btn-primary" onClick={changeHandler}>Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}
export default ApiTest;
