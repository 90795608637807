import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { CgClose } from 'react-icons/cg';
import axios from 'axios'
import CounsellorForm from './CounsellorForm';
import Constants from '../resource/Constants';

const emailURL = Constants.Application.APPLIER_END_URL + "/counsellor-school-register/"

const customStyles = {

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function CounsellorEmail() {
  const [email, setEmail] = useState([]);

  const EmailCheck = async () => {

    setLoading(true)
    const payload = {
      email: email,
      "institute_id": 15
    }
    const resp = await axios({
      method: 'POST',
      url: emailURL,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    setLoading(false)

    console.log('CounsellorEmail.jsx EmailCheck(): resp ' + JSON.stringify(resp));
  }

  useEffect(() => {
    // console.log(EmailCheck())
  }, [])

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [loading, setLoading] = useState(false);

  function openModal() {
    setIsOpen(true);
  }


  if (loading) {
    return (
      <>
        <p> Loading...</p>
      </>
    )
  }



  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = 'black';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Enter Counsellor Email</h2>
        <button className='close-btn' onClick={closeModal}><CgClose /></button>
        <div>I am a modal</div>
        <>
          <input type="email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />

          <button onClick={EmailCheck}>Send</button>
        </>
      </Modal>
    </div>
  );
}



export default CounsellorEmail;