import { InstallDesktopSharp } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { BsToggleOn } from "react-icons/bs";
import Sidebar from "../Sidebar";
import { PlaceOfBirth } from "./FormFields";
import { ParentName } from "./FormFields";
import { MotherTonque } from "./FormFields";
import { ParentEmail } from "./FormFields";
import { ParentContact } from "./FormFields";
import { TempAddress } from "./FormFields";
import { PermAddress } from "./FormFields";
import { StudentReligion } from "./FormFields";
import { StudentCaste } from "./FormFields";
import { StudentNationality } from "./FormFields";
import { StudenClass } from "./FormFields";
import { AdharNumber } from "./FormFields";
import { BloodGroup } from "./FormFields";
import { RelativeInSchool } from "./FormFields";
import { LastAttend } from "./FormFields";
import { AcademicYear } from "./FormFields";
import { City } from "./FormFields";
import { StdPicture } from "./FormFields";
import { PrevSch } from "./FormFields";
import { ParentPhotos } from "./FormFields";
import { ChildDOB } from "./FormFields";
import { MedicalCertify } from "./FormFields";
import { AdharCardChild } from "./FormFields";
import { SelfAtt } from "./FormFields";
import { TransferCert } from "./FormFields";
import { CasteCert } from "./FormFields";
import Switch from "@mui/material/Switch";
import axios from "axios";
import Constants from '../../resource/Constants';

const APPLICATIONURL = Constants.Application.APPLIER_END_URL + "/create-school-custom-form/";
const GETFORMURL = Constants.Application.APPLIER_END_URL + "/get-school-custom-form/";

const TAG = "Checkbox.jsx";
const FORM_OBJECT = {
  institute_id: 17,
  fields: [
    {
      //student picture
      field_name: "student_picture",
      field_type: "text",
      field_label: "Upload Student Image",
      max_length: 20,
      max_file_size: 10,
      accept_type: "jpeg/png/gif",
      isSelected: true,
    },
    {
      //permanent address
      field_name: "perm_addr",
      field_type: "textarea",
      field_label: "Permanent Address",
      max_length: 500,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //last school attended
      field_name: "schl_attend",
      field_type: "text",
      field_label: "Last School Attended",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //parent email
      field_name: "parent_email",
      field_type: "email",
      field_label: "Parent Email",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //student email
      field_name: "student_email",
      field_type: "email",
      field_label: "Student Email",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //student contact
      field_name: "student_contact",
      field_type: "text",
      field_label: "Student Contact",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //student place of birth
      field_name: "student_placeofbirth",
      field_type: "text",
      field_label: "Student Place of Birth",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //student temp address
      field_name: "student_temp_addr",
      field_type: "text",
      field_label: "Student Temporary Address",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //student adhar number
      field_name: "student_adhar_number",
      field_type: "text",
      field_label: "Student Adhaar Number ",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //blood group
      field_name: "student_blood_group",
      field_type: "text",
      field_label: "Student Blood Group",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //resides with
      field_name: "resides_with",
      field_type: "text",
      field_label: "Student Resides With",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //admission year
      field_name: "admission_year",
      field_type: "text",
      field_label: "Admission Year",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //name of the student
      field_name: "student_name",
      field_type: "text",
      field_label: "Name of the Student",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Student Gender
      field_name: "student_gender",
      field_type: "text",
      field_label: "Student Gender",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Date of Birth
      field_name: "student_dob",
      field_type: "text",
      field_label: "Date of Birth",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //City
      field_name: "city",
      field_type: "text",
      field_label: "City",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Enrollment in Grade
      field_name: "student_enroll",
      field_type: "text",
      field_label: "Enrollment in Grade",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Campus
      field_name: "campus",
      field_type: "text",
      field_label: "Campus",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Parent Name
      field_name: "parent_name",
      field_type: "text",
      field_label: "Parent Name",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Parent Contact
      field_name: "parent_contact",
      field_type: "text",
      field_label: "Parent Contact",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Mother Tonque
      field_name: "mother_tongue",
      field_type: "text",
      field_label: "Mother Tonque",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Caste
      field_name: "caste",
      field_type: "text",
      field_label: "Caste",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Religion
      field_name: "religion",
      field_type: "text",
      field_label: "Religion",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
    {
      //Naionality
      field_name: "nationality",
      field_type: "text",
      field_label: "Naionality",
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
    },
  ],
};

const saveForm = () => {
  console.log(FORM_OBJECT);
};

console.log("label:", FORM_OBJECT.field_label);

const FORM_OBJECT_INPUT = (props) => {
  return (
    <>
      <div className="custom">
        <input type="text" placeholder={props.placeholder} />
      </div>
    </>
  );
};

const FORM_OBJECT_TEXTAREA = (props) => {
  return (
    <>
      <div className="custom">
        <textarea placeholder={props.placeholder}>this is text area </textarea>
      </div>
    </>
  );
};
const FORM_OBJECT_EMAIL = (props) => {
  return (
    <>
      <div className="custom">
        <input type="email" placeholder={props.placeholder} />
      </div>
    </>
  );
};

const label = { inputProps: { "aria-label": "Switch demo" } };

const StudentName = () => {
  return <input type="text" placeholder="Student Name" />;
};
const StudentEmail = () => {
  return <input type="email" placeholder="Student Email" />;
};
const StudentContact = () => {
  return <input type="number" placeholder="Student Contact" />;
};
const StudentDOB = () => {
  return <input type="text" placeholder="Student Date of birth" />;
};
const StudentGender = () => {
  return <input type="text" placeholder="Male or Female" />;
};

const Checkbox = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isBirth, setIsBirth] = useState(false);
  const [isGender, setIsGender] = useState(false);
  const [isPlace, setIsPlace] = useState(false);
  const [isparentName, setIsParenName] = useState(false);
  const [isMotherTonq, setIsMotherTonq] = useState(false);
  const [isParentEmail, setIsParenEmail] = useState(false);
  const [isParentContact, setIsParentContact] = useState(false);
  const [isTempAdd, setIsTempAdd] = useState(false);
  const [isPermAdd, setIsPermAdd] = useState(false);
  const [isReligion, setIsReligion] = useState(false);
  const [isCaste, setIsCaste] = useState(false);
  const [isNational, setIsNational] = useState(false);
  const [isClass, setIsClass] = useState(false);
  const [isAdhar, setIsAdhar] = useState(false);
  const [isBlood, setIsBlood] = useState(false);
  const [isRelative, setIsRelative] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [isAcademic, setIsAcademic] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [isPicture, setIsPicture] = useState(false);
  const [isPrevSch, setIsPrevSch] = useState(false);
  const [isParentPhotos, setIsParentPhotos] = useState(false);
  const [isChildDOB, setIsChildDOB] = useState(false);
  const [isMedicalC, setIsMedicalC] = useState(false);
  const [isChildAdhar, setIsChildAdhar] = useState(false);
  const [isSelfAtt, setIsSelfAtt] = useState(false);
  const [isTransC, setIsTransC] = useState(false);
  const [isCasteC, setCasteC] = useState(false);

  const [isInstId, setInstId] = useState(17);

  const [formObject, setFormObject] = useState(FORM_OBJECT);

  const applicationHandler = async () => {
    let body = formObject;

    let token = localStorage.getItem("applier.token");

    const headers = {
      headers: { Authorization: `${token}` },
    };

    console.log(TAG, "applicationHandler() headers " + JSON.stringify(headers));
    const response = await axios.post(APPLICATIONURL, body, headers);
    console.log(TAG, "loginHandler() response " + JSON.stringify(response));
  };

  const formSetupHandler = async () => {
    let token = localStorage.getItem("applier.token");
    const config = {
      headers: { Authorization: `${token}` },
    };

    const response = await axios.get(GETFORMURL, config);
    console.log(TAG, "response " + JSON.stringify(response));
    setFormObject(response.data.data.field_obj);
  };

  useEffect(() => {
    // console.log(formSetupHandler());

    formSetupHandler();
  }, []);

  // const LEFT_BAR = (props) => {
  //   return (
  //     <div
  //       className="left-section"
  //       style={{ width: "400px", height: "100vh", position: "fixed" }}
  //     >
  //       {/* ------------------------------Personal detail section------------------------------------ */}
  //       <div className="sect-title">
  //         <h5>Student Details</h5>

  //         <div style={{ display: "flex" }}>
  //           <h6>Student Picture</h6>
  //           <input
  //             type="checkbox"
  //             name="checkbox-name"
  //             onChange={stdImage}
  //             checked={isPicture}
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>

  //         <div style={{ display: "flex" }}>
  //           <h6>Permanent Address</h6>
  //           <input
  //             type="checkbox"
  //             name="checkbox-name"
  //             onChange={permAdd}
  //             checked={isPermAdd}
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //       </div>
  //       {/* ------------------------------end personal detail section------------------------------------ */}
  //       {/* Educational Background */}
  //       <h5>Educational Background</h5>

  //       <div style={{ display: "flex" }}>
  //         <h6>Last School Attended</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={lastAttend}
  //           checked={isLast}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>
  //       {/* Educational Background */}

  //       {/* Parent's information  */}
  //       <h5>Parent's Information</h5>

  //       <div style={{ display: "flex" }}>
  //         <h6>Parent Email</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={parentEmail}
  //           checked={isParentEmail}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>

  //       {/* end Parent's information  */}

  //       {/* Student Resides with */}
  //       <h5>Student Other Information</h5>
  //       {/* Student Resides with */}

  //       <div style={{ display: "flex" }}>
  //         <h6>Student Email</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={emailHandler}
  //           checked={isEmail}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>

  //       <div style={{ display: "flex" }}>
  //         <h6>Student Contact</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={contactHandler}
  //           checked={isContact}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>

  //       <div style={{ display: "flex" }}>
  //         <h6>Student Place of birth</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={placeHandler}
  //           checked={isPlace}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>

  //       <div style={{ display: "flex" }}>
  //         <h6>Student Temporary Address</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={tempAddress}
  //           checked={isTempAdd}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>

  //       <div style={{ display: "flex" }}>
  //         <h6>Student Adhaar Number</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={adhaar}
  //           checked={isAdhar}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <h6>Student Blood Group</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={bloodGroup}
  //           checked={isBlood}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <h6>Student Resides With</h6>
  //         <input
  //           type="checkbox"
  //           name="checkbox-name"
  //           onChange={relative}
  //           checked={isRelative}
  //           style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //         />
  //       </div>
  //       <div>
  //         <h5> Documents Upload</h5>
  //         <div style={{ display: "flex" }}>
  //           <h6>Parents Photo's</h6>
  //           <input
  //             type="checkbox"
  //             name="checkbox-name"
  //             onChange={parentPhotoUploads}
  //             checked={isParentPhotos}
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Child DOB Certificate</h6>
  //           <input
  //             type="checkbox"
  //             name="checkbox-name"
  //             onChange={childBirthC}
  //             checked={isChildDOB}
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Child Medical Certificate</h6>
  //           <input
  //             type="checkbox"
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Child Aadhar Card</h6>
  //           <input
  //             type="checkbox"
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Self Attested Copies</h6>
  //           <input
  //             type="checkbox"
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Transfer Certificate</h6>
  //           <input
  //             type="checkbox"
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <h6>Caste Certificate</h6>
  //           <input
  //             type="checkbox"
  //             style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const APPLICATION_FORM = () => {
  //   return (
  //     <div
  //       className="right-section"
  //       style={{
  //         position: "relative",
  //         left: "400px",
  //         width: "800px",
  //         height: "150vh",
  //         padding: "50px",
  //         marginLeft: "50px",
  //         borderRadius: "10px",
  //       }}
  //     >
  //       <h3
  //         style={{
  //           textAlign: "center",
  //           marginTop: "-20px",
  //           paddingBottom: "20px",
  //         }}
  //       >
  //         Application Form Preview
  //       </h3>

  //       <div style={{ display: "flex", gap: "10px" }}>
  //         <div>
  //           <div className="ind-inp">
  //             <h5>Admission for 2023</h5>
  //             <input type="text" value="Admission Year" />
  //           </div>
  //           <h6>Personal Detail of Student</h6>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="Name of the Student" />
  //           </div>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="Student Gender" />
  //           </div>
  //           <div className="ind-inp">{isEmail ? <StudentEmail /> : ""}</div>
  //           <div className="ind-inp">{isContact ? <StudentContact /> : ""}</div>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="Date of Birth" />
  //           </div>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="City" />
  //           </div>

  //           <div className="ind-inp">
  //             <input type="text" placeholder="Enrollment in Grade" />
  //           </div>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="Campus" />
  //           </div>

  //           <div className="ind-inp">
  //             {isMotherTonq ? <MotherTonque /> : ""}
  //           </div>

  //           <div className="ind-inp">{isPicture ? <StdPicture /> : ""}</div>

  //           <div className="ind-inp">
  //             {isNational ? <StudentNationality /> : ""}
  //           </div>
  //           <div className="ind-inp">{isPermAdd ? <PermAddress /> : ""}</div>
  //           <h6>Educational Background</h6>
  //           <div className="ind-inp">{isPrevSch ? <PrevSch /> : ""}</div>
  //           <div className="ind-inp">{isLast ? <LastAttend /> : ""}</div>

  //           <h6>Parent's Information</h6>

  //           <div className="ind-inp">
  //             <input type="text" placeholder="Parent Name" />
  //           </div>
  //           <div className="ind-inp">
  //             <input type="text" placeholder="Parent Contact" />
  //           </div>

  //           <div className="ind-inp">{isparentName ? <ParentName /> : ""}</div>

  //           <div className="ind-inp">
  //             {isParentEmail ? <ParentEmail /> : ""}
  //           </div>
  //           <div className="ind-inp">
  //             {isParentContact ? <ParentContact /> : ""}
  //           </div>
  //         </div>

  //         <div>
  //           <div>
  //             <h6>Other Information</h6>

  //             <div className="ind-inp">{isPlace ? <PlaceOfBirth /> : ""}</div>
  //             <div className="ind-inp">
  //               <input type="text" placeholder="Mother Tonque" />
  //             </div>
  //             <div className="ind-inp">
  //               <input type="text" placeholder="Caste" />
  //             </div>
  //             <div className="ind-inp">
  //               <input type="text" placeholder="Religion" />
  //             </div>
  //             <div className="ind-inp">
  //               <input type="text" placeholder="Nationality" />
  //             </div>
  //             <div className="ind-inp">
  //               {isAcademic ? <AcademicYear /> : ""}
  //             </div>
  //             <div className="ind-inp">{isTempAdd ? <TempAddress /> : ""}</div>

  //             <div className="ind-inp">
  //               {isReligion ? <StudentReligion /> : ""}
  //             </div>
  //             <div className="ind-inp">{isCaste ? <StudentCaste /> : ""}</div>

  //             <div className="ind-inp">{isClass ? <StudenClass /> : ""}</div>
  //             <div className="ind-inp">{isAdhar ? <AdharNumber /> : ""}</div>
  //             <div className="ind-inp">{isBlood ? <BloodGroup /> : ""}</div>
  //             <div className="ind-inp">
  //               {isRelative ? <RelativeInSchool /> : ""}
  //             </div>

  //             <div>
  //               <h6>Documents Upload</h6>
  //               <div className="ind-inp">
  //                 {isParentPhotos ? <ParentPhotos /> : ""}
  //               </div>
  //             </div>
  //             <div>
  //               <h6>Child Date of Birth Certificate</h6>
  //               <div className="ind-inp">{isChildDOB ? <ChildDOB /> : ""}</div>
  //             </div>
  //           </div>
  //         </div>
  //         <div>
  //           <button
  //             className="save-btn1"
  //             style={{ border: "10px solid black" }}
  //             onClick={applicationHandler}
  //           >
  //             Save11
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const onUpdateFieldChecked = (e, fieldName) => {
    let isChecked = e.target.checked;
    console.log(TAG, "onUpdateFieldChecked() isChecked " + isChecked);
    console.log(TAG, "onUpdateFieldChecked() fieldName " + fieldName);

    // update the object
    let newFormObject = JSON.parse(JSON.stringify(formObject)); // full copy

    newFormObject.fields.map((field, index) => {
      console.log(TAG, "onUpdateFieldChecked() field name " + field.field_name);
      if (field.field_name == fieldName) {
        console.log(TAG, "onUpdateFieldChecked(): match found!");

        field.isSelected = isChecked;
      }
    });

    setFormObject(newFormObject);

    console.log(
      TAG,
      "onUpdateFieldChecked(): setstate completed " +
      JSON.stringify(newFormObject)
    );
  };

  const LEFT_BAR2 = (props) => {
    //const { formObject } = props;
    console.log(TAG, "LEFTBAR is rendered again..");
    return (
      <>
        <div
          className="left-section"
          style={{ width: "400px", height: "100vh", position: "fixed" }}
        >
          {/* ------------------------------Personal detail section------------------------------------ */}
          <div className="sect-title">
            <h5>Select Fields</h5>
            {/* controls */}
            {formObject.fields.map((field, index) => {
              let isChecked = field.isSelected;
              return (
                <div className="formF" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    name="checkbox-name"
                    onChange={(e) => {
                      onUpdateFieldChecked(e, field.field_name);
                    }}
                    checked={isChecked}
                    style={{
                      position: "relative",
                      top: "-5px",
                      marginLeft: "10px",
                    }}
                  />
                  <h6 className="forInp">{field.field_label}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const APPLICATION_FORM2 = (props) => {
    //const { formObject } = props;
    return (
      <>
        <div
          className="right-section"
          style={{
            position: "relative",
            left: "400px",
            width: "800px",
            height: "150vh",
            padding: "50px",
            marginLeft: "50px",
            borderRadius: "10px",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              marginTop: "-20px",
              paddingBottom: "20px",
            }}
          >
            Application Form Preview
          </h3>
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <div className="ind-inp">
                <h5>Admission for</h5>
              </div>
              {formObject.fields.map((obj, index) => {
                // let formObj = null;
                console.log(
                  TAG,
                  "APPLICATION_FORM2 obj field_type " +
                  JSON.stringify(obj.field_type)
                );

                if (obj.isSelected) {
                  switch (obj.field_type) {
                    case "text":
                      console.log(TAG, "APPLICATION_FORM2 rendering text");
                      return (
                        <FORM_OBJECT_INPUT placeholder={obj.field_label} />
                      );

                    case "textarea":
                      console.log(TAG, "APPLICATION_FORM2 rendering textarea");
                      return (
                        <FORM_OBJECT_TEXTAREA placeholder={obj.field_label} />
                      );
                    case "email":
                      console.log(TAG, "APPLICATION_FORM2 rendering email");
                      return (
                        <FORM_OBJECT_EMAIL placeholder={obj.field_label} />
                      );
                  }
                } else {
                  return <></>;
                }
              })}
            </div>
            <div>
              <button className="save-btn1" onClick={applicationHandler}>
                Create Form
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const studentName = (event) => {
    setIsChecked(event.target.checked);
  };
  const stdImage = (e) => {
    setIsPicture(e.target.checked);
  };
  const prevSchool = (e) => {
    setIsPrevSch(e.target.checked);
  };
  const emailHandler = (event) => {
    setIsEmail(event.target.checked);
  };
  const contactHandler = (event) => {
    setIsContact(event.target.checked);
  };

  const birthHandler = (event) => {
    setIsBirth(event.target.checked);
  };
  const genderHandler = (event) => {
    setIsGender(event.target.checked);
  };
  const placeHandler = (event) => {
    setIsPlace(event.target.checked);
  };
  const parentNameHandler = (event) => {
    setIsParenName(event.target.checked);
  };
  const motherTong = (event) => {
    setIsMotherTonq(event.target.checked);
  };
  const parentEmail = (event) => {
    setIsParenEmail(event.target.checked);
  };
  const parentContact = (event) => {
    setIsParentContact(event.target.checked);
  };
  const tempAddress = (event) => {
    setIsTempAdd(event.target.checked);
  };
  const permAdd = (event) => {
    setIsPermAdd(event.target.checked);
  };
  const religion = (event) => {
    setIsReligion(event.target.checked);
  };
  const caste = (event) => {
    setIsCaste(event.target.checked);
  };
  const national = (event) => {
    setIsNational(event.target.checked);
  };
  const studentClass = (event) => {
    setIsClass(event.target.checked);
  };
  const adhaar = (event) => {
    setIsAdhar(event.target.checked);
  };
  const bloodGroup = (event) => {
    setIsBlood(event.target.checked);
  };
  const relative = (event) => {
    setIsRelative(event.target.checked);
  };
  const lastAttend = (event) => {
    setIsLast(event.target.checked);
  };
  const acdemicYear = (e) => {
    setIsAcademic(e.target.checked);
  };
  const stdCity = (e) => {
    setIsCity(e.target.checked);
  };
  const parentPhotoUploads = (e) => {
    setIsParentPhotos(e.target.checked);
  };
  const childBirthC = (e) => {
    setIsChildDOB(e.target.checked);
  };

  return (
    <>
      <Sidebar />
      <div
        className="full-section"
        style={{ width: "75%", height: "90vh", borderRadius: "10px" }}
      >
        <LEFT_BAR2 />
        <APPLICATION_FORM2 />
        {/* <button style={{display:'flex', justifyContent:'center'}}>Submit</button> */}
        {/* <APPLICATION_FORM /> */}
      </div>
    </>
  );
};
export default Checkbox;
