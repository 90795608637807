import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Constants from '../resource/Constants';

const url = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"


const LeadEnquiry = () => {
    const [parent_name, setParent_Name] = useState([]);
    const [student_name, setStudent_Name] = useState([]);
    const [student_Gender, setStudent_Gender] = useState([]);
    const [student_className_applied, setStudent_ClassName_Applied] = useState([]);
    const [parent_contact, setParent_Contact] = useState([]);
    const [parent_email, setParent_Email] = useState([]);
    const [parent_other_desc, setParent_Other_Desc] = useState([]);
    const [medium, setMedium] = useState([]);
    const [institute_id, setInstitute_Id] = useState([]);
    const [councellor_id, setCouncellor_Id] = useState([]);
    const [is_parent, setIs_Parent] = useState([]);
    const [councellor_exists, setCouncellor_exists] = useState([])

    const enquiryHandler = (e) => {
        e.preventDefault();
        let data = ({
            parent_name,
            student_name, "student_Gender": "male", student_className_applied,
            parent_contact, parent_email,
            parent_other_desc, medium, institute_id, councellor_id, "is_parent": 1, "councellor_exists": "yes"
        });
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {
                console.log("resp", resp)
            })
        })
    }


    return (
        <div>
            <Sidebar />
            <div className="row enquiry">

                <div className="row">
                    <div className="col-xl-6">
                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Name of the Parent</label>
                            <div className="col-lg-9">
                                <input type="text" className="form-control" name='parent_name' value={parent_name}
                                    onChange={(e) => setParent_Name(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Student Name</label>
                            <div className="col-lg-9">
                                <input type="text" className="form-control" name='student_name' value={student_name}
                                    onChange={(e) => setStudent_Name(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Gender</label>
                            <div className="col-lg-9">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked />
                                    <label className="form-check-label" for="gender_male">
                                        Male
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                    <label className="form-check-label" for="gender_female">
                                        Female
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-xl-6">
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">ClassName for Admission</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='student_className_applied' value={student_className_applied}
                                onChange={(e) => setStudent_ClassName_Applied(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Parent Contact Number</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='parent_contact' value={parent_contact}
                                onChange={(e) => setParent_Contact(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Parent Contact Email</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='parent_email' value={parent_email}
                                onChange={(e) => setParent_Email(e.target.value)} />
                        </div>
                    </div>
                </div>
                {/* ---------------------------------------------------------------- */}
                <div className="col-xl-6 moveUp">
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Description</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='parent_other_desc' value={parent_other_desc}
                                onChange={(e) => setParent_Other_Desc(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Medium</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='medium' value={medium}
                                onChange={(e) => setMedium(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Institute Id</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='institute_id' value={institute_id}
                                onChange={(e) => setInstitute_Id(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Counsellor Id</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control" name='councellor_id' value={councellor_id}
                                onChange={(e) => setCouncellor_Id(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Parent</label>
                        <div className="col-lg-9">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked />
                                <label className="form-check-label" for="gender_male">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                <label className="form-check-label" for="gender_female">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Counsellor Exists</label>
                        <div className="col-lg-9">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked />
                                <label className="form-check-label" for="gender_male">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                <label className="form-check-label" for="gender_female">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="text-end">
                    <button type="submit" className="btn btn-primary"
                        onClick={enquiryHandler}
                    >Submit</button>
                </div>

            </div>
        </div>
    )
}

export default LeadEnquiry