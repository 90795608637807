import React, { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts'

const TAG = 'AreaAdmin.jsx';
// Overview stats

const AreaAdmin = (props) => {

    const { stats } = props;
    const [likeChart, setLikeChart] = useState(stats)

    useEffect(() => {

        console.log(TAG, 'useEffect() called..');
        // console.log(TAG, 'useEffect() again with stats ', JSON.stringify(stats));

//        _setupStats();
        return () => {

        }
    }, [])

    console.log(TAG, 'rendered again with stats ', JSON.stringify(stats));

    return (
        <div>
            <ReactApexChart
                options={stats.options}
                series={stats.series}
                type="area" height={350} />
        </div>


    )
}

export default AreaAdmin;