import React from 'react'

const AlertPage = () => {
  return (

        <div class="card" style={{textAlign:'center', position:'absolute', left:'40%', top:'20%', width: "400px", padding:'30px'}}>
        <div style={{borderRadius:'200px', height:'200px', width:'200px', background: '#F8FAF5', margin:'0 auto'}}>
          <i class="checkmark"
          style={{ left:'120px', top:'0px',color:'green', position:'absolute', fontSize:'10rem'}}
          >✓</i>
        </div>
          <h1>Success</h1> 
          <p>You have successfully registered<br/>Please check you email and click on link to activate</p>
        </div>
  

  )
}

export default AlertPage