var config = {
    apiKey: "AIzaSyBTCkmhlLRvtmztSh9yIl_rgfXvieYg3eY",
    authDomain: "kgtopg-profiles.firebaseapp.com",
    databaseURL: "https://kgtopg-profiles.firebaseio.com",
    projectId: "kgtopg-profiles",
    storageBucket: "kgtopg-profiles.appspot.com",
    messagingSenderId: "218008319062",
    appId: "1:218008319062:web:5840b4edc2816a704337f7",
    measurementId: "G-5408JGKGHN"
};

export default config;
