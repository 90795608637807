import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import CounsDetailForm from './CounsDetailForm';
import Constants from '../resource/Constants';

const TAG = 'CounsellorForm.jsx';
const ACTIVATEAPI = Constants.Application.APPLIER_END_URL + "/counsellor-profile-updation/"

const CounsellorForm = () => {
  const [complete, setComplete] = useState(false);
  const search = useLocation().search
  const query = new URLSearchParams(useLocation().search);
  const eml = new URLSearchParams(search).get('email');

  const _doConfirm = async () => {
    // call the activation API
    const response = await axios.put(ACTIVATEAPI, {
      "lead_email": eml
    });
    console.log('SuccessPage.jsx _doConfirm(): response ' + JSON.stringify(response));
    try {

      setComplete(true)
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (eml)
      _doConfirm()
    else {
      console.log(TAG, 'Counsellor came here to update his profile details after login')
      setComplete(true)
    }
  }, [])



  if (!complete) {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  }

  return (
    <div>
      <CounsDetailForm />
    </div>
  )
}

export default CounsellorForm