import React, { useState, useEffect } from "react";
import Sidebar from './Sidebar'
import { MdOutlineDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import DateTimePicker from "./components/DateTime";
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import INSTITUTEAPI from '../http/institute';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);
const TAG = 'ExamScheduler.jsx';

const SCHEDULEROW = (props) => {
    const { schedule, idx } = props;

    let mDt = moment(schedule.schedule_time)
    let mDtStr = mDt.utc().format('DD-MM-YYYY hh:mm a');

    let clsStd = schedule.classroom_std;
    switch (clsStd) {
        case 13:
            clsStd = 'L.K.G';
            break;
        case 14:
            clsStd = 'U.K.G';
            break;
        case 15:
            clsStd = 'Nursery';
            break;
    }

    if (idx > 0)
        clsStd = '-';

    return (
        <tr>
            <td>{clsStd}{/*<sup>th</sup>*/}</td>
            {/* <td>
                <h2 className="table-avatar">
                    40

                </h2>
            </td> */}
            <td>{mDtStr}</td>
            {/* <td>10:00 AM</td> */}


        </tr>
    )
}

const SCHEDULES = (props) => {
    const { schedules } = props;

    return (
        <div className="table-responsive" >
            <table className="table border-0 star-student table-center mb-0 datatable" style={{ border: "1px solid black" }}>
                <thead className="student-thread">
                    <tr>
                        <th>Grade <BiSort /></th>
                        {/* <th>Participants<BiSort /></th> */}
                        <th>Schedule Date </th>
                        {/* <th> Time</th> */}

                        {/* <th className="text-end">Edit</th> */}
                    </tr>
                </thead>
                <tbody>

                    {schedules.map((schedule, idx) => {
                        return (<SCHEDULEROW
                            key={idx}
                            idx={idx}
                            schedule={schedule}
                        />
                        )
                    })}

                    {/*                     
                    <tr>
                        <td>10<sup>th</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                40

                            </h2>
                        </td>
                        <td>2 Feb 2002</td>
                        <td>10:00 AM</td>


                    </tr>
                    <tr>

                        <td>8<sup>th</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                50

                            </h2>
                        </td>
                        <td>2 Feb 2002</td>
                        <td>10:30 AM</td>


                    </tr>
                    <tr>

                        <td>10<sup>th</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                60
                            </h2>
                        </td>
                        <td>2 Feb 2002</td>
                        <td>10:00 AM</td>
                    </tr>
                    <tr>
                        <td>5<sup>th</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                40
                            </h2>
                        </td>
                        <td>4 Feb 2002</td>
                        <td>01:00 PM</td>
                    </tr>
                    <tr>
                        <td>7<sup>th</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                30

                            </h2>
                        </td>
                        <td>3 Feb 2002</td>
                        <td>10:00 AM</td>

                    </tr>

                    <tr>

                        <td>2<sup>nd</sup></td>
                        <td>
                            <h2 className="table-avatar">
                                40

                            </h2>
                        </td>
                        <td>5 Feb 2002</td>
                        <td>10:30 AM</td>

                    </tr>
                     */}

                </tbody>

            </table>
        </div>
    )
}

const SCHEDULELIST = (props) => {
    const { schedulesObj } = props;

    let schHTML = '';
    let keys = null;

    if (typeof schedulesObj !== undefined && schedulesObj != null) {
        keys = Object.keys(schedulesObj);
    }

    // iterate each key in object
    // for (const [key, classSchedules] of Object.entries(schedulesObj)) {
    //     return (
    //         <></>
    //     )
    // }

    if (keys) {
        return keys.map((key, idx) => {
            const schedules = schedulesObj[key];
            return (
                <SCHEDULES
                    schedules={schedules}
                />
            )
        })

    }

    return (
        <></>
    )

}

const ExamScheduler = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [classStd, setClassStd] = useState(-1);
    const [schedules, setSchedules] = useState(null);

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setModalOpen(false);
    };

    const _handleClassChange = (event) => {
        const { value } = event.target;
        setClassStd(parseInt(value));

    };

    const _handleDateChange = (event) => {
        const { valueAsDate, value } = event.target;
        let newDate = moment(new Date(value)).format('YYYY-MM-DD HH:MM:SS');
        newDate = newDate + '.000000';
        setSelectedDate(newDate);

        console.log(TAG, '_handleDateChange() value ' + value);
        console.log(TAG, '_handleDateChange() valueAsDate ' + valueAsDate);
        console.log(TAG, '_handleDateChange() newDate ' + newDate);

    };

    const _handleSaveExamSchedule = async () => {
        let instId = localStorage.getItem("applier.inst.id");
        instId = parseInt(instId);

        let stdList = [classStd];
        let timeList = [[selectedDate]];

        const body = {
            school_id: instId,
            cls_stds_list: stdList,
            schedular_time_list: timeList
        }

        console.log(TAG, '_handleSaveExamSchedule body ' + JSON.stringify(body));

        setModalOpen(false); // close modal
        MySwal.fire({
            title: 'Saving..',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const resp = await INSTITUTEAPI.postSaveExamSchedule(body);
        console.log(TAG, '_handleSaveExamSchedule resp ' + JSON.stringify(resp));

        MySwal.fire('Exam schedule saved successfully..', '', 'info');

        // reload the page
        _getExamSchedules();
    }

    const _getExamSchedules = async () => {
        const resp = await INSTITUTEAPI.getExamSchedules();

        let data = null;
        if (resp && resp.data) {
            data = resp.data;
        }

        console.log(TAG, '_getExamSchedules() data ' + JSON.stringify(data));
        setSchedules(data);
    }

    useEffect(() => {
        _getExamSchedules();
    }, [])

    return (
        <div>
            <Sidebar />
            <div className="page-wrapper">
                <div className="row w-80" style={{ position: 'relative', top: '50px', left: '5%', width: '90%' }}>
                    <div className="col-sm-12">
                        <div className="card card-table comman-shadow">
                            <div className="card-body">
                                <div className="page-header">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <div className="col" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <h3 className="page-title">EXAM SCHEDULE</h3>

                                                </div>

                                            </div>
                                            <div className="col-auto text-end float-end ms-auto download-grp">
                                                <div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="search-student-btn">

                                                <Button variant="contained" startIcon={<AddIcon />}
                                                    onClick={handleClick}
                                                >
                                                    Schedule Exam
                                                </Button>
                                                {/* open Modal */}
                                                {modalOpen && (
                                                    <div
                                                        onClick={handleClose}
                                                        style={{
                                                            position: "fixed",
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            zIndex: 1,
                                                        }}
                                                    >
                                                        <div
                                                            onClick={(e) => e.stopPropagation()}
                                                            style={{
                                                                background: "white",
                                                                padding: "20px",
                                                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                                                            }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <h4 style={{ textAlign: 'center', width: '100%' }}>Exam Scheduler</h4>
                                                            </div>
                                                            <div className="exam-modal">
                                                                {/* Select Class: */}

                                                                {/* <select>
                                                                    <option>1 <sup>st</sup></option>
                                                                    <option>2 <sup>nd</sup></option>
                                                                    <option>3 <sup>rd</sup></option>
                                                                    <option>4 <sup>th</sup></option>
                                                                    <option>5 <sup>th</sup></option>
                                                                    <option>6 <sup>th</sup></option>
                                                                    <option>7 <sup>th</sup></option>
                                                                    <option>8 <sup>th</sup></option>L
                                                                    <option>9 <sup>th</sup></option>
                                                                    <option>10 <sup>th</sup></option>
                                                                    <option>11 <sup>th</sup></option>
                                                                    <option>12 <sup>th</sup></option>

                                                                </select> */}
                                                                <Form.Select aria-label="Default select example"
                                                                    onChange={_handleClassChange}>
                                                                    <option>Select Class:</option>
                                                                    <option value="1">1 <sup>st</sup></option>
                                                                    <option value="2">2 <sup>nd</sup></option>
                                                                    <option value="3">3 <sup>rd</sup></option>
                                                                    <option value="4">4 <sup>th</sup></option>
                                                                    <option value="5">5 <sup>th</sup></option>
                                                                    <option value="6">6 <sup>th</sup></option>
                                                                    <option value="7">7 <sup>th</sup></option>
                                                                    <option value="8">8 <sup>th</sup></option>
                                                                    <option value="9">9 <sup>th</sup></option>
                                                                    <option value="10">10 <sup>th</sup></option>
                                                                    <option value="11">11 <sup>th</sup></option>
                                                                    <option value="12">12 <sup>th</sup></option>
                                                                    <option value="13">L.K.G</option>
                                                                    <option value="14">U.K.G</option>
                                                                    <option value="15">Nursery</option>
                                                                </Form.Select>

                                                                <div className="datePick">
                                                                    {/* <h5>Select Date and Time </h5> */}
                                                                    <Form.Label htmlFor="inputDate">Select Date and Time</Form.Label>
                                                                    <Form.Control
                                                                        type="datetime-local"
                                                                        id="inputDate"
                                                                        aria-describedby=""
                                                                        onChange={_handleDateChange}

                                                                    />
                                                                    {/* 
                                                                    <div>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="dateicon"
                                                                        />
                                                                    </div> */}


                                                                </div>


                                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div>
                                                                        <Button variant="contained" color="success">
                                                                            Submit
                                                                        </Button>
                                                                    </div>

                                                                </div> */}


                                                            </div>
                                                            <div>
                                                                <Button onClick={handleClose}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="contained" color="success"
                                                                    onClick={_handleSaveExamSchedule}>
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* end open Modal */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <SCHEDULELIST
                                    schedulesObj={schedules}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamScheduler