import React from 'react'
import TextInput from './TextInput'

const InputProps = () => {
  return (
    <div>
        <input type='text' />
        <br /><br />
        <TextInput />
    </div>
  )
}

export default InputProps