import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SidebarCouns from './slots/SidebarCouns'
import Sidebar from './Sidebar';
import { BiPlusMedical } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Constants from '../resource/Constants';

const TAG = "SchoolCounsDashB.jsx";
const URL = Constants.Application.APPLIER_END_URL + "/school-counsellors/";

const COUNSELLORROW = (props) => {
  const { cons } = props;

  let consName = cons.councellor_name;
  if (!consName) {
    consName = 'Deactivated';
  }

  let dehighlight = {};
  if (cons.councellor_status == 0) {
    dehighlight = { color: '#CCCCCC' }
  }

  let imgSrc2 = "assets/img/profiles/avatar-04.jpg";
  let imgSrc = "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-26.jpg";

  return (
    <>
      <tr style={dehighlight}>
        <td>{cons.councellor_id}</td>
        <td>
          <h2 className="table-avatar">
            <Link style={dehighlight} to={`/counsellor-leads?id=${cons.councellor_id}`}><img className="avatar-img rounded-circle" src={imgSrc} alt="User Image" height={50} />&nbsp; {consName}</Link>
            {/* <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={imgSrc} alt="User Image" /></a> */}
            {/* <a href="student-details.html" style={dehighlight}>{consName}</a> */}
          </h2>
        </td>
        <td>{cons.councellor_email}</td>

        <td>{cons.councellor_phone}</td>
      </tr>
    </>
  )
}

const COUNSELLORTBL = (props) => {
  const { schCounsellors } = props;
  return (
    <table className="table border-0 star-student table-center mb-0 datatable " style={{ border: "1px solid black" }}>
      <thead className="student-thread">
        <tr>

          <th> Id <BiSort /> </th>
          <th > Name <BiSort /></th>
          <th> Email <BiSort /></th>
          <th> Phone No. <BiSort /></th>
          <th></th>
          <th></th>

        </tr>
      </thead>
      <tbody>
        {schCounsellors ?
          schCounsellors.map((cons, idx) => {
            return (
              <>
                <COUNSELLORROW cons={cons} />
              </>
            )
          })

          :
          <></>
        }

        {/*
        <tr>
          <td>PRE2209</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="User Image" /></a>
              <a href="student-details.html">Madhav</a>
            </h2>
          </td>
          <td>madhav@gmail.com</td>

          <td>097 3584 5870</td>
        </tr>
        <tr>

          <td>PRE2213</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
              <a href="student-details.html">Praveen</a>
            </h2>
          </td>
          <td>praveen@gmail.com</td>
          <td>242 362 3100</td>
        </tr>
        <tr>

          <td>PRE2214</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-05.jpg" alt="User Image" /></a>
              <a href="student-details.html">Vinayak</a>
            </h2>
          </td>
          <td>vinayak@gmail.com</td>
          <td>263 254 2332</td>
        </tr>
        <tr>

          <td>PRE2215</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-06.jpg" alt="User Image" /></a>
              <a href="student-details.html">Maheswari</a>
            </h2>
          </td>
          <td>maheswari@gmail.com</td>
          <td>229 223 6248</td>
        </tr>
        <tr>

          <td>PRE2216</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-07.jpg" alt="User Image" /></a>
              <a href="student-details.html">Lalitha</a>
            </h2>
          </td>
          <td>lalitha@gmail.com</td>
          <td>242 536 2289</td>
        </tr>
        <tr>

          <td>PRE2217</td>
          <td>
            <h2 className="table-avatar">
              <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-08.jpg" alt="User Image" /></a>
              <a href="student-details.html">Padma</a>
            </h2>
          </td>
          <td>padma@gmail.com</td>
          <td>289 221 2684</td>
        </tr>
        */}
      </tbody>
    </table>
  )
}

const COUNSELLORLIST = (props) => {
  return (

    <div className="table-responsive" >
      <COUNSELLORTBL schCounsellors={props.schCounsellors} />
    </div>

  )
}

const SEARCHBAR = (props) => {
  const { schCounsellors, oSchCounsellors } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oSchCounsellors && oSchCounsellors.length) {
      oSchCounsellors.map(itm => {

        console.log(TAG, 'handleSearchById(): itm ' + itm);
        if (itm.councellor_id.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setSchCounsellors(filtered)
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oSchCounsellors && oSchCounsellors.length) {
      oSchCounsellors.map(itm => {

        if (itm.councellor_name.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setSchCounsellors(filtered)
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oSchCounsellors && oSchCounsellors.length) {
      oSchCounsellors.map(itm => {

        if (itm.councellor_phone.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setSchCounsellors(filtered)
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div className="w-25b">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div>
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const SchoolCounsDashB = () => {

  const [schCounsellors, setSchCounsellors] = useState([]);
  const [oSchCounsellors, setOSchCounsellors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const loadEnquiries = async () => {
    let token = localStorage.getItem('applier.inst.token');
    const config = {
      headers: { Authorization: `${token}` }
    };

    const response = await axios.get(URL, config)
    console.log(TAG, "response " + JSON.stringify(response));
    const data = (response.data && response.data.data) ? response.data.data : null;
    setSchCounsellors(data)
    setOSchCounsellors(data) // filter from here and re-set the above var
  }

  useEffect(() => {
    loadEnquiries();
  }, [])

  console.log(schCounsellors);


  const TBL_BODY = (props) => {
    const { schCounsellors } = props
    return (
      (schCounsellors && schCounsellors.length) ?
        schCounsellors?.map((item) => {
          const { councellor_id, councellor_name, councellor_email, councellor_phone } = item

          let dehighlight = {};
          if (item.councellor_status == 0) {
            dehighlight = { color: '#CCCCCC' }
          }

          return (
            // <tbody>
            <div className='couns-det'>
              <tr style={dehighlight}>
                <td>
                  {councellor_id}
                </td>
                <td>
                  {councellor_name}
                </td>
                <td>{councellor_email}</td>
                <td>{councellor_phone}</td>

                {/* <td>--</td>

                <td className="text-end">
                  <div className="actions ">
                    <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
                      <i className="feather-eye"></i>
                    </a>
                    <a href="edit-student.html" className="btn btn-sm bg-danger-light">
                      <i className="feather-edit"></i>
                    </a>
                  </div>
                </td> */}
              </tr>
            </div>
            // </tbody>
          )
        })

        :
        <>
          <div>No Counsellors!</div>
        </>
    )
  }

  const TBL = (props) => {
    // const { schCounsellors } = props;

    return (
      (schCounsellors && schCounsellors.length) ?
        <>
          <table className="couns-table ">
            <div className="couns-sub">
              <thead className="couns-head">
                <tr>

                  <th>Counsellor Id</th>
                  <th>Counsellor Name</th>
                  <th>Counsellor Email</th>
                  <th>Counsellor Phone No.</th>
                  {/* <th></th>
                  <th></th> */}

                  <th className="text-end">Action</th>
                </tr>
              </thead>
              <TBL_BODY schCounsellors={schCounsellors} />
            </div>
          </table>
        </>
        :
        <><MAIN /></>
    )
  }

  const MAIN = (props) => {
    return (
      <>
        <SidebarCouns />
        {/* <div className="page-wrapper" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
          <h3 style={{ marginLeft: '50px' }} >List of Counsellors</h3>
          <Link to='/counsellor-email'>
            <button
              style={{ marginRight: '50px', backgroundColor: '#0d6efd', padding: '10px', color: 'white', border: '2px solid blue' }}

            > A</button>
          </Link>
        </div> */}

        <div className='enquiry-section w-80' >
          <div className="student-group-form">
            <div className="row">

              <div className="table-responsive" >
                <TBL schCounsellors={props.schCounsellors} />
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      {/* <MAIN schCounsellors={schCounsellors} /> */}
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table comman-shadow">
                <div className="card-body">


                  {/* <div className="page-header">
<div className="row align-items-center">
<div className="col" style={{display:'flex', justifyContent:'space-between'}}>
<h3 className="page-title">Leads</h3>
<div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
Assign Counsellor:
<select>
  <option>one</option>
  <option>two</option>
  <option>three</option>
</select>
</div>
  </div> */}

                  <div className="page-header">
                    <div className="row align-items-center">
                      <div className="col2">
                        <div className="col2" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                          <h3 className="page-title">Counsellors</h3>
                          <SEARCHBAR schCounsellors={schCounsellors}
                            oSchCounsellors={oSchCounsellors}
                            setSchCounsellors={setSchCounsellors}
                          />
                        </div>

                        <div className="col-lg-2">
                          <div className="search-student-btn">
                            {/* <button type="btn" className="btn btn-primary"
                              onClick={handleOpenModal}
                            >Add Counsellor</button> */}
                            {/* Email Modal MUI  */}

                            <div>
                              {/* <Button variant="outlined" onClick={handleClickOpen}>
                                Add Counsellor
                              </Button> */}
                              <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Appoint Counsellor</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Please Enter the email you wish to register as a Counsellor, a link will be sent to his/her email, once clicked on the link
                                    account gets activated as a counsellor
                                  </DialogContentText>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Cancel</Button>
                                  <Button onClick={handleClose}>Send</Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                            {/* end Email Modal MUI  */}

                            {/* ----------------------------------------------------------- */}
                            {/* <div className={`modal-overlay ${showModal ? "active" : ""}`}>
                              <div className={`modal-content ${showModal ? "active" : ""}`}>
                                <span className="close" onClick={handleCloseModal}>
                                  &times;
                                </span>
                                <div className='email-modal'>
                                  <p>Enter Counsellor Email </p>
                                  <div style={{ display: 'flex' }}>
                                    <input type='email' placeholder='Enter counsellor email' />
                                    <button>Send</button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* ----------------------------------------------------------- */}

                          </div>
                        </div>

                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <div>
                        </div>

                        {/* <Link to='/enquiry'  className="btn btn-outline-primary me-2">
Add Lead
</Link> */}


                        {/* openmodal */}



                        {/* endopenmodal */}



                        {/* <a href="EnquiryForm.html" className="btn btn-primary"><i className="fas fa-plus"></i></a> */}
                      </div>
                    </div>
                  </div>

                  <COUNSELLORLIST schCounsellors={schCounsellors} />

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  )


}

export default SchoolCounsDashB;