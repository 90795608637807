import React from 'react'

const TextInput = () => {
  return (
    <div>
        <input type='date' />
    </div>
  )
}

export default TextInput