import React, { useEffect, useState } from "react";
import { BiSort } from "react-icons/bi";
import { Link } from "react-router-dom";
import PageNav from "./CounsellorSidebar";
import SearchIcon from '@mui/icons-material/Search';
import Modal from 'react-bootstrap/Modal';
import INSTITUTEAPI from '../http/institute';
import Alert from 'react-bootstrap/Alert';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Sidebar from "./Sidebar";

const MySwal = withReactContent(Swal);

const stat = 'APPLICATION';
const pageType = 'ADMISSIONS';
const TAG = 'Admissions.jsx';

const SEARCHBAR = (props) => {
  const { applications, oApplications } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.admission_form_id.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_name.toString().includes(value)
          || itm.Student_name.toString().includes(value)
        ) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const NoteModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'NoteModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setChannel(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setNotes(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleClose();
      props.handleSaveNote();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the activity notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const ADMROW = (props) => {

  const { adm, lead } = props;
  const { enquiry_id, admission_form_id } = adm; // extract the current application row
  console.log(TAG, 'PAYMENTROW payment ' + JSON.stringify(adm));

  const onChangeStatus = (e) => {

    const applId = adm.admission_form_id;
    const { name, value } = e.target;

    console.log(TAG, 'PAYMENTROW onChangeStatus(): name ' + name);
    console.log(TAG, 'PAYMENTROW onChangeStatus(): value ' + value);

    props.onChangeStatus(value, adm);
  }

  let stat2 = adm.admission_status;
  let stat3 = adm.admission_status1;
  let sSel = 'selected="selected"';
  let noShow = (stat2 == 'NO_SHOW') ? sSel : '';
  let paid = (stat2 == 'PAID') ? sSel : '';
  let unPaid = (stat2 == 'UNPAID') ? sSel : '';
  let noProof = (adm.payment_verify_url == '');

  let statuses2 = [
    'No Response from Applicant',
    'Not Eligible',
    'Branch Offered',
    'Rejected',
    'Enrolled',
    'Wait List',
    'Admission Done',
    'Admission Withdrawn',
    'Registration Fees Paid',
    'Admission Registration'
  ]

  return (

    <tr>
      <td>{adm.admission_form_id}</td>
      <td>
        <h2 class="table-avatar">
          <a
            href="IndividualStudent.html"
            class="avatar avatar-sm me-2"
          >
            <img
              class="avatar-img rounded-circle"
              src="assets/img/profiles/avatar-02.jpg"
              alt="User Image"
            />
          </a>
          <Link to={`/lead-details?enquiry_id=${adm.enquiry_id}`}>{adm.Student_name}
            <br />

          </Link>
        </h2>
      </td>
      {/* <td style={{ textAlign: 'center' }}>2 <sup>nd</sup> </td> */}
      <td style={{ textAlign: 'left' }}>{adm.student_class_applied}</td>
      <td style={{ width: '20%' }}>
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleNoteShown(); props.handleAddNote(lead)
          }}
        >
          <i className='fa fa-edit fa-1x'></i>
        </a>
      </td>
      <td >
        {/* <select>
          <option>No Show</option>
          <option>Pass</option>
          <option>Fail</option>
        </select> */}
        <div className='w-75'>
          {stat3}

        </div>
      </td>

    </tr>
  )
}

const ADMLIST = (props) => {
  const { adms } = props;


  if (!adms || !adms.length) {
    return (
      <Alert variant="warning">
        <Alert.Heading>No payments yet!</Alert.Heading>
        <p>
          No admissions are shown here as they have not yet been set in the system. Start setting up now!
        </p>
        <hr />
        <p className="mb-0">
          Admissions can be achieved by converting payment status.
        </p>
      </Alert>
    )
  }

  return (
    <>
      <table
        class="table border-0 star-student table-center mb-0 datatable"
        style={{ border: "1px solid black" }}
      >
        <thead class="student-thread">
          <tr>
            <th>
              Application Id
              <BiSort />
            </th>
            <th>
              Name <BiSort />
            </th>

            <th>
              Grade <BiSort />
            </th>
            <th>Add notes <BiSort /></th>
            <th>
              Application Status <BiSort />
            </th>

          </tr>
        </thead>
        <tbody>

          {adms.map((adm, index) => {
            return <ADMROW
              lead={adm}
              adm={adm}
              key={index}
              index={index}
              onChangeStatus={(stat, adm) => props.onChangeStatus(stat, adm)}
              handleAddNote={(lead) => props.handleAddNote(lead)}
              handleNoteShown={() => props.handleNoteShown()}
              currLead={props.currLead}
              setCurrLead={(lead) => props.setCurrLead(lead)}
            />
          })}




        </tbody>
      </table>
    </>
  )
}

const CounsAdmission = () => {

  const [applications, setApplications] = useState(null);
  const [oApplications, setOApplications] = useState(null);
  const [currApp, setCurrApp] = useState(null);
  const [stat2, setStat2] = useState('');
  const [applicationId2, setApplicationId2] = useState(0);
  const [onShow, setOnShow] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const [notes, setNotes] = useState(null)
  const [channel, setChannel] = useState(null)
  const [currLead, setCurrLead] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNoteShown = () => {
    setShowNote(true);
  }

  const handleAddNote = (lead) => {
    setCurrLead(lead);
  }

  const handleNoteClose = () => {
    setShowNote(false);
  }


  const _loadApplications = async () => {
    const data = await INSTITUTEAPI.getApplications(stat, pageType);
    console.log(TAG, '_loadApplications() data: ' + JSON.stringify(data));

    setApplications(data);
    setOApplications(data);
  }

  const _onUpdateApplicationStatus = async (stat, adm) => {

    let oStat = adm.admission_status;
    let oVerifyUrl = adm.payment_verify_url;
    let applicationId = adm.admission_form_id;

    const body = {
      admission_form_id: applicationId,
      admission_status: oStat,
      admission_status1: stat,
      payment_verify_url: oVerifyUrl
    }

    MySwal.fire({
      title: 'Are you sure?',
      text: "Do you want to update the application status to '" + stat + "' ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(TAG, '_onUpdateApplicationStatus() body ' + JSON.stringify(body));

        const resp = await INSTITUTEAPI.postSaveApplicationStatus(body);
        console.log(TAG, '_onUpdateApplicationStatus() resp ' + JSON.stringify(resp));

        MySwal.fire('Success', 'Admission status updated successfully!', 'info');

      }

      // reload the page
      _loadApplications();
    })

  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  const handleSaveNote = async () => {

    console.log(TAG, 'handleSaveNote(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveNote(): notes ' + notes);
    console.log(TAG, 'handleSaveNote(): channel ' + channel);

    const { enquiry_id } = currLead;
    if (notes && enquiry_id > 0 && channel) {
      const body = {
        enquiry_id: enquiry_id,
        page_feature: channel,
        activity_text: notes
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Note for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await INSTITUTEAPI.postSaveActivityNotes(body);

      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleNoteClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleNoteShown();
    }
  }

  useEffect(() => {
    _loadApplications();
  }, [])

  return (
    <div>
      <Sidebar />
      <div class="page-wrapper">
        <div class="content container-fluid">


          <div class="row">
            <div class="col-sm-12">
              <div class="card card-table comman-shadow">
                <div class="card-body">


                  <div class="page-header">
                    <div class="row2 d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="col2">
                        <div
                          class="col2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3 class="page-title">Admissions</h3>
                        </div>
                      </div>
                      <div class="col-auto2 text-end2 float-end2 ms-auto2 download-grp2 w-75">

                        <SEARCHBAR
                          applications={applications}
                          oApplications={oApplications}
                          setApplications={setApplications}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">

                    <ADMLIST
                      adms={applications}
                      onChangeStatus={(stat, adm) => _onUpdateApplicationStatus(stat, adm)}
                      onShowEditForm={(lead) => onShowEditForm(lead)}
                      setOnShow={setOnShow}
                      handleAddNote={(lead) => handleAddNote(lead)}
                      handleSaveNote={(lead) => handleSaveNote(lead)} /* show modal */
                      showNote={showNote}
                      handleClose={() => handleNoteClose()}
                      handleNoteShown={() => handleNoteShown()}
                    />
                    {/*
                    <table
                      class="table border-0 star-student table-center mb-0 datatable"
                      style={{ border: "1px solid black" }}
                    >
                      <thead class="student-thread">
                        <tr>
                          <th>
                            Application Id
                            <BiSort />
                          </th>
                          <th>
                            Name <BiSort />
                          </th>

                          <th>
                            Grade <BiSort />
                          </th>
                          <th>
                            Application Status <BiSort />
                          </th>
                          
                          <th></th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        <tr>
                          <td>4AV35</td>
                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-03.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Ajay Kumar
                                <br />
                                <span >Pending</span>
                              </Link>
                            </h2>
                          </td>
                          <td >9th </td>
                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>

                          <td className="text-end" style={{ width: '10px' }}>
                            <div className="dropdown dropdown-action">
                              
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>
                                <a className="dropdown-item" href=""><i className="far fa-copy me-2"></i>Enter Application</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3BG63</td>

                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-02.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Bharat</Link>
                            </h2>
                          </td>
                          <td>8th</td>

                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>


                        </tr>
                        <tr>
                          <td>AM623S</td>

                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-04.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Mukesh</Link>
                            </h2>
                          </td>
                          <td>10 B</td>

                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>


                        </tr>
                        <tr>
                          <td>JV6321</td>

                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-03.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Krishna</Link>
                            </h2>
                          </td>
                          <td>8 A</td>

                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>

                        </tr>
                        <tr>
                          <td>GF6214F</td>

                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-01.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Vinay</Link>
                            </h2>
                          </td>
                          <td>6 A</td>

                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>

                        </tr>
                        <tr>
                          <td>GF6214F</td>

                          <td>
                            <h2 class="table-avatar">
                              <a
                                href="IndividualStudent.html"
                                class="avatar avatar-sm me-2"
                              >
                                <img
                                  class="avatar-img rounded-circle"
                                  src="assets/img/profiles/avatar-08.jpg"
                                  alt="User Image"
                                />
                              </a>
                              <Link to="/sch-student">Priya</Link>
                            </h2>
                          </td>
                          <td>6 A</td>

                          <td>
                            <select>
                              <option> Enrolled</option>
                              <option>Withdrawn</option>
                              <option>Rejected</option>
                              <option>Waitlist</option>
                            </select>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* NOTE MODAL */}
      <NoteModal
        show={showNote}
        handleClose={() => handleNoteClose()}
        handleNoteShown={() => handleNoteShown()}
        handleSaveNote={(lead) => handleSaveNote(lead)}
        setNotes={(note) => setNotes(note)}
        currLead={currLead}
        setCurrLead={setCurrLead}
        handleChangeNote={(val) => setNotes(val)}
        setChannel={(val) => setChannel(val)}
      />

    </div>
  );
};

export default CounsAdmission;
