import React, { useState, useEffect } from 'react'
import axios from 'axios'
import SchoolCounsDashB from './SchoolCounsDashB';
import Constants from '../resource/Constants';


const COUNSELLORLIST = Constants.Application.APPLIER_END_URL + "/school-counsellors/";

const TAG = "CounsellorNumber.jsx"





const CounsellorNumber = () => {
    const [counsList, setCounsList] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCouns = async () => {
            const token = localStorage.getItem('applier.inst.token');
            const headers = {
                "Authorization": `${token}`
            };
            try {
                const response = await axios.get(COUNSELLORLIST, { headers });
                console.log(TAG, "response" + JSON.stringify(response))
                setCounsList(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchCouns()
    }, [])

    // const listOfCounsellors = counsList.map((counsellor) => {
    //     const { councellor_id, councellor_name, councellor_email, councellor_phone } = counsellor;
    //     <tbody>
    //         <tr>
    //             <td>{councellor_id}</td>
    //             <td>{councellor_name}</td>
    //             <td>{councellor_email}</td>
    //             <td>{councellor_phone}</td>
    //         </tr>
    //     </tbody>
    // })



    console.log('list is here:', counsList)
    return (
        <div>
            {(counsList.length > 0) ? (
                <div>
                    {counsList.map((counsellor) => {
                        const { councellor_id, councellor_name, councellor_email, councellor_phone } = counsellor;
                        <tbody>
                            <tr>
                                <td>{councellor_id}</td>
                                <td>{councellor_name}</td>
                                <td>{councellor_email}</td>
                                <td>{councellor_phone}</td>
                            </tr>
                        </tbody>
                    })}
                </div>
            ) :
                <div>
                    <SchoolCounsDashB />
                </div>
            }
        </div>
    )
}

export default CounsellorNumber