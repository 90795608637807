import React, { useState } from "react";

function ToggleButton() {
  const [left, setLeft] = useState(0);

  const handleClick = () => {
    setLeft(left === 0 ? 200 : 0);
  };

  return (
    <button style={{ position: "relative", left: `${left}px` }} onClick={handleClick}>
      Toggle Button
    </button>
  );
}

export default ToggleButton;
