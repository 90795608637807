import React, { useState } from 'react';

import ReactApexChart from 'react-apexcharts'

const VerticalChartCouns = (props) => {
    const { stats } = props;
    const [likeChart, setLikeChart] = useState(stats)

    return (
        <div>
            <ReactApexChart
                options={stats.options}
                series={stats.series}
                type="bar" height={350} />
        </div>


    )
}

export default VerticalChartCouns;