import React from 'react'

const APPLICATIONURL = ""


const DummyApplications = () => {
    return (
        <div>DummyApplications</div>
    )
}

export default DummyApplications;