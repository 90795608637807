import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { BsChatLeftFill } from 'react-icons/bs';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { BsFillPersonFill } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import Sidebar from './Sidebar';



const SchoolStudent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }



  return (
    <div>
      <Sidebar />
      <div className='page-wrapper'>
        <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>Application Details</h3>
        <div className='std-top' style={{ position: 'relative', top: '-40px' }}>
          <div className="student">
            <div className="std-id">
              IN093456#@4
              <div className="dots">
                <BsThreeDotsVertical />
              </div>
            </div>
            <hr />
            <div className="std-img">
              <img src="assets/img/profiles/avatar-03.jpg" alt="" />
              <div className='std-profile'>
                <h3 style={{
                  color: '#23468c',
                  padding: '5px 0px', fontSize: '1.3rem', width: '200px'
                }}>Ajay Kumar</h3>
                <div className="std-name">
                  <div className="comm-icons">
                    <BsFillTelephoneFill className='icon' style={{ color: '#0d6efd', fontSize: '1rem' }} />
                    <BsChatLeftFill className='icon' style={{ color: '198754', fontSize: '1rem' }} />
                    <BsFillChatDotsFill className='icon' style={{ color: '#f7dc6f', fontSize: '1rem' }} />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    Email: ********@gmail.com
                    Mobile: <BsWhatsapp style={{ color: 'green' }} />  ************
                  </div>
                </div>
              </div>
            </div>
            <div className="follow-up">
              <h6>Follow Up</h6>
              <div className="add-on">
                <div><span style={{ fontWeight: 500 }}> Added On:</span>  02/09/2021 <span style={{ fontSize: '0.8rem', color: 'gray' }}> 2 months ago</span></div>
                <div><span style={{ fontWeight: 500 }}> Last Active :</span>  05/12/2021 <span style={{ fontSize: '0.8rem', color: 'gray' }}> 1 week ago</span></div>

              </div>
            </div>
          </div>
          <div className="std-couns">

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div className="assg-couns">
                <h6 style={{ color: '#23468c' }}>Communication Status</h6>

                <p>Email Sent - 2</p>
                <p>SMS Sent - 0</p>
                <p>Whatsapp Sent - 0</p>
              </div>
              <div className="up-coming">
                <h6 style={{ color: '#23468c' }}>
                  UpComing Followup
                </h6>
                <p>NA</p>
              </div>
            </div>


            <div className="comm-stat" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

              <div className="assg-couns">
                <h6 style={{ color: '#23468c' }}>Telephony Status</h6>

                <p>Inbound Call - 2</p>
                <p>Outbound Call - 0</p>
                <p>Whatsapp Sent - 0</p>
              </div>
              <div className="up-coming">
                <h6 style={{ color: '#23468c' }}>
                  Lead Source
                </h6>
                <p>Direct</p>
              </div>

            </div>
            <div className="tel-stat" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

              <div className="assg-couns">
                <h6 style={{ color: '#23468c' }}>Assigned Status</h6>

                NA
              </div>
              <div className="up-coming">
                <h6 style={{ color: '#23468c' }}>
                  Assigned RA
                </h6>
                <p>NA</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* step-progress */}
      <div className='page-wrapper'>
        <div className="step-progress">
          <div className="container">
            <div className="arrow-wrapper">
              <div className="arrow-steps clearfix"  >
                <div className="step "> <span> Unverified</span> </div>
                <div className="step"> <span>Verified</span> </div>
                <div className="step current"> <span>Application Started</span></div>
                <div className="step"> <span>Payment Approved</span> </div>
                <div className="step"> <span>Payment Submitted</span> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end step-progress */}

      <div className="page-wrapper" style={{
        backgroundColor: 'white',
        width: '1250px', height: '500px', position: 'relative', top: '-100px',
        left: '50px', borderRadius: '10px'
      }}>
        <div className="lead-top">
          <h3 style={{ color: '#23468c' }}>
            <BsFillPersonFill />
            Lead Details</h3>
          <div><BsThreeDotsVertical /></div>
        </div>
        <hr />
        <div>

          <div>
            <table>
              <thead>
                <tr>

                </tr>
              </thead>
              <tbody className='table-body'>
                <tr>
                  <td
                    className={`tab ${activeTab === 0 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(0)}
                  >
                    Timeline
                  </td>
                  <td
                    className={`tab ${activeTab === 1 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Follow Up & Notes
                  </td>
                  <td
                    className={`tab ${activeTab === 2 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(2)}
                  >
                    Communication Logs
                  </td>
                  <td
                    className={`tab ${activeTab === 3 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(3)}
                  >
                    Document Locker
                  </td>
                  <td
                    className={`tab ${activeTab === 4 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(4)}
                  >
                    Tickets
                  </td>
                  <td
                    className={`tab ${activeTab === 5 ? 'selected' : ''}`}
                    onClick={() => handleTabClick(5)}
                  >
                    Call Logs
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="tab-content">
              {activeTab === 0 && <div>
                <p>Form Interested In: <span>Application Form</span></p>
                <p>Candidate's Email Address:<span>ajayk@gmail.com</span></p>
                <p>State: <span>Telangana</span></p>
                <p>City: <span>Hyderabad</span></p>
                <p>Country: <span>India</span></p>
                <p>Course: <span>10<sup>th</sup></span></p>
                <p>Specilization : <span>Computers</span></p>


              </div>}
              {activeTab === 1 && <div>Follow Up & Notes</div>}
              {activeTab === 2 && <div>Communication Logs</div>}
              {activeTab === 3 && <div>Document Locker</div>}
              {activeTab === 4 && <div>Tickets</div>}
              {activeTab === 5 && <div>Call Logs</div>}
            </div>
          </div>


        </div>
      </div>
    </div>

  )
}

export default SchoolStudent;