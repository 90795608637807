import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Constants from '../resource/Constants';
import COUNSELLORAPI from '../http/counsellor';
import LEADAPI from '../http/lead';
import INSTITUTEAPI from '../http/institute';

import firebase from "firebase";
// import firebaseui from 'firebaseui'
import firebaseConfig from '../firebaseConfig';
import { Button } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const MySwal = withReactContent(Swal);

/* can be school or by counsellor */
const TAG = 'EnquiryForm.jsx';

const queryURL = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"

// initialize
try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

} catch (error) {

}

firebase.auth().languageCode = 'en';

const SMSModal2 = (props) => {
  const [smsCode, setSmsCode] = useState("");
  const [show, setShow] = useState("");

  const handleClose = () => {
    setShow(false);
    props.setShowSMSModal(false);
  }
  const handleShow = () => {
    setShow(true);
    props.setShowSMSModal(true);
  }

  const handleSubmit = () => {
    console.log(TAG, 'SMSModal handleSubmit() smsCode ' + smsCode);
    try {
      window.confirmationResult.confirm(smsCode).then((result) => {
        // User signed in successfully.
        const user = result.user;
        // ...
        Swal.fire({
          title: 'Success',
          text: 'Authentication successful!',
          icon: 'success'
        })

        console.log(TAG, 'SMSModal handleSubmit() user ' + JSON.stringify(user))

        // submit form
        props.enquiryHandler();
        handleClose(); // close modal..

        // send him to his page
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        // clear
        setSmsCode("");
        handleClose();
        Swal.fire({
          title: 'Oops!',
          text: 'The code you entered is invalid. Try again!',
          icon: 'error',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }


          handleShow();
        })
        // console.error(TAG, 'SMSModal handleSubmit(): confirm sms error ' + error);
        console.error(TAG, 'SMSModal handleSubmit(): confirm sms error ' + error);
      });

    } catch (error) {
      console.error(TAG, 'SMSModal handleSubmit(): error ' + error);
    }
  }

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Show SMS
      </Button> */}

      <Modal show={props.showSMSModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Label htmlFor="inputPassword5">Please enter SMS received in your phone</Form.Label>
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              value={smsCode}
              onChange={(e) => setSmsCode(e.target.value)}
            />
            <Form.Text id="passwordHelpBlock" muted>
              Enter the SMS OTP in the above text field to login.
            </Form.Text>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Form2 = () => {
  const [parentName, setParentName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentGender, setStudentGender] = useState(null);
  const [classApplied, setClassApplied] = useState('');
  const [contact, setContact] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [desc, setDesc] = useState("ghdfgfg");
  const [medium, setMedium] = useState("english");
  const [instId, setInstID] = useState(17);
  const [consId, setConsId] = useState(0);
  const [isParent, setIsParent] = useState(1);
  const [consExs, setConsExs] = useState("yes");
  const [isSchool, setIsSchool] = useState(false);

  const [smsAuth, setSmsAuth] = useState(true); // indicates otp authorization succeeded..
  const [smsCode, setSmsCode] = useState("");
  const [showRecaptcha, setShowRecaptcha] = useState(true)
  const [showSMSModal, setShowSMSModal] = useState(false);

  const search = useLocation().search;
  const schoolId = new URLSearchParams(search).get('school_id');
  const logo = new URLSearchParams(search).get('logo');
  const { code, source } = useParams();
  console.log(TAG, 'schoolId ' + schoolId);
  console.log(TAG, 'logo ' + logo);

  console.log(TAG, 'code ' + code);
  console.log(TAG, 'source ' + source);

  const navigate = useNavigate();

  const _verifyPhoneNumber = () => {
    // validate
    let proceed = true;
    if (!parentName || !classApplied
      || !studentGender
      || (!contact && !parentEmail)) {

      proceed = false;

      // show success
      MySwal.fire({
        title: 'Error',
        text: 'Please fill in mandatory details and either of parent contact',
        icon: 'error',
        confirmButtonText: 'OK',
        // didOpen: () => {
        //   // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        //   MySwal.showLoading()
        // },
      })
    }

    if (proceed) {
      // pre-pend +91
      let phoneNumber2 = '+91' + contact;
      // const phoneNumber = getPhoneNumberFromUserInput();

      console.log(TAG, '_verifyPhoneNumber() phoneNumber2 ' + phoneNumber2);
      try {
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber2, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            console.log(TAG, '_verifyPhoneNumber() confirmationResult: ' + JSON.stringify(confirmationResult));
            // ...

            // show SMS code modal
            setSmsCode(true);
            setShowSMSModal(true);

            // hide recaptcha
            setShowRecaptcha(false);

          }).catch((error) => {
            // Error; SMS not sent
            // ...

            console.error(TAG, '_verifyPhoneNumber() error: ' + JSON.stringify(error));
            // window.recaptchaVerifier.render().then(function (widgetId) {
            // grecaptcha.reset(widgetId);
            // });
          });
      } catch (error) {
        console.error(TAG, '_verifyPhoneNumber() error ' + error);
      }

    } else {
      // Swal.fire({
      //   title: 'Oops!',
      //   text: 'Please enter a phone number',
      //   icon: 'warning'
      // })
    }
  }

  const enquiryHandler = () => {

    console.log(TAG, 'enquiryHandler(): parentName ' + parentName);
    console.log(TAG, 'enquiryHandler(): parentEmail ' + parentEmail);
    console.log(TAG, 'enquiryHandler(): classApplied ' + classApplied);
    console.log(TAG, 'enquiryHandler(): studentGender ' + studentGender);
    console.log(TAG, 'enquiryHandler(): contact ' + contact);
    console.log(TAG, 'enquiryHandler(): parentEmail ' + parentEmail)
    let proceed = true;
    // validate
    if (!parentName || !classApplied
      || !studentGender
      || (!contact && !parentEmail)) {

      proceed = false;

      // show success
      MySwal.fire({
        title: 'Error',
        text: 'Please fill in mandatory details and either of parent contact',
        icon: 'error',
        confirmButtonText: 'OK',
        // didOpen: () => {
        //   // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        //   MySwal.showLoading()
        // },
      })
    }

    // choose which medium he used to register
    let MEDIUM = 'OTHER';
    switch (source) {
      case 'fb':
        MEDIUM = ''
        break;
      case 'tw':
        break;
      default:
        MEDIUM = 'OTHER';
        break;
    }

    if (proceed) {
      let leadDetail = ({
        parent_name: parentName,
        student_name: studentName,
        student_Gender: studentGender,
        student_class_applied: classApplied,
        parent_contact: contact,
        parent_email: parentEmail,
        parent_other_desc: desc,
        medium: 'OTHER',
        institute_id: instId,
        councellor_id: consId,
        is_parent: isParent,
        councellor_exists: (!isSchool) ? "yes" : "no"
      });
      console.log(leadDetail);


      // show success
      MySwal.fire({
        title: 'Saving',
        text: 'Please wait until your enquiry is submitted..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      axios({
        method: 'post',
        url: queryURL,
        data: leadDetail,

      }).then(function (response) {
        console.log(response);

        // show success
        MySwal.fire({
          title: 'Success',
          text: 'Your enquiry was saved and sent to the school successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
          // didOpen: () => {
          //   // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          //   MySwal.showLoading()
          // },


        })

        // take the user to thank-you page
        let path = "/thank-you";
        navigate(path);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  const _getSource = async () => {
    if (schoolId && parseInt(schoolId) > 0) {
      setIsSchool(true);
      setInstID(schoolId);
    } else {

      // verify who it is by the campaign code
      if (code) {
        console.log(TAG, '_getSource() code is ' + code);
        let resp3 = await LEADAPI._getCodeUser(code);

        console.log(TAG, '_getSource() _getCodeUser resp3 is ' + JSON.stringify(resp3));

        if (resp3) {
          // decide if it is school
          if (resp3.type == 'SCHOOL') {
            setIsSchool(true);
            // const resp = await INSTITUTEAPI.getCodeInstitute(code);

            let instId3 = resp3.user_id;
            setInstID(instId3);
            setConsId(0);
          } else {
            setIsSchool(false);

            // now get the counsellor id
            // const resp = await COUNSELLORAPI.getCodeCounsellor(code);
            // console.log(TAG, '_getSource() resp ' + JSON.stringify(resp));

            let consId3 = resp3.user_id;
            setConsId(consId3);
            setInstID(0);
          }

        }
      }
    }
  }

  const _initializeReCaptcha = () => {
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    } catch (error) {
      console.error(TAG, '_initializeReCaptcha() exception ' + error);
    }
  }

  useEffect(() => {
    _getSource();

    _initializeReCaptcha();
  }, [])

  let dt = new Date();
  let yr = dt.getFullYear();
  let nextYr = yr + 1;
  let nDt = new Date();
  nDt.setFullYear(nextYr);

  return (
    <div className='enq-form' >
      <div className="">
        <div className='w-100 d-flex justify-content-center'>
          <img src="/assets/img/schooler-applier.png" height={64} />
        </div>
        <h6 className="form-tit p-2" style={{ color: '#0d6efd' }}>
          Admission Enquiry Form {yr} - {nextYr}
        </h6>

        <div className="right-form">
          <div >
            <label className="col-lg-3 col-form-label">Student Name*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='studentName' onChange={(e) => setStudentName(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Name of the Parent*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='parentName' onChange={(e) => setParentName(e.target.value)} />
            </div>
          </div>

          <div >
            <label className="col-lg-3 col-form-label">Gender</label>
            <div className="col-lg-9">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="male"
                  onChange={(e) => setStudentGender('male')}
                />
                <label className="form-check-label" for="gender_male">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="female"
                  onChange={(e) => setStudentGender('female')}
                />
                <label className="form-check-label" for="gender_female">
                  Female
                </label>
              </div>
            </div>
          </div>



          <div >
            <label className="col-lg-3 col-form-label">Class for Admission*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='classApplied' onChange={(e) => setClassApplied(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Parent Contact Number</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='contact' onChange={(e) => setContact(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Parent Contact Email</label>
            <div className="col-lg-9">
              <input type="email" className="form-control" name='parentEmail' onChange={(e) => setParentEmail(e.target.value)} />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-4 w-100">
        <button type="button"
          className="btn btn-primary w-75 "
          disabled={!smsAuth}
          onClick={_verifyPhoneNumber}>Submit</button>
      </div>

      {showRecaptcha ?
        <div id="recaptcha-container"></div>
        :
        <></>
      }

      <SMSModal2
        showSMSModal={showSMSModal}
        setShowSMSModal={(show) => setShowSMSModal(show)}
        smsCode={smsCode}
        enquiryHandler={enquiryHandler}
      />

      <hr />

    </div>
  )
}

const EnquiryForm = () => {
  const search = useLocation().search;

  const schoolId = new URLSearchParams(search).get('school_id');
  const logo = new URLSearchParams(search).get('logo');
  console.log(TAG, 'schoolId ' + schoolId);
  console.log(TAG, 'logo ' + logo);


  const SplitScreen = () => {
    return (
      <div className="w-100 d-flex flex-row justify-content-between">
        <div className="w-50 d-flex flex-row justify-content-center align-items-center"
          style={{
            backgroundImage: 'url("/assets/img/enquiry2.gif")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',

          }}>
          <div className="">
            <img src={logo} className="w-100" />
          </div>
        </div>

        <div className="w-50">
          <Form2 />
        </div>
      </div>
    )
  }

  return (
    <div>
      <SplitScreen />

    </div>

  )
}

export default EnquiryForm