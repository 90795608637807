import React, { useState } from 'react';

import ReactApexChart from 'react-apexcharts'

const ResourceBar = (props) => {
    const { campaignLeads } = props;
    const [likeChart, setLikeChart] = useState(campaignLeads);

    return (
        <div>
            <ReactApexChart
                options={campaignLeads.options}
                series={campaignLeads.series}
                type="bar" height={350} />
        </div>


    )
}

export default ResourceBar;