import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import PageNav from "./CounsellorSidebar";
import { BiSort } from "react-icons/bi";
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { BiEdit } from 'react-icons/bi';
import Constants from "../resource/Constants";
import Modal from 'react-bootstrap/Modal';
import COUNSELLORAPI from '../http/counsellor';
import INSTITUTEAPI from '../http/institute';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Sidebar from "./Sidebar";

const MySwal = withReactContent(Swal);

const stat = 'APPLICATION';
const pageType = 'APPLICATION';
const TAG = 'Applications.jsx';

const instId = localStorage.getItem("applier.inst.id"); // 24
const APPLICATIONURL = Constants.Application.APPLIER_END_URL + "/create-school-custom-form/";
const GETFORMURL = Constants.Application.APPLIER_END_URL + "/get-school-admission-form/?school_id=" + instId;

const NoteModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'NoteModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setChannel(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setNotes(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleClose();
      props.handleSaveNote();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the activity notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


const EnquiryModal = (props) => {

  const { onShow, currLead } = props;

  let parentName2 = (currLead) ? currLead.parent_name : '';
  let studentName2 = (currLead) ? currLead.Student_name : '';
  let studentGender2 = (currLead) ? currLead.student_Gender : 'male';
  let classApplied2 = (currLead) ? currLead.student_class_applied : '';
  let contact2 = (currLead) ? currLead.parent_contact : '';
  let parentEmail2 = (currLead) ? currLead.parent_email : '';
  let isParent2 = (currLead) ? ((currLead.is_parent == 1) ? '1' : '0') : '1';

  const formDetails = (currLead) ? currLead.admission_obj : FORM_OBJECT;
  console.log(TAG, 'EnquiryModal currLead: ' + JSON.stringify(currLead));
  console.log(TAG, 'EnquiryModal formDetails: ' + JSON.stringify(formDetails));

  const [parentName, setParentName] = useState(parentName2);
  const [studentName, setStudentName] = useState(studentName2);
  const [studentGender, setStudentGender] = useState(studentGender2);
  const [classApplied, setClassApplied] = useState(classApplied2);
  const [contact, setContact] = useState(contact2);
  const [parentEmail, setParentEmail] = useState(parentEmail2);
  const [desc, setDesc] = useState("ghdfgfg");
  const [medium, setMedium] = useState("english");
  const [instId, setInstId] = useState(0);
  const [consId, setConsId] = useState(0);
  const [isParent, setIsParent] = useState(isParent2);
  const [consExs, setConsExs] = useState('no');
  const [actvLead, setActvLead] = useState(null)

  const [show, setShow] = useState(false);
  const [formObject, setFormObject] = useState(formDetails);

  const applicationHandler = async () => {
    let body = formObject;

    let token = localStorage.getItem("applier.couns.token");

    const headers = {
      headers: { Authorization: `${token}` },
    };


    MySwal.fire({
      title: 'Please wait..',
      text: 'Saving the form setup..',
      icon: 'info',
      confirmButtonText: 'OK',
      didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    }).then(() => {
      // do something..
    })

    console.log(TAG, "applicationHandler() headers " + JSON.stringify(headers));
    const response = await axios.post(APPLICATIONURL, body, headers);
    console.log(TAG, "applicationHandler() response " + JSON.stringify(response));


    MySwal.fire({
      title: 'Success!',
      text: 'Your form was saved successfully..',
      icon: 'success',
      confirmButtonText: 'OK'
    })

  };

  const formSetupHandler1 = async () => {
    let token = localStorage.getItem("applier.couns.token");
    const config = {
      headers: { Authorization: `${token}` },
    };

    const response = await axios.get(GETFORMURL, config);
    console.log(TAG, "formSetupHandler() response " + JSON.stringify(response));

    const data3 = (response.data && response.data.data) ? response.data.data.field_obj : null;
    if (data3) {

      setFormObject(data3);

      // update object with pre-filled data
    }

  };

  const formSetupHandler = async () => {
    console.log(TAG, 'formSetupHandler() called')
    const formDetails2 = (currLead) ? currLead.admission_obj : null;

    console.log(TAG, 'formSetupHandler() currLead: ' + JSON.stringify(currLead));
    console.log(TAG, 'formSetupHandler() formDetails: ' + JSON.stringify(formDetails2));
    if (formDetails2)
      setFormObject(formDetails2);
  }

  const onUpdateFieldChecked = (e, fieldName) => {
    let isChecked = e.target.checked;
    console.log(TAG, "onUpdateFieldChecked() isChecked " + isChecked);
    console.log(TAG, "onUpdateFieldChecked() fieldName " + fieldName);

    // update the object
    let newFormObject = JSON.parse(JSON.stringify(formObject)); // full copy

    newFormObject.fields.map((field, index) => {
      console.log(TAG, "onUpdateFieldChecked() field name " + field.field_name);
      if (field.field_name == fieldName) {
        console.log(TAG, "onUpdateFieldChecked(): match found!");

        field.isSelected = isChecked;
      }
    });

    setFormObject(newFormObject);

    console.log(
      TAG,
      "onUpdateFieldChecked(): setstate completed " +
      JSON.stringify(newFormObject)
    );
  };

  const SELECT_FIELD = (props) => {
    const { field } = props;
    let isChecked = field.isSelected;
    let isDisabled = !field.isEditable;
    console.log(TAG, 'isDisabled ' + isDisabled);
    return (
      <div className="formF" style={{ display: "flex" }}>
        <input
          type="checkbox"
          name="checkbox-name"
          onChange={(e) => {
            onUpdateFieldChecked(e, field.field_name);
          }}
          checked={isChecked}
          style={{
            position: "relative",
            top: "-5px",
            marginLeft: "10px",
          }}
          disabled={isDisabled}
        />
        <h6 className="forInp">{field.field_label}</h6>
      </div>
    )
  }

  const LEFT_BAR = (props) => {
    //const { formObject } = props;

    let groupedFields = groupObject();
    let admYearFields = groupedFields[0];
    let stuFields = groupedFields[1];
    let parentFields = groupedFields[2];
    let docFields = groupedFields[3];

    console.log(TAG, "LEFTBAR is rendered again..");
    return (
      <>
        <div
          className="left-section"
          style={{ width: "25%", height: "auto", position: "relative", overflowY: 'scroll' }}
        >
          {/* ------------------------------Personal detail section------------------------------------ */}
          <div className="sect-title">
            <h4>Select Fields</h4>
            {/* controls */}

            <h6>Admission for</h6>
            {admYearFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}

            <hr className="divider" style={{ margin: 10 }} />

            <h6>Student details</h6>
            {stuFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}

            <hr className="divider" style={{ margin: 10 }} />

            <h6>Parent details</h6>
            {parentFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}


            <hr className="divider" style={{ margin: 10 }} />
            <h6>Documents</h6>
            {docFields.map((field, index) => {

              return (
                <SELECT_FIELD field={field} />
              );
            })}
          </div>
        </div>
      </>
    );
  };

  function groupObject() {
    var result = formDetails.fields.reduce(function (r, a) {
      r[a.groupIndex] = r[a.groupIndex] || [];
      r[a.groupIndex].push(a);
      return r;
    }, Object.create(null));

    return result;
  }

  const FORM_FIELD = (props) => {

    const { obj } = props;
    console.log(TAG, 'SetApplicationForm.jsx FORM_FIELD: ' + JSON.stringify(obj));
    if (obj.isSelected) {

      /* show based on name */

      switch (obj.field_type) {
        case "text":
          console.log(TAG, "APPLICATION_FORM2 rendering text");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_INPUT obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case "textarea":
          console.log(TAG, "APPLICATION_FORM2 rendering textarea");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_TEXTAREA obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case "email":
          console.log(TAG, "APPLICATION_FORM2 rendering email");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_EMAIL obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'radio':
          console.log(TAG, "APPLICATION_FORM2 rendering radio");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_RADIO obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'date':
          console.log(TAG, "APPLICATION_FORM2 rendering date");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_DATE obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case 'file':
          console.log(TAG, "APPLICATION_FORM2 rendering file");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_FILE obj={obj} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          )
        default:
          return <></>
      }
    } else {
      return <></>;
    }
  }

  const APPLICATION_FORM = (props) => {
    let objIdx = -1;

    let groupedFields = groupObject();
    let admYearFields = groupedFields[0];
    let stuFields = groupedFields[1];
    let parentFields = groupedFields[2];
    let docFields = groupedFields[3];
    console.log(TAG, 'APPLICATION_FORM groupedFields: ' + JSON.stringify(groupedFields));
    //const { formObject } = props;

    const onChange = (e, obj) => {
      const { name, value } = e.target;
      console.log(TAG, 'APPLICATION_FORM onChange(): name ' + name);
      console.log(TAG, 'APPLICATION_FORM onChange(): value ' + value);

      obj.field_value = value;

      // update form object
      // let newFormObject = JSON.parse(JSON.stringify(formObject));

      // setFormObject(newFormObject);

      // see if form object was updated
      console.log(TAG, 'APPLICATION_FORM onChange(): updated form object ' + JSON.stringify(formObject));
    }

    let studentName = '-';
    let classFor = '-'
    if (currLead) {
      studentName = currLead.Student_name

      if (currLead.student_class_applied) {
        classFor = currLead.student_class_applied;
      }

    }

    return (
      <>
        <div
          className="right-section"
          style={{
            position: "relative",
            // left: "400px",
            width: "75%",
            padding: "50px",
            // marginLeft: "50px",
            borderRadius: "10px",
          }}
        >

          <div style={{ display: "flex", flexDirection: 'column', gap: "10px" }}>
            {/* admission for */}
            <div>
              <div className="ind-inp">
                <h4>Admission for {studentName} (Class {classFor})</h4>
              </div>

              <div className="row">
                {admYearFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={(e, obj) => onChange(e, obj)} />
                })}
              </div>
            </div>

            {/* Student details */}
            <div>
              <div className="ind-inp">
                <h4>Student details</h4>
              </div>

              <div className="row">
                {stuFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={onChange} />
                })}
              </div>
            </div>


            {/* Student information */}
            <div>
              <div className="ind-inp">
                <h4>Parent's / Guardian's information</h4>
              </div>

              <div className="row">
                {parentFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );

                  return <FORM_FIELD obj={obj} onChange={onChange} />
                })}
              </div>
            </div>


            {/* Parent information */}
            <div>
              <div className="ind-inp">
                <h4>Documents to upload</h4>
              </div>

              {docFields && docFields.length ?
                <div className="row">
                  {docFields.map((obj, index) => {
                    // let formObj = null;
                    console.log(
                      TAG,
                      "APPLICATION_FORM2 obj field_type " +
                      JSON.stringify(obj.field_type)
                    );

                    return <FORM_FIELD obj={obj} onChange={onChange} />
                  })}
                </div>
                :
                <>
                  <p>You have not selected any document fields!</p>
                </>
              }
            </div>

          </div>

        </div>
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    props.setOnShow(false);
  }

  const handleShow = () => {
    setShow(true);
    props.setOnShow(true);
    props.setCurrLead(null);
  }

  const queryURL = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"
  let enqId = (currLead) ? currLead.enquiry_id : 0;

  const onSaveClicked = async () => {
    if (currLead) {
      handleClose();
      const body = {
        enquiry_id: currLead.enquiry_id,
        admission_obj: currLead,
        admission_status: 'APPLICATION',
        payment_verify_url: ''
      }

      const resp = await COUNSELLORAPI.postSaveAdmission(body);
      console.log(TAG, 'onSaveClicked(): resp ' + JSON.stringify(resp));

      Swal.fire({
        title: 'Success',
        text: 'Application saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

    } else {
      // sweetalert
      console.log(TAG, 'onSaveClicked: empty values')

      MySwal.fire({
        title: 'Error',
        text: 'Please enter required fields..',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        // handleClose();


      })

      return false;
    }

    // reload leads
    // props.reloadEnquiries();
  }

  useEffect(() => {
    const instId2 = localStorage.getItem("applier.couns.school.id");
    setInstId(instId2);

    // get school application form

    formSetupHandler();

  }, [])

  console.log(TAG, 'EnquiryModal props currLead parent name2 ' + parentName2);
  console.log(TAG, 'EnquiryModal state parent name ' + parentName);

  return (
    <>
      {/* <Button variant="contained" color='primary' onClick={handleShow}
        startIcon={<AddIcon />}>
        Add Lead
      </Button> */}

      <Modal
        size="xl"
        show={onShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Admission Enquiry Form 2022-2023</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <AddLeadForm
            studentName={studentName}
            studentGender={studentGender}
            parentName={parentName}
            parentEmail={parentEmail}
            classApplied={classApplied}
            contact={contact}
            isParent={isParent}
            setParentEmail={(em) => setParentEmail(em)}
            setClassApplied={(cl) => setClassApplied(cl)}
            setStudentName={(nm) => setStudentName(nm)}
            setStudentGender={(g) => setStudentGender(g)}
            setParentName={(nm) => setParentName(nm)}
            setContact={(c) => setContact(c)}
            setIsParent={(v) => setIsParent(v)}
          /> */}
          <APPLICATION_FORM
            formDetails={formDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={onSaveClicked}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}


const APPLICATIONROW = (props) => {
  const { data, lead } = props;
  const { enquiry_id, admission_form_id } = data; // extract the current application row
  console.log(TAG, 'APPLICATIONROW data: ' + JSON.stringify(data));

  const _onChooseExamSlot = (e) => {
    const { name, value } = e.target;
    console.log(TAG, '_onChooseExamSlot() value ' + value);

    Swal.fire({
      title: 'Do you want to choose this time?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Set',
      denyButtonText: `Don't set`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        // see if the value is not no_exam
        if (value != 'NO_EXAM') {
          // post back to parent component
          props._onSetExamSlot(value, enquiry_id);
          // update the status also
          props._onUpdateApplicationStatus('EXAM_SCHEDULED', admission_form_id);
        } else {
          // update the status also
          props._onUpdateApplicationStatus('NO_EXAM', admission_form_id);
        }
        Swal.fire('Saved!', '', 'success');

      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'error');
      }
    })

  }

  const { schedule_times, exam_allocated_time } = data;
  console.log(TAG, 'APPLICATIONROW schedule_times ' + JSON.stringify(schedule_times));

  let date = '-';
  let dtStr = '-';
  if (exam_allocated_time) {
    date = moment(exam_allocated_time);
    dtStr = date.utc().format('YYYY-MM-DD hh:mm a')
  }

  return (
    <tr>
      <td>{data.admission_form_id}</td>
      <td>
        <h2 class="table-avatar">
          <a
            href="IndividualStudent.html"
            class="avatar avatar-sm me-2"
          >
            <img
              class="avatar-img rounded-circle"
              src="assets/img/profiles/avatar-03.jpg"
              alt="User Image"
            />
          </a>
          <Link to={`/lead-details?enquiry_id=${data.enquiry_id}`}>{data.Student_name}</Link>
        </h2>
      </td>
      <td style={{ textAlign: 'center' }}>{data.student_class_applied} <sup></sup></td>
      <td style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>

        <div class="w-50">
          {/* <Form.Select aria-label="Default select example"
            onChange={_onChooseExamSlot}>
            <option>Choose exam slot</option>

            {(schedule_times) ?
              schedule_times.map((tm, idx3) => {
                const tme = tm.schedule_time;
                var date = moment(tme);
                var dtStr = date.utc().format('YYYY-MM-DD hh:mm a')
                return (
                  <>
                    <option value={tme}>{dtStr}</option>
                  </>
                )
              })

              :
              <></>
            }

            <option value="NO_EXAM">NO EXAM</option>
            
          </Form.Select> */}
          {dtStr}
        </div>

      </td>
      {/* <td style={{ width: '20%' }}>
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleNoteShown(); props.handleAddNote(lead)
          }}
        >
          <i className='fa fa-edit fa-1x'></i>
        </a>
      </td> */}
      <td style={{ textAlign: 'center' }}>

        <Button variant="contained"
          onClick={() => {
            // props.setCurrLead(lead);
            props.onShowEditForm(data);

          }}
        >Preview</Button>
      </td>

      {/* <td className="text-end" style={{ width: '10px' }}>
        <div className="dropdown dropdown-action">
          <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
          <div className="dropdown-menu dropdown-menu-end">
            <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
            <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

          </div>
        </div>
      </td> */}
    </tr>
  )
}


const SEARCHBAR = (props) => {
  const { applications, oApplications } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.admission_form_id.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_name.toString().includes(value)
          || itm.Student_name.toString().includes(value)
        ) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}


const FORM_OBJECT = {
  institute_id: 0,
  fields: [
    {
      //admission year
      field_name: "admission_year",
      field_type: "text",
      field_label: "Admission Year",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 0,
    },
    {
      //name of the student
      field_name: "student_name",
      field_type: "text",
      field_label: "Name of the Student",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Student Gender
      field_name: "student_gender",
      field_type: "radio",
      field_label: "Student Gender",
      field_value: '',
      max_length: 5,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
      options: ['M', 'F']
    },
    {
      //Date of Birth
      field_name: "student_dob",
      field_type: "date",
      field_label: "Date of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //City
      field_name: "city",
      field_type: "text",
      field_label: "City",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Enrollment in Grade
      field_name: "student_enroll",
      field_type: "text",
      field_label: "Enrollment in Grade",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Campus
      field_name: "campus",
      field_type: "text",
      field_label: "Campus",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    {
      //student picture
      field_name: "student_picture",
      field_type: "file",
      field_label: "Upload Student Image",
      field_value: '',
      max_length: 20,
      max_file_size: 10,
      accept_type: "jpeg/png/gif",
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //permanent address
      field_name: "perm_addr",
      field_type: "textarea",
      field_label: "Permanent Address",
      field_value: '',
      max_length: 500,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //last school attended
      field_name: "schl_attend",
      field_type: "text",
      field_label: "Last School Attended",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //Caste
      field_name: "caste",
      field_type: "text",
      field_label: "Caste",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Religion
      field_name: "religion",
      field_type: "text",
      field_label: "Religion",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Mother Tongue
      field_name: "mother_tongue",
      field_type: "text",
      field_label: "Mother Tongue",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //Naionality
      field_name: "nationality",
      field_type: "text",
      field_label: "Nationality",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //student email
      field_name: "student_email",
      field_type: "email",
      field_label: "Student Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student contact
      field_name: "student_contact",
      field_type: "text",
      field_label: "Student Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student place of birth
      field_name: "student_placeofbirth",
      field_type: "text",
      field_label: "Student Place of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student temp address
      field_name: "student_temp_addr",
      field_type: "text",
      field_label: "Student Temporary Address",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student adhar number
      field_name: "student_adhar_number",
      field_type: "text",
      field_label: "Student Adhaar Number ",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //blood group
      field_name: "student_blood_group",
      field_type: "text",
      field_label: "Student Blood Group",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //resides with
      field_name: "resides_with",
      field_type: "text",
      field_label: "Student Resides With",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    // PARENT DETAILS
    {
      //Parent Name
      field_name: "parent_name",
      field_type: "text",
      field_label: "Parent Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },
    {
      //Parent Contact
      field_name: "parent_contact",
      field_type: "text",
      field_label: "Parent Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },

    {
      //parent email
      field_name: "parent_email",
      field_type: "email",
      field_label: "Parent Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Name
      field_name: "father_name",
      field_type: "text",
      field_label: "Father Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Contact
      field_name: "father_contact",
      field_type: "text",
      field_label: "Father Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Father email
      field_name: "father_email",
      field_type: "email",
      field_label: "Father Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Name
      field_name: "mother_name",
      field_type: "text",
      field_label: "Mother Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "mother_contact",
      field_type: "text",
      field_label: "Mother Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "mother_email",
      field_type: "email",
      field_label: "Mother Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Guardian Name
      field_name: "guard_name",
      field_type: "text",
      field_label: "Guardian Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "guard_contact",
      field_type: "text",
      field_label: "Guardian Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "guard_email",
      field_type: "email",
      field_label: "Guardian Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    // DOCUMENTS
    {
      // Father photo
      field_name: "father_photo",
      field_type: "file",
      field_label: "Father photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Mother photo
      field_name: "mother_photo",
      field_type: "file",
      field_label: "Mother photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },

    {
      // Guardian photo
      field_name: "guardian_photo",
      field_type: "file",
      field_label: "Guardian photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // DOB certificate
      field_name: "student_dob_cert",
      field_type: "file",
      field_label: "Student DOB Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Child Aadhaar
      field_name: "student_aadhaar",
      field_type: "file",
      field_label: "Student Aadhaar",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student TC
      field_name: "student_tc",
      field_type: "file",
      field_label: "Student TC",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_caste_cert",
      field_type: "file",
      field_label: "Student Caste Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_res_cert",
      field_type: "file",
      field_label: "Student Residence Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    }
  ],
};

const FORM_OBJECT_INPUT = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="text" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}</Form.Label>
          {/* <Form.Control type="text" placeholder={obj.field_name} onChange={(e) => props.onChange(e, obj)} /> */}
          <h6 variant="h6">{obj.field_value}</h6>
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_TEXTAREA = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <textarea placeholder={props.placeholder}>this is text area </textarea> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{obj.field_label}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} as="textarea" placeholder={obj.field_name} rows={3} /> */}
          <h6 variant="h6">{obj.field_value}</h6>
        </Form.Group>

      </div>
    </>
  );
};

const FORM_OBJECT_EMAIL = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} type="email" placeholder={obj.field_name} /> */}
          <h6 variant="h6">{obj.field_value}</h6>
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_RADIO = (props) => {
  const { obj } = props;
  const count = obj.options.length;
  return (
    <>
      <Form.Label>{obj.field_label}</Form.Label>

      <div className="custom2 d-flex flex-row ">
        {/* <input type="email" placeholder={props.placeholder} /> */}

        {/* {obj.options.map((op, idx) => {
          return (
            <div key={`default-radio`} className="me-3">
              <Form.Check
                type={'radio'}
                id={`default-${op}`}
                label={op}
                onChange={(e) => props.onChange(e, obj)}
              />
            </div>
          )
        })} */}
        <h6 variant="h6">{obj.field_value}</h6>

      </div>
    </>
  );
};

const FORM_OBJECT_DATE = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} type="date" placeholder={obj.field_name} /> */}
          <h6 variant="h6">{obj.field_value}</h6>
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_FILE = (props) => {
  const { obj } = props;

  let imgSrc1 = obj.field_value;
  let imgSrc = imgSrc1;
  if (!imgSrc1.includes('jpg')
    && !imgSrc1.includes('jpeg')
    && !imgSrc1.includes('png')
    && !imgSrc1.includes('gif')
    && !imgSrc1.includes('avif'))
    imgSrc = 'https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png'
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{obj.field_label}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} type="file" /> */}
          <p>
            <a href={imgSrc1} target="_blank"><img src={imgSrc} height={200} /></a>
          </p>
        </Form.Group>
      </div>
    </>
  );
};

const APPLICATIONLIST = (props) => {
  const { applications } = props;

  if (!applications || !applications.length) {
    return (
      <Alert variant="warning">
        <Alert.Heading>No applications filled yet!</Alert.Heading>
        <p>
          No applications are shown here as they have not yet been added in the system. Start adding now!
        </p>
        <hr />
        <p className="mb-0">
          Applications can be achieved by converting leads.
        </p>
      </Alert>
    )
  }

  return (
    <table class="table border-0 star-student  table-center mb-0 datatable">
      <thead class="student-thread">
        <tr>
          <th>
            Application ID <BiSort />
          </th>
          <th>
            Name <BiSort />{" "}
          </th>
          <th style={{ textAlign: 'center' }}>
            Grade <BiSort />
          </th>
          <th style={{ textAlign: 'center' }}>
            Exam Date<BiSort />
          </th>
          {/* <th>
            Counsellor <BiSort />
          </th> */}
          {/* <th className="text-end">Edit</th> */}
          {/* <th>Add notes <BiSort /></th> */}
          <th style={{ textAlign: 'center' }}>
            Preview
          </th>
        </tr>
      </thead>
      <tbody>

        {applications.map((row, idx) => {
          return <APPLICATIONROW key={idx} data={row}
            lead={row}
            _onSetExamSlot={(dt, eId) => props._onSetExamSlot(dt, eId)}
            setCurrApp={(currApp2) => props.setCurrApp(currApp2)}
            _onUpdateApplicationStatus={(stat, id) => props._onUpdateApplicationStatus(stat, id)}
            handleAddNote={(lead) => props.handleAddNote(lead)}
            handleNoteShown={() => props.handleNoteShown()}
            currLead={props.currLead}
            setCurrLead={(lead) => props.setCurrLead(lead)}
            onShowEditForm={(lead) => props.onShowEditForm(lead)}
          />
        })}


        {/*
        <tr></tr>
        <tr>
          <td>4AV35</td>
          <td>
            <h2 class="table-avatar">
              <a
                href="IndividualStudent.html"
                class="avatar avatar-sm me-2"
              >
                <img
                  class="avatar-img rounded-circle"
                  src="assets/img/profiles/avatar-03.jpg"
                  alt="User Image"
                />
              </a>
              <Link to="/sch-student">Ajay Kumar</Link>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>9 <sup>th</sup></td>
          <td style={{ textAlign: 'center' }}>
            <select>
              <option>01-02-2023(09:30am)</option>
              <option>01-02-2023(01:00pm)</option>
              <option>03-02-2023(09:30am)</option>
            </select>

          </td>
          <td>Aravind</td>

          <td className="text-end" style={{ width: '10px' }}>
            <div className="dropdown dropdown-action">
              <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>3BG63</td>

          <td>
            <h2 class="table-avatar">
              <a
                href="IndividualStudent.html"
                class="avatar avatar-sm me-2"
              >
                <img
                  class="avatar-img rounded-circle"
                  src="assets/img/profiles/avatar-02.jpg"
                  alt="User Image"
                />
              </a>
              <Link to="/sch-student">Bharat</Link>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>2 <sup>nd</sup> </td>
          <td style={{ textAlign: 'center' }}>
            ----
          </td>

          <td>Praveen</td>

          <td className="text-end" style={{ width: '10px' }}>
            <div className="dropdown dropdown-action">
              <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>AM623S</td>

          <td>
            <h2 class="table-avatar">
              <a
                href="IndividualStudent.html"
                class="avatar avatar-sm me-2"
              >
                <img
                  class="avatar-img rounded-circle"
                  src="assets/img/profiles/avatar-04.jpg"
                  alt="User Image"
                />
              </a>
              <Link to="/sch-student">Mukesh</Link>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>6 <sup>th</sup> </td>
          <td style={{ textAlign: 'center' }}>
            <select>
              <option>05-02-2023(09:30am)</option>
              <option>01-02-2023(01:00pm)</option>
              <option>03-02-2023(09:30am)</option>
            </select>

          </td>

          <td>Sushma</td>

          <td className="text-end" style={{ width: '10px' }}>
            <div className="dropdown dropdown-action">
              <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>GF6214F</td>

          <td>
            <h2 class="table-avatar">
              <a
                href="IndividualStudent.html"
                class="avatar avatar-sm me-2"
              >
                <img
                  class="avatar-img rounded-circle"
                  src="assets/img/profiles/avatar-05.jpg"
                  alt="User Image"
                />
              </a>
              <Link to="/sch-student">Sushanth</Link>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>8 <sup>th</sup> </td>
          <td style={{ textAlign: 'center' }}>
            <select>
              <option>01-02-2023(01:00pm)</option>
              <option>01-02-2023(09:30am)</option>
              <option>03-02-2023(09:30am)</option>
            </select>

          </td>
          <td>Vinay</td>

          <td className="text-end" style={{ width: '10px' }}>
            <div className="dropdown dropdown-action">
              <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>MJ6242</td>

          <td>
            <h2 class="table-avatar">
              <a
                href="IndividualStudent.html"
                class="avatar avatar-sm me-2"
              >
                <img
                  class="avatar-img rounded-circle"
                  src="assets/img/profiles/avatar-08.jpg"
                  alt="User Image"
                />
              </a>
              <Link to="/sch-student">Pavani</Link>
            </h2>
          </td>
          <td style={{ textAlign: 'center' }}>10 <sup>th</sup> </td>
          <td style={{ textAlign: 'center' }}>
            <select>
              <option>03-02-2023(09:30am)</option>
              <option>01-02-2023(09:30am)</option>
              <option>01-02-2023(01:00pm)</option>
            </select>

          </td>
          <td>Sekhar</td>

          <td className="text-end" style={{ width: '10px' }}>
            <div className="dropdown dropdown-action">
              <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

              </div>
            </div>
          </td>
        </tr>
        */}

      </tbody>
    </table>
  )
}

const CounsApps = () => {

  const [applications, setApplications] = useState(null)
  const [oApplications, setOApplications] = useState(null)
  const [currApp, setCurrApp] = useState(null)
  const [onShow, setOnShow] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const [notes, setNotes] = useState(null)
  const [channel, setChannel] = useState(null)
  const [currLead, setCurrLead] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNoteShown = () => {
    setShowNote(true);
  }

  const handleAddNote = (lead) => {
    setCurrLead(lead);
  }

  const handleNoteClose = () => {
    setShowNote(false);
  }

  const _loadApplications = async () => {
    const data = await INSTITUTEAPI.getApplications(stat, pageType);
    console.log(TAG, '_loadApplications() data: ' + JSON.stringify(data));

    setApplications(data);
    setOApplications(data);
  }

  // save the exam slot
  const _onSetExamSlot = async (dt2, enquiryId) => {
    // for now static time
    const dt = new Date();
    // const dt2 = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    // const dt2 = moment().utc().format("YYYY-MM-DD HH:mm:ss");

    const body = {
      enquiry_id: enquiryId,
      time_interval: '1-2',
      test_location: 'Jubilee Hills',
      exam_schedule_date: dt2
    }

    const resp = await INSTITUTEAPI.postSaveExamSlot(body);
    console.log(TAG, '_onSetExamSlot() resp ' + JSON.stringify(resp));
  }

  const _onUpdateApplicationStatus = async (stat, applicationId) => {

    const body = {
      admission_form_id: applicationId,
      admission_status: stat,
      admission_status1: "",
      payment_verify_url: ""
    }

    console.log(TAG, '_onUpdateApplicationStatus() body ' + JSON.stringify(body));

    const resp = await INSTITUTEAPI.postSaveApplicationStatus(body);
    console.log(TAG, '_onUpdateApplicationStatus() resp ' + JSON.stringify(resp));

    MySwal.fire('Success', 'Exam scheduled successfully!', 'info');

    // reload the page
    _loadApplications();
  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  const handleSaveNote = async () => {

    console.log(TAG, 'handleSaveNote(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveNote(): notes ' + notes);
    console.log(TAG, 'handleSaveNote(): channel ' + channel);

    const { enquiry_id } = currLead;
    if (notes && enquiry_id > 0 && channel) {
      const body = {
        enquiry_id: enquiry_id,
        page_feature: channel,
        activity_text: notes
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Note for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await INSTITUTEAPI.postSaveActivityNotes(body);

      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleNoteClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleNoteShown();
    }
  }

  useEffect(() => {
    _loadApplications();
  }, [])

  return (
    <div>
      <Sidebar />
      <div class="page-wrapper">
        <div class="content container-fluid">


          <div class="row">
            <div class="col-sm-12">
              <div class="card card-table comman-shadow">
                <div class="card-body">
                  <div class="page-header">
                    <div class="row2 d-flex flex-row flex-horizontal align-items-center">
                      <div class="col2">
                        <div
                          class="col2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3 class="page-title">Applications</h3>
                        </div>
                      </div>
                      <div class="col2-auto w-75 text-end float-end ms-auto2 download-grp2">
                        <SEARCHBAR
                          applications={applications}
                          oApplications={oApplications}
                          setApplications={setApplications}
                        />
                      </div>
                    </div>


                    <div className="col-lg-2">
                      <div className="search-student-btn">
                        {/* <Link to='/enquiry'>
                            <Button variant="contained" startIcon={<AddIcon />}>
                              Add Lead
                            </Button>
                          </Link> */}

                        <EnquiryModal
                          // reloadEnquiries={_loadApplications}
                          onShow={onShow}
                          setOnShow={setOnShow}
                          currLead={currLead}
                        // setCurrLead={(lead) => setCurrLead(lead)} // update
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive w-100">
                    <div
                      class="col-lg-12"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      {/* <div
                        class="search-student-btn"
                        style={{ position: "absolute", top: "1px" }}
                      > */}
                      {/* <div class="btn-group">
                          <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style={{ marginTop: '15px', backgroundColor: '#3066BE', fontWeight: '400' }}
                          >Application Form</button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Send Email</a>


                          </div>
                        </div> */}

                      {/* </div> */}
                    </div>

                    <APPLICATIONLIST
                      applications={applications}
                      _onSetExamSlot={(dt, eId) => _onSetExamSlot(dt, eId)}
                      setCurrApp={(currApp2) => setCurrApp(currApp2)}
                      _onUpdateApplicationStatus={(stat, id) => _onUpdateApplicationStatus(stat, id)}
                      onShowEditForm={(lead) => onShowEditForm(lead)}
                      setOnShow={setOnShow}
                      handleAddNote={(lead) => handleAddNote(lead)}
                      handleSaveNote={(lead) => handleSaveNote(lead)} /* show modal */
                      showNote={showNote}
                      handleClose={() => handleNoteClose()}
                      handleNoteShown={() => handleNoteShown()}

                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* NOTE MODAL */}
      <NoteModal
        show={showNote}
        handleClose={() => handleNoteClose()}
        handleNoteShown={() => handleNoteShown()}
        handleSaveNote={(lead) => handleSaveNote(lead)}
        setNotes={(note) => setNotes(note)}
        currLead={currLead}
        setCurrLead={setCurrLead}
        handleChangeNote={(val) => setNotes(val)}
        setChannel={(val) => setChannel(val)}
      />

    </div>
  );
};

export default CounsApps;
