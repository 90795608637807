import React, { useState } from 'react';

function VerticalTable() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
           
          </tr>
        </thead>
        <tbody className='table-body'>
          <tr>
            <td
              className={`tab ${activeTab === 0 ? 'selected' : ''}`}
              onClick={() => handleTabClick(0)}
            >
              Timeline
            </td>
            <td
              className={`tab ${activeTab === 1 ? 'selected' : ''}`}
              onClick={() => handleTabClick(1)}
            >
              Follow Up & Notes
            </td>
            <td
              className={`tab ${activeTab === 2 ? 'selected' : ''}`}
              onClick={() => handleTabClick(2)}
            >
              Communication Logs
            </td>
            <td
              className={`tab ${activeTab === 3 ? 'selected' : ''}`}
              onClick={() => handleTabClick(3)}
            >
               Document Locker
            </td>
            <td
              className={`tab ${activeTab === 4 ? 'selected' : ''}`}
              onClick={() => handleTabClick(4)}
            >
              Tickets
            </td>
            <td
              className={`tab ${activeTab === 5 ? 'selected' : ''}`}
              onClick={() => handleTabClick(5)}
            >
              Call Logs
            </td>
          </tr>
        </tbody>
      </table>
      <div className="tab-content">
        {activeTab === 0 && <div>
            
Form Interested In :	Application form
Candidate's
Email Address:	ajayk@gmail.com
State:	Telangana
City:	Hyderabad
Country:	India
Course:	10 th
Specialisation:	Computers
            </div>}
        {activeTab === 1 && <div>Content for Tab 2</div>}
        {activeTab === 2 && <div>Content for Tab 3</div>}
      </div>
    </div>
  );
}

export default VerticalTable;