import React from 'react'

const SchoolAdmin = () => {
  return (
  <>
    <div>
        <div className="student-group-form">
<div className="row">
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by ID ..." />
</div>
</div>
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Name ..." />
</div>
</div>
<div className="col-lg-4 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Phone ..." />
</div>
</div>
<div className="col-lg-2">
<div className="search-student-btn">
<button type="btn" className="btn btn-primary">Search</button>
</div>
</div>
</div>
</div>
    </div>

<div>
    <div className='detail-info'>
    <div className="db-info-admin">
                                        <h6>Total Leads</h6>
                                        <h3>10,032</h3>
                                        <input type="text" value='verified: 1,408' />
                                        <input type="text" value='Unverified: 8,624' />
                                    </div>
                                    <div className="db-info-admin">
                                        <h6>Total Paid Applications</h6>
                                        <h3>84</h3>
                                        <input type="text" value='Online:84' />
                                        <input type="text" value='Offline: 0' />
                                        <input type="text" value='Vocher: 0' />
                                    </div>
                                 
                              
                                    <div className="db-info-admin">
                                        <h6>Total unpaid Applications</h6>
                                        <h3>294</h3>
                                        <input type="text" value='Payment Initited: 18' />
                                        <input type="text" value='Payment Not Initited: 276' />                                   
                                </div>
                              
                              
                                    <div className="db-info-admin">
                                        <h6>Total Queries</h6>
                                        <h3>818</h3>
                                        <input type="text" value='Open: 753' />
                                        <input type="text" value='Progress: 64' />
                                        <input type="text" value='Closed: 1' />                                    
                                </div>
                          
                           
    </div>




<div className="row">
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                        <div className=" w-75" style={{margin:"20px 0px !important",position:'relative',width:'100%',maxWidth:'250px !important',border:'1px solid red'}}>
                            <div className="card-body" >
                                <div className="db-widgets d-flex justify-content-between align-items-center">
                                    <div className="db-info">
                                        <h6>Total Leads</h6>
                                        <h3>10,032</h3>
                                        <input type="text" value='verified: 1,408' />
                                        <input type="text" value='Unverified: 8,624' />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                        <div className=" w-75" style={{margin:"20px 0px !important",position:'relative',width:'100%',maxWidth:'250px !important',border:'1px solid red'}}>
                            <div className="card-body" >
                                <div className="db-widgets d-flex justify-content-between align-items-center">
                                    <div className="db-info">
                                        <h6>Total Paid Applications</h6>
                                        <h3>84</h3>
                                        <input type="text" value='Online:84' />
                                        <input type="text" value='Offline: 0' />
                                        <input type="text" value='Vocher: 0' />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                        <div className=" w-75" style={{margin:"20px 0px !important",position:'relative',width:'100%',maxWidth:'250px !important',border:'1px solid red'}}>
                            <div className="card-body" >
                                <div className="db-widgets d-flex justify-content-between align-items-center">
                                    <div className="db-info">
                                        <h6>Total unpaid Applications</h6>
                                        <h3>294</h3>
                                        <input type="text" value='Payment Initited: 18' />
                                        <input type="text" value='Payment Not Initited: 276' />
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12 d-flex">
                        <div className=" w-75" style={{margin:"20px 0px !important",position:'relative',width:'100%',maxWidth:'250px !important',border:'1px solid red'}}>
                            <div className="card-body" >
                                <div className="db-widgets d-flex justify-content-between align-items-center">
                                    <div className="db-info">
                                        <h6>Total Queries</h6>
                                        <h3>818</h3>
                                        <input type="text" value='Open: 753' />
                                        <input type="text" value='Progress: 64' />
                                        <input type="text" value='Closed: 1' />

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>


  </>
  )
}

export default SchoolAdmin