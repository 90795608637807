import React from 'react'
import ApplicationForm from './dashboard/ApplicationForm'

import { Route, Routes } from "react-router-dom"


import './App.css'
import Home from './dashboard/Home'
import CampaignAnalytics from './dashboard/CampaignAnalytics'
import CampaignByClass from './dashboard/CampaignByClass'
import CounsellorDashboard from './dashboard/CounsellorDashboard'
import EnquiryForm from './dashboard/EnquiryForm'

import EmailApplicationForm from './dashboard/EmailApplicationForm'
import ExamSlot from './dashboard/ExamSlot'
import IndividualStudent from './dashboard/IndividualStudent'
import LeadDetails from './dashboard/LeadDetails'

import LeadDetails2 from './dashboard/LeadDetails2'
import OfflineEnquiry from './dashboard/OfflineEnquiry'
import OnlineApplications from './dashboard/OnlineApplications'
import ParentRegister from './dashboard/ParentRegister'
import ApiTest from './ApiTest'
import SuccessPage from './dashboard/SuccessPage'
import Login from './dashboard/Login'
import CounsellorForm from './dashboard/CounsellorForm'
import CounsellorEmail from './dashboard/CounsellorEmail'
import CounsDetailForm from './dashboard/CounsDetailForm'
import SchoolRegister from './dashboard/SchoolRegister'
import SchReg from './dashboard/SchReg'
import LeadEnquiry from './dashboard/LeadEnquiry'
import RegSch from './dashboard/RegSch'
import FirstReg from './dashboard/FirstReg'
import SchoolActivate from './dashboard/SchoolActivate'
import AlertPage from './dashboard/AlertPage'
import SchoolEnquiries from './dashboard/SchoolEnquiries'
import Dynamic from './dashboard/Dynamic'
import InputProps from './dashboard/inputs/InputProps'
import BinPage from './dashboard/inputs/BinPage'
import Checkbox from './dashboard/inputs/Checkbox'
import SchActivate from './dashboard/SchActivate'
import Example from './dashboard/CounsEmailInp'
import CounsellorPage from './dashboard/CounsellorSidebar'
import CreateCampaign from './dashboard/CreateCampaign'
import SchoolAdmin from './dashboard/SchoolAdmin'
import SchoolCounsDash from './dashboard/SchoolCounsDashB'
import { ValidationPage } from './dashboard/ValidationPage'
import GetForm from './dashboard/GetForm'
import CounsellorList from './dashboard/CounsellorList'
import DynamicSidebar from './dashboard/DynamicSidebar'
import SchoolCounsDashB from './dashboard/SchoolCounsDashB'
import Applications from './dashboard/Applications'
import Results from './dashboard/Results'
import Payments from './dashboard/Payments'
import Approved from './dashboard/Approved'
import Admissions from './dashboard/Admissions'
import CampLeads from './dashboard/CampLeads'
import CampLeads2 from './dashboard/CounsellorLeads'
import CounsellorLeads2 from './dashboard/CounsellorLeads2'
import SchoolAnalytics from './dashboard/SchoolAnalytics'
import CampaignForm from './dashboard/components/CampaignForm'
import SchoolCampaign from './dashboard/SchoolCampaign'
import CounsApps from './dashboard/CounsApps'
import CounsResults from './dashboard/CounsResults'
import CounsPayment from './dashboard/CounsPayment'
import CounsAdmission from './dashboard/CounsAdmission'
import VerticalTable from './dashboard/components/VerticalTable'
import SchoolStudent from './dashboard/SchoolStudent'
import CounsCampaigns from './dashboard/CounsCampaigns'
import Sample from './dashboard/Sample'
import SchoolAdmissionEnquiry from './dashboard/Widget'
import Drawer from './dashboard/Drawer'
import ExamScheduler from './dashboard/ExamScheduler'
import DateTimePicker from './dashboard/components/DateTime'
import SeatAvailable from './dashboard/SeatAvailable'
import SetApplicationForm from './dashboard/inputs/SetApplicationForm'
import AddCounsellor from './dashboard/AddCounsellor'
import ExampleModal from './dashboard/components/ApplicationModal'
import FilterTable from './dashboard/components/FilterTable'
import CounsellorNumber from './dashboard/CounsellorNumber'
import DummyApplications from './dashboard/dummy/DummyApplications'
import ThankYou from './dashboard/ThankYou';
import ThankYou2 from './dashboard/ThankYou2';

import LeadInfo from './dashboard/LeadInfo';
import CounsProfile from './dashboard/CounsProfile';

import Logout from './dashboard/Logout';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/admin-dashboard" element={<Home />} />
        <Route path="/check" element={<ApplicationForm />} />
        <Route path='/camp' element={<CampaignAnalytics />} />
        <Route path='/by-class' element={<CampaignByClass />} />
        <Route path='/counsellor' element={<CounsellorDashboard />} />

        {/* campaign return page */}
        <Route path='/enquiry' element={<EnquiryForm />} />
        <Route path='/enquiry/:code/:source' element={<EnquiryForm />} />

        <Route path='/fill-application-form' element={<EmailApplicationForm />} />
        <Route path='/fill-application-form/:code/:source' element={<EmailApplicationForm />} />

        <Route path='/examslot' element={<ExamSlot />} />
        <Route path='/student' element={<IndividualStudent />} />
        <Route path='/lead' element={<LeadDetails />} />

        {/* school lead-manager */}
        <Route path='/lead-manager' element={<OfflineEnquiry />} />

        <Route path='/online-application' element={<OnlineApplications />} />
        <Route path='/lead-register' element={<ParentRegister />} />
        <Route path='/register-form' element={<ApiTest />} />
        <Route path="/lead-activate" element={<SuccessPage />} />
        <Route path="/login" element={<Login />} />
        <Route path='/counsellor-profile' element={<CounsellorForm />} />
        <Route path='/counsellor-email' element={<CounsellorEmail />} />
        <Route path='/counsellor-detail-form' element={<CounsDetailForm />} />
        {/* couns-details ------> ????? */}
        <Route path='/school-register' element={<SchoolRegister />} />
        {/* school-register ------> ????? */}
        <Route path='/a' element={<SchReg />} />
        {/* commented full file */}
        <Route path='/lead-enquiry' element={<LeadEnquiry />} />
        {/* parent-register pending */}
        <Route path='/sch-reg' element={<RegSch />} />
        <Route path="/" element={<Login />} />
        <Route path='/register' element={<FirstReg />} />
        <Route path='/school-activate' element={<SchoolActivate />} />
        <Route path='/alert-resp' element={<AlertPage />} />
        <Route path='/sch-enquiries' element={<SchoolEnquiries />} />
        <Route path='/dynamic' element={<Dynamic />} />
        <Route path='/input' element={<InputProps />} />
        <Route path='/bin-page' element={<BinPage />} />
        <Route path='/form' element={<Checkbox />} />
        <Route path='/schActivate' element={<SchActivate />} />
        <Route path='/couns-page' element={<CounsellorPage />} />
        <Route path='/create-camp' element={<CreateCampaign />} />
        <Route path='sch-admin' element={<SchoolAdmin />} />

        {/* Counsellor dashboard */}
        <Route path='/sch-couns-db' element={<SchoolCounsDashB />} />
        <Route path='/valid' element={<ValidationPage />} />
        <Route path='/get-form' element={<GetForm />} />
        <Route path='/enq-list' element={<CounsellorList />} />

        {/* school applications */}
        <Route path='/lead-info' element={<LeadInfo />} />
        <Route path='/applications' element={<Applications />} />
        <Route path='/results' element={<Results />} />
        <Route path='/payment' element={<Payments />} />
        <Route path='/approved' element={<Approved />} />
        <Route path='/admissions' element={<Admissions />} />

        {/* counsellor leads page for school */}
        <Route path='/counsellor-leads' element={<CounsellorLeads2 />} />

        {/* counsellor own leads */}
        <Route path='/camp-leads' element={<CampLeads2 />} />
        <Route path='/camp-analytics' element={<CampaignAnalytics />} />
        <Route path='/sch-analytics' element={<SchoolAnalytics />} />
        <Route path='/test-check' element={<CampaignForm />} />
        <Route path='/sch-campaign' element={<SchoolCampaign />} />

        {/* counsellor applications */}
        <Route path='/couns-apps' element={<CounsApps />} />
        <Route path='/couns-results' element={<CounsResults />} />
        <Route path='/couns-payment' element={<CounsPayment />} />
        <Route path='/couns-admissions' element={<CounsAdmission />} />
        <Route path='/sch-student' element={<SchoolStudent />} />
        {/* counsellor profile */}
        {/* <Route path='/counsellor-profile' element={<CounsProfile />} /> */}

        {/* counsellor lead details for school */}
        <Route path='/lead-details' element={<LeadDetails2 />} />
        <Route path='/couns-campaigns' element={<CounsCampaigns />} />
        <Route path='/sample' element={<Sample />} />
        <Route path='/widget' element={<SchoolAdmissionEnquiry />} />
        <Route path='/open' element={<Drawer />} />
        <Route path='/exam-schedule' element={<ExamScheduler />} />
        <Route path='/date' element={<DateTimePicker />} />
        <Route path='/seat-avail' element={<SeatAvailable />} />
        <Route path='/set-form' element={<SetApplicationForm />} />
        <Route path='/add-couns' element={<AddCounsellor />} />
        <Route path='/modal' element={<ExampleModal />} />
        <Route path='/filter' element={<FilterTable />} />
        {/* dummy */}
        <Route path='/count-couns' element={<CounsellorNumber />} />
        <Route path='/dummy-app' element={<DummyApplications />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/application-submitted" element={<ThankYou2 />} />

        {/* logout */}
        <Route path="/logout" element={<Logout />} />
        {/* this is dummy file calling counsellor list api */}
      </Routes>
    </div>
  )
}

export default App