import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import Constants from '../resource/Constants';

const url = Constants.Application.APPLIER_END_URL + "/lead-registration/"

const ParentRegister = () => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState("");
  const [password, setPassword] = useState("");
  const [comments, setComments] = useState("");
  const [location, setLocation] = useState("")

  const parentRegHandler = () => {
    let body = {
      parent_name: name,
      parent_contact: contact,
      parent_email: email,
      parent_photo: photo,
      parent_password: password,
      parent_comments: comments,
      permanent_location: location,
      "verification_url": "http://127.0.0.1:8007",
      "is_parent": 1
    }
    console.log(body);
    axios({
      method: 'post',
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      console.log(response);
    }).catch(function (error) {
      console.log(error);
    })
  }


  return (
    <section>
      <Sidebar />
      <div>

        <div className="page-wrapper">
          <div className="row w-50 ">
            <div className="col-xl-6 d-flex parent-form">
              <div className="card flex-fill parentForm">
                <div className="card-header">
                  <h5 className="card-title">Test form</h5>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Name</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='name' value={name} onChange={(e) => setName(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Contact</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='contact' value={contact} onChange={(e) => setContact(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Email</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Photo</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='photo' value={photo} onChange={(e) => setPhoto(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Password</label>
                      <div className="col-lg-9">
                        <input type="password" className="form-control" name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parent Comments</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='comments' value={comments} onChange={(e) => setComments(e.target.value)} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Parmanent Location</label>
                      <div className="col-lg-9">
                        <input type="text" className="form-control" name='location' value={location} onChange={(e) => setLocation(e.target.value)} />
                      </div>
                    </div>
                    <div className="text-end">
                      <button type="button" className="btn btn-primary" onClick={parentRegHandler}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>
  )
}

export default ParentRegister;