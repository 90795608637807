import { InstallDesktopSharp } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { BsToggleOn } from "react-icons/bs";
import Sidebar from "../Sidebar";
import { PlaceOfBirth } from "./FormFields";
import { ParentName } from "./FormFields";
import { MotherTongue } from "./FormFields";
import { ParentEmail } from "./FormFields";
import { ParentContact } from "./FormFields";
import { TempAddress } from "./FormFields";
import { PermAddress } from "./FormFields";
import { StudentReligion } from "./FormFields";
import { StudentCaste } from "./FormFields";
import { StudentNationality } from "./FormFields";
import { StudenClass } from "./FormFields";
import { AdharNumber } from "./FormFields";
import { BloodGroup } from "./FormFields";
import { RelativeInSchool } from "./FormFields";
import { LastAttend } from "./FormFields";
import { AcademicYear } from "./FormFields";
import { City } from "./FormFields";
import { StdPicture } from "./FormFields";
import { PrevSch } from "./FormFields";
import { ParentPhotos } from "./FormFields";
import { ChildDOB } from "./FormFields";
import { MedicalCertify } from "./FormFields";
import { AdharCardChild } from "./FormFields";
import { SelfAtt } from "./FormFields";
import { TransferCert } from "./FormFields";
import { CasteCert } from "./FormFields";
import Switch from "@mui/material/Switch";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form';
import Constants from "../../resource/Constants";

const MySwal = withReactContent(Swal)

const APPLICATIONURL = Constants.Application.APPLIER_END_URL + "/create-school-custom-form/";
const GETFORMURL = Constants.Application.APPLIER_END_URL + "/get-school-custom-form/";

const TAG = "Checkbox.jsx";
const instId = localStorage.getItem("applier.inst.id");
const FORM_OBJECT = {
    institute_id: instId,
    fields: [
        {
            //admission year
            field_name: "admission_year",
            field_type: "text",
            field_label: "Admission Year",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 0,
        },
        {
            //name of the student
            field_name: "student_name",
            field_type: "text",
            field_label: "Name of the Student",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //Student Gender
            field_name: "student_gender",
            field_type: "radio",
            field_label: "Student Gender",
            field_value: '',
            max_length: 5,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
            options: ['M', 'F']
        },
        {
            //Date of Birth
            field_name: "student_dob",
            field_type: "date",
            field_label: "Date of Birth",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //City
            field_name: "city",
            field_type: "text",
            field_label: "City",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //Enrollment in Grade
            field_name: "student_enroll",
            field_type: "text",
            field_label: "Enrollment in Grade",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //Campus
            field_name: "campus",
            field_type: "text",
            field_label: "Campus",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },

        {
            //student picture
            field_name: "student_picture",
            field_type: "file",
            field_label: "Upload Student Image",
            field_value: '',
            max_length: 20,
            max_file_size: 10,
            accept_type: "jpeg/png/gif",
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //permanent address
            field_name: "perm_addr",
            field_type: "textarea",
            field_label: "Permanent Address",
            field_value: '',
            max_length: 500,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //last school attended
            field_name: "schl_attend",
            field_type: "text",
            field_label: "Last School Attended",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //Caste
            field_name: "caste",
            field_type: "text",
            field_label: "Caste",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //Religion
            field_name: "religion",
            field_type: "text",
            field_label: "Religion",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },
        {
            //Mother Tongue
            field_name: "mother_tongue",
            field_type: "text",
            field_label: "Mother Tongue",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },

        {
            //Naionality
            field_name: "nationality",
            field_type: "text",
            field_label: "Nationality",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 1,
        },

        {
            //student email
            field_name: "student_email",
            field_type: "email",
            field_label: "Student Email",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //student contact
            field_name: "student_contact",
            field_type: "text",
            field_label: "Student Contact",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //student place of birth
            field_name: "student_placeofbirth",
            field_type: "text",
            field_label: "Student Place of Birth",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //student temp address
            field_name: "student_temp_addr",
            field_type: "textarea",
            field_label: "Student Temporary Address",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //student adhar number
            field_name: "student_adhar_number",
            field_type: "text",
            field_label: "Student Adhaar Number ",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //blood group
            field_name: "student_blood_group",
            field_type: "text",
            field_label: "Student Blood Group",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },
        {
            //resides with
            field_name: "resides_with",
            field_type: "text",
            field_label: "Student Resides With",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 1,
        },

        // PARENT DETAILS
        {
            //Parent Name
            field_name: "parent_name",
            field_type: "text",
            field_label: "Parent Name",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 2,
        },
        {
            //Parent Contact
            field_name: "parent_contact",
            field_type: "text",
            field_label: "Parent Contact",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: true,
            isEditable: false,
            groupIndex: 2,
        },

        {
            //parent email
            field_name: "parent_email",
            field_type: "email",
            field_label: "Parent Email",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Father Name
            field_name: "father_name",
            field_type: "text",
            field_label: "Father Name",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Father Contact
            field_name: "father_contact",
            field_type: "text",
            field_label: "Father Contact",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },

        {
            //Father email
            field_name: "father_email",
            field_type: "email",
            field_label: "Father Email",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Mother Name
            field_name: "mother_name",
            field_type: "text",
            field_label: "Mother Name",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Mother Contact
            field_name: "mother_contact",
            field_type: "text",
            field_label: "Mother Contact",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },

        {
            //Mother email
            field_name: "mother_email",
            field_type: "email",
            field_label: "Mother Email",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Guardian Name
            field_name: "guard_name",
            field_type: "text",
            field_label: "Guardian Name",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },
        {
            //Mother Contact
            field_name: "guard_contact",
            field_type: "text",
            field_label: "Guardian Contact",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },

        {
            //Mother email
            field_name: "guard_email",
            field_type: "email",
            field_label: "Guardian Email",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 2,
        },

        // DOCUMENTS
        {
            // Father photo
            field_name: "father_photo",
            field_type: "file",
            field_label: "Father photo",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // Mother photo
            field_name: "mother_photo",
            field_type: "file",
            field_label: "Mother photo",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },

        {
            // Guardian photo
            field_name: "guardian_photo",
            field_type: "file",
            field_label: "Guardian photo",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // DOB certificate
            field_name: "student_dob_cert",
            field_type: "file",
            field_label: "Student DOB Certificate",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // Child Aadhaar
            field_name: "student_aadhaar",
            field_type: "file",
            field_label: "Student Aadhaar",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // Student TC
            field_name: "student_tc",
            field_type: "file",
            field_label: "Student TC",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // Student Caste Certificate
            field_name: "student_caste_cert",
            field_type: "file",
            field_label: "Student Caste Certificate",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        },
        {
            // Student Caste Certificate
            field_name: "student_res_cert",
            field_type: "file",
            field_label: "Student Residence Certificate",
            field_value: '',
            max_length: 100,
            max_file_size: 10,
            isSelected: false,
            isEditable: true,
            groupIndex: 3,
        }
    ],
};

const saveForm = () => {
    console.log(FORM_OBJECT);
};

console.log("label:", FORM_OBJECT.field_label);

const FORM_OBJECT_INPUT = (props) => {
    const { obj } = props;
    return (
        <>
            <div className="custom2">
                {/* <input type="text" placeholder={props.placeholder} /> */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{obj.field_label}</Form.Label>
                    <Form.Control type="text" placeholder={obj.field_name} />
                </Form.Group>
            </div>
        </>
    );
};

const FORM_OBJECT_TEXTAREA = (props) => {
    const { obj } = props;
    return (
        <>
            <div className="custom2">
                {/* <textarea placeholder={props.placeholder}>this is text area </textarea> */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>{obj.field_label}</Form.Label>
                    <Form.Control as="textarea" placeholder={obj.field_name} rows={3} />
                </Form.Group>

            </div>
        </>
    );
};

const FORM_OBJECT_EMAIL = (props) => {
    const { obj } = props;
    return (
        <>
            <div className="custom2">
                {/* <input type="email" placeholder={props.placeholder} /> */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{obj.field_label}</Form.Label>
                    <Form.Control type="email" placeholder={obj.field_name} />
                </Form.Group>
            </div>
        </>
    );
};

const FORM_OBJECT_RADIO = (props) => {
    const { obj } = props;
    const count = obj.options.length;
    return (
        <>
            <Form.Label>{obj.field_label}</Form.Label>

            <div className="custom2 d-flex flex-row ">
                {/* <input type="email" placeholder={props.placeholder} /> */}

                {obj.options.map((op, idx) => {
                    return (
                        <div key={`default-radio`} className="me-3">
                            <Form.Check
                                type={'radio'}
                                id={`default-${op}`}
                                label={op}
                            />
                        </div>
                    )
                })}


            </div>
        </>
    );
};

const FORM_OBJECT_DATE = (props) => {
    const { obj } = props;
    return (
        <>
            <div className="custom2">
                {/* <input type="email" placeholder={props.placeholder} /> */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{obj.field_label}</Form.Label>
                    <Form.Control type="date" placeholder={obj.field_name} />
                </Form.Group>
            </div>
        </>
    );
};

const FORM_OBJECT_FILE = (props) => {
    const { obj } = props;
    return (
        <>
            <div className="custom2">
                {/* <input type="email" placeholder={props.placeholder} /> */}
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{obj.field_label}</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
            </div>
        </>
    );
};

const label = { inputProps: { "aria-label": "Switch demo" } };

const StudentName = () => {
    return <input type="text" placeholder="Student Name" />;
};
const StudentEmail = () => {
    return <input type="email" placeholder="Student Email" />;
};
const StudentContact = () => {
    return <input type="number" placeholder="Student Contact" />;
};
const StudentDOB = () => {
    return <input type="text" placeholder="Student Date of birth" />;
};
const StudentGender = () => {
    return <input type="text" placeholder="Male or Female" />;
};

const SetApplicationForm = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isBirth, setIsBirth] = useState(false);
    const [isGender, setIsGender] = useState(false);
    const [isPlace, setIsPlace] = useState(false);
    const [isparentName, setIsParenName] = useState(false);
    const [isMotherTongue, setIsMotherTongue] = useState(false);
    const [isParentEmail, setIsParenEmail] = useState(false);
    const [isParentContact, setIsParentContact] = useState(false);
    const [isTempAdd, setIsTempAdd] = useState(false);
    const [isPermAdd, setIsPermAdd] = useState(false);
    const [isReligion, setIsReligion] = useState(false);
    const [isCaste, setIsCaste] = useState(false);
    const [isNational, setIsNational] = useState(false);
    const [isClass, setIsClass] = useState(false);
    const [isAdhar, setIsAdhar] = useState(false);
    const [isBlood, setIsBlood] = useState(false);
    const [isRelative, setIsRelative] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [isAcademic, setIsAcademic] = useState(false);
    const [isCity, setIsCity] = useState(false);
    const [isPicture, setIsPicture] = useState(false);
    const [isPrevSch, setIsPrevSch] = useState(false);
    const [isParentPhotos, setIsParentPhotos] = useState(false);
    const [isChildDOB, setIsChildDOB] = useState(false);
    const [isMedicalC, setIsMedicalC] = useState(false);
    const [isChildAdhar, setIsChildAdhar] = useState(false);
    const [isSelfAtt, setIsSelfAtt] = useState(false);
    const [isTransC, setIsTransC] = useState(false);
    const [isCasteC, setCasteC] = useState(false);

    const [isInstId, setInstId] = useState(instId);
    const [formObject, setFormObject] = useState(FORM_OBJECT);

    const applicationHandler = async () => {
        let body = formObject;

        let token = localStorage.getItem("applier.inst.token");

        const headers = {
            headers: { Authorization: `${token}` },
        };


        MySwal.fire({
            title: 'Please wait..',
            text: 'Saving the form setup..',
            icon: 'info',
            confirmButtonText: 'OK',
            didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                MySwal.showLoading()
            },
        }).then(() => {
            // do something..
        })

        console.log(TAG, "applicationHandler() headers " + JSON.stringify(headers));
        const response = await axios.post(APPLICATIONURL, body, headers);
        console.log(TAG, "loginHandler() response " + JSON.stringify(response));


        MySwal.fire({
            title: 'Success!',
            text: 'Your form was saved successfully..',
            icon: 'success',
            confirmButtonText: 'OK'
        })

    };

    const formSetupHandler = async () => {
        let token = localStorage.getItem("applier.inst.token");
        const config = {
            headers: { Authorization: `${token}` },
        };

        const response = await axios.get(GETFORMURL, config);
        console.log(TAG, "formSetupHandler() response " + JSON.stringify(response));

        const data3 = (response.data && response.data.data) ? response.data.data.field_obj : null;
        if (data3) {
            setFormObject(data3);
        }

    };

    useEffect(() => {
        // console.log(formSetupHandler());

        formSetupHandler();
    }, []);

    const LEFT_BAR1 = (props) => {
        return (
            <div
                className="left-section"
                style={{ width: "250px", height: "auto", position: "fixed" }}
            >
                {/* ------------------------------Personal detail section------------------------------------ */}
                <div className="sect-title">
                    <h5>Student Details</h5>

                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            name="checkbox-name"
                            onChange={stdImage}
                            checked={isPicture}
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Student Picture</h6>
                    </div>

                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            name="checkbox-name"
                            onChange={permAdd}
                            checked={isPermAdd}
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Permanent Address</h6>
                    </div>
                </div>
                {/* ------------------------------end personal detail section------------------------------------ */}
                {/* Educational Background */}
                <h5>Educational Background</h5>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={lastAttend}
                        checked={isLast}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Last School Attended</h6>
                </div>
                {/* Educational Background */}

                {/* Parent's information  */}
                <h5>Parent's Information</h5>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={parentEmail}
                        checked={isParentEmail}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Parent Email</h6>
                </div>

                {/* end Parent's information  */}

                {/* Student Resides with */}
                <h5>Student Other Information</h5>
                {/* Student Resides with */}

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={emailHandler}
                        checked={isEmail}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Email</h6>
                </div>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={contactHandler}
                        checked={isContact}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Contact</h6>
                </div>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={placeHandler}
                        checked={isPlace}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Place of birth</h6>
                </div>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={tempAddress}
                        checked={isTempAdd}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Temporary Address</h6>
                </div>

                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={adhaar}
                        checked={isAdhar}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Adhaar Number</h6>
                </div>
                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={bloodGroup}
                        checked={isBlood}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Blood Group</h6>
                </div>
                <div style={{ display: "flex" }}>
                    <input
                        type="checkbox"
                        name="checkbox-name"
                        onChange={relative}
                        checked={isRelative}
                        style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                    />
                    <h6>Student Resides With</h6>
                </div>
                <div>
                    <h5> Documents Upload</h5>
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            name="checkbox-name"
                            onChange={parentPhotoUploads}
                            checked={isParentPhotos}
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Parents Photo's</h6>
                    </div>
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            name="checkbox-name"
                            onChange={childBirthC}
                            checked={isChildDOB}
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Child DOB Certificate</h6>
                    </div>
                    {/* <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Child Medical Certificate</h6>
                    </div> */}
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Child Aadhar Card</h6>
                    </div>
                    {/* <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Self Attested Copies</h6>
                    </div> */}
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Transfer Certificate</h6>
                    </div>
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            style={{ position: "relative", top: "-5px", marginLeft: "10px" }}
                        />
                        <h6>Caste Certificate</h6>
                    </div>
                </div>
            </div>
        );
    };

    const APPLICATION_FORM1 = () => {
        return (
            <div
                className="right-section"
                style={{
                    position: "absolute",
                    width: "1000px",
                    padding: "50px",
                    marginLeft: "250px",
                    borderRadius: "10px",

                }}
            >
                <h3
                    style={{
                        textAlign: "center",
                        marginTop: "-20px",
                        paddingBottom: "20px",
                        fontSize: 'large'

                    }}
                >
                    Application Form Preview
                </h3>


                <div style={{ display: "flex", gap: "10px" }}>

                    <div >
                        <h6>Personal Detail of Student</h6>
                        <div className="ind-inp">
                            <input type="text" placeholder="Name of the Student" />
                        </div>
                        <div className="ind-inp">
                            <input type="text" placeholder="Student Gender" />
                        </div>
                        <div className="ind-inp">{isEmail ? <StudentEmail /> : ""}</div>

                        <div className="ind-inp">{isContact ? <StudentContact /> : ""}</div>
                        <div className="ind-inp">
                            <input type="text" placeholder="Date of Birth" />
                        </div>
                        <div className="ind-inp">
                            <input type="text" placeholder="City" />
                        </div>

                        <div className="ind-inp">
                            <input type="text" placeholder="Enrollment in Grade" />
                        </div>
                        <div className="ind-inp">
                            <input type="text" placeholder="Campus" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <h6>Educational Background</h6>
                            <div className="ind-inp">{isPrevSch ? <PrevSch /> : ""}</div>
                            <div className="ind-inp">{isLast ? <LastAttend /> : ""}</div>
                        </div>

                        <div>
                            <h6>Parent's Information</h6>
                            <div className="ind-inp">
                                <input type="text" placeholder="Parent Name" />
                            </div>
                            <div className="ind-inp">
                                <input type="text" placeholder="Parent Contact" />
                            </div>

                            <div className="ind-inp">{isparentName ? <ParentName /> : ""}</div>

                            <div className="ind-inp">
                                {isParentEmail ? <ParentEmail /> : ""}
                            </div>
                            <div className="ind-inp">{isAdhar ? <AdharNumber /> : ""}</div>
                            <div className="ind-inp">{isBlood ? <BloodGroup /> : ""}</div>
                            <div className="ind-inp">
                                {isParentContact ? <ParentContact /> : ""}
                            </div>
                            <div className="ind-inp">
                                {isRelative ? <RelativeInSchool /> : ""}
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div>

                        <div className="ind-inp">
                            {isMotherTongue ? <MotherTongue /> : ""}
                        </div>



                        <div className="ind-inp">
                            {isNational ? <StudentNationality /> : ""}
                        </div>
                    </div>
                    <div>
                        <div className="ind-inp">
                            {isReligion ? <StudentReligion /> : ""}
                        </div>
                        <div className="ind-inp">{isCaste ? <StudentCaste /> : ""}</div>

                        <div className="ind-inp">{isClass ? <StudenClass /> : ""}</div>



                    </div>

                    <div>
                        <div>
                            <h6>Other Information</h6>
                            <div className="ind-inp">{isPlace ? <PlaceOfBirth /> : ""}</div>
                            <div className="ind-inp">
                                <input type="text" placeholder="Mother Tongue" />
                            </div>
                            <div className="ind-inp">
                                <input type="text" placeholder="Caste" />
                            </div>
                            <div className="ind-inp">
                                <input type="text" placeholder="Religion" />
                            </div>
                            <div className="ind-inp">
                                <input type="text" placeholder="Nationality" />
                            </div>
                            <div className="ind-inp">
                                {isAcademic ? <AcademicYear /> : ""}
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="ind-inp">{isPicture ? <StdPicture /> : ""}</div>
                        <div className="ind-inp">{isPermAdd ? <PermAddress /> : ""}</div>
                        <div className="ind-inp">{isTempAdd ? <TempAddress /> : ""}</div>



                        {isParentPhotos ?
                            <div>
                                <h6>Documents Upload</h6>
                                <div className="ind-inp">
                                    <ParentPhotos />
                                </div>
                            </div>
                            : <></>}

                        {isChildDOB ?
                            <div>
                                <h6>Child Date of Birth Certificate</h6>
                                <div className="ind-inp"> <ChildDOB /> </div>
                            </div>
                            : <></>}
                    </div>
                    <div>
                        <button
                            className="save-btn1"
                            onClick={applicationHandler}
                        >
                            Set Form
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const onUpdateFieldChecked = (e, fieldName) => {
        let isChecked = e.target.checked;
        console.log(TAG, "onUpdateFieldChecked() isChecked " + isChecked);
        console.log(TAG, "onUpdateFieldChecked() fieldName " + fieldName);

        // update the object
        let newFormObject = JSON.parse(JSON.stringify(formObject)); // full copy

        newFormObject.fields.map((field, index) => {
            console.log(TAG, "onUpdateFieldChecked() field name " + field.field_name);
            if (field.field_name == fieldName) {
                console.log(TAG, "onUpdateFieldChecked(): match found!");

                field.isSelected = isChecked;
            }
        });

        setFormObject(newFormObject);

        console.log(
            TAG,
            "onUpdateFieldChecked(): setstate completed " +
            JSON.stringify(newFormObject)
        );
    };

    const SELECT_FIELD = (props) => {
        const { field } = props;
        let isChecked = field.isSelected;
        let isDisabled = !field.isEditable;
        console.log(TAG, 'isDisabled ' + isDisabled);
        return (
            <div className="formF" style={{ display: "flex" }}>
                <input
                    type="checkbox"
                    name="checkbox-name"
                    onChange={(e) => {
                        onUpdateFieldChecked(e, field.field_name);
                    }}
                    checked={isChecked}
                    style={{
                        position: "relative",
                        top: "-5px",
                        marginLeft: "10px",
                    }}
                    disabled={isDisabled}
                />
                <h6 className="forInp">{field.field_label}</h6>
            </div>
        )
    }

    const LEFT_BAR = (props) => {
        //const { formObject } = props;

        let groupedFields = groupObject();
        let admYearFields = groupedFields[0];
        let stuFields = groupedFields[1];
        let parentFields = groupedFields[2];
        let docFields = groupedFields[3];

        console.log(TAG, "LEFTBAR is rendered again..");
        return (
            <>
                <div
                    className="left-section"
                    style={{ width: "25%", height: "auto", position: "relative", overflowY: 'scroll' }}
                >
                    {/* ------------------------------Personal detail section------------------------------------ */}
                    <div className="sect-title">
                        <h4>Select Fields</h4>
                        {/* controls */}

                        <h6>Admission for</h6>
                        {admYearFields.map((field, index) => {

                            return (
                                <SELECT_FIELD field={field} />
                            );
                        })}

                        <hr className="divider" style={{ margin: 10 }} />

                        <h6>Student details</h6>
                        {stuFields.map((field, index) => {

                            return (
                                <SELECT_FIELD field={field} />
                            );
                        })}

                        <hr className="divider" style={{ margin: 10 }} />

                        <h6>Parent details</h6>
                        {parentFields.map((field, index) => {

                            return (
                                <SELECT_FIELD field={field} />
                            );
                        })}


                        <hr className="divider" style={{ margin: 10 }} />
                        <h6>Documents</h6>
                        {docFields.map((field, index) => {

                            return (
                                <SELECT_FIELD field={field} />
                            );
                        })}
                    </div>
                </div>
            </>
        );
    };

    function groupObject() {
        var result = formObject.fields.reduce(function (r, a) {
            r[a.groupIndex] = r[a.groupIndex] || [];
            r[a.groupIndex].push(a);
            return r;
        }, Object.create(null));

        return result;
    }

    const FORM_FIELD = (props) => {

        const { obj } = props;
        console.log(TAG, 'SetApplicationForm.jsx FORM_FIELD: ' + JSON.stringify(obj));
        if (obj.isSelected) {
            switch (obj.field_type) {
                case "text":
                    console.log(TAG, "APPLICATION_FORM2 rendering text");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_INPUT obj={obj} placeholder={obj.field_label} />
                        </div>
                    );

                case "textarea":
                    console.log(TAG, "APPLICATION_FORM2 rendering textarea");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_TEXTAREA obj={obj} placeholder={obj.field_label} />
                        </div>
                    );
                case "email":
                    console.log(TAG, "APPLICATION_FORM2 rendering email");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_EMAIL obj={obj} placeholder={obj.field_label} />
                        </div>
                    );
                case 'radio':
                    console.log(TAG, "APPLICATION_FORM2 rendering radio");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_RADIO obj={obj} placeholder={obj.field_label} />
                        </div>
                    );
                case 'date':
                    console.log(TAG, "APPLICATION_FORM2 rendering date");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_DATE obj={obj} placeholder={obj.field_label} />
                        </div>
                    );

                case 'file':
                    console.log(TAG, "APPLICATION_FORM2 rendering file");
                    return (
                        <div className="col-md-6 col-lg-6">
                            <FORM_OBJECT_FILE obj={obj} />
                        </div>
                    )
                default:
                    return <></>
            }
        } else {
            return <></>;
        }
    }

    const APPLICATION_FORM = (props) => {
        let objIdx = -1;

        let groupedFields = groupObject();
        let admYearFields = groupedFields[0];
        let stuFields = groupedFields[1];
        let parentFields = groupedFields[2];
        let docFields = groupedFields[3];
        console.log(TAG, 'APPLICATION_FORM groupedFields: ' + JSON.stringify(groupedFields));
        //const { formObject } = props;
        return (
            <>
                <div
                    className="right-section"
                    style={{
                        position: "relative",
                        // left: "400px",
                        width: "75%",
                        padding: "50px",
                        // marginLeft: "50px",
                        borderRadius: "10px",
                    }}
                >

                    <div style={{ display: "flex", flexDirection: 'column', gap: "10px" }}>
                        {/* admission for */}
                        <div>
                            <div className="ind-inp">
                                <h4>Admission for</h4>
                            </div>

                            <div className="row">
                                {admYearFields.map((obj, index) => {
                                    // let formObj = null;
                                    console.log(
                                        TAG,
                                        "APPLICATION_FORM2 obj field_type " +
                                        JSON.stringify(obj.field_type)
                                    );
                                    return <FORM_FIELD obj={obj} />
                                })}
                            </div>
                        </div>

                        {/* Student details */}
                        <div>
                            <div className="ind-inp">
                                <h4>Student details</h4>
                            </div>

                            <div className="row">
                                {stuFields.map((obj, index) => {
                                    // let formObj = null;
                                    console.log(
                                        TAG,
                                        "APPLICATION_FORM2 obj field_type " +
                                        JSON.stringify(obj.field_type)
                                    );
                                    return <FORM_FIELD obj={obj} />
                                })}
                            </div>
                        </div>


                        {/* Student information */}
                        <div>
                            <div className="ind-inp">
                                <h4>Parent's / Guardian's information</h4>
                            </div>

                            <div className="row">
                                {parentFields.map((obj, index) => {
                                    // let formObj = null;
                                    console.log(
                                        TAG,
                                        "APPLICATION_FORM2 obj field_type " +
                                        JSON.stringify(obj.field_type)
                                    );

                                    return <FORM_FIELD obj={obj} />
                                })}
                            </div>
                        </div>


                        {/* Parent information */}
                        <div>
                            <div className="ind-inp">
                                <h4>Documents to upload</h4>
                            </div>

                            {docFields && docFields.length ?
                                <div className="row">
                                    {docFields.map((obj, index) => {
                                        // let formObj = null;
                                        console.log(
                                            TAG,
                                            "APPLICATION_FORM2 obj field_type " +
                                            JSON.stringify(obj.field_type)
                                        );

                                        return <FORM_FIELD obj={obj} />
                                    })}
                                </div>
                                :
                                <>
                                    <p>You have not selected any document fields!</p>
                                </>
                            }
                        </div>

                        <div>
                            <button className="save-btn1" onClick={applicationHandler}>
                                Save Template
                            </button>
                        </div>
                    </div>

                </div>
            </>
        );
    };

    const studentName = (event) => {
        setIsChecked(event.target.checked);
    };
    const stdImage = (e) => {
        setIsPicture(e.target.checked);
    };
    const prevSchool = (e) => {
        setIsPrevSch(e.target.checked);
    };
    const emailHandler = (event) => {
        setIsEmail(event.target.checked);
    };
    const contactHandler = (event) => {
        setIsContact(event.target.checked);
    };

    const birthHandler = (event) => {
        setIsBirth(event.target.checked);
    };
    const genderHandler = (event) => {
        setIsGender(event.target.checked);
    };
    const placeHandler = (event) => {
        setIsPlace(event.target.checked);
    };
    const parentNameHandler = (event) => {
        setIsParenName(event.target.checked);
    };
    const motherTong = (event) => {
        setIsMotherTongue(event.target.checked);
    };
    const parentEmail = (event) => {
        setIsParenEmail(event.target.checked);
    };
    const parentContact = (event) => {
        setIsParentContact(event.target.checked);
    };
    const tempAddress = (event) => {
        setIsTempAdd(event.target.checked);
    };
    const permAdd = (event) => {
        setIsPermAdd(event.target.checked);
    };
    const religion = (event) => {
        setIsReligion(event.target.checked);
    };
    const caste = (event) => {
        setIsCaste(event.target.checked);
    };
    const national = (event) => {
        setIsNational(event.target.checked);
    };
    const studentClass = (event) => {
        setIsClass(event.target.checked);
    };
    const adhaar = (event) => {
        setIsAdhar(event.target.checked);
    };
    const bloodGroup = (event) => {
        setIsBlood(event.target.checked);
    };
    const relative = (event) => {
        setIsRelative(event.target.checked);
    };
    const lastAttend = (event) => {
        setIsLast(event.target.checked);
    };
    const acdemicYear = (e) => {
        setIsAcademic(e.target.checked);
    };
    const stdCity = (e) => {
        setIsCity(e.target.checked);
    };
    const parentPhotoUploads = (e) => {
        setIsParentPhotos(e.target.checked);
    };
    const childBirthC = (e) => {
        setIsChildDOB(e.target.checked);
    };

    return (
        <>
            <Sidebar />
            <div
                className="full-section"
                style={{ width: "75%", height: "auto", borderRadius: "10px" }}
            >
                <LEFT_BAR />
                <APPLICATION_FORM />
                {/* <button style={{display:'flex', justifyContent:'center'}}>Submit</button> */}
                {/* <APPLICATION_FORM /> */}
            </div>
        </>
    );
};
export default SetApplicationForm;
