import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Sidebar from './Sidebar'
import { MdOutlineDeleteForever } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { BiSort } from 'react-icons/bi';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import Constants from '../resource/Constants';
import Modal from 'react-bootstrap/Modal';
import INSTITUTEAPI from '../http/institute';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Avatar from '@mui/material/Avatar';

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

// import 'sweetalert2/src/sweetalert2.scss'
const MySwal = withReactContent(Swal);
const COUNSLISTURL = Constants.Application.APPLIER_END_URL + '/school-list-of-enquiries/'

// const TableHead =()=>{
//   return(
//     <div>

//     </div>
//   )
// }

const TAG = 'CounsellorList.jsx';

const CounsellorList = (props) => {
  const { lead, cons } = props;

  // let oConsId = lead.councellor_id;

  const [oConsId, setOConsId] = useState(lead.councellor_id);
  console.log(TAG, 'CounsellorList lead ' + JSON.stringify(lead));
  console.log(TAG, 'CounsellorList cons ' + JSON.stringify(cons));

  const handleChangeCons = async (event) => {
    const { name, value } = event.target;
    console.log(TAG, 'CounsellorList handleChangeCons(): name ' + name)
    console.log(TAG, 'CounsellorList handleChangeCons(): value ' + value)

    const leadId = lead.enquiry_id;
    const consId = value;

    console.log(TAG, 'CounsellorList handleChangeCons(): leadId ' + leadId);
    console.log(TAG, 'CounsellorList handleChangeCons(): consId ' + consId);

    const body = {
      counsellor_id: consId,
      lead_id: leadId
    }

    // swal show and update on accept
    MySwal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const resp = await INSTITUTEAPI.setCounsellor(body);
        console.log(TAG, 'CounsellorList handleChangeCons(): resp ' + JSON.stringify(resp));

        Swal.fire('Saved!', '', 'success')
        // update state
        setOConsId(consId);

        // reload page
        props.reloadEnquiries();

        // reset 
        setOConsId(0);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  return (
    <Form.Select aria-label="Default select example"
      onChange={handleChangeCons}
      defaultValue={oConsId}
      value={oConsId}
    >
      <option value={0}>Choose counsellor</option>
      {(cons && cons.length) ?
        <>
          {cons.map((con, idx) => {
            let sel = 'false';

            let conId = con.councellor_id;
            if (oConsId == conId)
              sel = 'selected';
            return <option value={conId} selected={sel}>{con.councellor_name}</option>
          })}
          {/* 
           <option value="2">Two</option>
           <option value="3">Three</option> */}
        </>
        :
        <></>
      }
    </Form.Select>
  );
}

const LEADROW = (props) => {
  const { lead, cons } = props;

  let classStd = '-';
  switch (lead.student_class_applied) {
    case 13:
      classStd = 'L.K.G';
      break;
    case 14:
      classStd = 'U.K.G';
      break;
    case 15:
      classStd = 'Nursery';
      break;
    default:
      classStd = lead.student_class_applied;
      break;
  }

  console.log(TAG, 'LEADROW: lead ' + JSON.stringify(lead));
  return (
    <tr>
      <td>{lead.enquiry_id}</td>
      <td>
        <h2 className="table-avatar">
          <Link to={`/lead-info?enquiry_id=${lead.enquiry_id}`} className="avatar avatar-sm me-2">
            {lead.Student_name}
          </Link>

        </h2>
      </td>
      <td>{classStd} </td>
      <td>{lead.created_date}</td>
      <td style={{ width: '20%' }}>
        {/* <select>
          <option>Jeffrey Wong</option>
          <option>Fields Malynne</option>
          <option>	Jeffrey Scott</option>
          <option>J Shaffer</option>
        </select> */}
        <CounsellorList
          cons={cons}
          lead={lead}
          reloadEnquiries={props.reloadEnquiries} />
      </td>


      <td className="text-end" style={{ width: '10px' }}>
        {/* <div className="dropdown dropdown-action">
          <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
          <div className="dropdown-menu dropdown-menu-end">
            <a className="dropdown-item" href="javascript:void(0)"
              onClick={() => props.onShowEditForm(lead)}><i className="far fa-edit me-2"></i>Edit</a>
            <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

          </div>
        </div> */}

        <a className="" href="javascript:void(0)" title="Edit Enquiry"
          onClick={() => props.onShowEditForm(lead)}><i className="far fa-edit me-2 text-primary"></i></a>
      </td>
    </tr>

  )
}

const LEADSLIST = (props) => {
  const { enquiryList, cons } = props;
  return (
    (enquiryList && enquiryList.length) ?
      <>
        <div className="table-responsive" >
          <table className="table border-0 star-student table-center mb-0 datatable" style={{ border: "1px solid black" }}>
            <thead className="student-thread">
              <tr>
                <th>Enquiry Id <BiSort /></th>
                <th>Name <BiSort /></th>
                <th>Grade <BiSort /></th>
                <th>Date <BiSort /></th>
                <th>Assign Counsellor <BiSort /></th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {enquiryList.map((lead, idx) => {
                return <LEADROW
                  lead={lead}
                  idx={idx}
                  key={idx}
                  cons={cons}
                  onShowEditForm={(lead) => props.onShowEditForm(lead)}
                  reloadEnquiries={props.reloadEnquiries}
                />
              })}

              {/*
          <tr>
            <td>PRE2209</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Siva Shankar
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>2 Feb 2002</td>
            <td>
              <select>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>


            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2213</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Madhu bala
                </Link>

              </h2>
            </td>
            <td>8 </td>
            <td>3 June 2010</td>
            <td>
              <select>
                <option>Fields Malynne</option>
                <option>Jeffrey Wong</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2143</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Rahul Rana
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>12 Apr 2002</td>
            <td>
              <select>
                <option>	Jeffrey Scott</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE2431</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Koushik
                </Link>

              </h2>
            </td>
            <td>11 </td>
            <td>24 Feb 2000</td>
            <td>
              <select>
                <option>J Shaffer</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>

            <td>PRE1534</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Lawrence
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>22 Jul 2006</td>
            <td>
              <select>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>	Jeffrey Scott</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>

          <tr>

            <td>PRE1234</td>
            <td>
              <h2 className="table-avatar">
                <Link to='/sch-student' className="avatar avatar-sm me-2">
                  Jasmin
                </Link>

              </h2>
            </td>
            <td>10 </td>
            <td>26 Apr 1994</td>
            <td>
              <select>
                <option>	Jeffrey Scott</option>
                <option>Jeffrey Wong</option>
                <option>Fields Malynne</option>
                <option>J Shaffer</option>
              </select>
            </td>
            <td className="text-end">
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>
                </div>
              </div>
            </td>
          </tr>
          */}

            </tbody>
          </table>
        </div>
      </>
      :
      <>
        <Alert variant="warning">
          <Alert.Heading>No results!</Alert.Heading>
          <p>
            No leads are shown here as they have not matched the criteria. Start adding now!
          </p>
          <hr />
          <p className="mb-0">
            Leads can be achieved by adding using the button above or using various social media campaigns.
          </p>
        </Alert>
      </>
  )
}

const AddLeadDialog2 = () => {
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AddLeadForm />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

const AddLeadForm = (props) => {

  const {
    studentName,
    studentGender,
    parentName,
    classApplied,
    contact,
    parentEmail,
    isParent,
    currLead
  } = props;

  // console.log(TAG, 'AddLeadForm studentGender ' + studentGender);
  console.log(TAG, 'AddLeadForm currLead ' + JSON.stringify(currLead));

  let studentName2 = (currLead) ? currLead.Student_name : studentName;
  let studentGender2 = (currLead) ? currLead.student_Gender : studentGender;
  let parentName2 = (currLead) ? currLead.parent_name : parentName;
  let classApplied2 = (currLead) ? currLead.student_class_applied : classApplied;
  let contact2 = (currLead) ? currLead.parent_contact : contact;
  let parentEmail2 = (currLead) ? currLead.parent_email : parentEmail;
  let isParent2 = (currLead) ? currLead.is_parent : isParent;

  /*
    console.log(TAG, 'AddLeadForm studentName ' + studentName)
    console.log(TAG, 'AddLeadForm studentGender ' + studentGender)
    console.log(TAG, 'AddLeadForm parentName ' + parentName)
    console.log(TAG, 'AddLeadForm classApplied ' + classApplied)
    console.log(TAG, 'AddLeadForm contact ' + contact)
    console.log(TAG, 'AddLeadForm parentEmail ' + parentEmail)
    console.log(TAG, 'AddLeadForm isParent ' + isParent)
  */

  console.log(TAG, 'AddLeadForm studentName2 ' + studentName2)
  console.log(TAG, 'AddLeadForm studentGender2 ' + studentGender2)
  console.log(TAG, 'AddLeadForm parentName2 ' + parentName2)
  console.log(TAG, 'AddLeadForm classApplied2 ' + classApplied2)
  console.log(TAG, 'AddLeadForm contact2 ' + contact2)
  console.log(TAG, 'AddLeadForm parentEmail2 ' + parentEmail2)
  console.log(TAG, 'AddLeadForm isParent2 ' + isParent2)

  let classAppliedStr = classApplied2;
  switch (classApplied) {
    case 13:
      classAppliedStr = 'L.K.G';
      break;
    case 14:
      classAppliedStr = 'U.K.G';
      break;
    case 15:
      classAppliedStr = 'Nursery';
      break;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const setClassApplied = (cls) => {
    if (currLead) {
      let currLead2 = JSON.parse(JSON.stringify(currLead));
      currLead2.student_class_applied = cls;
      props.setCurrLead(currLead2);
    }
  }

  return (
    <>

      {/*
      <div
        className='d-none w-100 align-self-center d-flex flex-column justify-content-center align-items-center'>
        <div
          className='w-75'>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="Student Name"
                        autoFocus
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="given-name"
                        name="parentName"
                        required
                        fullWidth
                        id="parentName"
                        label="Name of the Parent"
                        autoFocus
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className='row'>
                        <label className="col-lg-3 col-form-label">Gender</label>
                        <div className="col-lg-9">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="gender" id="gender_male" value="male" checked={(studentGender == 'male')}
                              onClick={() => props.setStudentGender('male')}
                            />
                            <label className="form-check-label" for="gender_male">
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="gender" id="gender_female" value="female" checked={(studentGender == 'female')}
                              onClick={() => props.setStudentGender('female')}
                            />
                            <label className="form-check-label" for="gender_female">
                              Female
                            </label>
                          </div>
                        </div>
                      </div>

                    </Grid>
                  </Grid>

                </Box>

              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
      */}


      <div className='enq-form py-0' >
        <div className="">
          {/* <h5 className="form-title" style={{ color: '#0d6efd' }}>
          Admission Enquiry Form 2022-2023
        </h5> */}

          <div className="right-form">
            <div >
              <label className="col-lg-3 col-form-label">Student Name <span className='text-danger'>*</span></label>
              <div className="col-lg-9">
                <input type="text" className="form-control"
                  name='studentName' defaultValue={studentName2}
                  onChange={(e) => {
                    props.setStudentName(e.target.value)
                    if (currLead) {
                      let currLead2 = JSON.parse(JSON.stringify(currLead));
                      currLead2.Student_name = e.target.value;
                      props.setCurrLead(currLead2);
                    }
                  }} />
              </div>
            </div>
            <div >
              <label className="col-lg-3 col-form-label">Name of the Parent <span className='text-danger'>*</span></label>
              <div className="col-lg-9">
                <input type="text" className="form-control"
                  name='parentName' defaultValue={parentName2}
                  onChange={(e) => {
                    props.setParentName(e.target.value)
                    if (currLead) {
                      let currLead2 = JSON.parse(JSON.stringify(currLead));
                      currLead2.parent_name = e.target.value;
                      props.setCurrLead(currLead2);
                    }
                  }} />
              </div>
            </div>

            <div >
              <label className="col-lg-3 col-form-label">Gender</label>
              <div className="col-lg-9">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"
                    name="gender" id="gender_male" value="male"
                    checked={(studentGender2 == 'male')}
                    onClick={() => {
                      props.setStudentGender('male')
                      // update curr lead
                      if (currLead) {
                        let currLead2 = JSON.parse(JSON.stringify(currLead));
                        currLead2.student_Gender = 'male';
                        props.setCurrLead(currLead2);
                      }
                    }}
                  />
                  <label className="form-check-label" for="gender_male">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="gender"
                    id="gender_female" value="female"
                    checked={(studentGender2 == 'female')}
                    onClick={() => {
                      props.setStudentGender('female')
                      let currLead2 = JSON.parse(JSON.stringify(currLead));
                      currLead2.student_Gender = 'female';
                      if (currLead) {

                        props.setCurrLead(currLead2);

                      }
                    }}
                  />
                  <label className="form-check-label" for="gender_female">
                    Female
                  </label>
                </div>
              </div>
            </div>



            <div >
              <label className="col-lg-3 col-form-label">Class for Admission <span className='text-danger'>*</span></label>
              <div className="col-lg-9">
                {/* <input type="text" className="form-control" name='classApplied' value={classApplied} onChange={(e) => props.setClassApplied(e.target.value)} /> */}

                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Standard {classAppliedStr}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(1)
                      setClassApplied(1)
                    }}>Standard 1</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(2)
                      setClassApplied(2)
                    }}>Standard 2</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(3)
                      setClassApplied(3)
                    }}>Standard 3</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(4)
                      setClassApplied(4)
                    }}>Standard 4</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(5)
                      setClassApplied(5)
                    }}>Standard 5</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(6)
                      setClassApplied(6)
                    }}>Standard 6</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(7)
                      setClassApplied(7)
                    }}>Standard 7</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(8)
                      setClassApplied(8)
                    }}>Standard 8</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(9)
                      setClassApplied(9)
                    }}>Standard 9</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(10)
                      setClassApplied(10)
                    }}>Standard 10</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(11)
                      setClassApplied(11)
                    }}>Standard 11</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(12)
                      setClassApplied(12)
                    }}>Standard 12</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(13)
                      setClassApplied(13)
                    }}>Standard L.K.G</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(14)
                      setClassApplied(14)
                    }}>Standard U.K.G</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => {
                      props.setClassApplied(15)
                      setClassApplied(15)
                    }}>Standard Nursery</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div >
              <label className="col-lg-3 col-form-label">Enquired by</label>
              <div className="col-lg-9">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"
                    name="enq" id="enq_parent" value="1"
                    checked={(isParent2 == '1')}
                    onClick={() => {
                      props.setIsParent('1')

                      if (currLead) {
                        let currLead2 = JSON.parse(JSON.stringify(currLead));
                        currLead2.is_parent = '1';
                        props.setCurrLead(currLead2);
                      }
                    }}
                  />
                  <label className="form-check-label" for="gender_male">
                    Parent
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"
                    name="enq" id="enq_student" value="0"
                    checked={(isParent2 == '0')}
                    onClick={() => {
                      props.setIsParent('0')
                      if (currLead) {
                        let currLead2 = JSON.parse(JSON.stringify(currLead));
                        currLead2.is_parent = '0';
                        props.setCurrLead(currLead2);
                      }
                    }}
                  />
                  <label className="form-check-label" for="gender_female">
                    Student
                  </label>
                </div>
              </div>
            </div>

            <div
              className='mt-3'>

              <p className='text-info'>Please fill in either contact number or email  <span className='text-danger'>*</span></p>
            </div>

            <div >
              <label className="col-lg-3 col-form-label">Enquirer Contact Number</label>
              <div className="col-lg-9">
                <input type="text" className="form-control" name='contact'
                  value={contact2} onChange={(e) => {
                    props.setContact(e.target.value)
                    if (currLead) {
                      let currLead2 = JSON.parse(JSON.stringify(currLead));
                      currLead2.parent_contact = e.target.value;
                      props.setCurrLead(currLead2);
                    }
                  }} />
              </div>
            </div>
            <div >
              <label className="col-lg-3 col-form-label">Enquirer Contact Email</label>
              <div className="col-lg-9">
                <input type="email" className="form-control"
                  name='parentEmail'
                  value={parentEmail2} onChange={(e) => {
                    props.setParentEmail(e.target.value)

                    if (currLead) {

                      let currLead2 = JSON.parse(JSON.stringify(currLead));
                      currLead2.parent_email = e.target.value;
                      props.setCurrLead(currLead2);

                    }
                  }} />
              </div>
            </div>
          </div>
        </div>
        {/* 
      <div className="text-end" style={{ display: 'flex', justifyContent: "center", textAlign: "center", marginTop: '20px' }}>
        <button type="button" className="btn btn-primary" onClick={enquiryHandler}>Submit</button>
      </div> */}

      </div>
    </>
  )
}

const EnquiryModal = (props) => {
  const { onShow, currLead } = props;

  let parentName2 = (currLead) ? currLead.parent_name : '';
  let studentName2 = (currLead) ? currLead.Student_name : '';
  let studentGender2 = (currLead) ? currLead.student_Gender : 'male';
  let classApplied2 = (currLead) ? currLead.student_class_applied : '';
  let contact2 = (currLead) ? currLead.parent_contact : '';
  let parentEmail2 = (currLead) ? currLead.parent_email : '';
  let isParent2 = (currLead) ? ((currLead.is_parent == 1) ? '1' : '0') : '1';

  console.log(TAG, 'EnquiryModal parentName2 ' + parentName2);
  console.log(TAG, 'EnquiryModal studentName2 ' + studentName2);
  console.log(TAG, 'EnquiryModal studentGender2 ' + studentGender2);
  console.log(TAG, 'EnquiryModal classApplied2 ' + classApplied2);
  console.log(TAG, 'EnquiryModal contact2 ' + contact2);
  console.log(TAG, 'EnquiryModal parentEmail2 ' + parentEmail2);
  console.log(TAG, 'EnquiryModal isParent2 ' + isParent2);

  console.log(TAG, 'EnquiryModal currLead ' + JSON.stringify(currLead));

  const [parentName, setParentName] = useState(parentName2);
  const [studentName, setStudentName] = useState(studentName2);
  const [studentGender, setStudentGender] = useState(studentGender2);
  const [classApplied, setClassApplied] = useState(classApplied2);
  const [contact, setContact] = useState(contact2);
  const [parentEmail, setParentEmail] = useState(parentEmail2);
  const [desc, setDesc] = useState("ghdfgfg");
  const [medium, setMedium] = useState("SELF");
  const [instId, setInstId] = useState(0);
  const [consId, setConsId] = useState(0);
  const [isParent, setIsParent] = useState(isParent2);
  const [consExs, setConsExs] = useState('no');
  const [actvLead, setActvLead] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.setOnShow(false);
  }

  const handleShow = () => {
    setShow(true);
    props.setOnShow(true);
    // props.setCurrLead(null);
  }

  const queryURL = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"
  const queryURL2 = Constants.Application.APPLIER_END_URL + "/enquiry-update/"
  let enqId = (currLead) ? currLead.enquiry_id : 0;

  const onSaveClicked = async () => {

    console.log(TAG, 'EnquiryModal onSaveClicked() parentName ' + parentName);
    console.log(TAG, 'EnquiryModal onSaveClicked() studentName ' + studentName);
    console.log(TAG, 'EnquiryModal onSaveClicked() studentGender ' + studentGender);
    console.log(TAG, 'EnquiryModal onSaveClicked() classApplied ' + classApplied);
    console.log(TAG, 'EnquiryModal onSaveClicked() contact ' + contact);
    console.log(TAG, 'EnquiryModal onSaveClicked() parentEmail ' + parentEmail);
    console.log(TAG, 'EnquiryModal onSaveClicked() isParent ' + isParent);


    console.log(TAG, 'EnquiryModal onSaveClicked() parentName2 ' + parentName2);
    console.log(TAG, 'EnquiryModal onSaveClicked() studentName2 ' + studentName2);
    console.log(TAG, 'EnquiryModal onSaveClicked() studentGender2 ' + studentGender2);
    console.log(TAG, 'EnquiryModal onSaveClicked() classApplied2 ' + classApplied2);
    console.log(TAG, 'EnquiryModal onSaveClicked() contact2 ' + contact2);
    console.log(TAG, 'EnquiryModal onSaveClicked() parentEmail2 ' + parentEmail2);
    console.log(TAG, 'EnquiryModal onSaveClicked() isParent2 ' + isParent2);

    parentName2 = (currLead) ? parentName2 : parentName;
    studentName2 = (currLead) ? studentName2 : studentName;
    studentGender2 = (currLead) ? studentGender2 : studentGender;
    classApplied2 = (currLead) ? classApplied2 : classApplied;
    contact2 = (currLead) ? contact2 : contact;
    parentEmail2 = (currLead) ? parentEmail2 : parentEmail;
    isParent2 = (currLead) ? isParent2 : isParent;

    if (parentName2 && studentName2 && (parentEmail2 || contact2)
      && classApplied2) {
      handleClose();

      MySwal.fire({
        title: 'Saving',
        text: 'Please wait as we save the details..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      }).then(() => {
        // handleClose();

      })

      let leadDetail = ({
        enquiry_id: enqId,
        parent_name: parentName2,
        student_name: studentName2,
        student_Gender: studentGender2,
        student_class_applied: classApplied2,
        parent_contact: contact2,
        parent_email: parentEmail2,
        parent_other_desc: desc,
        medium: 'OTHER',
        institute_id: instId,
        councellor_id: consId,
        is_parent: parseInt(isParent2),
        councellor_exists: consExs
      });
      console.log(TAG, 'onSaveClicked(): leadDetail ' + JSON.stringify(leadDetail));


      if (enqId > 0) {
        // update with auth key
        // edit

        const resp = await INSTITUTEAPI.updateEnquiry(leadDetail);
        props.reloadEnquiries();

        MySwal.close();


        /*
        axios({
          method: 'put',
          url: queryURL2,
          data: leadDetail,

        }).then(function (response) {
          console.log(TAG, 'onSaveClicked() update response ' + JSON.stringify(response));
          props.reloadEnquiries();

          MySwal.close();
        }).catch(function (error) {
          console.log(error);

          MySwal.close();
        });
        */


      } else {


        // add
        axios({
          method: 'post',
          url: queryURL,
          data: leadDetail,

        }).then(function (response) {
          console.log(TAG, 'onSaveClicked() insert response ' + JSON.stringify(response));
          props.reloadEnquiries();

          MySwal.close();
        }).catch(function (error) {
          console.log(error);

          MySwal.close();
        });

      }

    } else {
      // sweetalert
      console.log(TAG, 'onSaveClicked: empty values')

      MySwal.fire({
        title: 'Please enter required fields!',
        // text: 'Email (or) number should be entered..',
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then(() => {
        // handleClose();

      })

      return false;
    }
  }

  useEffect(() => {
    const instId2 = localStorage.getItem("applier.inst.id");
    setInstId(instId2);
  }, [])

  console.log(TAG, 'EnquiryModal props currLead parent name2 ' + parentName2);
  console.log(TAG, 'EnquiryModal state parent name ' + parentName2);

  let dt = new Date();
  let dtYr = dt.getFullYear();
  let nextDt = new Date();
  nextDt.setFullYear(dtYr + 1); // one year ahead
  let nextYr = nextDt.getFullYear();
  let dateRange = `${dtYr} - ${nextYr}`;

  return (
    <>
      <Button variant="contained" color='primary' onClick={() => {
        // clear curr lead
        props.setCurrLead(null)
        handleShow()
      }}
        startIcon={<AddIcon />}>
        Add Enquiry
      </Button>

      <Modal
        size="xl"
        show={onShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Admission Enquiry Form {dateRange}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddLeadForm
            studentName={studentName}
            studentGender={studentGender}
            parentName={parentName}
            parentEmail={parentEmail}
            classApplied={classApplied}
            contact={contact}
            isParent={isParent}

            setParentEmail={(em) => setParentEmail(em)}
            setClassApplied={(cl) => setClassApplied(cl)}
            setStudentName={(nm) => setStudentName(nm)}
            setStudentGender={(g) => setStudentGender(g)}
            setParentName={(nm) => setParentName(nm)}
            setContact={(c) => setContact(c)}
            setIsParent={(v) => setIsParent(v)}
            currLead={currLead}
            setCurrLead={(currLead2) => props.setCurrLead(currLead2)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSaveClicked}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


const SEARCHBAR = (props) => {
  const { enquiryList, oEnquiryList } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.enquiry_id.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        if (itm.parent_name.toLowerCase().toString().includes(value)
          || itm.Student_name.toLowerCase().toString().includes(value)
        ) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oEnquiryList && oEnquiryList.length) {
      oEnquiryList.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm)
        }
      })
    }

    // update
    props.setEnquiryList(filtered)
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}



const OfflineEnquiry = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [enquiryList, setEnquiryList] = useState([])
  const [oEnquiryList, setOEnquiryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cons, setCons] = useState(null)
  const [onShow, setOnShow] = useState(false)
  const [currLead, setCurrLead] = useState(null)

  // const [parentName, setParentName] = useState("");
  // const [studentName, setStudentName] = useState("");
  // const [classApplied, setClassApplied] = useState('');
  // const [contact, setContact] = useState("");
  // const [parentEmail, setParentEmail] = useState("");

  // const [parentName, setParentName] = useState("");
  // const [studentName, setStudentName] = useState("");
  // const [studentGender, setStudentGender] = useState("male");
  // const [classApplied, setClassApplied] = useState('');
  // const [contact, setContact] = useState("");
  // const [parentEmail, setParentEmail] = useState("");
  // const [desc, setDesc] = useState("ghdfgfg");
  // const [medium, setMedium] = useState("english");
  // const [instId, setInstID] = useState(17);
  // const [consId, setConsId] = useState(1);
  // const [isParent, setIsParent] = useState(1);
  // const [consExs, setConsExs] = useState("yes");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = () => {
    setModalOpen(true);
    document.body.classNameList.add('blurred');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classNameList.remove('blurred');
  };


  console.log('list of councellors', cons);

  const ROWS = () => {
    return enquiryList.map((item) => {
      return (
        <tr>

          <td>{item.enquiry_id}</td>
          <td>
            <h2 className="table-avatar">
              {/* <a href="student-details.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-07.jpg" alt="User Image" /></a> --> */}
              <td>{item.parent_name}</td>
            </h2>
          </td>
          <td>{item.student_className_applied}</td>
          <td>{item.parent_contact}</td>
          <td>
            <select>
              <option>{item.parent_name}</option>
              <option>two</option>
              <option>three</option>
              <option>four</option>
            </select>
          </td>
          <td>
            <select>
              <option>Jeffrey Wong</option>
              <option>Fields Malynne</option>
              <option>	Jeffrey Scott</option>
              <option>J Shaffer</option>
            </select>
          </td>
          <td>
            <Link to={`/lead-info?enquiry_id=${item.enquiry_id}`}>
              <AiOutlineEye style={{ fontSize: 'large' }} />
            </Link>
          </td>
          <td className="text-end">
            <div className="actions ">
              <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">

              </a>class
              <a href="" className="btn btn-sm bg-danger-light" >
                <div >
                  <MdOutlineDeleteForever style={{ fontSize: '1.2rem', color: 'red' }} />
                </div>

              </a>
            </div>
          </td>
        </tr>
      )
    })
  }

  const loadEnquiries = async () => {
    const data = await INSTITUTEAPI.loadEnquiries();
    console.log(TAG, 'loadEnquiries(): data ' + JSON.stringify(data));
    setEnquiryList(data);
    setOEnquiryList(data);
  }

  const loadCons = async () => {
    const data = await INSTITUTEAPI.loadCounsellors(); // get logged in institute's counsellor's list
    setCons(data);
  }

  useEffect(() => {
    loadEnquiries()
    loadCons()
  }, [])

  const onSaveClicked2 = async () => {

    handleClose();
  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  return (
    <div>
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* 
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title"></h3>

                </div>
              </div>
            </div>
          </div> */}

          {/* 
          <div className="student-group-form" style={{ position: 'relative', top: '-20px' }}>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by ID ..." />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search by Name ..." />
                </div>
              </div>

              <div className="col-lg-2">
                <Button variant="contained" endIcon={<SearchIcon />}>
                  Search
                </Button>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table comman-shadow">
                <div className="card-body">


                  {/* <div className="page-header">
<div className="row align-items-center">
<div className="col" style={{display:'flex', justifyContent:'space-between'}}>
<h3 className="page-title">Leads</h3>
<div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
  Assign Counsellor:
  <select>
    <option>one</option>
    <option>two</option>
    <option>three</option>
  </select>
</div>
</div> */}

                  <div className="page-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="d-flex flex-row align-items-center w-75">
                        <div className="col2">
                          <div className="col2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <h3 className="page-title">Enquiries</h3>

                          </div>

                        </div>
                        <div className="col2-auto w-100">
                          <SEARCHBAR
                            enquiryList={enquiryList}
                            oEnquiryList={oEnquiryList}
                            setEnquiryList={setEnquiryList}
                          />

                        </div>

                      </div>
                      <div className="col-lg-2">
                        <div className="search-student-btn">
                          {/* <Link to='/enquiry'>
                            <Button variant="contained" startIcon={<AddIcon />}>
                              Add Lead
                            </Button>
                          </Link> */}

                          <EnquiryModal
                            reloadEnquiries={loadEnquiries}
                            onShow={onShow}
                            setOnShow={setOnShow}
                            currLead={currLead}
                            setCurrLead={setCurrLead} // update
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <LEADSLIST
                    enquiryList={enquiryList}
                    cons={cons}
                    onShowEditForm={(lead) => onShowEditForm(lead)}
                    setOnShow={setOnShow}
                    reloadEnquiries={loadEnquiries}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default OfflineEnquiry