import React, { useState } from 'react'
import PageNav from './CounsellorSidebar';
import { TagsInput } from "react-tag-input-component";
import Analytics from './components/Analytics';


const CreateCampaign = () => {
  const [selected, setSelected] = useState(["Admissions"]);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <PageNav />
      <div>
        <div >
          <button onClick={handleOpenModal}
            style={{
              position: 'absolute', top: '80px', right: '100px', zIndex: '20', backgroundColor: '#3498db',
              color: 'white', padding: '10px', border: '1px solid white'
            }}
          >Start Campaign</button>
          <div className={`modalForm-overlay ${showModal ? "active" : ""}`}>
            <div className={`modalForm-content ${showModal ? "active" : ""}`}>
              <span className="close" onClick={handleCloseModal} style={{ cursor: 'pointer' }}>
                &times;
              </span>
              <div>
                <div className="" style={{ height: "80vh", overflow: 'scroll' }}>
                  <div className="">
                    <div className="row">
                      <h3>
                        Social Campaign
                      </h3>

                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="bank-inner-details">
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <label>Title<span className="text-danger"></span></label>
                                    <input type="text" className="form-control" value="" placeholder='Define a title for your campaign' />
                                  </div>
                                </div>

                                <div style={{ width: '500px' }}>
                                  Add hash tags for campaign
                                  <TagsInput
                                    value={selected}
                                    onChange={setSelected}
                                    name="fruits"
                                    placeHolder="#tags"
                                  />
                                </div>

                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <label>Upload File/Image</label>
                                    <div className="change-photo-btn">
                                      <div>
                                        <p>Upload File</p></div>
                                      <input type="file" className="upload" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group w-50">
                                    <form action="index.html">
                                      <div className="form-group">
                                        <label> <span className=""></span></label>
                                        <div >
                                          <input className="form-control" type="text" style={{ width: '600px' }} />

                                          <div style={{ display: 'flex', gap: '20px', width: '600px', marginTop: '20px' }}>
                                            <button>
                                              <a href="#"><i class="fab fa-facebook-f"></i></a>
                                              Facebook
                                            </button>
                                            <button >
                                              <a href="#"><i class="fab fa-google-plus-g"></i></a>
                                              Google
                                            </button>
                                            <button>
                                              <a href="#"><i class="fab fa-twitter"></i></a>
                                              Twitter
                                            </button>
                                            <button>
                                              <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                              LinkedIn
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className=" blog-categories-btn pt-0 ">
                              <div className="bank-details-btn ">
                                <a href="blog.html" className="btn bank-cancel-btn me-2">Add Post</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="page-wrapper" style={{ position: 'relative', top: '100px' }}>
          <div class="col-sm-12">
            <div class="card card-table comman-shadow">
              <div class="card-body">

                <div class="page-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="col" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 class="page-title">Leads</h3>
                        {/* <div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
        Assign Leads:
        <select>
          <option>one</option>
          <option>two</option>
          <option>three</option>
        </select>
      </div> */}
                      </div>

                    </div>
                    <div class="col-auto text-end float-end ms-auto download-grp">
                      <div>
                      </div>

                      {/* <Link to='/enquiry'  className="btn btn-outline-primary me-2">
        Add Lead
      </Link> */}


                      {/* openmodal */}



                      {/* endopenmodal */}



                      {/* <a href="EnquiryForm.html" class="btn btn-primary"><i class="fas fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="table-responsive" >
                  <table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped" style={{ border: "1px solid black" }}>
                    <thead class="student-thread">
                      <tr>

                        <th>Campaign Id</th>
                        <th>Campaign Name</th>
                        <th>Medium</th>
                        <th>Leads</th>
                        <th></th>

                        {/* <th class="text-end">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default CreateCampaign