import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { BsChatLeftFill } from 'react-icons/bs';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { BsFillPersonFill } from 'react-icons/bs';
import PageNav from './CounsellorSidebar';



const IndividualStudent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  return (
    <>
      <PageNav />
      <div className='page-wrapper'>
        <div className='std-top'>
          <div className="student">
            <div className="std-id">
              IN093456#@4
              <div className="dots">
                <BsThreeDotsVertical />
              </div>
            </div>
            <hr />
            <div className="std-img">
              <img src="assets/img/profiles/avatar-03.jpg" alt="" />
              <div className='std-profile'>
                <h3 style={{ color: '#23468c' }}>Student Name</h3>
                <div className="std-name">
                  <div className="comm-icons">
                    <BsFillTelephoneFill className='icon' style={{ color: '#0d6efd' }} />
                    <BsChatLeftFill className='icon' style={{ color: '198754' }} />
                    <BsFillChatDotsFill className='icon' style={{ color: '#f7dc6f' }} />

                  </div>
                </div>
              </div>
            </div>
            <div className="follow-up">
              <h6>Follow Up</h6>
              <div className="add-on">
                <div> Added On:02/09/2021| 11:36 AM</div>
                <div> Added On:02/09/2021| 11:36 AM</div>

              </div>
            </div>
          </div>
          <div className="std-couns">
            <div className="assg-couns">
              <h4 style={{ color: '#23468c' }}>Assigned Counsellor</h4>
              <p>NA</p>
              <div className="up-coming">UpComing Followup
                <p>NA</p>
              </div>
            </div>
            <div className="comm-stat">
              <h4 style={{ color: '#23468c' }}>Communication Status</h4>
              <li>Email Sent: <span>4</span></li>
              <li>SMS Sent: <span>5</span></li>
              <li>WhatApp Sent: <span>4</span></li>
            </div>
            <div className="tel-stat">
              <h4 style={{ color: '#23468c' }}>Telephony Status</h4>
              <li>Inbound Calls:23
              </li>
              <li>Outbound Calls:7</li>
              <li>Avg Call Duration03.20</li>
            </div>

          </div>
        </div>
        {/* step-progress */}
        <div>
          <div className="step-progress">
            <div className="container">
              <div className="arrow-wrapper">
                <div className="arrow-steps clearfix"  >
                  <div className="step "> <span> Unverified</span> </div>
                  <div className="step"> <span>Verified</span> </div>
                  <div className="step current"> <span>Application Started</span> </div>
                  <div className="step"> <span>Payment Approved</span> </div>
                  <div className="step"> <span>Payment Submitted</span> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end step-progress */}

        <div className="lead-details">
          <div className="lead-top">
            <h3 style={{ color: '#23468c' }}>
              <BsFillPersonFill />
              Lead Details</h3>
            <div><BsThreeDotsVertical /></div>
          </div>
          <hr />
          <div>

            <div>
              <table>
                <thead>
                  <tr>

                  </tr>
                </thead>
                <tbody className='table-body'>
                  <tr>
                    <td
                      className={`tab ${activeTab === 0 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(0)}
                    >
                      Timeline
                    </td>
                    <td
                      className={`tab ${activeTab === 1 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(1)}
                    >
                      Follow Up & Notes
                    </td>
                    <td
                      className={`tab ${activeTab === 2 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(2)}
                    >
                      Communication Logs
                    </td>
                    <td
                      className={`tab ${activeTab === 3 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(3)}
                    >
                      Document Locker
                    </td>
                    <td
                      className={`tab ${activeTab === 4 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(4)}
                    >
                      Tickets
                    </td>
                    <td
                      className={`tab ${activeTab === 5 ? 'selected' : ''}`}
                      onClick={() => handleTabClick(5)}
                    >
                      Call Logs
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="tab-content">
                {activeTab === 0 && <div>
                  <p>Form Interested In: <span>Application Form</span></p>
                  <p>Candidate's Email Address:<span>ajayk@gmail.com</span></p>
                  <p>State: <span>Telangana</span></p>
                  <p>City: <span>Hyderabad</span></p>
                  <p>Country: <span>India</span></p>
                  <p>Course: <span>10<sup>th</sup></span></p>
                  <p>Specilization : <span>Computers</span></p>


                </div>}
                {activeTab === 1 && <div>Follow Up & Notes</div>}
                {activeTab === 2 && <div>Communication Logs</div>}
                {activeTab === 3 && <div>Document Locker</div>}
                {activeTab === 4 && <div>Tickets</div>}
                {activeTab === 5 && <div>Call Logs</div>}
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}

export default IndividualStudent