import React, { useEffect } from 'react'
import AnalyticBar from './components/charts/AnalyticBar';
import ResourceBar from './components/charts/ResourceBar';

import PageNav from './CounsellorSidebar';
import { useState } from 'react';
import COUNSELLORAPI from '../http/counsellor';
const TAG = 'CampaignAnalytics.jsx';

const ANALYTICS = {
  series: [{
      data: [21, 22, 10, 28, 16, 21, 13, 30]
  }],
  options: {
      chart: {
          height: 350,
          type: 'bar',
          events: {
              click: function (chart, w, e) {
                  // console.log(chart, w, e)
              }
          }
      },

      plotOptions: {
          bar: {
              columnWidth: '45%',
              distributed: true,
          }
      },
      dataLabels: {
          enabled: false
      },
      legend: {
          show: false
      },
      xaxis: {
          categories: [
              ['10th'],
              ['9th'],
              ['8th'],
              ['7th'],
              ['6th'],
              ['5th'],
              ['4th'],
              ['3rd'],
              ['2nd'],
              ['1st']
          ],
          labels: {
              style: {

                  fontSize: '12px'
              }
          }
      }
  }

}

const RESOURCES = {
  series: [{
      name: "Leads",
      data: [{
          x: 'Facebook',
          y: 400
      }, {
          x: 'LinkedIn',
          y: 430
      }, {
          x: 'Twitter',
          y: 448
      }, {
          x: 'Instagram',
          y: 470
      }, {
          x: 'Email',
          y: 540
      }, {
          x: 'Whatsapp',
          y: 580
      }]
  }],
  options: {
      chart: {
          type: 'bar',
          height: 380
      },
      xaxis: {
          type: 'category',

          group: {
              style: {
                  fontSize: '10px',
                  fontWeight: 700
              },
              groups: [
                  { title: 'January-23', cols: 4 },

              ]
          }
      },
  }

}

const CHANNEL2 = (props) => {
  const { lead } = props;
  console.log()
  return (
    <tr>
      <td>{lead.name}</td>
      <td>{lead.lead_count}</td>
      <td>{lead.admissions_count}</td>
      <td>{lead.enroll_count}</td>
    </tr>
  )
}

const CHANNELSUMMARY = (props) => {
  const { channels } = props;

  if(!channels) {
    return <></>
  }

  return channels.map((lead,idx) => {
    return <CHANNEL2 lead={lead} key={idx} />
  })
}

const CampaignAnalytics = () => {

  const [analytics, setAnalytics] = useState(ANALYTICS);
  const [campaignLeads, setCampaignLeads] = useState(RESOURCES);
  const [channels, setChannels] = useState(null);

  const _setAnalytics = (stats) => {
    let sLikeChart = JSON.parse(JSON.stringify(ANALYTICS)); // copy back

    console.log(TAG, '_setAnalytics stats from api ', JSON.stringify(stats));
    if(stats) {
        // leads data
        let leadsData = [];
        let admData = [];
        let categories = []; // standards

        if(stats.lead_data) {
            stats.lead_data.map((led, idx) => {
                // console.log(TAG, '_setupStats led ', JSON.stringify(led));
                // console.log(TAG, '_setupStats led keys ', Object.keys(led));
                const key = Object.keys(led)[0];
                console.log(TAG, '_setAnalytics key ', key);
                const val = led[key];
                console.log(TAG, '_setAnalytics val ', val);

                let suffix = 'th';
                if(parseInt(key) == 1)
                    suffix = 'st';
                else if(parseInt(key) == 2)
                    suffix = 'nd';
                else if(parseInt(key) == 3)
                    suffix = 'rd';
                categories.push(key+suffix);
                leadsData.push(val);
            })
        }

        if(stats.admissions_data) {
            stats.admissions_data.map((adm, idx2) => {
                const key2 = Object.keys(adm)[0]; // only 1 key exists in this object
                const val2 = adm[key2];

                admData.push(val2);
            })
        }

        // update object
        sLikeChart.series[0].data = leadsData;
        // sLikeChart.series[1].data = leadsData;

        sLikeChart.options.xaxis.categories = categories;
    }

    // update
    setAnalytics(sLikeChart);
  }

  const _getStdwiseAnalytics = async () => {
      const resp = await COUNSELLORAPI.getStdwiseLeads();
      
      console.log(TAG, '_getStdwiseAnalytics resp ', JSON.stringify(resp));
      _setAnalytics(resp);
  }

  const _setCampaignLeads = (leads) => {
    let campChart = JSON.parse(JSON.stringify(RESOURCES));
    let categories = [];

    console.log(TAG, '_setCampaignLeads campChart ', JSON.stringify(campChart));
    console.log(TAG, '_setCampaignLeads stats from api ', JSON.stringify(leads));

    if(leads) {
        let data1 = [];
        let data2 = [];
        
        
        // each counsellor
        const keys = Object.keys(leads);
        // console.log(TAG, '_setLeadStatuses keys ', keys);

        keys.map((key) => {
            let val = leads[key];
            let key2 = key.toString();
            key2 = key2.replaceAll('_', ' ');
            console.log(TAG, '_setCampaignLeads key ', key2);
            console.log(TAG, '_setCampaignLeads val ', JSON.stringify(val));

            const obj2 = {
              x: key,
              y: val.lead_count
            }
            data1.push(obj2);

            const channelObj = {
              name: key,
              lead_count: val.lead_count,
              admissions_count: val.admissions_count,
              enroll_count: val.enroll_count
            }
            
            categories.push(channelObj);
        })

        console.log(TAG, '_setCampaignLeads() data1 ', JSON.stringify(data1));
        
        // update object
        campChart.series[0].data = data1;
        
    }

    // update
    setCampaignLeads(campChart);
    setChannels(categories);
}

const _getCampaignLeads = async () => {
    const resp = await COUNSELLORAPI.getCampaignLeads();
    
    console.log(TAG, '_getCampaignLeads resp ', JSON.stringify(resp));
    _setCampaignLeads(resp);
    
}

  useEffect(() => {

    _getStdwiseAnalytics();
    _getCampaignLeads();
    return () => {

    }
  }, [])

  return (
    <div>
      <PageNav />

      <div class="main-wrapper">
        <div class="col-lg-2">
          <div class="search-student-btn" style={{ position: 'absolute', top: '80px', right: '100px', zIndex: '20' }}>
            {/* <button type="button" class="btn btn-primary" style={{cursor:'pointer !important'}}>Create Campaign</button> */}
          </div>
        </div>
        <div class="page-wrapper">
          <div class="content container-fluid">

            <div class="page-header">
              <div class="row">
                <div class="col">
                  <h3 class="page-title">Analytics</h3>

                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-title">Leads by Class</div>
                  </div>
                  <div class="card-body">
                    <AnalyticBar 
                    analytics={analytics}
                    />
                  </div>
                </div>
              </div>


              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-title">Leads Resource </div>
                  </div>
                  <div class="card-body">
                    <ResourceBar 
                    campaignLeads={campaignLeads}
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <ul >
                        <li>Channel Summary</li>

                      </ul>

                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="datatable table table-stripped">
                          <thead>
                            <tr>
                              <th>Traffic Channel</th>
                              <th>Leads Count</th>
                              <th>Applications Count</th>
                              <th>Enrolments Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>
                                <select >
                                  <option>Social</option>
                                  <option>Facebook</option>
                                  <option>Instagram</option>
                                  <option>Linkedin</option>
                                </select>
                              </td>
                              <td>90</td>
                              <td>61</td>
                              <td>24</td>

                            </tr> */}


                            <CHANNELSUMMARY 
                            channels={channels}
                            />
{/* 
                            <tr>
                              <td>Email</td>
                              <td>30</td>
                              <td>27</td>
                              <td>22</td>
                            </tr>
                            <tr>
                              <td>What's App</td>
                              <td>50</td>
                              <td>50</td>
                              <td>48</td>

                            </tr>
                            <tr>
                              <td>SMS</td>
                              <td>55</td>
                              <td>50</td>
                              <td>45</td>

                            </tr> */}


                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>



        </div>

      </div>
    </div>

  )
}

export default CampaignAnalytics;