import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import Constants from '../resource/Constants';

const ACTIVATEAPI = Constants.Application.APPLIER_END_URL + "/lead-registration-activate/"

const SuccessPage = () => {
  const [confirm, setConfirm] = useState([])
  const [complete, setComplete] = useState(false)


  let { newParams } = useParams();
  // console.log('SuccessPage.jsx SuccessPage newParams ' + JSON.stringify(newParams));

  const search = useLocation().search;
  const query = new URLSearchParams(useLocation().search);
  const eml = new URLSearchParams(search).get('email')

  // console.log('SuccessPage.jsx query ' + JSON.stringify(query));
  console.log('SuccessPage.jsx eml ' + eml);

  // const fetchConfirmation = async() =>{
  //     const response = await fetch(confirmation);
  //     setConfirm(response)
  //     console.log(confirm)
  // }

  const _doConfirm = async () => {
    // call the activation API
    const response = await axios.put(ACTIVATEAPI, {
      "lead_email": eml
    });
    console.log('SuccessPage.jsx _doConfirm(): response ' + JSON.stringify(response));
    try {

      setComplete(true)
    } catch (error) {
      console.log("error", error);
    }


  }

  useEffect(() => {
    _doConfirm()
  }, [])



  if (!complete) {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  }

  return (
    <div>
      <h4 align="center">Yor are successfully registered!</h4>
      <Link to="/login"><h2>Click here to login</h2></Link>
    </div>
  )
}

export default SuccessPage