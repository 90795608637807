import React, { useState } from 'react';

import { AiFillPhone } from 'react-icons/ai';
import { RiParentFill } from 'react-icons/ri';
import { SiGoogleclassroom } from 'react-icons/si';


import "./Drawer.css";

const SchoolAdmissionEnquiry = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [showWidget, setShowWidget] = useState(false);
  const [left, setLeft] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${name}, Email: ${email}, Phone: ${phone}, Message: ${message}`);
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
    setShowWidget(false);

  };
  const handleClick = () => {
    setShowWidget(!showWidget)
    setLeft(left === 0 ? -400 : 0);
  };

  return (
    <div className="drawer-container">
      <button
        style={{ left: `${left}px` }}
        onClick={handleClick} className='adm-enq-btn'>
        {showWidget ? '' : ''} Admission Enquiry
      </button>
      <div
        className={`drawer ${showWidget ? "drawer-open" : ""}`}
        style={{ right: showWidget ? 0 : "-100%" }}
      >
        <div>
          <div class="main-wrapper login-body" style={{ width: '300px', position: 'absolute', right: '0px', top: '10px' }} >
            <div class="login-wrapper">
              <div class="container">
                <div >

                </div>
                <div class="loginbox"  >

                  <div class="login-right">
                    <div class="login-right-wrap">
                      <h5 style={{ textAlign: 'center', paddingBottom: '10px' }}>Admimissions Open 2023-24</h5>

                      <form action="login.html" style={{ marginTop: '10px' }}>
                        <div class="form-group">
                          <label>Student Name <span class="login-danger">*</span></label>
                          <input class="form-control" type="text" />
                          <span class="profile-views"><i class="fas fa-user-circle"></i></span>
                        </div>
                        <div class="form-group">
                          <label>Name of the Parent <span class="login-danger">*</span></label>
                          <input class="form-control" type="text" />
                          <span class="profile-views"><RiParentFill /></span>
                        </div>
                        <div class="form-group">
                          <label>Mobile Number <span class="login-danger">*</span></label>
                          <input class="form-control pass-input" type="text" />
                          <span class="profile-views"><AiFillPhone /></span>
                        </div>
                        <div className="form-check form-check-inline">
                          <div style={{ display: 'flex' }}>

                            <div>
                              <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked />
                              <label className="form-check-label" for="gender_male">
                                Male
                              </label>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                <label className="form-check-label" for="gender_female">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Class for Admission<span class="login-danger">*</span></label>
                          <input class="form-control pass-confirm" type="text" />
                          <span class="profile-views"><SiGoogleclassroom /></span>
                        </div>

                        <div class="form-group">
                          <label>Parent Email <span class="login-danger">*</span></label>
                          <input class="form-control" type="email" />
                          <span class="profile-views"><i class="fas fa-envelope"></i></span>
                        </div>

                      </form>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <input type='checkbox' />
                        </div>
                        <p style={{ marginLeft: '10px' }}>  I agree to receive information regarding my submitted application by signing up on APPLIER</p>
                      </div>
                      <div class="form-group mb-0">
                        <button class="btn btn-primary btn-block" type="submit">Apply Now</button>
                        <p style={{ fontSize: '.6rem', color: 'blue' }}>Powered by Applier</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SchoolAdmissionEnquiry;
