import React, { useEffect, useState } from 'react';
import PageNav from './CounsellorSidebar';
import { BiSort } from "react-icons/bi";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import COUNSELLORAPI from '../http/counsellor';
import Alert from 'react-bootstrap/Alert';
import { BiEdit } from 'react-icons/bi';
import { ImDownload3 } from 'react-icons/im';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const stat = 'APPLICATION';
const pageType = 'RESULTS';
const TAG = 'CounsResults.jsx';


const NoteModal = (props) => {
  const { show, currLead } = props;
  console.log(TAG, 'NoteModal currLead ' + currLead);

  const handleChangeChannel = (e) => {
    const { name, value } = e.target;

    props.setChannel(value);
  }

  const handleChangeNote = (e) => {
    const { name, value } = e.target;

    props.setNotes(value);
  }

  const handleSaveNote = (e) => {
    if (currLead) {
      // close modal
      props.handleClose();
      props.handleSaveNote();
    }
  }

  return (
    <>
      <Modal show={show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Select aria-label="Mode of communication"
            onChange={handleChangeChannel}>
            <option>Select A Communication Channel</option>
            <option value="PHONE_CALL">Phone Call</option>
            <option value="EMAIL">Email</option>
            <option value="SMS">SMS</option>
            <option value="WhatsApp">WhatsApp</option>
          </Form.Select>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter the activity notes for enquiry</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleChangeNote} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNote}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const RESULTROW = (props) => {

  const { result, lead } = props;
  const { enquiry_id, admission_form_id } = result;
  console.log(TAG, 'RESULTROW result ' + JSON.stringify(result));

  const onChangeStatus = (e) => {

    const applId = result.admission_form_id;
    const { name, value } = e.target;

    console.log(TAG, 'RESULTROW onChangeStatus(): name ' + name);
    console.log(TAG, 'RESULTROW onChangeStatus(): value ' + value);

    props.onChangeStatus(value, applId);
  }

  let stat2 = result.admission_status;
  let sSel = 'selected="selected"';
  let noShow = (stat2 == 'NO_SHOW') ? sSel : '';
  let pass = (stat2 == 'PASS') ? sSel : '';
  let fail = (stat2 == 'FAIL') ? sSel : '';

  return (
    <tr>
      <td>{result.admission_form_id}</td>
      <td>
        <h2 class="table-avatar">
          <a
            href="IndividualStudent.html"
            class="avatar avatar-sm me-2"
          >
            <img
              class="avatar-img rounded-circle"
              src="assets/img/profiles/avatar-02.jpg"
              alt="User Image"
            />
          </a>
          <Link to={`/lead-details?enquiry_id=${result.enquiry_id}`}>{result.Student_name}
            <br />

          </Link>
        </h2>
      </td>
      {/* <td style={{ textAlign: 'center' }}>2 <sup>nd</sup> </td> */}
      <td style={{ textAlign: 'center' }}>{result.student_class_applied}</td>
      <td style={{ width: '20%' }}>
        <a href="javascript:void(0)"
          onClick={(e) => {
            props.handleNoteShown(); props.handleAddNote(lead)
          }}
        >
          Add notes
        </a>
      </td>
      <td style={{ textAlign: 'center' }} className="d-flex flex-row justify-content-center">
        {/* <select>
          <option>No Show</option>
          <option>Pass</option>
          <option>Fail</option>
        </select> */}
        <div className='w-50'>
          <Form.Select aria-label="Exam result" onChange={onChangeStatus}>

            <option>Choose result</option>
            {noShow ?
              <option value="NO_SHOW" selected>No Show</option>
              :
              <option value="NO_SHOW" >No Show</option>
            }

            {pass ?
              <option value="PASS" selected>Pass</option>
              :
              <option value="PASS" >Pass</option>
            }

            {fail ?
              <option value="FAIL" selected>Fail</option>
              :
              <option value="FAIL">Fail</option>
            }

          </Form.Select>
        </div>

      </td>
      {/* <td>Aravind</td>

      <td className="text-end" style={{ width: '10px' }}>
        <div className="dropdown dropdown-action">
          <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
          <div className="dropdown-menu dropdown-menu-end">
            <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
            <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

          </div>
        </div>
      </td> */}
    </tr>
  )
}

const RESULTSLIST = (props) => {
  const { results } = props;


  if (!results || !results.length) {
    return (
      <Alert variant="warning">
        <Alert.Heading>No results yet!</Alert.Heading>
        <p>
          No results are shown here as they have not yet been set in the system. Start setting up now!
        </p>
        <hr />
        <p className="mb-0">
          Results can be achieved by converting applications.
        </p>
      </Alert>
    )
  }

  return (
    <>
      <table
        class="table border-0 star-student  table-center mb-0 datatable"
        style={{ border: "1px solid black" }}
      >
        <thead class="student-thread">
          <tr>
            <th>
              Application Id <BiSort />
            </th>
            <th>
              Name <BiSort />
            </th>
            <th style={{ textAlign: 'center' }}>
              Grade <BiSort />
            </th>
            <th>Activity <BiSort /></th>
            <th style={{ textAlign: 'center' }}>
              Result <BiSort />
            </th>
            {/* <th>
              Counsellor <BiSort />
            </th>

            <th class="text-end">Action</th> */}
          </tr>
        </thead>
        <tbody>

          {results.map((result, index) => {
            return <RESULTROW
              lead={result}
              result={result}
              key={index}
              index={index}
              onChangeStatus={(stat, applId) => props.onChangeStatus(stat, applId)}
              handleAddNote={(lead) => props.handleAddNote(lead)}
              handleNoteShown={() => props.handleNoteShown()}
              currLead={props.currLead}
              setCurrLead={(lead) => props.setCurrLead(lead)}
            />
          })}






          {/*
          <tr>
            <td>PRE2243</td>
            <td>
              <h2 class="table-avatar">
                <a
                  href="IndividualStudent.html"
                  class="avatar avatar-sm me-2"
                >
                  <img
                    class="avatar-img rounded-circle"
                    src="assets/img/profiles/avatar-02.jpg"
                    alt="User Image"
                  />
                </a>
                <Link to="/sch-student">Ajay Kumar
                  <br />

                </Link>
              </h2>
            </td>
            <td style={{ textAlign: 'center' }}>2 <sup>nd</sup> </td>
            <td style={{ textAlign: 'center' }}>
              <select>
                <option>No Show</option>
                <option>Pass</option>
                <option>Fail</option>
              </select>
            </td>
            <td>Aravind</td>

            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>PRV35</td>
            <td>
              <h2 class="table-avatar">
                <a
                  href="IndividualStudent.html"
                  class="avatar avatar-sm me-2"
                >
                  <img
                    class="avatar-img rounded-circle"
                    src="assets/img/profiles/avatar-03.jpg"
                    alt="User Image"
                  />
                </a>
                <Link to="/sch-student">Praveen
                  <br />

                </Link>
              </h2>
            </td>
            <td style={{ textAlign: 'center' }}>10 <sup>th</sup> </td>
            <td style={{ textAlign: 'center' }}>
              <select>
                <option>Pass</option>
                <option>Fail</option>
                <option>No Show</option>
              </select>
            </td>
            <td>Aravind</td>

            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>PRE2632</td>
            <td>
              <h2 class="table-avatar">
                <a
                  href="IndividualStudent.html"
                  class="avatar avatar-sm me-2"
                >
                  <img
                    class="avatar-img rounded-circle"
                    src="assets/img/profiles/avatar-04.jpg"
                    alt="User Image"
                  />
                </a>
                <Link to="/sch-student">Ajay Kumar
                  <br />

                </Link>
              </h2>
            </td>
            <td style={{ textAlign: 'center' }}>7 <sup>th</sup> </td>
            <td style={{ textAlign: 'center' }}>
              <select>
                <option>Pass</option>
                <option>Fail</option>
                <option>No Show</option>
              </select>
            </td>
            <td>Aravind</td>

            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>PRE2213</td>
            <td>
              <h2 class="table-avatar">
                <a
                  href="IndividualStudent.html"
                  class="avatar avatar-sm me-2"
                >
                  <img
                    class="avatar-img rounded-circle"
                    src="assets/img/profiles/avatar-05.jpg"
                    alt="User Image"
                  />
                </a>
                <Link to="/sch-student">Praveen
                  <br />

                </Link>
              </h2>
            </td>
            <td style={{ textAlign: 'center' }}>10 <sup>th</sup> </td>
            <td style={{ textAlign: 'center' }}>
              <select>
                <option>Pass</option>
                <option>Fail</option>
                <option>No Show</option>
              </select>
            </td>
            <td>Aravind</td>

            <td className="text-end" style={{ width: '10px' }}>
              <div className="dropdown dropdown-action">
                <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><BiEdit /></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href=""><i className="far fa-edit me-2"></i>Edit</a>
                  <a className="dropdown-item" href=""><i className="far fa-paper-plane me-2"></i>Send Email</a>

                </div>
              </div>
            </td>
          </tr>
          */}

        </tbody>
      </table>
    </>
  )
}

const SEARCHBAR = (props) => {
  const { applications, oApplications } = props;
  const handleSearchById = (e) => {
    const { name, value } = e.target;
    // console.log(TAG, 'handleSearchById(): oSchCounsellors ' + oSchCounsellors);
    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        console.log(TAG, 'SEARCHBAR handleSearchById(): itm ' + itm);
        if (itm.admission_form_id.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByName = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_name.toString().includes(value)
          || itm.Student_name.toString().includes(value)
        ) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  const handleSearchByPhone = (e) => {
    const { name, value } = e.target;

    // filter now
    let filtered = [];
    if (oApplications && oApplications.length) {
      oApplications.map(itm => {

        if (itm.parent_contact.toString().includes(value)) {
          filtered.push(itm);
        }
      })
    }

    // update
    props.setApplications(filtered);
  }

  return (
    <div
      style={{ width: '85%' }}>
      {/* <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title"></h3>

            </div>
          </div>
        </div>
      </div> */}

      <div className="student-group-form">
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div className="w-25b me-3">

            <label>Filter your search</label>
          </div>
          <div className="col-lg-3b w-25 me-3">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by ID ..." onChange={handleSearchById} />
            </div>
          </div>
          <div className="col-lg-3b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Name ..." onChange={handleSearchByName} />
            </div>
          </div>
          {/* <div className="col-lg-4b w-25">
            <div className="form-group1">
              <input type="text" className="form-control" placeholder="Search by Phone ..." onChange={handleSearchByPhone} />
            </div>
          </div> */}
          {/* <div className="col-lg-2b w-25b" style={{ width: '20%' }}>
            <Button variant="contained" endIcon={<SearchIcon />}>
              Search
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const CounsResults = () => {

  const [applications, setApplications] = useState(null)
  const [oApplications, setOApplications] = useState(null)
  const [onShow, setOnShow] = useState(false)
  const [currApp, setCurrApp] = useState(null)
  const [showNote, setShowNote] = useState(false)
  const [notes, setNotes] = useState(null)
  const [channel, setChannel] = useState(null)
  const [currLead, setCurrLead] = useState(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNoteShown = () => {
    setShowNote(true);
  }

  const handleAddNote = (lead) => {
    setCurrLead(lead);
  }

  const handleNoteClose = () => {
    setShowNote(false);
  }

  const _loadApplications = async () => {
    const data = await COUNSELLORAPI.getApplications(stat, pageType);
    console.log(TAG, '_loadApplications() data: ' + JSON.stringify(data));

    setApplications(data);
    setOApplications(data);
  }

  const _onUpdateApplicationStatus = async (stat, applicationId) => {
    const body = {
      admission_form_id: applicationId,
      admission_status: stat,
      admission_status1: "",
      payment_verify_url: ""
    }

    console.log(TAG, '_onUpdateApplicationStatus() body ' + JSON.stringify(body));

    const resp = await COUNSELLORAPI.postSaveApplicationStatus(body);
    console.log(TAG, '_onUpdateApplicationStatus() resp ' + JSON.stringify(resp));

    MySwal.fire('Success', 'Exam scheduled successfully!', 'info');

    // reload the page
    _loadApplications();
  }

  const onShowEditForm = (lead) => {
    handleShow(true);

    setOnShow(true);
    setCurrLead(lead);

    // prefill with lead details..
  }

  const handleSaveNote = async () => {

    console.log(TAG, 'handleSaveNote(): currLead ' + JSON.stringify(currLead));
    console.log(TAG, 'handleSaveNote(): notes ' + notes);
    console.log(TAG, 'handleSaveNote(): channel ' + channel);

    const { enquiry_id } = currLead;
    if (notes && enquiry_id > 0 && channel) {
      const body = {
        enquiry_id: enquiry_id,
        page_feature: channel,
        activity_text: notes
      }

      // show success
      MySwal.fire({
        title: 'Saving..',
        text: 'Note for communication now saving..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      const resp = await COUNSELLORAPI.postSaveActivityNotes(body);

      MySwal.fire({
        title: 'Success',
        text: 'Note for communication saved successfully..',
        icon: 'success',
        confirmButtonText: 'OK'
      })

      // close modal
      handleNoteClose();
    } else {
      // show error
      MySwal.fire('Please enter required fields..');

      // show modal
      handleNoteShown();
    }
  }

  useEffect(() => {
    _loadApplications();
  }, [])

  return (
    <div>
      <PageNav />
      <div class="page-wrapper">
        <div class="content container-fluid">

          {/*
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-sub-header">
                  <h3 class="page-title"></h3>
                </div>
              </div>
            </div>
          </div>



          <div
            class="student-group-form"
            style={{ position: "relative", top: "-20px" }}
          >
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search by ID ..."
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search by Name ..."
                  />
                </div>
              </div>

              <div class="col-lg-2">
                <div class="search-student-btn">
                  <Button variant="contained" endIcon={<SearchIcon />}>
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
  */}


          <div class="row">
            <div class="col-sm-12">
              <div class="card card-table comman-shadow">
                <div class="card-body">
                  {/* <div class="page-header">
<div class="row align-items-center">
<div class="col" style={{display:'flex', justifyContent:'space-between'}}>
<h3 class="page-title">Leads</h3>
<div style={{display:'flex', justifyContent:'space-between', marginRight:'600px'}}>
Assign Counsellor:
<select>
  <option>one</option>
  <option>two</option>
  <option>three</option>
</select>
</div>
</div> */}

                  <div class="page-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <div
                          class="col"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3 class="page-title">Results</h3>
                          <div class="col-lg2-2 d-flex flex-row w-75 justify-content-between align-items-center">
                            <SEARCHBAR
                              applications={applications}
                              oApplications={oApplications}
                              setApplications={setApplications}
                            />
                            <div class="search-student-btn">
                              <Button variant="contained">
                                Download
                                <ImDownload3 />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto text-end float-end ms-auto download-grp">
                        <div></div>

                        {/* <Link to='/enquiry'  className="btn btn-outline-primary me-2">
Add Lead
</Link> */}

                        {/* openmodal */}

                        {/* endopenmodal */}

                        {/* <a href="EnquiryForm.html" class="btn btn-primary"><i class="fas fa-plus"></i></a> */}
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">

                    <RESULTSLIST
                      results={applications}
                      onChangeStatus={(stat, applId) => _onUpdateApplicationStatus(stat, applId)}
                      onShowEditForm={(lead) => onShowEditForm(lead)}
                      setOnShow={setOnShow}
                      handleAddNote={(lead) => handleAddNote(lead)}
                      handleSaveNote={(lead) => handleSaveNote(lead)} /* show modal */
                      showNote={showNote}
                      handleClose={() => handleNoteClose()}
                      handleNoteShown={() => handleNoteShown()}

                    />


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* NOTE MODAL */}
      <NoteModal
        show={showNote}
        handleClose={() => handleNoteClose()}
        handleNoteShown={() => handleNoteShown()}
        handleSaveNote={(lead) => handleSaveNote(lead)}
        setNotes={(note) => setNotes(note)}
        currLead={currLead}
        setCurrLead={setCurrLead}
        handleChangeNote={(val) => setNotes(val)}
        setChannel={(val) => setChannel(val)}
      />

    </div>
  )
}

export default CounsResults;