import React from 'react'

const SchActivate = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center vh-100">
      <div>
        <h5>Registration successful! </h5>
        <p>Please Check to ur email to Activate your Account..</p>
      </div>
    </div>
  )
}

export default SchActivate