import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Constants from '../resource/Constants';

const TAG = "Logout.jsx";
// import * as firebaseui from "firebaseui";
// import firebase from "firebase";

const Logout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const _doLogout = () => {

  }

  useEffect(() => {
    // remove token
    localStorage.removeItem("applier.inst.token");
    localStorage.removeItem("applier.couns.token");

    // remove role
    localStorage.removeItem("applier.role");
    let path = "/login";
    navigate(path);
  }, [])

  return (
    <>
      Logging out. Please wait..
    </>
  )
}

export default Logout;