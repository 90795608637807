/**
 * @project kgtopg - schooler - publisher
 * http api calls for
 * authentication
 */

import API from './http';
import axios from 'axios';

import Constants from '../resource/Constants';
import Cookie from 'js-cookie';
import UserSession from '../resource/UserSession';
//  import UserSession from '../resource/UserSession';

const TAG = "institute.js";

export default {

    async getCampaignLeads() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-or-school-campaign-medium-lead-admission-enroll-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "geCampaignLeads() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "geCampaignLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    async getStdwiseLeads() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-stdwise-leads-admissions-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getStdwiseLeads() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getStdwiseLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getOverviewStats() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-stdwise-leads-admissions-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getOverviewStats() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getOverviewStats() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getCounsellorStats() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-counsellorwise-leads-admissions-track/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getCounsellorStats() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getCounsellorStats() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getCounsellorTargets() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-counsellors-admissionswith-target-percentage/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getCounsellorTargets() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getCounsellorTargets() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    async getSeatsAvail() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-seats-availability-by-class/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getSeatsAvail() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getSeatsAvail() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getTopCounts() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-admin-dashboard-counts/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getTopCounts() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getTopCounts() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async getSocialCounts() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/campaign-medium-count/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getSocialCounts() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getSocialCounts() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },
    
    async loadCounsellors(instId) {
        const URL = Constants.Application.APPLIER_END_URL + "/school-counsellors/";
        let token = localStorage.getItem('applier.inst.token');
        const config = {
            headers: { Authorization: `${token}` }
        };

        let response = null
        let data = null;

        try {
            response = await axios.get(URL, config);
            data = (response && response.data && response.data.data);
        } catch (e) {
            data = null;
            console.error(TAG, 'loadEnquiries(): exception ' + e);
        }

        return data;
    },

    async getCounsellorLeads(id) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/counsellor-lead-details/?councellor_id=' + id;

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getLeads() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getLeads() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getLeadDetails(id, token) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/lead-details/?enquiry_id=' + id;

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getLeadDetails() URL: ", URL);
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getLeadDetails() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async addCounsellor(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/counsellor-school-register/';
        let token = localStorage.getItem('applier.inst.token');
        const config = {
            headers: { Authorization: `${token}` }
        };

        console.log(TAG, 'addCounsellor(): URL ' + URL);
        console.log(TAG, 'addCounsellor(): body ' + JSON.stringify(body));

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'addCounsellor(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'addCounsellor(): exception ' + e);
            return false;
        }
    },

    async loadEnquiries() {
        const URL = Constants.Application.APPLIER_END_URL + '/school-list-of-enquiries/';
        let token = localStorage.getItem('applier.inst.token');
        const config = {
            headers: { Authorization: `${token}` }
        };
        let response = null
        let data = null;

        try {
            response = await axios.get(URL, config);
            data = (response && response.data && response.data.data);
        } catch (e) {
            data = null;
            console.error(TAG, 'loadEnquiries(): exception ' + e)
        }

        return data;
    },
    async setCounsellor(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/school-assign-counsellor-lead/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'setCounsellor(): URL ' + URL);

        console.log(TAG, 'setCounsellor(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'setCounsellor(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'setCounsellor(): exception ' + e);
            return false;
        }
    },

    async getSeatsAvailable() {
        let instId = localStorage.getItem('applier.inst.id')
        const URL = Constants.Application.APPLIER_END_URL + '/get-school-stds-seats/?school_id=' + instId;
        let token = localStorage.getItem('applier.inst.token');
        const config = {
            headers: { Authorization: `${token}` }
        };
        let response = null
        let data = null;

        try {
            response = await axios.get(URL, config);
            data = (response && response.data && response.data.data);
        } catch (e) {
            data = null;
            console.error(TAG, 'loadEnquiries(): exception ' + e)
        }

        return data;
    },

    async postSeatsAvailable(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/save-school-seats/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'postSeatsAvailable(): URL ' + URL);

        console.log(TAG, 'postSeatsAvailable(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSeatsAvailable(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSeatsAvailable(): exception ' + e);
            return false;
        }
    },

    async genCampaignUrl(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/campaign-url-generation/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'genCampaignUrl(): URL ' + URL);

        console.log(TAG, 'genCampaignUrl(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'genCampaignUrl(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'genCampaignUrl(): exception ' + e);
            return false;
        }
    },

    async postSaveExamSchedule(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/set-exam-schedule/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'postSaveExamSchedule(): URL ' + URL);

        console.log(TAG, 'postSaveExamSchedule(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.post(URL, body, config);

            console.log(TAG, 'postSaveExamSchedule(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'postSaveExamSchedule(): exception ' + e);
            return false;
        }
    },

    async getExamSchedules() {
        const URL = Constants.Application.APPLIER_END_URL + '/get-exam-schedule-by-standard/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'getExamSchedules(): URL ' + URL);

        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.get(URL, config);

            console.log(TAG, 'getExamSchedules(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'getExamSchedules(): exception ' + e);
            return false;
        }
    },

    async getCampaignStats() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/campaign-medium-count/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getCampaignStats() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getCampaignStats() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    async getApplications(stat, pageType) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/list-school-admissions/?application_status=' + stat;
        URL += '&page_type=' + pageType;

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getApplications() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getApplications() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }

    },

    // update enquiry
    async updateEnquiry(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/enquiry-update/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'updateEnquiry(): URL ' + URL);

        console.log(TAG, 'updateEnquiry(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.put(URL, body, config);

            console.log(TAG, 'updateEnquiry(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'updateEnquiry(): exception ' + e);
            return false;
        }
    },

    async getSchoolDetails() {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-details/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getSchoolDetails() URL: ", URL);
            let token = localStorage.getItem('applier.inst.token');
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getSchoolDetails() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }


    },

    async getSchoolDetails2(token) {
        let URL = Constants.Application.APPLIER_END_URL;
        URL += '/school-details/';

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        };

        // we wait
        try {

            console.log(TAG, "getSchoolDetails2() URL: ", URL);
            const config = {
                headers: { Authorization: `${token}` }
            };

            let response = await axios.get(URL, config);
            console.log(TAG, "getSchoolDetails2() response: ", JSON.stringify(response));

            let data = null;
            if (response) {
                data = response.data;
            }

            if (data) return data;
            else return false;
        }
        catch (ex) {
            // console.log(ex);
            // console.log(`exception ${ex.message}`)
            return false
        }


    },

    async updateProfileDetails(body) {
        const URL = Constants.Application.APPLIER_END_URL + '/school-profile-updation/';
        let token = localStorage.getItem('applier.inst.token');
        console.log(TAG, 'updateProfileDetails(): URL ' + URL);

        console.log(TAG, 'updateProfileDetails(): body ' + JSON.stringify(body));
        const config = {
            headers: { Authorization: `${token}` }
        };

        try {
            const resp = await axios.put(URL, body, config);

            console.log(TAG, 'updateProfileDetails(): resp ' + JSON.stringify(resp));
            return resp;
        } catch (e) {
            console.error(TAG, 'updateProfileDetails(): exception ' + e);
            return false;
        }
    },

}