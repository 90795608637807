import React, { useState } from 'react'

const TAG = 'Dynamic.jsx';
const Dynamic = () => {
    const [fields, setFields] = useState([])


    const TEXTINPUTC = (props) => {
        const { height } = props
        return (
            <>
                <input type="text" style={{height: height}} />

            </>
        )
    }
    const RADIOBUTTONC = (props) => {
        const { name } = props
        return (
            <>
                {
                    // Array.from(Array(count), (e, i) => {
                        // return (
                            <>
                                <input type="radio" /> &nbsp; Radio {name}
                            </>
                        // )

                    // })
                }

            </>
        )
    }

    const onAddTextInput = () => {
        let newFields = JSON.parse(JSON.stringify(fields)) // raw copy
        let inputObj = {
            type: 'TEXTINPUTC',
            name: 'name',
            maxLength: 30,
            width: 200,
            height: 30,
            count: 0,
            sub: []
        }
        newFields.push(inputObj)
        setFields(newFields)
    }
    const onAddRadioButton = () => {
        let newFields = JSON.parse(JSON.stringify(fields)) // raw copy
        let inputObj = {
            type: 'RADIOBUTTONC',
            name: 'name',
            maxLength: 30,
            width: 200,
            height: 30,
            count: 0,
            sub: [
                {
                    name: 'Radio 1',
                },
                {
                    name: 'Radio 2',
                },
                {
                    name: 'Radio 3'
                }
            ]
        }
        newFields.push(inputObj)
        setFields(newFields)
    }


    return (
        <div>
            {fields.map((item) => {
                console.log(TAG, 'render item ' + item)
                const { type, width, height, maxLength, name, sub } = item;
                switch (type) {
                    case 'TEXTINPUTC':
                        return <TEXTINPUTC height={height} />;

                    case 'RADIOBUTTONC':
                        return sub.map((sub, idx) => {
                            return (
                        
                                <RADIOBUTTONC name={idx} />
                            )
    
                        })
                        
                    default:
                        return <></>;
                }
            })}
            <hr />
            <button onClick={onAddTextInput}>Text Input</button>
            <button onClick={onAddRadioButton}>Radio Button</button>
        </div>


    )
}

export default Dynamic