import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router-dom";

const TAG = 'CounsellorSidebar.jsx';

const CounsellorSidebar = () => {
    const [showDash, setShowDash] = useState(false);
    const [showLead, setShowLead] = useState(false);
    const [showApp, setShowApp] = useState(false);
    const [showCamp, setShowCamp] = useState(false);

    const [dropdown1Open, setDropdown1Open] = useState(false);
    const [dropdown2Open, setDropdown2Open] = useState(false);
    const [dropdown3Open, setDropdown3Open] = useState(false);
    const [counsName, setCounsName] = useState('Counsellor');
    const [counsEmail, setCounsEmail] = useState("");
    const [counsPhoto, setCounsPhoto] = useState("assets/img/profiles/avatar-05.jpg")

    const toggleDropdown = (dropdown) => {
        if (dropdown === 1) {
            setDropdown1Open(!dropdown1Open);
            setDropdown2Open(false);
            setDropdown3Open(false);
        }
        if (dropdown === 2) {
            setDropdown2Open(!dropdown2Open);
            setDropdown1Open(false);
            setDropdown3Open(false);
        }
        if (dropdown === 3) {
            setDropdown3Open(!dropdown3Open);
            setDropdown1Open(false);
            setDropdown2Open(false);
        }
    }


    const ClickDash = () => {
        setShowDash(!showDash)
    }
    const ClickLead = () => {
        setShowLead(!showLead)
    }
    const ClickApp = () => {
        setShowApp(!showApp)
    }
    const ClickCamp = () => {
        setShowCamp(!showCamp)
    }

    const navigate = useNavigate();
    const _doLogout = () => {
        // remove token
        localStorage.removeItem("applier.couns.token");

        // remove role
        localStorage.removeItem("applier.role");
        let path = "/login";
        navigate(path);
    };

    useEffect(() => {
        let consName2 = localStorage.getItem("applier.couns.name");
        let consPhoto2 = localStorage.getItem("applier.couns.photo");

        setCounsName(consName2);
        // set photo once completed
    }, [])


    let logo = 'https://lh4.googleusercontent.com/-VSTsf5QYny8/AAAAAAAAAAI/AAAAAAAAABc/To2kzliLeP0/photo.jpg?sz=256';
    let instLogo = logo;
    let counsLogo = localStorage.getItem('applier.couns.photo');
    let instLogo2 = localStorage.getItem('applier.couns.inst.photo');
    let instName2 = localStorage.getItem('applier.couns.inst.name');
    let instName = 'School Name';
    console.log(TAG, 'counsLogo ' + counsLogo);

    if (counsLogo) {
        logo = counsLogo;
        
    }

    if(instLogo2) {
        instLogo = instLogo2;
    }

    if(instName2) {
        instName = instName2;
    }

    const NOTIFS = (props) => {
        return (
            <ul class="nav user-menu">

                {/*
                <li class="nav-item dropdown noti-dropdown me-2">
                    <a href="#" class="dropdown-toggle nav-Navlink header-nav-list" data-bs-toggle="dropdown">
                        <img src="assets/img/icons/header-icon-05.svg" alt="" />
                    </a>
                    <div class="dropdown-menu notifications">
                        <div class="topnav-dropdown-header">
                            <span class="notification-title">Notifications</span>
                            <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                        </div>
                        <div class="noti-content">
                            <ul class="notification-list">
                                <li class="notification-message">
                                    <a href="#">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image"
                                                    src="assets/img/profiles/avatar-02.jpg" />
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"><span class="noti-title">Carlson Tech</span> has
                                                    approved <span class="noti-title">your estimate</span></p>
                                                <p class="noti-time"><span class="notification-time">4 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="#">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image"
                                                    src="assets/img/profiles/avatar-11.jpg" />
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"><span class="noti-title">International Software
                                                    Inc</span> has sent you a invoice in the amount of <span
                                                        class="noti-title">$218</span></p>
                                                <p class="noti-time"><span class="notification-time">6 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="#">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image"
                                                    src="assets/img/profiles/avatar-17.jpg" />
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"><span class="noti-title">John Hendry</span> sent
                                                    a cancellation request <span class="noti-title">Apple iPhone
                                                        XR</span></p>
                                                <p class="noti-time"><span class="notification-time">8 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="#">
                                        <div class="media d-flex">
                                            <span class="avatar avatar-sm flex-shrink-0">
                                                <img class="avatar-img rounded-circle" alt="User Image"
                                                    src="assets/img/profiles/avatar-13.jpg" />
                                            </span>
                                            <div class="media-body flex-grow-1">
                                                <p class="noti-details"><span class="noti-title">Mercury Software
                                                    Inc</span> added a new product <span class="noti-title">Apple
                                                        MacBook Pro</span></p>
                                                <p class="noti-time"><span class="notification-time">12 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="topnav-dropdown-footer">
                            <a href="#">View all Notifications</a>
                        </div>
                    </div>
                </li>
                */}
                <li class="nav-item dropdown has-arrow new-user-menus">

                    <a href="#" class="dropdown-toggle nav-Navlink" data-bs-toggle="dropdown">
                        <span class="user-img">
                            <img class="rounded-circle" src={logo} width="31"
                                alt="Soeng Souy" />
                            <div class="user-text" >
                                <h6>{counsName}</h6>
                                <p class="text-muted mb-0">Counsellor</p>
                            </div>
                        </span>
                    </a>

                    <div class="dropdown-menu">
                        <div class="user-header">
                            <div class="avatar avatar-sm">
                                <img src={logo} alt="User Image"
                                    class="avatar-img rounded-circle" />
                            </div>
                            <div class="user-text" >
                                <h6>Counsellor</h6>
                                <p class="text-muted mb-0">{counsName}</p>
                            </div>
                        </div>
                        {/* <a class="dropdown-item" href="profile.html">My Profile</a> */}
                        <NavLink to='/counsellor-profile?email='
                            className='dropdown-item'>My Profile</NavLink>
                        {/* <a class="dropdown-item" href="inbox.html">Inbox</a> */}
                        {/* <a class="dropdown-item" href="javascript:void(0)" onClick={_doLogout}>Logout</a> */}
                        <NavLink to='/'
                            className='dropdown-item'
                            onClick={(e) => { e.preventDefault(); _doLogout() }}
                        >Logout</NavLink>
                    </div>
                </li>
            </ul>
        )
    }

    return (
        <div>
            <div class="header ">
                <div class="header-left">
                    <a href="index.html" class="logo">
                        <NavLink to='/counsellor'>
                            <img src="assets/img/schooler-applier.png" alt="Logo"
                            style={{
                                maxHeight: 70
                            }}
                            />
                        </NavLink>
                    </a>
                    <a href="index.html" class="logo logo-small">
                        <img src="assets/img/logo-small.png" alt="Logo" width="30" height="30" />
                    </a>
                </div>
                {/* <div class="top-nav-search">
                    <form>
                        <input type="text" class="form-control" placeholder="Search here" />
                        <button class="btn" type="submit"><i class="fas fa-search"></i></button>
                    </form>
                </div> */}

                <div
                className='d-flex flex-row justify-content-between align-items-center'>
                    <h3>{instName2}</h3>


                    <a class="mobile_btn" id="mobile_btn">
                        <i class="fas fa-bars"></i>
                    </a>
                    <NOTIFS />
                </div>
                
            </div>
            <section className='sideBar'>

                <div className="sidebar-menu">
                    <div className="Dashboard">
                        <div className="menu-item activa"
                            onClick={ClickDash}>
                            <NavLink className='NavlinkTag' to='/counsellor'>
                                Dashboard
                            </NavLink>
                        </div>
                    </div>
                    <div className="Dashboard-couns">
                        <div className="menu-item"
                            onClick={ClickLead}
                        >
                            <NavLink className='NavlinkTag' to='/camp-leads2' onClick={(e) => e.preventDefault()}>
                                Enquiry Manager
                            </NavLink>

                            <div>
                                <div>

                                    <ul>
                                        <div className="campManager">
                                            <div className="menu-item" >
                                                <NavLink className='NavlinkTag' to='/camp-leads'>
                                                    Enquiries
                                                </NavLink>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            {/* <div>
                            {showLead ? (
                                <ul>
                                    <li>
                                        <NavLink to="/lead-enquiry">Online Enquiries</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/offline">Offline Enquiries</NavLink>
                                    </li>

                                </ul>) : ""

                            }
                        </div> */}

                        </div>
                    </div>

                    <div className="applicationManager">
                        <div className="menu-item"

                        >
                            <NavLink className='NavlinkTag' to='/couns-apps2' onClick={(e) => e.preventDefault()}>  Application Manager</NavLink>


                            <div>
                                <div>

                                    <ul>
                                        <div className="campManager">
                                            <div className="menu-item" >
                                                <NavLink className='NavlinkTag' to='/couns-apps'>
                                                    Applications
                                                </NavLink>
                                            </div>
                                        </div>

                                        <div className="applicationManager">
                                            <div className="menu-item"

                                            >
                                                <NavLink className='NavlinkTag' to='/couns-results'>Results</NavLink>
                                            </div>
                                        </div>

                                        <div className="applicationManager">
                                            <div className="menu-item"

                                            >
                                                <NavLink className='NavlinkTag' to='/couns-payment'>
                                                    Payments
                                                </NavLink>
                                            </div>
                                        </div>

                                        <div className="applicationManager">
                                            <div className="menu-item"

                                            >
                                                <NavLink className='NavlinkTag' to='/couns-admissions'>  Admissions</NavLink>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            {showApp ? (
                                <ul>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/online-application">Online Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/form">Offline Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/examslot">Exam Slot</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/">Admissions</NavLink>
                                    </li>
                                </ul>) : ""
                            }
                        </div> */}

                    </div>
                    {/* <div className="applicationManager">
                        <div className="menu-item"

                        >
                            <NavLink className='NavlinkTag' to='/couns-results'>Results</NavLink>
                        </div> */}
                    {/* <div>
                            {showApp ? (
                                <ul>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/online-application">Online Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/form">Offline Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/examslot">Exam Slot</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/">Admissions</NavLink>
                                    </li>
                                </ul>) : ""
                            }
                        </div> */}

                    {/* </div> */}
                    {/* <div className="applicationManager">
                        <div className="menu-item"

                        >
                            <NavLink className='NavlinkTag' to='/couns-payment'>
                                Payments
                            </NavLink>
                        </div> */}
                    {/* <div>
                            {showApp ? (
                                <ul>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/online-application">Online Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/form">Offline Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/examslot">Exam Slot</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/">Admissions</NavLink>
                                    </li>
                                </ul>) : ""
                            }
                        </div> */}

                    {/* </div> */}

                    {/* <div className="applicationManager">
                        <div className="menu-item"

                        >
                            <NavLink className='NavlinkTag' to='/couns-admissions'>  Admissions</NavLink>
                        </div> */}
                    {/* <div>
                            {showApp ? (
                                <ul>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/online-application">Online Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/form">Offline Application</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/examslot">Exam Slot</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className='NavlinkTag' to="/">Admissions</NavLink>
                                    </li>
                                </ul>) : ""
                            }
                        </div> */}

                    {/* </div> */}
                    <div className="campManager">
                        <div className="menu-item"
                            onClick={() => toggleDropdown(1)}
                        >
                            <NavLink className='NavlinkTag' to="/camp-analytics2" onClick={(e) => e.preventDefault()}>Marketing</NavLink>
                            <div>
                                <div>

                                    <ul>

                                        <div className="campManager">
                                            <div className="menu-item"
                                                onClick={() => toggleDropdown(1)}
                                            >
                                                <NavLink className='NavlinkTag' to="/couns-campaigns">Campaigns</NavLink>
                                            </div>
                                        </div>

                                        <div className="campManager">
                                            <div className="menu-item"
                                                onClick={() => toggleDropdown(1)}
                                            >
                                                <NavLink className='NavlinkTag' to="/camp-analytics">Campaign Analytics</NavLink>
                                            </div>
                                        </div>

                                    </ul>
                                </div>
                            </div>
                            {/* <div>
                            {dropdown1Open && (
                                <ul>

                                    <li>
                                        <NavLink className='NavlinkTag' to="/sch-analytics">Campaigns</NavLink>
                                    </li>
                                </ul>)}
                        </div> */}

                        </div>

                        {/* <div className="campManager">
                        <div className="menu-item"
                            onClick={() => toggleDropdown(1)}
                        >
                             <NavLink className='NavlinkTag' to="/enquiry">Enquiry</NavLink>
                        </div>
                        

                    </div> */}

                    </div>
                    {/* <div className="campManager">
                        <div className="menu-item"
                            onClick={() => toggleDropdown(2)}
                            style={{ marginLeft: '-150px' }}
                        >
                            Settings
                        </div> */}
                    {/* <div>
                            {dropdown2Open && (
                                <ul>

                                    <li>
                                        Set application form
                                    </li>
                                    <li>
                                        Exam Schedule
                                    </li>
                                    <li>
                                        Payment Option
                                    </li>
                                    <li>
                                        School Details
                                    </li>
                                    <li>
                                        My Account
                                    </li>

                                </ul>)}
                        </div> */}
                    {/* </div> */}
                </div>
            </section>
        </div>
    )
}

export default CounsellorSidebar;