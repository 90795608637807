import React, { useState } from 'react';



const ExampleModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <div>
            <button onClick={handleOpen}>Open Modal</button>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Modal Content</h2>
                        <button onClick={handleClose}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExampleModal;
