import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import { BiSort } from "react-icons/bi";
import Form from 'react-bootstrap/Form';
import INSTITUTEAPI from '../http/institute';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const TAG = 'SeatAvailable.jsx';
const seatsAvailObj = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const SeatAvailable = () => {
    const [seatsAvail, setSeatsAvail] = useState(seatsAvailObj);

    const getSeatsAvailable = async () => {

        const resp = await INSTITUTEAPI.getSeatsAvailable();
        console.log(TAG, 'getSeatsAvailable() data ' + JSON.stringify(resp));

        if (resp && resp.length) {

            let newSeatsAvail = [];
            resp.map((st) => {
                newSeatsAvail.push(st.no_of_seats);
            })

            console.log(TAG, 'getSeatsAvailable() newSeatsAvail ' + JSON.stringify(newSeatsAvail));
            setSeatsAvail(newSeatsAvail);
        }
    }

    useEffect(() => {
        getSeatsAvailable();
    }, [])

    const saveSeatsAvailable = async () => {
        const instId = localStorage.getItem("applier.inst.id");
        let clsStdsList = []
        let noSeatsList = []

        if (seatsAvail && seatsAvail.length) {
            seatsAvail.map((st, idx) => {
                let clsStd = (idx + 1);
                clsStdsList.push(clsStd);
                noSeatsList.push(st);
            })
        }

        const body = {
            school_id: instId,
            cls_stds_list: clsStdsList,
            no_of_seats_list: noSeatsList
        }

        console.log(TAG, 'saveSeatsAvailable() body ' + JSON.stringify(body));

        const resp = await INSTITUTEAPI.postSeatsAvailable(body);
        console.log(TAG, 'saveSeatsAvailable() resp ' + JSON.stringify(resp));


        MySwal.fire({
            title: 'Saved',
            text: 'Seats setting saved successfully!',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then(() => {
            // handleClose();


        })

    }

    const updateClassSeats = (e, idx) => {
        const { name, value } = e.target;
        console.log(TAG, "updateClassSeats() name " + name);
        console.log(TAG, "updateClassSeats() value " + value);

        let newSeatsAvail = JSON.parse(JSON.stringify(seatsAvail));
        newSeatsAvail[idx] = parseInt(value);
        setSeatsAvail(newSeatsAvail);
    }

    console.log(TAG, 'render() seatsAvail ' + JSON.stringify(seatsAvail));
    return (
        <div>
            <div>
                <Sidebar />
                <div className="page-wrapper">
                    <div className="row w-80" style={{ position: 'relative', top: '50px', left: '5%', width: '90%' }}>
                        <div className="col-sm-12">
                            <div className="card card-table comman-shadow">
                                <div className="row">
                                    <div class="table-responsive">
                                        <table
                                            class="table border-0 star-student  table-center mb-0 datatable"
                                            style={{ border: "1px solid black" }}
                                        >
                                            <thead class="student-thread">
                                                <tr>
                                                    <th>
                                                        Grade
                                                    </th>
                                                    <th>
                                                        No. of Seats
                                                    </th>

                                                    {/* <th class="text-end">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody className='inp-count'>
                                                <tr>
                                                    <td>Nursery</td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 14)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[14]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>L.K.G</td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 12)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[12]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>U.K.G</td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[13]}
                                                        onChange={(e) => updateClassSeats(e, 13)}
                                                        min={0}
                                                    /></td>
                                                </tr>
                                                <tr>
                                                    <td>1 <sup>st</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 0)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[0]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>2 <sup>nd</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 1)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[1]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>3 <sup>rd</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 2)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[2]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>4 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 3)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[3]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>5 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 4)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[4]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>6 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 5)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[5]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>7 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 6)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[6]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>8 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 7)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[7]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>9 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 8)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[8]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>10 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 9)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[9]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>11 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 10)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[10]} /></td>
                                                </tr>
                                                <tr>
                                                    <td>12 <sup>th</sup></td>
                                                    {/* <input type="number" style={{ height: '2.2rem', marginTop: '10px' }} value='40' /> */}
                                                    <td><Form.Control
                                                        onChange={(e) => updateClassSeats(e, 11)}
                                                        min={0}
                                                        className="w-25" type="number" placeholder="Enter seats number" value={seatsAvail[11]} /></td>
                                                </tr>


                                            </tbody>
                                        </table>

                                        <div style={{
                                            padding: 10,
                                            margin: 10
                                        }} className="d-flex flex-row justify-content-end">
                                            <Button onClick={saveSeatsAvailable}>Save</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeatAvailable