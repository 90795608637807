import React from 'react'
import Analytics from './components/Analytics'
import CampaignForm from './components/CampaignForm'
import Sidebar from './Sidebar'

const SchoolAnalytics = () => {
    const clickHandler = () => {
        <CampaignForm />
    }
    return (
        <>
            <div>
                <Sidebar />
            </div>


            <div>
            {/* <div class="col-lg-2">
<div class="search-student-btn"  style={{position:'absolute', top:'80px', right:'100px', zIndex:'20'}}>
<button type="button" class="btn btn-primary" style={{cursor:'pointer !important'}}
onClick={clickHandler}
>Create Campaign</button>
</div>
</div> */}
<div class="btn-group"  style={{position:'absolute', top:'80px', right:'100px', zIndex:'20'}}>
{/* <button type="button" class="btn btn-primary dropdown-toggle" 
 style={{cursor:'pointer !important'}}
 onClick={clickHandler}
data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Create Campaign</button> */}
<div class="dropdown-menu">
<a class="dropdown-item" href="#">Social Campaign</a>
<a class="dropdown-item" href="#">Single Campaign</a>

</div>
</div>

                <Analytics />
            </div>

        </>

    )
}

export default SchoolAnalytics