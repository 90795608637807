import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constants from '../resource/Constants';

const TAG = "GetForm.jsx";

const GETFORMURL = Constants.Application.APPLIER_END_URL + '/get-school-custom-form/'

const GetForm = () => {
  const [formSetup, setFormSetup] = useState([]);

  // const formSetupHandler =async()=>{
  //     const response = await fetch(GETFORMURL);
  //     const newForm = await response.json()
  //     setIsFormSetup(newForm) 
  // }

  const formSetupHandler = async () => {
    let token = localStorage.getItem('applier.inst.token');
    const config = {
      headers: { Authorization: `${token}` }
    };

    const response = await axios.get(GETFORMURL, config)
    console.log(TAG, "response " + JSON.stringify(response));
    setFormSetup(response.data.data)
  }



  useEffect(() => {
    formSetupHandler();
  }, [])
  console.log(formSetup);
  return (
    <div>

      {formSetup.map((form) => {
        return (
          <div>
            <h5>{form.field_name}</h5>
            <input type={form.field_type} placeholder={form.field_label} />
          </div>
        )
      })}

    </div>
  )
}

export default GetForm