import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Constants from '../resource/Constants';
import COUNSELLORAPI from '../http/counsellor';
import INSTITUTEAPI from '../http/institute';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

const MySwal = withReactContent(Swal);

/* can be school or by counsellor */
const TAG = 'EnquiryForm.jsx';

const queryURL = Constants.Application.APPLIER_END_URL + "/lead-enquiry-save/"

const FORM_OBJECT = {
  institute_id: 0,
  fields: [
    {
      //admission year
      field_name: "admission_year",
      field_type: "text",
      field_label: "Admission Year",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 0,
    },
    {
      //name of the student
      field_name: "student_name",
      field_type: "text",
      field_label: "Name of the Student",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Student Gender
      field_name: "student_gender",
      field_type: "radio",
      field_label: "Student Gender",
      field_value: '',
      max_length: 5,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
      options: ['M', 'F']
    },
    {
      //Date of Birth
      field_name: "student_dob",
      field_type: "date",
      field_label: "Date of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //City
      field_name: "city",
      field_type: "text",
      field_label: "City",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Enrollment in Grade
      field_name: "student_enroll",
      field_type: "text",
      field_label: "Enrollment in Grade",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Campus
      field_name: "campus",
      field_type: "text",
      field_label: "Campus",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    {
      //student picture
      field_name: "student_picture",
      field_type: "file",
      field_label: "Upload Student Image",
      field_value: '',
      max_length: 20,
      max_file_size: 10,
      accept_type: "jpeg/png/gif",
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //permanent address
      field_name: "perm_addr",
      field_type: "textarea",
      field_label: "Permanent Address",
      field_value: '',
      max_length: 500,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //last school attended
      field_name: "schl_attend",
      field_type: "text",
      field_label: "Last School Attended",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //Caste
      field_name: "caste",
      field_type: "text",
      field_label: "Caste",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Religion
      field_name: "religion",
      field_type: "text",
      field_label: "Religion",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },
    {
      //Mother Tongue
      field_name: "mother_tongue",
      field_type: "text",
      field_label: "Mother Tongue",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //Naionality
      field_name: "nationality",
      field_type: "text",
      field_label: "Nationality",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 1,
    },

    {
      //student email
      field_name: "student_email",
      field_type: "email",
      field_label: "Student Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student contact
      field_name: "student_contact",
      field_type: "text",
      field_label: "Student Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student place of birth
      field_name: "student_placeofbirth",
      field_type: "text",
      field_label: "Student Place of Birth",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student temp address
      field_name: "student_temp_addr",
      field_type: "text",
      field_label: "Student Temporary Address",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //student adhar number
      field_name: "student_adhar_number",
      field_type: "text",
      field_label: "Student Adhaar Number ",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //blood group
      field_name: "student_blood_group",
      field_type: "text",
      field_label: "Student Blood Group",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },
    {
      //resides with
      field_name: "resides_with",
      field_type: "text",
      field_label: "Student Resides With",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 1,
    },

    // PARENT DETAILS
    {
      //Parent Name
      field_name: "parent_name",
      field_type: "text",
      field_label: "Parent Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },
    {
      //Parent Contact
      field_name: "parent_contact",
      field_type: "text",
      field_label: "Parent Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: true,
      isEditable: false,
      groupIndex: 2,
    },

    {
      //parent email
      field_name: "parent_email",
      field_type: "email",
      field_label: "Parent Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Name
      field_name: "father_name",
      field_type: "text",
      field_label: "Father Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Father Contact
      field_name: "father_contact",
      field_type: "text",
      field_label: "Father Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Father email
      field_name: "father_email",
      field_type: "email",
      field_label: "Father Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Name
      field_name: "mother_name",
      field_type: "text",
      field_label: "Mother Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "mother_contact",
      field_type: "text",
      field_label: "Mother Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "mother_email",
      field_type: "email",
      field_label: "Mother Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Guardian Name
      field_name: "guard_name",
      field_type: "text",
      field_label: "Guardian Name",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },
    {
      //Mother Contact
      field_name: "guard_contact",
      field_type: "text",
      field_label: "Guardian Contact",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    {
      //Mother email
      field_name: "guard_email",
      field_type: "email",
      field_label: "Guardian Email",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 2,
    },

    // DOCUMENTS
    {
      // Father photo
      field_name: "father_photo",
      field_type: "file",
      field_label: "Father photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Mother photo
      field_name: "mother_photo",
      field_type: "file",
      field_label: "Mother photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },

    {
      // Guardian photo
      field_name: "guardian_photo",
      field_type: "file",
      field_label: "Guardian photo",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // DOB certificate
      field_name: "student_dob_cert",
      field_type: "file",
      field_label: "Student DOB Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Child Aadhaar
      field_name: "student_aadhaar",
      field_type: "file",
      field_label: "Student Aadhaar",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student TC
      field_name: "student_tc",
      field_type: "file",
      field_label: "Student TC",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_caste_cert",
      field_type: "file",
      field_label: "Student Caste Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    },
    {
      // Student Caste Certificate
      field_name: "student_res_cert",
      field_type: "file",
      field_label: "Student Residence Certificate",
      field_value: '',
      max_length: 100,
      max_file_size: 10,
      isSelected: false,
      isEditable: true,
      groupIndex: 3,
    }
  ],
};

const Form2 = () => {
  const [parentName, setParentName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentGender, setStudentGender] = useState(null);
  const [classApplied, setClassApplied] = useState('');
  const [contact, setContact] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [desc, setDesc] = useState("ghdfgfg");
  const [medium, setMedium] = useState("english");
  const [instId, setInstID] = useState(17);
  const [consId, setConsId] = useState(0);
  const [isParent, setIsParent] = useState(1);
  const [consExs, setConsExs] = useState("yes");
  const [isSchool, setIsSchool] = useState(false);

  const search = useLocation().search;
  const schoolId = new URLSearchParams(search).get('school_id');
  const logo = new URLSearchParams(search).get('logo');
  const { code, source } = useParams();
  console.log(TAG, 'schoolId ' + schoolId);
  console.log(TAG, 'logo ' + logo);

  console.log(TAG, 'code ' + code);
  console.log(TAG, 'source ' + source);

  const navigate = useNavigate();

  const enquiryHandler = () => {

    console.log(TAG, 'enquiryHandler(): parentName ' + parentName);
    console.log(TAG, 'enquiryHandler(): parentEmail ' + parentEmail);
    console.log(TAG, 'enquiryHandler(): classApplied ' + classApplied);
    console.log(TAG, 'enquiryHandler(): studentGender ' + studentGender);
    console.log(TAG, 'enquiryHandler(): contact ' + contact);
    console.log(TAG, 'enquiryHandler(): parentEmail ' + parentEmail)
    let proceed = true;
    // validate
    if (!parentName || !classApplied
      || !studentGender
      || (!contact && !parentEmail)) {

      proceed = false;


      // show success
      MySwal.fire({
        title: 'Error',
        text: 'Please fill in mandatory details and either of parent contact',
        icon: 'error',
        confirmButtonText: 'OK',
        // didOpen: () => {
        //   // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        //   MySwal.showLoading()
        // },
      })
    }

    // choose which medium he used to register
    let MEDIUM = 'OTHER';
    switch (source) {
      case 'fb':
        MEDIUM = ''
        break;
      case 'tw':
        break;
      default:
        MEDIUM = 'OTHER';
        break;
    }

    if (proceed) {
      let leadDetail = ({
        parent_name: parentName,
        student_name: studentName,
        student_Gender: studentGender,
        student_class_applied: classApplied,
        parent_contact: contact,
        parent_email: parentEmail,
        parent_other_desc: desc,
        medium: 'OTHER',
        institute_id: instId,
        councellor_id: consId,
        is_parent: isParent,
        councellor_exists: !(isSchool)
      });
      console.log(leadDetail);


      // show success
      MySwal.fire({
        title: 'Saving',
        text: 'Please wait until your enquiry is submitted..',
        icon: 'info',
        confirmButtonText: 'OK',
        didOpen: () => {
          // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          MySwal.showLoading()
        },
      })
      axios({
        method: 'post',
        url: queryURL,
        data: leadDetail,

      }).then(function (response) {
        console.log(response);

        // show success
        MySwal.fire({
          title: 'Success',
          text: 'Your enquiry was saved and sent to the school successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
          // didOpen: () => {
          //   // `MySwal` is a subclass of `Swal` with all the same instance & static methods
          //   MySwal.showLoading()
          // },


        })

        // take the user to thank-you page
        let path = "/thank-you";
        navigate(path);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  const _getSource = async () => {
    if (schoolId && parseInt(schoolId) > 0) {
      setIsSchool(true);
      setInstID(schoolId);
    } else {
      setIsSchool(false);

      // now get the counsellor id
      const resp = await COUNSELLORAPI.getCodeCounsellor(code);
      console.log(TAG, '_getSource() resp ' + JSON.stringify(resp));

      let consId3 = resp.user_id;
      setConsId(consId3);
      setInstID(0);
    }
  }

  useEffect(() => {
    _getSource();
  }, [])

  let dt = new Date();
  let yr = dt.getFullYear();
  let nextYr = yr + 1;
  let nDt = new Date();
  nDt.setFullYear(nextYr);

  return (
    <div className='enq-form' >
      <div className="">
        <div className='w-100 d-flex justify-content-center'>
          <img src="/assets/img/schooler-applier.png" height={64} />
        </div>
        <h6 className="form-tit p-2" style={{ color: '#0d6efd' }}>
          Admission Enquiry Form {yr} - {nextYr}
        </h6>

        <div className="right-form">
          <div >
            <label className="col-lg-3 col-form-label">Student Name*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='studentName' onChange={(e) => setStudentName(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Name of the Parent*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='parentName' onChange={(e) => setParentName(e.target.value)} />
            </div>
          </div>

          <div >
            <label className="col-lg-3 col-form-label">Gender</label>
            <div className="col-lg-9">
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="male"
                  onChange={(e) => setStudentGender('male')}
                />
                <label className="form-check-label" for="gender_male">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="female"
                  onChange={(e) => setStudentGender('female')}
                />
                <label className="form-check-label" for="gender_female">
                  Female
                </label>
              </div>
            </div>
          </div>



          <div >
            <label className="col-lg-3 col-form-label">Class for Admission*</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='classApplied' onChange={(e) => setClassApplied(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Parent Contact Number</label>
            <div className="col-lg-9">
              <input type="text" className="form-control" name='contact' onChange={(e) => setContact(e.target.value)} />
            </div>
          </div>
          <div >
            <label className="col-lg-3 col-form-label">Parent Contact Email</label>
            <div className="col-lg-9">
              <input type="email" className="form-control" name='parentEmail' onChange={(e) => setParentEmail(e.target.value)} />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-4 w-100">
        <button type="button"
          className="btn btn-primary w-75 "
          onClick={enquiryHandler}>Submit</button>
      </div>

    </div>
  )
}

const FORM_OBJECT_INPUT = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="text" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control type="text" placeholder={obj.field_name} onChange={(e) => props.onChange(e, obj)} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_TEXTAREA = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <textarea placeholder={props.placeholder}>this is text area </textarea> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} as="textarea" placeholder={obj.field_name} rows={3} />
        </Form.Group>

      </div>
    </>
  );
};

const FORM_OBJECT_EMAIL = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} type="email" placeholder={obj.field_name} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_RADIO = (props) => {
  const { obj } = props;
  const count = obj.options.length;
  return (
    <>
      <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>

      <div className="custom2 d-flex flex-row ">
        {/* <input type="email" placeholder={props.placeholder} /> */}

        {obj.options.map((op, idx) => {
          return (
            <div key={`default-radio`} className="me-3">
              <Form.Check
                type={'radio'}
                name={`radio-${obj.field_label}`}
                id={`default-${op}`}
                label={op}
                value={op}
                onChange={(e) => props.onChange(e, obj)}
              />
            </div>
          )
        })}


      </div>
    </>
  );
};

const FORM_OBJECT_DATE = (props) => {
  const { obj } = props;
  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          <Form.Control onChange={(e) => props.onChange(e, obj)} type="date" placeholder={obj.field_name} />
        </Form.Group>
      </div>
    </>
  );
};

const FORM_OBJECT_FILE = (props) => {
  const { obj } = props;
  const [downloadUrls, setDownloadUrls] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ImageDownloadUrls, setImageDownloadUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
  };

  const handleUploadSingleSuccess = async (filename) => {

    const downloadURL = await firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    console.log("download url", downloadURL);

    if (downloadURL) {
      setProgress(0);
    }
    // setFileNames(filename)
    setImageDownloadUrls(downloadURL);
    // props.setDocPicSource(downloadURL)
    // setUploadProgress(100)
    // setIsUploading(false)

    props.onChangeFile(obj, downloadURL);

  };

  return (
    <>
      <div className="custom2">
        {/* <input type="email" placeholder={props.placeholder} /> */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{obj.field_label}{!obj.isEditable && <span className='text-danger'>*</span>}</Form.Label>
          {/* <Form.Control onChange={(e) => props.onChange(e, obj)} type="file" /> */}

          <div
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <label
                style={{
                  fontFamily: "Poppins, Helvetica, sans-serif",
                  backgroundColor: "lightsteelblue",
                  color: "black",
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 4,
                  cursor: "pointer",
                  // width: '100%',
                  textAlign: "center",

                }}
              >
                {/* <Typography style={{ width: 250, padding: 5 }}> */}
                CHOOSE
                {/* </Typography> */}

                <FileUploader
                  hidden
                  // accept="image/*"
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSingleSuccess}
                  onProgress={handleProgress}
                />
              </label>

              {obj.field_value ?
                <>
                  <i class="fa fa-check text-primary ms-3"></i>
                </>
                :
                <></>
              }

              {/* <div style={{ display: "flex", justifyContent: "center" }}>
              {progress ? <CircularProgress /> : <></>}
            </div> */}
              {/* 
          {ImageDownloadUrls ?
            <div style={{ marginLeft: 5, marginTop: 10 }} onClick={handleClickOpen}>
              <ImageOutlinedIcon style={{ fontsize: 80 }} />
            </div>
            :
            <></>
          } */}

            </div>
          </div>
        </Form.Group>
      </div>
    </>
  );
};

const EnquiryForm = () => {
  const search = useLocation().search;

  const schoolId = new URLSearchParams(search).get('school_id');
  const enqId = new URLSearchParams(search).get('enquiry_id');
  const logo = new URLSearchParams(search).get('logo');

  const GETFORMURL1 = Constants.Application.APPLIER_END_URL + "/get-school-admission-form/?school_id=" + schoolId;
  const GETFORMURL = Constants.Application.APPLIER_END_URL + "/get-school-custom-form1/";

  console.log(TAG, 'schoolId ' + schoolId);
  console.log(TAG, 'enqId ' + enqId);
  console.log(TAG, 'GETFORMURL ' + GETFORMURL);

  console.log(TAG, 'logo ' + logo);
  const [formObject, setFormObject] = useState(FORM_OBJECT);
  const [enqDetails, setEnqDetails] = useState(null);

  const navigate = useNavigate();
  function groupObject() {
    var result = formObject.fields.reduce(function (r, a) {
      r[a.groupIndex] = r[a.groupIndex] || [];
      r[a.groupIndex].push(a);
      return r;
    }, Object.create(null));

    return result;
  }

  const FORM_FIELD = (props) => {

    const { obj } = props;
    console.log(TAG, 'SetApplicationForm.jsx FORM_FIELD: ' + JSON.stringify(obj));
    if (obj.isSelected) {
      switch (obj.field_type) {
        case "text":
          console.log(TAG, "APPLICATION_FORM2 rendering text");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_INPUT obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case "textarea":
          console.log(TAG, "APPLICATION_FORM2 rendering textarea");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_TEXTAREA obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case "email":
          console.log(TAG, "APPLICATION_FORM2 rendering email");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_EMAIL obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'radio':
          console.log(TAG, "APPLICATION_FORM2 rendering radio");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_RADIO obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );
        case 'date':
          console.log(TAG, "APPLICATION_FORM2 rendering date");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_DATE obj={obj} placeholder={obj.field_label} onChange={(e, obj) => props.onChange(e, obj)} />
            </div>
          );

        case 'file':
          console.log(TAG, "APPLICATION_FORM2 rendering file");
          return (
            <div className="col-md-6 col-lg-6">
              <FORM_OBJECT_FILE obj={obj} onChange={(e, obj) => props.onChange(e, obj)} onChangeFile={(obj, val) => props.onChangeFile(obj, val)} />
            </div>
          )
        default:
          return <></>
      }
    } else {
      return <></>;
    }
  }

  const APPLICATION_FORM = (props) => {
    let objIdx = -1;

    let groupedFields = groupObject();
    let admYearFields = groupedFields[0];
    let stuFields = groupedFields[1];
    let parentFields = groupedFields[2];
    let docFields = groupedFields[3];
    console.log(TAG, 'APPLICATION_FORM groupedFields: ' + JSON.stringify(groupedFields));
    //const { formObject } = props;

    let studentName = '-';
    let classFor = '-'
    if (enqDetails) {
      studentName = enqDetails.Student_name

      if (enqDetails.student_class_applied) {
        classFor = enqDetails.student_class_applied;
      }

    }

    const onChangeFile = (obj, value) => {
      console.log(TAG, 'APPLICATION_FORM onChangeFile(): value ' + value);

      obj.field_value = value;

    }

    const onChange = (e, obj) => {
      const { name, value, files } = e.target;
      console.log(TAG, 'APPLICATION_FORM onChange(): name ' + name);
      console.log(TAG, 'APPLICATION_FORM onChange(): value ' + value);
      // console.log(TAG, 'APPLICATION_FORM onChange(): file ' + files[0]);

      // verify if object is file
      if (obj.field_type == 'file') {
        obj.field_value = files[0];

        // upload to firebase and get url

      } else {
        obj.field_value = value;
      }

      // update form object
      // let newFormObject = JSON.parse(JSON.stringify(formObject));

      // setFormObject(newFormObject);

      // see if form object was updated
      console.log(TAG, 'APPLICATION_FORM onChange(): updated form object ' + JSON.stringify(formObject));
    }

    let curDt = new Date();
    let curYr = curDt.getFullYear();
    let nextYr = curYr + 1;

    return (
      <>
        <div
          className="right-section"
          style={{
            position: "relative",
            // left: "400px",
            width: "100%",
            padding: "50px",
            // marginLeft: "50px",
            borderRadius: "10px",
          }}
        >

          <div style={{ display: "flex", flexDirection: 'column', gap: "10px" }}>
            {/* admission for */}
            <div>
              <div className="ind-inp">
                <h4>Admission for {curYr} - {nextYr} <small>[{studentName} (Class {classFor})]</small></h4>
              </div>

              <div className="row">
                {admYearFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={(e, obj) => onChange(e, obj)} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>

            {/* Student details */}
            <div>
              <div className="ind-inp">
                <h4>Student details</h4>
              </div>

              <div className="row">
                {stuFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );
                  return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>


            {/* Student information */}
            <div>
              <div className="ind-inp">
                <h4>Parent's / Guardian's information</h4>
              </div>

              <div className="row">
                {parentFields.map((obj, index) => {
                  // let formObj = null;
                  console.log(
                    TAG,
                    "APPLICATION_FORM2 obj field_type " +
                    JSON.stringify(obj.field_type)
                  );

                  return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                })}
              </div>
            </div>


            {/* Parent information */}
            <div>
              <div className="ind-inp">
                <h4>Documents to upload</h4>
              </div>

              {docFields && docFields.length ?
                <div className="row">
                  {docFields.map((obj, index) => {
                    // let formObj = null;
                    console.log(
                      TAG,
                      "APPLICATION_FORM2 obj field_type " +
                      JSON.stringify(obj.field_type)
                    );

                    return <FORM_FIELD obj={obj} onChange={onChange} onChangeFile={(obj, val) => onChangeFile(obj, val)} />
                  })}
                </div>
                :
                <>
                  <p>You have not selected any document fields!</p>
                </>
              }
            </div>

            <div
              className='p-3'>
              <Button onClick={onSaveClicked}
                variant='contained' mode='contained' className='btn btn-primary'>Save</Button>
            </div>
          </div>

        </div>
      </>
    );
  };

  const formSetupHandler = async () => {
    let token = localStorage.getItem("applier.couns.token");
    const config = {
      headers: { Authorization: `${token}` },
    };

    const body = {
      school_id: schoolId,
      enquiry_id: enqId
    }

    const response = await axios.post(GETFORMURL, body, config);
    console.log(TAG, "formSetupHandler() response " + JSON.stringify(response));

    const data3 = (response.data && response.data.data) ? response.data.data.field_obj : null;
    const enq = (response.data && response.data.enquiry_obj) ? response.data.enquiry_obj : null;
    if (data3) {

      setFormObject(data3);

      // update object with pre-filled data
    }

    if (enq) {
      setEnqDetails(enq);
    }

  };

  const onSaveClicked = async () => {
    if (enqId) {

      // validate the form object
      let isValid = true;
      let emptyCount = 0;

      // any item empty should be invalid
      if (formObject && formObject.fields && formObject.fields.length) {
        formObject.fields.map((obj, idx) => {
          if (obj.isSelected && !obj.field_value)
            emptyCount++;
        })
      } else {
        isValid = false;
      }

      if (emptyCount > 0) {
        isValid = false;
      }

      if (isValid) {

        const body = {
          enquiry_id: enqId,
          admission_obj: formObject,
          admission_status: 'APPLICATION',
          payment_verify_url: ''
        }

        const resp = await COUNSELLORAPI.postSaveAdmission1(body);
        console.log(TAG, 'onSaveClicked(): resp ' + JSON.stringify(resp));

        Swal.fire({
          title: 'Success',
          text: 'Application saved successfully..',
          icon: 'success',
          confirmButtonText: 'OK'
        })

        let path = "/application-submitted";
        navigate(path);

      } else {

        Swal.fire({
          title: 'Invalid input',
          text: 'Please check if all fields are entered..',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

    } else {
      // sweetalert
      console.log(TAG, 'onSaveClicked: empty values')

      MySwal.fire({
        title: 'Error',
        text: 'Please enter required fields..',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        // handleClose();


      })

      return false;
    }

  }

  const getEnquiryDetails = async () => {
    const enqDetails = await INSTITUTEAPI.getLeadDetails(enqId);
    console.log(TAG, 'getEnquiryDetails enqDetails: ' + JSON.stringify(enqDetails));
    setEnqDetails(null);
  }

  useEffect(() => {
    formSetupHandler();
    // getEnquiryDetails(enqId);
  }, [])

  const SplitScreen = () => {
    return (
      <div className="w-100 d-flex flex-row justify-content-between">
        <div className="w-50 d-flex flex-row justify-content-center align-items-center"
          style={{
            backgroundImage: 'url("/assets/img/enquiry2.gif")',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',

          }}>
          <div className="">
            <img src={logo} className="w-100" />
          </div>
        </div>

        <div className="w-50">
          <APPLICATION_FORM />
        </div>
      </div>
    )
  }

  return (
    <div>
      <SplitScreen />



    </div>



  )
}

export default EnquiryForm