import React, { useState } from "react";

const DateTimePicker = ({ onChange }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (event) => {
        setSelectedDate(event.target.valueAsDate);
        onChange(event.target.valueAsDate);
    };

    return (
        <div>
            <input
                type="datetime-local"
                value={selectedDate.toISOString().substr(0, 16)}
                onChange={handleDateChange}
            />
        </div>
    );
};

export default DateTimePicker;
