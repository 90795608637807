import React, { useState, useEffect } from 'react';
import CounsBar from './components/charts/CounsBar';
import VerticalChartCouns from './components/charts/VerticalChartCouns';
import Sidebar from './CounsellorSidebar';
import { useNavigate } from "react-router-dom";
import COUNSELLORAPI from '../http/counsellor';

const TAG = 'CounsellorDashboard.jsx';
const LEAD_STATUS_STATS = {
    series: [{
        data: [30, 130, 48, 25, 40, 80]
    }],
    options: {
        chart: {
            type: 'bar',
            height: 380
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f'],
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: ['Cold', 'Warm', 'Busy', 'Calldrop', 'Closed', 'Untouched'],
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        title: {
            text: '',
            align: 'center',
            floating: true
        },
        subtitle: {
            text: '',
            align: 'center',
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    }

};
const CAMPAIGN_LEADS = {
    series: [{
        name: 'Leads',
        data: [44, 55, 41, 64, 22, 43, 21]
    }, {
        name: 'Admissions',
        data: [30, 32, 33, 52, 13, 35, 15]
    }],
    options: {
        chart: {
            type: 'bar',
            height: 430
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            },
            labels: ['Leads', 'Admissions']
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: ['School',
                'Facebook',
                'LinkedIn',
                'Twitter',
                'Instagram',
                'Email',
                'Whats app'],
        },
        labels: ['Leads', 'Admissions']
    },
    labels: ['Leads', 'Admissions']
}

const CounsellorDashboard = () => {

    const [leadStatuses, setLeadStatuses] = useState(LEAD_STATUS_STATS);
    const [campaignLeads, setCampaignLeads] = useState(CAMPAIGN_LEADS);

    const navigate = useNavigate();
    const _doLogout = () => {
        // remove token
        localStorage.removeItem("applier.couns.token");

        // remove role
        localStorage.removeItem("applier.role");
        let path = "/login";
        navigate(path);
    };

    const _setLeadStatuses = (leadStatuses) => {
        let sLikeChart = JSON.parse(JSON.stringify(LEAD_STATUS_STATS)); // copy back

        console.log(TAG, '_setLeadStatuses stats from api ', JSON.stringify(leadStatuses));
        if(leadStatuses) {
            // leads data
            let data5 = [];
            let categories5 = []; // standards

            // each counsellor
            const keys = Object.keys(leadStatuses);
            // console.log(TAG, '_setLeadStatuses keys ', keys);

            keys.map((key) => {
                let val = leadStatuses[key];
                val = val.toString();
                val = val.replaceAll('_', ' ');
                let key2 = key.toString();
                key2 = key2.replaceAll('_', ' ');
                console.log(TAG, '_setLeadStatuses key ', key2);
                console.log(TAG, '_setLeadStatuses val ', val);
    
                data5.push(val);
                categories5.push(key2);
                    
            })
            
            // update object
            sLikeChart.series[0].data = data5;
            sLikeChart.options.xaxis.categories = categories5;
        }

        // update
        setLeadStatuses(sLikeChart);
    }
    

    const _getLeadStatuses = async () => {
        const resp = await COUNSELLORAPI.getLeadStatuses();
        
        console.log(TAG, '_getLeadStatuses resp ', JSON.stringify(resp));
        _setLeadStatuses(resp);
    }

    const _setCampaignLeads = (leads) => {
        let campChart = JSON.parse(JSON.stringify(CAMPAIGN_LEADS));
        console.log(TAG, '_setCampaignLeads stats from api ', JSON.stringify(leads));

        if(leads) {
            let data1 = [];
            let data2 = [];
            let categories = [];

            
            // each counsellor
            const keys = Object.keys(leads);
            // console.log(TAG, '_setLeadStatuses keys ', keys);

            keys.map((key) => {
                let val = leads[key];
                let key2 = key.toString();
                key2 = key2.replaceAll('_', ' ');
                console.log(TAG, '_setLeadStatuses key ', key2);
                console.log(TAG, '_setLeadStatuses val ', JSON.stringify(val));
    
                categories.push(key2);
                data1.push(val.lead_count);
                data2.push(val.admissions_count);
            })

            console.log(TAG, '_setCampaignLeads() data1 ', JSON.stringify(data1));
            console.log(TAG, '_setCampaignLeads() data2 ', JSON.stringify(data2));


            // update object
            campChart.options.xaxis.categories = categories;
            campChart.series[0].data = data1;
            campChart.series[1].data = data2;
            
        }

        // update
        setCampaignLeads(campChart);
    }

    const _getCampaignLeads = async () => {
        const resp = await COUNSELLORAPI.getCampaignLeads();
        
        console.log(TAG, '_getCampaignLeads resp ', JSON.stringify(resp));
        _setCampaignLeads(resp);
        
    }

    useEffect(() => {

        _getLeadStatuses();
        _getCampaignLeads();
        return () => {

        }
    }, [])

    return (
        <div>
            <div class="main-wrapper">

                <div class="header">
                    <Sidebar />
                    <div class="header-left">
                        <a href="index.html" class="logo">
                            <img src="assets/img/schooler-applier.png" alt="Logo" />
                        </a>
                        <a href="index.html" class="logo logo-small">
                            <img src="assets/img/logo-small.png" alt="Logo" width="30" height="30" />
                        </a>
                    </div>
                    <div class="menu-toggle">
                        <a href="javascript:void(0);" id="toggle_btn">
                            <i class="fas fa-bars"></i>
                        </a>
                    </div>

                    <div class="top-nav-search">
                        <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <button class="btn" type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </div>
                    <a class="mobile_btn" id="mobile_btn">
                        <i class="fas fa-bars"></i>
                    </a>

                    <ul class="nav user-menu">
                        <li class="nav-item dropdown noti-dropdown language-drop me-2">
                            <a href="#" class="dropdown-toggle nav-link header-nav-list" data-bs-toggle="dropdown">
                                <img src="assets/img/icons/header-icon-01.svg" alt="" />
                            </a>
                            <div class="dropdown-menu ">
                                <div class="noti-content">
                                    <div>
                                        <a class="dropdown-item" href="javascript:;"><i
                                            class="flag flag-lr me-2"></i>English</a>
                                        <a class="dropdown-item" href="javascript:;"><i
                                            class="flag flag-bl me-2"></i>Francais</a>
                                        <a class="dropdown-item" href="javascript:;"><i class="flag flag-cn me-2"></i>Turkce</a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item dropdown noti-dropdown me-2">
                            <a href="#" class="dropdown-toggle nav-link header-nav-list" data-bs-toggle="dropdown">
                                <img src="assets/img/icons/header-icon-05.svg" alt="" />
                            </a>
                            <div class="dropdown-menu notifications">
                                <div class="topnav-dropdown-header">
                                    <span class="notification-title">Notifications</span>
                                    <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                                </div>
                                <div class="noti-content">
                                    <ul class="notification-list">
                                        <li class="notification-message">
                                            <a href="#">
                                                <div class="media d-flex">
                                                    <span class="avatar avatar-sm flex-shrink-0">
                                                        <img class="avatar-img rounded-circle" alt="User Image"
                                                            src="assets/img/profiles/avatar-02.jpg" />
                                                    </span>
                                                    <div class="media-body flex-grow-1">
                                                        <p class="noti-details"><span class="noti-title">Carlson Tech</span> has
                                                            approved <span class="noti-title">your estimate</span></p>
                                                        <p class="noti-time"><span class="notification-time">4 mins ago</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="notification-message">
                                            <a href="#">
                                                <div class="media d-flex">
                                                    <span class="avatar avatar-sm flex-shrink-0">
                                                        <img class="avatar-img rounded-circle" alt="User Image"
                                                            src="assets/img/profiles/avatar-11.jpg" />
                                                    </span>
                                                    <div class="media-body flex-grow-1">
                                                        <p class="noti-details"><span class="noti-title">International Software
                                                            Inc</span> has sent you a invoice in the amount of <span
                                                                class="noti-title">$218</span></p>
                                                        <p class="noti-time"><span class="notification-time">6 mins ago</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="notification-message">
                                            <a href="#">
                                                <div class="media d-flex">
                                                    <span class="avatar avatar-sm flex-shrink-0">
                                                        <img class="avatar-img rounded-circle" alt="User Image"
                                                            src="assets/img/profiles/avatar-17.jpg" />
                                                    </span>
                                                    <div class="media-body flex-grow-1">
                                                        <p class="noti-details"><span class="noti-title">John Hendry</span> sent
                                                            a cancellation request <span class="noti-title">Apple iPhone
                                                                XR</span></p>
                                                        <p class="noti-time"><span class="notification-time">8 mins ago</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="notification-message">
                                            <a href="#">
                                                <div class="media d-flex">
                                                    <span class="avatar avatar-sm flex-shrink-0">
                                                        <img class="avatar-img rounded-circle" alt="User Image"
                                                            src="assets/img/profiles/avatar-13.jpg" />
                                                    </span>
                                                    <div class="media-body flex-grow-1">
                                                        <p class="noti-details"><span class="noti-title">Mercury Software
                                                            Inc</span> added a new product <span class="noti-title">Apple
                                                                MacBook Pro</span></p>
                                                        <p class="noti-time"><span class="notification-time">12 mins ago</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="topnav-dropdown-footer">
                                    <a href="#">View all Notifications</a>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item zoom-screen me-2">
                            <a href="#" class="nav-link header-nav-list win-maximize">
                                <img src="assets/img/icons/header-icon-04.svg" alt="" />
                            </a>
                        </li>

                        <li class="nav-item dropdown has-arrow new-user-menus">
                            <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                                <span class="user-img">
                                    <img class="rounded-circle" src="assets/img/profiles/avatar-01.jpg" width="31"
                                        alt="Soeng Souy" />
                                    <div class="user-text">
                                        <h6>Soeng Souy</h6>
                                        <p class="text-muted mb-0">Administrator</p>
                                    </div>
                                </span>
                            </a>
                            <div class="dropdown-menu">
                                <div class="user-header">
                                    <div class="avatar avatar-sm">
                                        <img src="assets/img/profiles/avatar-01.jpg" alt="User Image"
                                            class="avatar-img rounded-circle" />
                                    </div>
                                    <div class="user-text">
                                        <h6>Soeng Souy</h6>
                                        <p class="text-muted mb-0">Administrator</p>
                                    </div>
                                </div>
                                <a class="dropdown-item" href="profile.html">My Profile</a>
                                <a class="dropdown-item" href="inbox.html">Inbox</a>
                                <a class="dropdown-item" href="javascript:void(0)" onClick={_doLogout}>Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="page-wrapper">
                    <div class="content container-fluid">

                        <div class="page-header">
                            <div class="row">
                                <div class="col">
                                    <h3 class="page-title">Counsellor Dashboard</h3>
                                    {/* <ul class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                                        <li class="breadcrumb-item">leads</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-title">Leads </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="chartjs-wrapper-demo">
                                            <CounsBar 
                                            campaignLeads={campaignLeads}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="card-title">Lead Status</div>
                                    </div>
                                    <div class="card-body">
                                        <div class="chartjs-wrapper-demo">
                                            <VerticalChartCouns 
                                            stats={leadStatuses}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="col-md-6 w-100 h-50 d-none">
                            <div class="card">
                                <div class="card-header">
                                    <div class="card-title">Total Admissions Overview</div>
                                </div>
                                <div class="card-body">
                                    <div class="chartjs-wrapper-demo">
                                        <canvas id="chartLine1" class="h-300"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>

            </div>

        </div>
    )
}

export default CounsellorDashboard