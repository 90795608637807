import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Constants from '../resource/Constants';

const ACTIVATEAPI = Constants.Application.APPLIER_END_URL + "/school-activate/"

const SchoolActivate = () => {
  const [complete, setComplete] = useState(false);
  const search = useLocation().search
  const query = new URLSearchParams(useLocation().search);
  const eml = new URLSearchParams(search).get('email');

  const _doConfirm = async () => {
    // call the activation API
    const response = await axios.put(ACTIVATEAPI, {
      "school_email": eml
    });
    console.log('SuccessPage.jsx _doConfirm(): response ' + JSON.stringify(response));
    try {

      setComplete(true)
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    _doConfirm()
  }, [])



  if (!complete) {
    return (
      <div
        class="d-flex flex-row justify-content-center align-items-center vh-100">
        <h4>Loading...</h4>
      </div>
    )
  }

  return (
    <>
      <div class="card" style={{ textAlign: 'center', position: 'absolute', left: '40%', top: '20%', width: "400px", padding: '30px' }}>
        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
          <i class="checkmark"
            style={{ left: '120px', top: '0px', color: 'green', position: 'absolute', fontSize: '10rem' }}
          >✓</i>
        </div>
        <h1>Success</h1>

        <Link to='/login'>Click here to Login</Link>
      </div>
    </>
  )
}

export default SchoolActivate;