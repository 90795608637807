import React from 'react'
import Sidebar from './Sidebar'

const OnlineApplications = () => {
  return (
    <div>
      <Sidebar />
        <div class="page-wrapper">
<div class="content container-fluid">

<div class="page-header">
<div class="row">
<div class="col-sm-12">
<div class="page-sub-header">
<h3 class="page-title">Online Applications</h3>
<ul class="breadcrumb">

</ul>
</div>
</div>
</div>
</div>

<div class="student-group-form">
<div class="row">
<div class="col-lg-3 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by ID ..." />
</div>
</div>
<div class="col-lg-3 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by Name ..." />
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="form-group">
<input type="text" class="form-control" placeholder="Search by Phone ..." />
</div>
</div>
<div class="col-lg-2">
<div class="search-student-btn">
<button type="btn" class="btn btn-primary">Search</button>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-sm-12">
<div class="card card-table comman-shadow">
<div class="card-body">

<div class="page-header">
<div class="row align-items-center">
<div class="col">

</div>
<div class="col-auto text-end float-end ms-auto download-grp">


</div>
</div>
</div>

<div class="table-responsive w-100">
<table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead class="student-thread">
<tr>
<th>
<div class="form-check check-tables">
<input class="form-check-input" type="checkbox" value="something"/>
</div>
</th>
<th>Registered Name</th>
<th>Application Number</th>
<th>DOB</th>
<th>Gender</th>
<th>Registered Mobile</th>
<th>Form Status</th>
<th>Payment Status</th>
<th>Payment Method</th>

<th class="text-end">Action</th>
</tr>
</thead>
<tbody>


<tr>


</tr>
<tr>
<td>
<div class="form-check check-tables">
<input class="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 class="table-avatar">
<a href="IndividualStudent.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Ajay Kumar</a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>
<td>Male</td>
<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>
<td>Cash</td>
<td class="text-end">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  style={{backgroundColor: "white", border:" none"}}>
            <i class="fa fa-gear" style={{fontSize:"24px", color: "black"}}></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Edit</a>
          <a class="dropdown-item" href="#">Print</a>
          <a class="dropdown-item" href="#">View</a>
          <a class="dropdown-item" href="#">Payment</a>
          <a class="dropdown-item" href="#">Mark Offline Payment</a>
        </div>
      </div>
</td>
</tr>
<tr>
<td>
<div class="form-check check-tables">
<input class="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 class="table-avatar">
<a href="IndividualStudent.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/profiles/avatar-02.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Bharat</a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>
<td>Male</td>
<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>
<td>Cash</td>
<td class="text-end">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        style={{backgroundColor: "white", border: "none"}}
        >
            <i class="fa fa-gear" style={{fontSize:"24px", color:"black"}}></i>
            
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Edit</a>
          <a class="dropdown-item" href="#">Print</a>
          <a class="dropdown-item" href="#">View</a>
          <a class="dropdown-item" href="#">Payment</a>
          <a class="dropdown-item" href="#">Mark Offline Payment</a>
        </div>
      </div>
</td>
</tr>
<tr>
<td>
<div class="form-check check-tables">
<input class="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 class="table-avatar">
<a href="IndividualStudent.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/profiles/avatar-04.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Mukesh </a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>
<td>Male</td>
<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>
<td>Cash</td>
<td class="text-end">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        style={{backgroundColor: "white", border:" none"}}
        >
            <i class="fa fa-gear" style={{fontSize:"24px", color:" black"}}></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Edit</a>
          <a class="dropdown-item" href="#">Print</a>
          <a class="dropdown-item" href="#">View</a>
          <a class="dropdown-item" href="#">Payment</a>
          <a class="dropdown-item" href="#">Mark Offline Payment</a>
        </div>
      </div>
</td>
</tr>
<tr>
<td>
<div class="form-check check-tables">
<input class="form-check-input" type="checkbox" value="something" />
</div>
</td>

<td>
<h2 class="table-avatar">
<a href="IndividualStudent.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
<a href="IndividualStudent.html">Vinay</a>
</h2>
</td>
<td>9 B</td>
<td>03/03/2003</td>
<td>Male</td>
<td>701 753 3810</td>
<td>Completed</td>
<td>Paid</td>
<td>Cash</td>
<td class="text-end">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        style={{backgroundColor: "white", border:" none"}}
        >
            <i class="fa fa-gear" style={{fontSize:"24px", color:" black"}}></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Edit</a>
          <a class="dropdown-item" href="#">Print</a>
          <a class="dropdown-item" href="#">View</a>
          <a class="dropdown-item" href="#">Payment</a>
          <a class="dropdown-item" href="#">Mark Offline Payment</a>
        </div>
      </div>
</td>
</tr>





</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>



</div>

    </div>
  )
}

export default OnlineApplications